import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Stack, Typography, Icon, Space, Button, Message, Row, Col, Radio } from 'witmeg-ui-system';
import { getCurrencyFormat } from '../themes/default/utilities/helper';
import IMAGE_PLACEHOLDER from '../assets/Images/image-placeholder.png';
import '../themes/default/WidgetCompoentens/common/common.css'
import { updateRetailCart, fetchCartData } from '../redux/retailDataSlice';
export default function Cart() {
    const dispatch = useDispatch();
    const history = useHistory()
    const retailDataState = useSelector((state) => state.retailData);
    const settingsState = useSelector((state) => state.companyData.settings)
    const currencyFormat = getCurrencyFormat("GBP");

    // const memoizedCartArray = useMemo(() => {
    //     return retailDataState.cart || []
    // }, [retailDataState]);

    useEffect(() => {
        if (Object.keys(settingsState).length > 0) {
            dispatch(fetchCartData(settingsState))
        }

    }, [settingsState])

    const navigateToPaymentDetails = () => {
        history.push('/checkout')
    }


    const onUpdateCartItemQuantity = async (i, opt) => {
        const updatedCart = await retailDataState.cart.map((item, index) => {
            return {
                ...item,
                quantity: i === index ?
                    opt === "plus" ?
                        Number(item.quantity + 1)
                        : item.quantity > 1 ? Number(item.quantity - 1) : 1
                    : item.quantity
            }
        })

        localStorage.setItem("retailCart", JSON.stringify(updatedCart))
        dispatch(updateRetailCart(updatedCart))
    }

    const deleteCartItem = async (item) => {
        const updatedCart = await retailDataState.cart.filter(r => r.ID !== item.ID)
        localStorage.setItem("retailCart", JSON.stringify(updatedCart))
        dispatch(updateRetailCart(updatedCart))
    }

    const isButtonsDisabled = false
    const disabledButtonStyles = {
        background: isButtonsDisabled ? '#f4f4f4' : '',
        cursor: 'pointer',
    };
    return (
        <div style={{ maxWidth: '1650px', margin: 'auto' }} className='eco-retailcart-summary'>
            <h2>Shopping Cart</h2>
            <table className="table ps-table--shopping-cart ps-table--responsive">
                <thead>
                    <tr>
                        <th>Product name</th>
                        <th>PRICE</th>
                        <th>QUANTITY</th>
                        <th>TOTAL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {retailDataState.cart ?
                        <>
                            {retailDataState.cart.map((item, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <div class="ps-product__thumbnail">
                                                    <img src={IMAGE_PLACEHOLDER} width="80" />
                                                </div>
                                                <div class="ps-product__content">
                                                    <a href={`/product/${item.ProductID}`}>{item.Name}</a>
                                                    <p>
                                                        <b>Item Code:</b> {item.productDetails.ProductRefNo}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>{item.productDetails.SellingPricesArray.SellingPrice}</p>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <div className="eco-product-addon-mins"
                                                    style={{ ...disabledButtonStyles }}
                                                    onClick={() => onUpdateCartItemQuantity(index, 'minus')}>
                                                    <Icon name="minus" size={16} />
                                                </div>
                                                <div className="eco-product-addon-count">
                                                    <input className="input-order-amount" value={item.quantity} readOnly />
                                                </div>
                                                <div className="eco-product-addon-plus"
                                                    style={{ ...disabledButtonStyles }}
                                                    onClick={() => onUpdateCartItemQuantity(index, 'plus')}>
                                                    <Icon name="plus" size={16} />
                                                </div>
                                            </div>
                                            {/* <p>{item.quantity}</p> */}
                                        </td>
                                        <td>
                                            <p>{Number(item.productDetails.SellingPricesArray.SellingPrice * item.quantity).toFixed(2)}</p>
                                        </td>
                                        <td>
                                            <div style={{ cursor: 'pointer' }} onClick={() => deleteCartItem(item)}>
                                                <Icon name="delete" size={16} color="red" />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                        : <>
                            <td>
                                <td colSpan={5}>No Items Added</td>
                            </td>
                        </>
                    }
                </tbody>
            </table>

            <Row justify="end">
                <Col xs={24} sm={24} md={12} lg={8}>
                    <div style={{ border: '1px solid #eee', padding: '8px' }}>
                        <Stack horizontalAlign="space_between" className="eco-mb-24" style={{ marginTop: '30px' }}>
                            <Typography type="title" level={5}>Order Type</Typography>
                        </Stack>
                        <hr />
                        <div>
                            <Radio value="card-payment" checked={true} >Delivery</Radio>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <div style={{ border: '1px solid #eee', padding: '8px' }}>
                        <Stack horizontalAlign="space_between" className="eco-mb-24" style={{ marginTop: '30px' }}>
                            <Typography type="title" level={5}>Sub total</Typography>
                            <Typography type="title" level={5} pattern="bold">
                                {retailDataState.cart.reduce((accumulator, currentItem) => {
                                    return accumulator + currentItem.productDetails.SellingPricesArray.SellingPrice * currentItem.quantity;
                                }, 0)}
                            </Typography>
                        </Stack>
                        <Stack horizontalAlign="space_between" className="eco-mb-24" style={{ marginTop: '30px' }}>
                            <Typography type="title" level={5}>Vat</Typography>
                            <Typography type="title" level={5} pattern="bold">-</Typography>
                        </Stack>
                        <Stack horizontalAlign="space_between" className="eco-mb-24" style={{ marginTop: '30px' }}>
                            <Typography type="title" level={5}>Total</Typography>
                            <Typography type="title" level={5} pattern="bold">
                                {retailDataState.cart.reduce((accumulator, currentItem) => {
                                    return accumulator + currentItem.productDetails.SellingPricesArray.SellingPrice * currentItem.quantity;
                                }, 0)}
                            </Typography>
                        </Stack>
                        <div>
                            <Button
                                fullWidth
                                className="proceed-payment-button"
                                category="icon-text"
                                icon="cash"
                                text="Proceed to Payment"
                                variant="primary"
                                color="white"
                                onClick={() => navigateToPaymentDetails()}
                                style={{ background: '#363B41' }} />
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )
}
