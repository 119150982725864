import React, { useLayoutEffect, useState } from 'react';
import * as _ from 'lodash';
import { Typography, Divider, Skeleton } from 'antd'
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment'
import Blog from "./default/blogs.jsx";
 
const { Title, Paragraph, Text, Link } = Typography;

export default class ResponsiveLocalStorageLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { listDataCat: [], listData: [], selectedTags: [], loading: false, loadingCat: false };

  }


  componentDidMount() {
 
    var url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

    axios.get(config.get('backendURL') + 'articles/byslug/' + url.replace('#', '') + '/' + config.get('companyID'))
      .then(response => {
        console.log(response)
        this.setState({
          loadingCat: true,
          listDataCat: response.data
        });
      })
      .catch((error) => {
        console.log(error);
      })

    axios.get(config.get('backendURL') + 'articles/bycomid/' + config.get('companyID'))
      .then(response => {
        console.log(response)
        this.setState({
          loading: true,
          listData: response.data,
          alltags: JSON.stringify(response.data.map((d) => d.tags.join(",")) + ",").substring(1).slice(0, -2)
        });
      })
      .catch((error) => {
        console.log(error);
      })

    this._isMounted = true;

  }

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    //console.log('You are interested in: ', nextSelectedTags);
    var url = '';
    if (nextSelectedTags.length > 0) {
      url = config.get('backendURL') + 'articles/bytags/' + nextSelectedTags + '/' + config.get('companyID')
    } else {
      url = config.get('backendURL') + 'articles/bycomid/' + config.get('companyID')
    }
    axios.get(url)
      .then(response => {
        //console.log(response)
        this.setState({
          loading: true,
          listData: response.data
        });


      })
      .catch((error) => {
        console.log(error);
      })

    this.setState({ selectedTags: nextSelectedTags });
  }

  render() {
    const { listDataCat, listData, selectedTags, alltags, loading, loadingCat } = this.state;
    return (
      <div>
       <Row>
                  <Col sm={12} >&nbsp;
                    </Col>
                  <Col sm={2} >&nbsp;
                    </Col>
                  <Col sm={8} >
                    {
                      loadingCat ? (
                        <Typography>
                          <Title level={2}>{listDataCat.title}</Title>
                          <Text type="secondary"><b>{listDataCat.author.toUpperCase()} .  {moment(listDataCat.createdAt).format("LL")}</b></Text>
                          <Paragraph  >
                            <img
                              width={272}
                              className="articleimg"
                              alt=""
                              src={'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + listDataCat.featuredimage}
                            /> <div dangerouslySetInnerHTML={{ __html: listDataCat.content }} />
                          </Paragraph>
                          <Divider />
                        </Typography>
                      ) : (
                          <Skeleton />
                        )
                    }
                  </Col>
                  <Col sm={2} >&nbsp;
                    </Col>

                  <Col sm={2} >&nbsp;
                    </Col>

                  <Col sm={8} >
                    <Typography>
                      <Title level={3}>LATEST POSTS</Title>
                    </Typography>

                    <Blog params={{ listData: this.state.listData, selectedTags: this.state.selectedTags, alltags: this.state.alltags, loading: this.state.loading }} handleChange={this.handleChange} />
                  </Col>
                  <Col sm={2} >&nbsp;
          </Col>
                </Row>

      </div>
    );
  }
}



