import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Stack, Typography, Icon, Space, Button, Message, Row, Col } from 'witmeg-ui-system';
import { updateRetailCart } from '../../../../redux/retailDataSlice'
import { getCurrencyFormat, isECommerceProcessPath } from '../../utilities/helper';
import IMAGE_PLACEHOLDER from '../../../../assets/Images/image-placeholder.png';
export default function RetailOrderSummary() {
    const dispatch = useDispatch();
    const history = useHistory()
    const retailDataState = useSelector((state) => state.retailData);

    const currencyFormat = getCurrencyFormat("GBP");



    return (
        <div>
            {retailDataState.cart.map((item, index) => {
                return (
                    <div className="eco-mb-20 " key={`item_${item.ID}`} style={{width:'100%', display:'flex'}}>

                        <img src={IMAGE_PLACEHOLDER} alt={item.Name} style={{ width: '64px', marginRight: '16px' }} />

                        <div style={{width:'100%'}}>
                            <Stack direction="column" verticalAlign="v_start" className="eco-product-addon-row">

                                <Typography type="text" className="truncate-text eco-product-title">
                                    {item.Name}
                                </Typography>

                            </Stack>
                            <Row>
                                <Col span={18}>
                                    <Typography type="text" pattern="bold" className="eco-mb-12 eco-accordion-addon-price">
                                        {item.productDetails.CostPricesArray ? currencyFormat(item.productDetails.CostPricesArray[0]?.CostPrice * item.quantity || 0) : ''}
                                    </Typography>
                                </Col>
                                <Col span={6}>
                                    <Typography type="text" className="eco-mb-12 eco-accordion-addon-price">
                                        Qty: {item.quantity}
                                    </Typography>
                                </Col>
                            </Row>
                        </div>




                    </div>
                )
            })}
        </div>
    )
}
