import React from 'react';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Form, Button, Input } from 'antd';
import { Table } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

const { loaded } = React
const ProducBoxMartFury = (props) => (
  <>
    
 
    <div className='col-md-6 col-lg-4 col-xl-3'>

<div className='prd-item'>
  <a className="prd-item-link" key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID}>
   
   
  {
            props.params.product.OnLineImages.PrimaryImages !== null ?
              <div style={{ width: 'auto' }}>
                <a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} title="Quick View" >
                  <img src={props.params.product.OnLineImages.PrimaryImages.Main}  className="img-fluid"  alt={props.params.product.Name} /></a>
                <input type="hidden" id={'prodprimimage' + props.params.product.ProductID} value={props.params.product.OnLineImages.PrimaryImages.Main} />
              </div>
              : <div><a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} title="Quick View" >
                <img src="/images/no-image.jpg" className="img-fluid"  alt={props.params.product.Name} /></a>
                <input type="hidden" id={'prodprimimage' + props.params.product.ProductID} value='' /></div>
          }
    
    <div className="prd-item-text">
      <br/>
       <h5>{props.params.product.Name}</h5> 
      
       
      {
                props.params.product.Discount != 0 ?
                  <><del>{localStorage.getItem('ecurrencyCode')}{(parseInt(props.params.product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(props.params.product.Discount)}</del></>
                  : ''
              }

              <input type="hidden" id={'prodprice' + props.params.product.ProductID} value={props.params.product.SellingPricesArray.SellingPrice} />


     



    </div>
  </a>

        
                      {props.params.product.IsOutOfStock ? ( <> <span className='out-of-stock'>Out of Stock</span>
                      <button className='add-btn' onClick={props.onNotify.bind(this, props.params.product.ProductID, props.params.product.Name, props.params.product.MaxOnlineOrderQty)} id={props.params.product.ProductID}>
                        Notify
                      </button>
  <Modal
                  show={props.params.visible}
                  onHide={props.handleCancel}
                  centered
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Notify
          </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      onFinish={props.onFinish}
                      name="basic"
                      initialValues={{ remember: true }}
                    >
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[{ required: true, type: 'email' }]}
                      >
                        <Input placeholder="Email Address" />
                      </Form.Item>
                      <Form.Item  >
                        <Input name="prdt" type="hidden" id="prdt" value={props.params.product.Name} />
                        <Input name="prdtURL" type="hidden" id="prdtURL" value={window.location.href} />
                        <Button type="primary" htmlType="submit" danger >
                          Submit
                      </Button>
                      </Form.Item>
                    </Form>

                  </Modal.Body>
                </Modal></>) : (

<>

 
{sessionStorage.getItem('loggedin') === "true" ? (<>
<div className="prd-item-text">
      
      <h6 className='prd-price'>{localStorage.getItem('ecurrencyCode')}{
                String((props.params.product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2))
              } </h6></div>                
  <div className="prd-count-inner">
  <span className="qty-sub">
    <button>
      <i className="fas fa-minus" onClick={props.onQtyReduce.bind(this, props.params.product.ProductID)}></i>
    </button>
  </span>
  <span className="qty-no">
    <input type="number"  min={1} max={props.params.product.MaxOnlineOrderQty === 0 ? 10000 : props.params.product.MaxOnlineOrderQty} precision={0} id={'prodqty' + props.params.product.ProductID} value={1} snap placeholder={1}/>
  </span>
  <span className="qty-add">
    <button>
    <i className="fas fa-plus" onClick={props.onQtyIncrese.bind(this, props.params.product.ProductID)}></i> 
    </button>
  </span>
  <button onClick={props.onAddItem.bind(this, 'add', props.params.product.ProductID, props.params.product.Name, props.params.product.MaxOnlineOrderQty, props.params.product.ProductCategoryID, props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferQty) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferPrice) : '')} className='add-btn'>
    Add
  </button>
</div></>):('')
   }

          </>        )}

</div>

          


</div>

 
  </>


);
export default ProducBoxMartFury;
