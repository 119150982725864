import Axios from "axios";
import apiEndpointPaths from "./apiEndpointPaths";
import { APIError, errorLogger } from "./errorHandlers";
import { generateTokenForEmailRegistry } from "./tokenGenerators";

export const sendEmail = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForEmailRegistry();

    const apiReqUrl = apiEndpointPaths.emailRegistry.sendEmail;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}