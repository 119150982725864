import React from 'react';
import { Button, Popup, Stack, Typography } from 'witmeg-ui-system';
import "@fontsource/plus-jakarta-sans";
import "@fontsource/inter"; 
const ConfirmContinueShopping = ({ visibility, onPopupClose }) => {

  return (
    <Popup
      className="confirm-cartitem-popup"
      modalVisible={visibility}
      modalTitle=""
      mask={true}
      centered
      onCancel={onPopupClose} 
      closable={false}>
        <div className='confirm-cartitem-icon eco-mt-12 eco-mb-24'></div>
        <Typography type="text" pattern="bold" className="eco-mb-20">
        <h2>You have <strong>30 minutes</strong> to place the order. The items added to cart  will be cleared if the order has not been placed within this time.</h2>
        </Typography>
          <Button variant="primary" className="eco-mb-24" onClick={onPopupClose}>
            Continue Shopping
          </Button>
          <Button variant="outline" className="eco-mb-16" onClick={()=>{}}>
            View Cart
          </Button>
    </Popup>
  );
}

export default ConfirmContinueShopping;