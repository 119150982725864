import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { async, Card, Icon, Stack, Typography } from "witmeg-ui-system";

export const Maps = (props) => {
  
  const [mapData, setMapData] = useState([])

  const API_KEY = "AIzaSyClktNC7hK4mzmSUmloOhSQ7rY3L0XvzH4";

  useEffect(()=>{
    axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    }).then(async (response) => {
      await axios.post(`${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
        {
          ID: props.params.ID,
          PageID: "",
          CompanyID: "",
          Start: 0,
          Limit: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      ).then(async (result) => {
        console.log("MapData==>> ", result.data.Result[0]?.ItemData);
        setMapData(JSON.parse(result.data.Result[0]?.ItemData))
      });
    })
  }, [])

  console.log("MapData==>> ", mapData)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });

  const renderMap = () => {
    return (
            <>
        {/* <Card
          className="map-widget"
          rounded="small"
          shadow="no_shadow"
          content={ 
          mapData && */}
            <div className={props.params.className} style={{ height: '100%'}}>
              <GoogleMap
                id="map-with-address-search"
                mapContainerStyle={{
                  height: "100%",
                  // width: "100%",
                }}
                zoom={13}
                center={{ lat: mapData.lat, lng: mapData.lng }}
              >
                <Marker
                  onLoad={() => {}}
                  position={{ lat: mapData.lat, lng: mapData.lng }}
                />
              </GoogleMap>
              {/* }>
          </Card> */}
          </div>
          </>
            )
    }
  return isLoaded ? renderMap() : "";
};
