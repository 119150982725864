const RequiredField = (_value) => {
  return _value && _value !== '' ? false : true;
};

const IsEmail = (_value) => {
  // const _mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const _mailFormat = /^[a-z]+[\w._\-]+@{1}[a-z]+[a-z\-.]*[.]{1}[a-z]{2,}$/;
  return _value.match(_mailFormat) ? false : true;
};

const IsPhoneNo = (_value) => {
  const _phoneNoFormat = /^\+?[0-9]+$/i;
  return _value.match(_phoneNoFormat) ? false : true;
}

const IsNumberOnly = (_value) => {
  const _numberFormat = /^[0-9]*$/;
  return _numberFormat.test(String(_value)) ? false : true;
};

const IsTextOnly = (_value) => {
  const _textFormat = /^[a-z]*$/i;
  return _value.match(_textFormat) ? false : true;
}

const ValidLength = ({ textLength }) => (_value) => {
  return String(_value).length === textLength ? false : true;
};

const IsPostcode = (_value) => {
  const _textFormat = /[^ a-z0-9-]/i;
  return _value.match(_textFormat) ? true : false;
}

const HasSelected = (_value) => {
  return (_value && _value.value !== "") ? false : true;
}

const ValidateData = (_type, _value, _additional) => {
  switch(_type){
    case VALIDATION_TYPES.REQUIRED_FIELD: {
      const hasError = RequiredField(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.IS_NUMBER_ONLY: {
      const hasError = IsNumberOnly(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.IS_EMAIL: {
      const hasError = IsEmail(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.IS_TEXT_ONLY: {
      const hasError = IsTextOnly(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.VALID_LENGTH: {
      const hasError = ValidateData(_value, _additional.length);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.HAS_SELECTED: {
      const hasError = HasSelected(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    };
    case VALIDATION_TYPES.IS_POSTCODE: {
      const hasError = IsPostcode(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType }; 
    }
    case VALIDATION_TYPES.IS_PHONE_NO: {
      const hasError = IsPhoneNo(_value);
      const errorType = hasError ? _type : null;
      return { hasError, errorType };
    }
  }
}

export const VALIDATION_TYPES = {
  REQUIRED_FIELD: 'REQUIRED_FIELD',
  IS_NUMBER_ONLY: 'IS_NUMBER_ONLY',
  IS_EMAIL: 'IS_EMAIL',
  IS_TEXT_ONLY: 'IS_TEXT_ONLY',
  VALID_LENGTH: 'VALID_LENGTH',
  HAS_SELECTED: 'HAS_SELECTED',
  IS_POSTCODE: 'IS_POSTCODE',
  IS_PHONE_NO: 'IS_PHONE_NO',
}

export default ValidateData;