import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Form, Button, Input, Select, Divider, Modal } from 'antd';
import config from 'react-global-configuration';
import AccountLeftMenu from "./account-left-menu";
import jwt from 'jsonwebtoken';
import { Redirect } from 'react-router-dom'

const { Option } = Select;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';
  }
  state = { visible: false };

  componentDidMount() {
    document.title = "My Account Info | " + config.get('companyPageTitle')
  }

  render() {
    const onFinish = values => {


      this.setState({ disabled: true });
      this.refs.btn.setAttribute("disabled", "disabled");

      var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
      //console.log(fullUserDetails)

      fullUserDetails.FirstName = values.FirstName
      fullUserDetails.LastName = values.LastName
      fullUserDetails.UserName = values.UserName

      if (values.Password)
        fullUserDetails.Password = values.Password


      localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

      const timestamp = Math.floor(Date.now() / 1000) + 1000;

      //console.log(fullUserDetails)


      var payload = {
        "aud": config.get('CustjwtAud'),
        "exp": timestamp,
        "iss": config.get('CustjwtIss'),
        "usertoken": sessionStorage.getItem('AccessToken')
      };


      var signOptions = {
        "algorithm": "RS256"
      };

      var privateKey = config.get('LoginprivateKey');
      var token = jwt.sign(payload, privateKey, signOptions);
      //    console.log(token);


      const requestUserDtlsOptions = {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify(fullUserDetails)
      };
      //console.log(config.get('API_URL_cust') + 'update/'+config.get('OrganisationID')+'/'+fullUserDetails.UserID)
      //   console.log(fullUserDetails)
      fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
        .then(response => response.json())
        .then(function (data) {
          //  console.log(data.Result);

          Modal.success({
            title: 'Account Details',
            content: 'Account has been updated',
            onOk() { window.location.href = "/account/my-info" },
          });

        });



    };

    if (!this.loggedIn) {
      return <Redirect to='/account/login' />;
    }
    return (
      <div className='account-pages-wrapper'>
        <Row>

          <Col sm={12} >
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/account/member">
                Your Account
              </Breadcrumb.Item> <Breadcrumb.Item >
                Edit Account
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>

            <AccountLeftMenu />
          </Col>

          <Col sm={8}>
            <div className="fade alert alert-light show">
              <Row className="justify-content-md-center">

                <Col sm={12}>
                  <Divider orientation="left"><h5>EDIT ACCOUNT</h5></Divider>

                  <Divider orientation="left"><h6>NAME</h6></Divider>

                  <Form layout="vertical"
                    name="register"
                    onFinish={onFinish} >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="FirstName"
                          label="First Name"
                          initialValue={JSON.parse(localStorage.getItem('fullUserDetails')).FirstName}
                          rules={[{ required: true, message: 'Please enter first name' }]}
                        >
                          <Input placeholder="Please enter first name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="LastName"
                          label="Last Name"
                          initialValue={JSON.parse(localStorage.getItem('fullUserDetails')).LastName}
                          rules={[{ required: true, message: 'Please enter last name' }]}
                        >
                          <Input placeholder="Please enter last name" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider orientation="left"><h6>EMAIL/LOG IN INFORMATION</h6></Divider>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="Email"
                          label="Email"
                          initialValue={JSON.parse(localStorage.getItem('fullUserDetails')).Email}
                          rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                        >
                          <Input disabled placeholder="Please enter email" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="UserName"
                          label="User Name"
                          initialValue={JSON.parse(localStorage.getItem('fullUserDetails')).UserName}
                          rules={[{ required: true, message: 'Please enter user name' }]}
                        >
                          <Input placeholder="Please enter user name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="Password"
                          label="Password"
                          rules={[{ message: 'Please enter password' }]}
                        >
                          <Input.Password placeholder="Please enter password" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Password} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="RePassword"
                          label="Confirm Password"
                          dependencies={['Password']}
                          rules={[{ message: 'Please enter a value for confirm password' },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('Password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('The two passwords that you entered do not match!');
                            },
                          }),]}
                        >
                          <Input.Password placeholder="Please enter confirm password" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Password} />
                        </Form.Item>
                      </Col>
                    </Row>


                    <Row gutter={16}>
                      {config.get('companyPageTitle')} is committed to respecting your privacy. By continuing you are accepting our  <a href="/pages/privacy-policy"> Terms and Conditions</a>, and our <a href="/pages/privacy-policy"> Privacy Policy</a>.
                    </Row>
                    <br />
                    <Form.Item  >
                      <Button type="primary" ref="btn" htmlType="submit" danger >
                        Update Profile
                      </Button>
                    </Form.Item>

                  </Form>




                </Col>
              </Row>

            </div>

          </Col>



        </Row>
      </div>
    )
  }
}

export default Registration;