export async function addToRetailCart(product, itemCount) {
    let retailCart=JSON.parse(localStorage.getItem('retailCart'))
    if(retailCart) {

        console.log("retailCart", retailCart)

        if( retailCart.findIndex(r => r.ID ===product.ID)>-1) {
            const cartItems=retailCart.map(rc => {
                return {
                    ...rc,
                    quantity: rc.ID===product.ID?(rc.quantity+itemCount):rc.quantity
                }
            });
            console.log("cartItems", cartItems)
            return cartItems
            // localStorage.setItem("retailCart", JSON.stringify(cartItems)) 
        } else {
            let cartItems=[...retailCart, ...[{
                ID: product.ID,
                ProductID: product.ProductID,
                Name: product.Name,
                quantity: itemCount,
                productDetails:product,
            }]]
            // localStorage.setItem("retailCart", JSON.stringify(cartItems)) 
            return cartItems
        }

    } else {
        let newCartItem = {
            ID: product.ID,
            ProductID: product.ProductID,
            Name: product.Name,
            quantity: itemCount,
            productDetails:product,
        }
        // localStorage.setItem("retailCart", JSON.stringify([newCartItem]))
        return [newCartItem]
    }
}