import axios from 'axios';

export const generateTokenForEmailRegistry = async (isLoginServerRequest) => {
  let serviceName = 'emailRegistry';
  try {
    // 	const isLoggedUser = checkIsUserLoggedIn();
    // 	let usertoken;
    // 	if (isLoggedUser) {
    // 		usertoken = getLoggedUserDetails().AccessToken;
    // 	}

    const tokenResponse = await axios.post(
      process.env.REACT_APP_TOKENSERVICE_URL,
      { isLoginServerRequest, serviceName }
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.log('Error in generating token', error);
  }
};
