import React from "react";
import Navbar from "./navbar.component";
import { useSelector } from "react-redux";
import './css/ecommerce-widgets.css'

const ShankarPageWrapper = ({ navbarPageClickCallback, children }) => {
  const { defaultOrderType } = useSelector((state) => state.resturantData.cartDetails);
  const isOrderTypeDineIn = defaultOrderType && defaultOrderType === 'table';

  return (
    <>
      <div className="page-wrapper">
        <Navbar parentPageCallback={navbarPageClickCallback} />
        <div className={`page-content ${isOrderTypeDineIn ? 'eco-dine-in-page' : ''}`}>
          { children }
        </div>
      </div>
    </>
  );
}

export default ShankarPageWrapper;