import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';


class App extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { vimage: [], loaded: false };
  }
  componentDidMount() {
    this._isMounted = true;

    axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    }).then(async response => {
      await axios.post(
        `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
        {
          ID: this.props.params.ID,
          PageID: "",
          CompanyID: "",
          Start: 0,
          Limit: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }).then(async result => {
          console.log("I AM FROM VIDEO", result.data.Result)
          this.setState({
            videoName: result.data.Result[0].ItemData,
          })
        })
    })


    // axios.get(config.get('backendURL') + 'homelayout/video/' + this.props.params.ItemData)
    //   .then(response => {
    //     //console.log((response.data)[0].img)
    //     this.setState({
    //       vtype: (response.data)[0].img.contentType,
    //       videoName: (response.data)[0].meta_data.filename,
    //       vimage: (response.data)[0].img.data.toString()
    //     })
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   })

  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>


        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel':
              return (

                <div className={this.props.params.className} style={{ height: '100%' }} >
                  <div class="containerz">

                    {/* <video autostart autoPlay loop muted="muted" poster="" src={this.state.videoName} style={{ height: '100%' }}></video> */}
                    <div class="overlayz">
                      <h1>{this.props.params.ItemHeading}</h1>
                      <h3>{this.props.params.Item}</h3>
                    </div>
                  </div></div>)
            default:
              return (
                <>
                  {/* Video */}
                  {/* <div className="video-responsive" style={{ height: '100%' }} > */}

                  <video className='react-video-player'
                    style={{ height: "100%", objectFit: 'cover' }} autoPlay={true} loop={true} muted={true} controls={false} src={this.state.videoName} />

                  {/* </div> */}
                  {/* /Video */}
                </>
              )
          }

        })()}

      </>
    )
  }
}

export default App;
