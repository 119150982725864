import React, { useState } from 'react';
import { Stack, Typography, Icon, Space, Button, Message, Row, Col } from 'witmeg-ui-system';
import './common.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteRestaurantMenuCartItem, updateRestaurantMenuCartItemQuantity } from '../../utilities/restaurantMenuUtil';
import { syncRestaurantMenuCartDetails } from '../../../../redux/resturantWidgetSlice';
import { getCurrencyFormat, isECommerceProcessPath } from '../../utilities/helper';
import IMAGE_PLACEHOLDER from '../../../../assets/Images/image-placeholder.png';
// import UpdateCartPopup from '../ResturatnWidget/Common/UpdateCartPopup';
import DeleteCartItemPopupConfirmationPopup from './DeleteCartItemConfirmationPopup';
import "@fontsource/inter";
import "@fontsource/plus-jakarta-sans";
import RetailCartSummary from './RetailCartSummary';

const CartSummaryPopup = ({ toggleVisibility, updateCartItemCallback, websiteType }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const restaurantDataState = useSelector((state) => state.resturantData);
  const retailDataState = useSelector((state) => state.retailData);
  const selectedOrderTypeOriginal = websiteType === "Restaurant" ? restaurantDataState.orderSettings.urlOrder?.orderType ?? restaurantDataState.orderSettings.settingsDefaultOrder?.orderType ?? restaurantDataState.cartDetails.defaultOrderType : null;
  const selectedOrderType = websiteType === "Restaurant" ? selectedOrderTypeOriginal.replace("table", "dineIn") : "retail"
  const currencyFormat = getCurrencyFormat(restaurantDataState.currency);

  const [editCartItemModalState, setEditCartItemModalState] = useState({
    data: null,
    cartItemData: null,
    selectedIndex: 0,
    visible: false,
    deleteCart: {
      visible: false,
      index: null,
    },
  });

  const isCartDataAvailable = restaurantDataState.cartDetails && restaurantDataState.cartDetails.items && restaurantDataState.cartDetails.items.length > 0 ? true : false;

  const isRetailCartAvailable = retailDataState.cart && retailDataState.cart.length > 0 ? true : false

  const isButtonsDisabled = !isCartDataAvailable || isECommerceProcessPath();

  const isOrderSuccessPage = window.location.pathname.split('/')[1] === 'order-success' ? true : false;

  const onContinueShoppingClick = () => toggleVisibility();

  const onCartItemDelete = (_index) => {
    if (!isCartDataAvailable) return;

    setEditCartItemModalState(prevState => ({
      ...prevState,
      deleteCart: {
        visible: true,
        index: _index,
      }
    }));
  };

  const onCartItemDeleteConfirm = () => {
    const _deleteCartItem = async (_index) => {
      await deleteRestaurantMenuCartItem(restaurantDataState.cartDetails, _index);
      dispatch(syncRestaurantMenuCartDetails());

      Message.success('Cart item was deleted!');
    }

    setEditCartItemModalState((prevState) => {
      _deleteCartItem(prevState.deleteCart.index);
      return {
        ...prevState,
        deleteCart: {
          visible: false,
          index: null,
        }
      }
    });
  }

  const onDeleteItemPopupClose = () => {
    setEditCartItemModalState(prevState => ({
      ...prevState,
      deleteCart: {
        visible: false,
        index: null,
      }
    }));
  }

  const onUpdateCartItemQuantity = async (_index, _increase = true) => {
    if (!isCartDataAvailable) return;

    await updateRestaurantMenuCartItemQuantity(restaurantDataState.cartDetails, _index, _increase);
    dispatch(syncRestaurantMenuCartDetails());
  }

  const onCartItemEdit = (_index) => {
    if (isCartDataAvailable) {
      const cartItem = restaurantDataState.cartDetails.items[_index];
      updateCartItemCallback(true, {
        data: cartItem.product,
        editData: cartItem.selection,
        index: _index,
      });
    }
  }

  const onCheckoutClick = () => {
    history.push('/checkout');
    toggleVisibility();
  };

  const onEditCartItemPopupClose = () => {
    setEditCartItemModalState((prevState) => ({
      ...prevState,
      data: null,
      visible: false,
      cartItemData: null,
      index: 0,
    }));
  }

  const showCartDetails = () => { 
    toggleVisibility();
    history.push('/cart')
  }

  const disabledButtonStyles = {
    background: isButtonsDisabled ? '#f4f4f4' : '',
    cursor: isButtonsDisabled ? 'no-drop' : 'pointer',
  };
  return (
    <div style={{ width: '100%' }}>
      <div className="eco-product-list">
        {websiteType === "Restaurant" ?
          <>

            {/* Cart items*/}
            {isCartDataAvailable ? (
              restaurantDataState.cartDetails.items.map((item, index) => (
                <Stack className="item eco-mb-20 " verticalAlign="v_start">

                  <Typography
                    style={{ backgroundImage: `url(${item.product.image ?? IMAGE_PLACEHOLDER})` }}
                    className="cart-summary-image">
                  </Typography>


                  <Stack direction="column" verticalAlign="v_start" className="eco-product-addon-row">

                    <Typography type="text" className="truncate-text eco-product-title">
                      {item.selection.name}
                      {(item.selection.unitChartItem && item.selection.unitChartItem.typeName) ? ` (${item.selection.unitChartItem.typeName})` : ''}
                    </Typography>

                    <Stack horizontalAlign="space_between" className="eco-product-addon-content">
                      <Space className="eco-product-addon">
                        <div className="eco-product-addon-mins"
                          style={{ ...disabledButtonStyles }}
                          onClick={isButtonsDisabled ? null : () => onUpdateCartItemQuantity(index, false)}>
                          <Icon name="minus" size={16} />
                        </div>
                        <div className="eco-product-addon-count">
                          <input className="input-order-amount" value={item.selection.quantity} readOnly />
                        </div>
                        <div className="eco-product-addon-plus"
                          style={{ ...disabledButtonStyles }}
                          onClick={isButtonsDisabled ? null : () => onUpdateCartItemQuantity(index)}>
                          <Icon name="plus" size={16} />
                        </div>
                        <div>
                          <Space className="eco-product-add" >
                            <div className='eco-product-add-ctl'
                              style={{ ...disabledButtonStyles }}
                              onClick={isButtonsDisabled ? null : () => onCartItemDelete(index)}>
                              <Icon name="delete" size={16} />
                            </div>
                            <div className='eco-product-add-ctl'
                              style={{ ...disabledButtonStyles }}
                              onClick={isButtonsDisabled ? null : () => onCartItemEdit(index)}>
                              <Icon name="pencil" size={16} />
                            </div>
                          </Space>
                        </div>
                      </Space >
                      <Typography type="text" pattern="bold" className="eco-mb-12 eco-accordion-addon-price">
                        {currencyFormat(item.selection.total[selectedOrderType]?.amount)}
                      </Typography>
                    </Stack>
                  </Stack>




                </Stack>
              ))
            ) : (
              <Stack horizontalAlign="space_between" className="item eco-mb-16 eco-mt-16" style={{ padding: '20px 5px' }}>
                <div>No cart items</div>
              </Stack>
            )}
          </>
          : <div>
            <RetailCartSummary />
          </div>
        }
      </div>

      <div className="eco-mt-24">
        {/* Order Total */}
        {/* VAT charges */}
        <Stack
          horizontalAlign="space_between" className="eco-order-summary-row">
          <Typography type="text" className="eco-order-summary-label">VAT</Typography>
          <Typography type="text" pattern="bold" className="eco-order-summary-total">
            {currencyFormat(isCartDataAvailable ? restaurantDataState.cartDetails.total[selectedOrderType]?.vatAmount : 0)}
          </Typography>
        </Stack>
        <Stack horizontalAlign="space_between" className="eco-order-summary-total-row">
          <Typography type="text" pattern="bold" className="eco-order-summary-total-label">Total:</Typography>
          <Typography type="text" pattern="bold" className="eco-order-summary-total-bg">
          {websiteType === "Restaurant" ?
          <>
            {currencyFormat(isCartDataAvailable ? restaurantDataState.cartDetails.total[selectedOrderType]?.amount : 0)}
            </>:<>
            {retailDataState.cart.reduce((accumulator, currentItem) => {
                                    return accumulator + currentItem.productDetails.SellingPricesArray.SellingPrice * currentItem.quantity;
                                }, 0)}
            </>
          }
          </Typography>
        </Stack>
        {websiteType === "Restaurant" ?
          <>
            <Button fullWidth variant="primary" className="eco-mb-12 eco-checkout-button" onClick={onCheckoutClick} disabled={isButtonsDisabled}>
              Check Out
            </Button>
            <Button fullWidth variant="outline" className="eco-mb-12 eco-continue-shopping-button" onClick={onContinueShoppingClick} disabled={isOrderSuccessPage}>
              Continue Shopping
            </Button>
          </>
          : <>
          <Button fullWidth variant="primary" className="eco-mb-12 eco-checkout-button" onClick={onCheckoutClick} disabled={!isRetailCartAvailable}>
              Check Out
            </Button>
            <Button fullWidth variant="outline" className="eco-mb-12 eco-continue-shopping-button" onClick={showCartDetails} disabled={isOrderSuccessPage}>
              View Cart
            </Button>
          </>
        }
      </div>
      <DeleteCartItemPopupConfirmationPopup
        visibility={editCartItemModalState.deleteCart.visible}
        onPopupClose={onDeleteItemPopupClose}
        onDeleteConfirm={onCartItemDeleteConfirm} />
    </div>
  );
};

export default CartSummaryPopup;
