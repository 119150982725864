import React, { useState } from 'react';
import { Stack, Typography, Button, Radio, Row, Col, Label, InputBox, Select, Spin } from 'witmeg-ui-system';
import paymentDetailBG from "../../../../assets/Images/payment-detail.svg"
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData, getRestaurantMenuAvailablePath, LOCAL_STORAGE_ITEMS_NAMES } from '../../utilities/helper';
import { createRestaurantMenuOrder, getOrderTypeListDataForRestaurant } from '../../utilities/apiRequests/neutriPosServerRequests';
import _ from 'lodash';
import { sendEmail } from '../../utilities/apiRequests/emailRegistryServerRequests';
import { syncRestaurantMenuCartDetails } from '../../../../redux/resturantWidgetSlice';
import { RESTAURANT_SETTINGS_TYPES } from '../../utilities/restaurantMenuUtil';
import { useEffect } from 'react';
import RetailOrderSummary from './RetailOrderSummary';
import BillingAddressSelection from './BillingAddressSelection';

const RetailPaymentDetailsForm = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const restaurantDataState = useSelector((state) => state.resturantData);
  const companyData = useSelector((state) => state.companyData.companyDetails);
  const fullUserDetails = useSelector((state) => state.retailData.fullUserDetails)

  const [pageState, setPageState] = useState({
    months: [],
    years: [],
    payment: {
      isCard: false,
      selectedMonth: null,
      selectedYear: null,
      cardDetails: {
        cardNumber: '',
        cardName: '',
        security: {
          month: '',
          year: '',
          cvvCode: '',
        }
      }
    },
    hasError: false,
    isLoading: false,
  });

  const [menuPath, setMenuPath] = useState('/');



  const onPaymentMethodChange = (_value) => setPageState(prevState => ({ ...prevState, payment: { ...prevState.payment, isCard: _value } }));

  const onBackToMenu = () => history.push(menuPath);



  const onPlaceOrderClick = async () => {

  };

  return (
    <div className='order-confirmation-page'>
      <Button className="eco-order-confirm-back-but eco-mb-16" category="icon-text" icon="chevron-left" text="Back to menu" iconSize={16} variant="subtle" onClick={onBackToMenu} size="sm" />
      <Stack horizontalAlign="space_between">
        <Typography className="eco-mb-16 eco-order-confirm-title" type="title" level={3} color="gray_darkest">Payment Detail</Typography>
      </Stack>
      <Row gutter={[32, 16]}>
        <Col span={15}>
          <div style={{ border: '1px solid #C6C7D2', borderRadius: '8px' }} className="eco-mb-16" >
            <div style={{ backgroundColor: '#F0F1F4', borderRadius: '8px 8px 0px 0px', padding: '15px 30px' }}><Typography type="text">Payment Method</Typography></div>
            <div style={{ padding: '20px 30px' }}>
              <Row>
                <Col span={24}>
                  <Row className="eco-mb-24" gutter={[0, 32]}>
                    <Col lg={12} sm={24} xs={24}>
                      <Row gutter={[0, { xs: 32 }]}>
                        <Col lg={12} sm={24} xs={24}>
                          <Radio value="card-payment" checked={pageState.payment.isCard} onChange={(e) => onPaymentMethodChange(true)}>Card Payment 1</Radio>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                          <Radio value="cash-payment" checked={!pageState.payment.isCard} onChange={(e) => onPaymentMethodChange(false)}>Pay Later</Radio>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={24} sm={24} xs={24}>
                  {pageState.payment.isCard &&
                    <Row gutter={[0, 32]}>
                      <Col span={24}>
                        <Typography type="title" level={5} pattern="bold" className="eco-mb-24 eco-mt-12">Credit Card Information</Typography>
                        <div>
                          <Row className="form-row eco-mb-24" gutter={[0, 8]}>
                            <Col span={24}>
                              <Typography type="text">Credit Card Number</Typography>
                            </Col>
                            <Col span={24}>
                              <InputBox type="text" icon="right" iconName="check" size={16} />
                            </Col>
                          </Row>
                          <Row className="form-row eco-mb-24" gutter={[0, 8]}>
                            <Col span={24}>
                              <Typography type="text">Name on Card</Typography>
                            </Col>
                            <Col span={24}>
                              <InputBox type="text" />
                            </Col>
                          </Row>
                          <Row className="form-row eco-mb-24" gutter={[0, 8]}>
                            <Col lg={6} sm={24} xs={24}>
                              <Row gutter={[0, 8]}>
                                <Col span={24}>
                                  <Typography type="text">Month</Typography>
                                </Col>
                                <Col span={24}>
                                  <Select />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6} sm={24} xs={24}>
                              <Row gutter={[0, 8]}>
                                <Col span={24}>
                                  <Typography type="text">Year</Typography>
                                </Col>
                                <Col span={24}>
                                  <Select />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6} sm={24} xs={24}>
                              <Row gutter={[0, 8]}>
                                <Col span={24}>
                                  <Typography type="text">CVV Code</Typography>
                                </Col>
                                <Col span={24} className="cvv-code">
                                  <InputBox type="number" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  }
                </Col>
                {/* <Col span={12} className="payment-details-bg">
              <img src={paymentDetailBG} />
            </Col> */}
              </Row>
              <hr />
              <div>
                <BillingAddressSelection userDetails={fullUserDetails}/>
              </div>
            </div>
          </div>
        </Col>
        <Col span={9}>
          <div style={{ border: '1px solid #C6C7D2', borderRadius: '8px', padding: '15px' }}>
            <h4 className='eco-mb-16'>Order Summary</h4>
            <hr />
            <RetailOrderSummary />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '50px' }}>
        <Col span={24}>
          <Stack horizontalAlign="center" style={{ textAlign: 'center' }}>
            <Typography type="text" style={{ textAlign: 'center' }} >  {pageState.isLoading ? (<Spin style={{ marginRight: '10px' }} />) : ''}</Typography>
          </Stack>
          <Stack horizontalAlign="h_end">
            <Button className="full-width-btn" variant="primary" onClick={onPlaceOrderClick} disabled={pageState.isLoading}>
              Confirm and Place Order
            </Button>
          </Stack>

        </Col>
      </Row>
    </div>
  )
}

export default RetailPaymentDetailsForm