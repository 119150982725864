import React, { useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'

// import SearchPage from '../themes/default/EcommerceWidgets/SearchPage'
import ProductsByCategory from '../themes/default/EcommerceWidgets/ProductsByCategory';
import ShankarProductsByCategory from '../themes/sharkar/EcommerceWidgets/ProductsByCategory';

export default function Application(props) {
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search)
  let { id, name } = useParams();

  const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));

  const updateQueryParams = (newParams) => {
    const updatedParams = new URLSearchParams(queryParams);
    for (const [key, value] of Object.entries(newParams)) {
      updatedParams.set(key, value);
    }

    // Use `history.push` to navigate to the current route with updated query parameters.
    // history.push(`${search.pathname}?${updatedParams.toString()}`);
    history.replace({
      // pathname: '/page',
      search: updatedParams.toString(),
    });
  };
  return (
    <div>
      {props.theme === "Shankar" ?
        <ShankarProductsByCategory name={name} categoryId={id} searchParams={name} location={props.location} updateQueryParams={updateQueryParams} />
        : <ProductsByCategory name={name} categoryId={id} searchParams={name} location={props.location} updateQueryParams={updateQueryParams} />
      }
    </div>
  )
}

// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHeart,
//   faShoppingCart,
//   faEnvelope,
// } from "@fortawesome/free-solid-svg-icons";
// import * as NumericInput from "react-numeric-input";
// import { Row, Col, Breadcrumb, Table } from "react-bootstrap";
// import LeftMenu from "../themes/default/leftmenu.component";
// import LeftMartFuryMenu from "../themes/martfury/leftmenu.component";
// import LeftSpicewayMenu from "../themes/spiceway/leftmenu.component";
// import LeftApparelMenu from "../themes/apparel/leftmenu.component";
// import LeftApparelCasiMenu from "../themes/apparel-casi/leftmenu.component";
// import LeftMeatShopMenu from "../themes/meatshop/leftmenu.component";
// import jwt from "jsonwebtoken";
// import LazyLoad from "react-lazyload";
// import { renderEmail } from "postonents";
// import { Skeleton, message, Button, Form, Input, Select, Modal } from "antd";
// import config from "react-global-configuration";
// import NotifyEmail from "../email/NotifyEmail";
// import ReactPaginate from "react-paginate";
// import ProducBoxMartFury from "../themes/martfury/product-box";
// import ProducBoxApparel from "./product-box";
// import ProducBoxSpiceway from "../themes/spiceway/product-box";
// import ProducBoxMeatShop from "../themes/meatshop/product-box";
// import $ from "jquery";

// import { LoadingOutlined, PicLeftOutlined } from "@ant-design/icons";
// import { assertAbstractType } from "graphql";

// const key = "updatable";

// function handleChange(value) {
//   console.log(`selected ${value}`);
// }

// class Application extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       filter: "",
//       ECDrawer: false,
//       categories: [],
//       category: this.props.match.params.name,
//       catid: this.props.match.params.id,
//       ploaded: true,
//       productscount: [],
//       perpage: 12,
//       products: [],
//       offers: [],
//       basketitem: [],
//       basketqty: [],
//       loaded: false,
//       regexp: /^[0-9\b]+$/,
//     };
//   }

//   componentDidMount() {
//     //localStorage.setItem('cart','')
//     const timestamp = Math.floor(Date.now() / 1000) + 1000;
//     var payload = {
//       aud: config.get("ProductjwtAud"),
//       exp: timestamp,
//       iss: config.get("ProductjwtIss"),
//     };

//     var signOptions = {
//       algorithm: "RS256",
//     };

//     var privateKey = config.get("ProductprivateKey");
//     var token = jwt.sign(payload, privateKey, signOptions);
//     // console.log(token)

//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ` + token,
//       },
//     };

//     // console.log(token)

//     const cachedCart = localStorage.getItem("cart");
//     if (cachedCart) {
//       this.setState({ basketitem: JSON.parse(cachedCart) });
//     } else {
//       const myArray = [];
//       this.setState({ basketitem: myArray });
//       localStorage.setItem("cart", JSON.stringify(myArray));
//     }

//     this.setState({ ploaded: false });
//     //  fetch(config.get('rest_API_productregistry') + '/category/pagination/' + config.get('OrganisationID') + '/' + this.state.catid + '/0/' + this.state.perpage, requestOptions)

//     fetch(
//       config.get("rest_API_productregistry_V2") +
//         "/category/pagination/" +
//         config.get("OrganisationID") +
//         "/" +
//         JSON.parse(sessionStorage.getItem("setting"))[0].SalesLocationID +
//         "/" +
//         this.state.catid +
//         "/0/" +
//         this.state.perpage,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then(async (data) => {
//         const products = data.Result;
//         if (data.ProductFilter) {
//           this.setState({
//             ECDrawer: false,
//             products: products,
//             ploaded: true,
//             ProductFilter: data.ProductFilter,
//             filterd: true,
//           });
//         } else {
//           this.setState({
//             ECDrawer: false,
//             products: products,
//             ploaded: true,
//             ProductFilter: data.ProductFilter,
//             PriceRanges: data.ProductFilter.PriceRanges,
//             filterd: true,
//           });
//         }
//       });

//     const requestCategories = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ` + token,
//       },
//     };

//     const cachedCategory = sessionStorage.getItem("menucategories");
//     if (cachedCategory) {
//       this.setState({ categories: JSON.parse(cachedCategory) });
//     } else {
//       fetch(
//         config.get("rest_API_productregistry") +
//           "/onlinecategories/" +
//           config.get("OrganisationID"),
//         requestCategories
//       )
//         .then((response) => response.json())
//         .then((data) => this.onSetCategoryResult(data.Result, data.Result));
//     }
//   }

//   onChangeValue = (event) => {
//     this.setState({ value: "1" });
//     // document.getElementsByClassName("basketadded").style.visibility = "hidden"
//   };

//   onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
//     this.setState({
//       visible: true,
//     });
//   };
//   handleCancel = (e) => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   };

//   onSetCategoryResult = (result, key) => {
//     this.setState({ categories: result });
//     sessionStorage.setItem("menucategories", JSON.stringify(result));
//   };
//   onAddItem = (
//     buyoradd,
//     ProductID,
//     ProductName,
//     MaxOnlineOrderQty,
//     ProductCategoryID,
//     offerItems,
//     offerqty,
//     offerprice
//   ) => {
//     let prodqty = "";

//     const basketitem = this.state.basketitem;

//     const prodprice = document.getElementById("prodprice" + ProductID).value;
//     const prodprimimage = document.getElementById(
//       "prodprimimage" + ProductID
//     ).value;

//     var index = this.state.basketitem.findIndex(
//       (a) => a.ProductID === ProductID
//     );
//     prodqty = document.getElementById("prodqty" + ProductID).value;

//     if (!this.state.regexp.test(prodqty)) {
//       message.error("Please enter only valid number for the product quantity.");
//       return;
//     }

//     if (index !== -1) {
//       const aprodqty = basketitem[index]["ProductQty"];

//       if (
//         MaxOnlineOrderQty != 0 &&
//         MaxOnlineOrderQty < parseInt(prodqty) + parseInt(aprodqty)
//       ) {
//         message.error(
//           "Please add less than " +
//             MaxOnlineOrderQty +
//             " items in total to cart"
//         );
//         return;
//       } else if (prodqty > 1000) {
//         message.error(
//           "You are not allowed to add more than 1000 " +
//             ProductName +
//             ", if you need more than 1000 please contact us."
//         );
//         document.getElementById("prodqty" + ProductID).value = 1;
//         return;
//       }

//       basketitem[index]["ProductQty"] =
//         parseFloat(basketitem[index]["ProductQty"]) +
//         parseFloat(document.getElementById("prodqty" + ProductID).value);
//       this.setState({ basketitem: basketitem });
//       localStorage.setItem("cart", JSON.stringify(basketitem));
//       document.getElementById("cartcount").innerHTML =
//         parseInt(document.getElementById("cartcount").innerHTML) +
//         parseInt(aprodqty);
//     } else {
//       prodqty = document.getElementById("prodqty" + ProductID).value;

//       if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
//         message.error(
//           "Please add less than " +
//             MaxOnlineOrderQty +
//             " items in total to cart"
//         );
//         return;
//       } else if (prodqty > 1000) {
//         message.error(
//           "You are not allowed to add more than 1000 " +
//             ProductName +
//             ", if you need more than 1000 please contact us."
//         );
//         document.getElementById("prodqty" + ProductID).value = 1;
//         return;
//       }

//       document.getElementById("cartcount").innerHTML =
//         parseInt(document.getElementById("cartcount").innerHTML) +
//         parseInt(prodqty);
//       var ProductsDtl = {
//         ProductID: ProductID,
//         ProductName: ProductName,
//         ProductQty: prodqty,
//         ProductPrice: prodprice,
//         ProductImage: prodprimimage,
//         OfferAmount: 0,
//         VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
//           (category) =>
//             category.ProductCategory.ProductCategoryID === ProductCategoryID
//         ).ProductCategory.VatRate,
//       };

//       //  console.log(ProductsDtl)
//       this.setState((state) => {
//         {
//           basketitem: state.basketitem.push(ProductsDtl);
//           localStorage.setItem("cart", JSON.stringify(state.basketitem));
//         }
//       });
//     }

//     setTimeout(() => {
//       //offer
//       var offeri = [];
//       offeri = JSON.parse("[" + offerItems + "]");

//       let checker = (arr, target) => target.some((v) => arr.includes(v));

//       let op = JSON.parse(localStorage.getItem("cart")).map(
//         ({ ProductID }) => ProductID
//       );

//       var offer = 0;

//       if (checker(op, offeri)) {
//         var totalitems = 0;
//         var totalamounts = 0;
//         var newArray = offeri.map(function (el) {
//           var cindex = JSON.parse(localStorage.getItem("cart")).findIndex(
//             (a) => a.ProductID === el
//           );
//           //  console.log(cindex)

//           if (cindex !== -1) {
//             totalitems =
//               totalitems +
//               parseFloat(
//                 JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
//               );
//             totalamounts =
//               totalamounts +
//               parseFloat(
//                 JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
//               ) *
//                 parseFloat(
//                   JSON.parse(localStorage.getItem("cart"))[cindex][
//                     "ProductPrice"
//                   ]
//                 );
//             basketitem[cindex]["OfferAmount"] = 0;
//           }
//         });

//         var quotient = Math.floor(totalitems / offerqty);
//         var remainder = totalitems % offerqty;
//         //console.log('total:' + totalamounts)
//         //console.log('offer total:' + quotient * offerprice)
//         //console.log('remainder: ' + remainder)
//         offer =
//           parseFloat(totalamounts - prodprice * remainder) -
//           parseFloat(quotient * offerprice);
//         //console.log('singleprice:' + prodprice * remainder)

//         var clickedindex = this.state.basketitem.findIndex(
//           (a) => a.ProductID === ProductID
//         );

//         basketitem[clickedindex]["OfferAmount"] = offer;

//         if (totalitems < offerqty) {
//           basketitem[clickedindex]["OfferAmount"] = 0;
//         }

//         this.setState({ basketitem: basketitem });
//         localStorage.setItem("cart", JSON.stringify(basketitem));

//         //console.log(localStorage.getItem('cart'))
//       }
//     }, 100);

//     message.loading({ content: "Adding...", key });
//     setTimeout(() => {
//       message.success({ content: "Added to the cart!", key, duration: 2 });
//       if (buyoradd === "buy") {
//         window.location.href = "/cart";
//       }
//     }, 800);
//   };

//   onQtyIncrese = (ProductID) => {
//     document.getElementById("prodqty" + ProductID).value =
//       parseFloat(document.getElementById("prodqty" + ProductID).value) + 1;
//   };
//   onQtyReduce = (ProductID) => {
//     if (document.getElementById("prodqty" + ProductID).value > 1) {
//       document.getElementById("prodqty" + ProductID).value =
//         parseFloat(document.getElementById("prodqty" + ProductID).value) - 1;
//     }
//   };

//   handleBtnFilterClick = (data) => {
//     this.setState({
//       ECDrawer: true,
//     });
//   };

//   handlePageClick = (data) => {
//     this.setState({
//       ECDrawer: false,
//     });

//     $("html, body").animate(
//       { scrollTop: $(".container-fluid").offset().top - 40 },
//       "slow"
//     );

//     this.setState({ ploaded: false });

//     let selected = data.selected;
//     let offset = Math.ceil(selected * this.state.perpage);

//     const timestamp = Math.floor(Date.now() / 1000) + 1000;
//     var payload = {
//       aud: config.get("ProductjwtAud"),
//       exp: timestamp,
//       iss: config.get("ProductjwtIss"),
//     };

//     var signOptions = {
//       algorithm: "RS256",
//     };

//     var privateKey = config.get("ProductprivateKey");
//     var token = jwt.sign(payload, privateKey, signOptions);
//     // console.log(token)

//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ` + token,
//       },
//     };

//     fetch(
//       config.get("rest_API_productregistry_V2") +
//         "/category/pagination/" +
//         config.get("OrganisationID") +
//         "/" +
//         JSON.parse(sessionStorage.getItem("setting"))[0].SalesLocationID +
//         "/" +
//         this.state.catid +
//         "/" +
//         offset +
//         "/" +
//         this.state.perpage,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then(async (data) => {
//         const products = data.Result;
//         if (data.ProductFilter) {
//           this.setState({
//             products: products,
//             ploaded: true,
//             ProductFilter: data.ProductFilter,
//             PriceRanges: data.ProductFilter.PriceRanges[0],
//             filterd: true,
//           });
//         } else {
//           this.setState({
//             products: products,
//             ploaded: true,
//             ProductFilter: data.ProductFilter,
//             PriceRanges: data.ProductFilter.PriceRanges,
//             filterd: true,
//           });
//         }
//       });
//   };

//   searchcNow = (event) => {
//     event.preventDefault();

//     if (document.getElementById("search2").value) {
//       window.location.href =
//         "/search/" + document.getElementById("search2").value;
//     } else {
//       let secondsToGo = 5;
//       const modal = Modal.warning({
//         title: "Invalid or empty keyword",
//         content: `Please enter a keyword to search`,
//       });
//       const timer = setInterval(() => {
//         secondsToGo -= 1;
//         modal.update({
//           content: `Please enter a keyword to search`,
//         });
//       }, 1000);
//       setTimeout(() => {
//         clearInterval(timer);
//         modal.destroy();
//       }, secondsToGo * 1000);
//     }
//   };

//   onECDrawerClose = () => {
//     this.setState({
//       ECDrawer: false,
//     });
//   };
//   handleCallback = (
//     childData,
//     ProductFilter,
//     ploaded,
//     category,
//     productscount,
//     catid,
//     ECDrawer
//   ) => {
//     this.setState({
//       ECDrawer: ECDrawer,
//       products: childData,
//       ProductFilter: ProductFilter,
//       category: category,
//       productscount: productscount,
//       catid: catid,
//     });
//   };
//   render() {
//     const onFinish = (values) => {
//       const Notifydata = {
//         companyPageTitle: config.get("companyPageTitle"),
//         SenderEmail: config.get("SenderEmail"),
//         companyLogoCDN: config.get("companyLogoCDN"),
//         prdtName: document.getElementById("prdt").value,
//       };

//       const emailBody = renderEmail(NotifyEmail, { lang: "en", Notifydata });
//       async function postData() {
//         const ecoEmail = {
//           to:
//             config.get("companyPageTitle") +
//             "<" +
//             config.get("SenderEmail") +
//             ">",
//           bcc: "suren.sivalingam@ecologital.com",
//           from: values.email,
//           subject: "Product Notification Request",
//           html: emailBody,
//         };

//         const response = await fetch(config.get("backendURL") + "mail/send", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(ecoEmail),
//         });
//         return response.json();
//       }

//       postData().then((data) => {
//         message.success(
//           "Email has been sent to " + config.get("companyPageTitle")
//         );
//         this.handleCancel();
//       });
//     };
//     const { categories } = this.state;
//     const { products, productscount, perpage, offers } = this.state;
//     const { basketitem } = this.state;
//     return (
//       <>
//         {(() => {
//           switch (config.get("Theme")) {
//             case "Apparel":
//               return (
//                 <div className='ps-layout--shop'>
//                   <LeftApparelMenu
//                     parentCallback={this.handleCallback}
//                     params={{
//                       ECDrawer: this.state.ECDrawer,
//                       catid: this.state.catid,
//                       catname: this.state.category,
//                     }}
//                   />
//                   <div className='ps-layout__right'>
//                     <div className='ps-shopping ps-tab-root'>
//                       <div className='ps-block__header'>
//                         <h3>{this.state.category}</h3>
//                       </div>
//                       <div className='ps-shopping__header'>
//                         <p>
//                           <a onClick={this.handleBtnFilterClick}>
//                             <PicLeftOutlined style={{ fontSize: "20px" }} />
//                           </a>
//                         </p>
//                         <p>
//                           <strong> {this.state.productscount}</strong> Products
//                           found
//                         </p>
//                         <div className='ps-shopping__actions'>
//                           <select
//                             className='ps-select select2-hidden-accessible'
//                             data-placeholder='Sort Items'
//                             data-select2-id={4}
//                             tabIndex={-1}
//                             aria-hidden='true'
//                           >
//                             <option data-select2-id={6}>Sort by latest</option>
//                             <option>Sort by popularity</option>
//                             <option>Sort by average rating</option>
//                             <option>Sort by price: low to high</option>
//                             <option>Sort by price: high to low</option>
//                           </select>
//                         </div>
//                       </div>
//                     </div>

//                     <div className='row'>
//                       {this.state.ploaded ? (
//                         products ? (
//                           products.map((product) => (
//                             <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                               <div className='list'>
//                                 <ProducBoxApparel
//                                   params={{
//                                     product: product,
//                                     visible: this.state.visible,
//                                     loaded: this.state.loaded,
//                                   }}
//                                   handleCancel={this.handleCancel}
//                                   onNotify={this.onNotify}
//                                   onAddItem={this.onAddItem}
//                                   onQtyIncrese={this.onQtyIncrese}
//                                   onQtyReduce={this.onQtyReduce}
//                                   onFinish={this.onFinish}
//                                 />

//                                 <hr />

//                                 <br />
//                               </div>
//                             </div>
//                           ))
//                         ) : (
//                           <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                             <br />
//                             <Skeleton active avatar paragraph={{ rows: 4 }} />
//                           </div>
//                         )
//                       ) : (
//                         <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                           <br />
//                           <Skeleton active avatar paragraph={{ rows: 4 }} />
//                         </div>
//                       )}
//                     </div>

//                     <div>
//                       <ReactPaginate
//                         previousLabel={"Previous"}
//                         nextLabel={"Next"}
//                         breakLabel={"..."}
//                         breakClassName={"break-me"}
//                         pageCount={
//                           this.state.productscount / this.state.perpage
//                         }
//                         marginPagesDisplayed={2}
//                         pageRangeDisplayed={5}
//                         onPageChange={this.handlePageClick}
//                         containerClassName={"pagination"}
//                         subContainerClassName={"pages pagination"}
//                         activeClassName={"active"}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               );
//             case "Apparel-casi":
//               return (
//                 <>
//                   <div>
//                     {/* Product Hero */}
//                     <div className='prd-hero'>
//                       <div className='prd-hero-header'>
//                         <h4 className>{this.state.category}</h4>
//                       </div>
//                       <div className='prd-hero-img'>
//                         <img
//                           src='https://cdn.neurolage.com/retailpacx/casipillai/retailpacx-1639865646642.jpg'
//                           className='img-fluid'
//                         />
//                       </div>
//                     </div>
//                     {/* /Product Hero */}
//                     {/* Breadcrumb */}
//                     <div className='breadcrumb-container'>
//                       <div className='row'>
//                         <div className='col-sm-12'>
//                           <div className='breadcrumb-in'>
//                             <ul className='site-breadcrumb'>
//                               <li>
//                                 <a href='index.html'>Home</a>
//                               </li>
//                               <li>{this.state.category}</li>
//                             </ul>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     {/* /Breadcrumb */}
//                     {/* Filter Area */}
//                     <div className='prd-filter-area'>
//                       <div className='row'>
//                         <div className='col-sm-12'>
//                           <div className='filter-inner'>
//                             <div className='filter-top'>
//                               <p className='cat-name'>{this.state.category}</p>
//                               <p className='cat-count'>
//                                 {this.state.productscount} Products Found
//                               </p>
//                             </div>

//                             <LeftApparelCasiMenu
//                               parentCallback={this.handleCallback}
//                               params={{
//                                 ECDrawer: this.state.ECDrawer,
//                                 catid: this.state.catid,
//                                 catname: this.state.category,
//                               }}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     {/* /Filter Area */}

//                     {/* List Items */}
//                     <div className='prd-list-items'>
//                       <div className='row'>
//                         {this.state.ploaded ? (
//                           products ? (
//                             products.map((product) => (
//                               <div className='col-sm-6 col-md-4 col-lg-3'>
//                                 <div
//                                   className='item-wrapper wow bounceInUp'
//                                   data-wow-delay='0.1s'
//                                   data-wow-offset={10}
//                                 >
//                                   <a
//                                     key={"pid" + product.ProductID}
//                                     href={
//                                       "/product/" +
//                                       product.Name.replace(/ /g, "-")
//                                         .replace("/", "-")
//                                         .toLowerCase() +
//                                       "/" +
//                                       product.ProductID
//                                     }
//                                     title='Quick View'
//                                   >
//                                     <div className='img-container'>
//                                       {product.OnLineImages.PrimaryImages !==
//                                       null ? (
//                                         <div style={{ width: "auto" }}>
//                                           <a
//                                             key={"pid" + product.ProductID}
//                                             href={
//                                               "/product/" +
//                                               product.Name.replace(/ /g, "-")
//                                                 .replace("/", "-")
//                                                 .toLowerCase() +
//                                               "/" +
//                                               product.ProductID
//                                             }
//                                             title='Quick View'
//                                           >
//                                             <img
//                                               src={
//                                                 product.OnLineImages
//                                                   .PrimaryImages.Main
//                                               }
//                                               className='img-fluid'
//                                               alt={product.Name}
//                                             />
//                                           </a>
//                                           <input
//                                             type='hidden'
//                                             id={
//                                               "prodprimimage" +
//                                               product.ProductID
//                                             }
//                                             value={
//                                               product.OnLineImages.PrimaryImages
//                                                 .Main
//                                             }
//                                           />
//                                         </div>
//                                       ) : (
//                                         <div>
//                                           <a
//                                             key={"pid" + product.ProductID}
//                                             href={
//                                               "/product/" +
//                                               product.Name.replace(/ /g, "-")
//                                                 .replace("/", "-")
//                                                 .toLowerCase() +
//                                               "/" +
//                                               product.ProductID
//                                             }
//                                             title='Quick View'
//                                           >
//                                             <img
//                                               src='/images/no-image.jpg'
//                                               className='img-fluid'
//                                               alt={product.Name}
//                                             />
//                                           </a>
//                                           <input
//                                             type='hidden'
//                                             id={
//                                               "prodprimimage" +
//                                               product.ProductID
//                                             }
//                                             value=''
//                                           />
//                                         </div>
//                                       )}
//                                       <span className='item-price'>
//                                         {" "}
//                                         <p
//                                           className={
//                                             product.Discount != 0
//                                               ? "ps-product__price sale"
//                                               : "ps-product__price"
//                                           }
//                                         >
//                                           <>
//                                             {localStorage.getItem(
//                                               "ecurrencyCode"
//                                             )}
//                                             {String(
//                                               (
//                                                 product.SellingPricesArray
//                                                   .SellingPrice *
//                                                 localStorage.getItem(
//                                                   "ecurrencyratio"
//                                                 )
//                                               ).toFixed(2)
//                                             )}{" "}
//                                             {product.Discount != 0 ? (
//                                               <>
//                                                 <del>
//                                                   {localStorage.getItem(
//                                                     "ecurrencyCode"
//                                                   )}
//                                                   {parseInt(
//                                                     product.SellingPricesArray
//                                                       .SellingPrice
//                                                   ) *
//                                                     parseInt(
//                                                       localStorage.getItem(
//                                                         "ecurrencyratio"
//                                                       )
//                                                     ) +
//                                                     parseInt(product.Discount)}
//                                                 </del>
//                                               </>
//                                             ) : (
//                                               ""
//                                             )}
//                                             <input
//                                               type='hidden'
//                                               id={
//                                                 "prodprice" + product.ProductID
//                                               }
//                                               value={
//                                                 product.SellingPricesArray
//                                                   .SellingPrice
//                                               }
//                                             />
//                                           </>
//                                         </p>
//                                       </span>
//                                     </div>
//                                     <div className='item-text'>
//                                       <p>{product.Name}</p>
//                                     </div>
//                                   </a>
//                                 </div>
//                               </div>
//                             ))
//                           ) : (
//                             <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                               <br />
//                               <Skeleton active avatar paragraph={{ rows: 4 }} />
//                             </div>
//                           )
//                         ) : (
//                           <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                             <br />
//                             <Skeleton active avatar paragraph={{ rows: 4 }} />
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     {/* /List Items */}

//                     {/* Pagination */}
//                     <div className='prd-pagination'>
//                       <div className='row'>
//                         <div className='col-sm-12'>
//                           <nav aria-label='...'>
//                             <ReactPaginate
//                               previousLabel={"<"}
//                               nextLabel={">"}
//                               nextClassName={"page-link"}
//                               previousClassName={"page-link"}
//                               breakLabel={"..."}
//                               breakClassName={"break-me"}
//                               pageCount={
//                                 this.state.productscount / this.state.perpage
//                               }
//                               marginPagesDisplayed={2}
//                               pageRangeDisplayed={5}
//                               pageLinkClassName={"page-link"}
//                               pageClassName={"page-item"}
//                               onPageChange={this.handlePageClick}
//                               containerClassName={"pagination"}
//                               subContainerClassName={"pages pagination"}
//                               activeClassName={"active"}
//                             />
//                           </nav>
//                         </div>
//                       </div>
//                     </div>
//                     {/* /Pagination */}
//                   </div>
//                 </>
//               );
//             case "Martfury":
//               return (
//                 <div className='ps-layout--shop'>
//                   <LeftMartFuryMenu
//                     parentCallback={this.handleCallback}
//                     params={{
//                       catid: this.state.catid,
//                       catname: this.state.category,
//                     }}
//                   />
//                   <div className='ps-layout__right'>
//                     <div className='ps-shopping ps-tab-root'>
//                       <div className='ps-block__header'>
//                         <h3>{this.state.category}</h3>
//                       </div>

//                       <div className='ps-shopping__header'>
//                         <p>
//                           <strong> {this.state.productscount}</strong> Products
//                           found
//                         </p>
//                         <div className='ps-shopping__actions'>
//                           <select
//                             className='ps-select select2-hidden-accessible'
//                             data-placeholder='Sort Items'
//                             data-select2-id={4}
//                             tabIndex={-1}
//                             aria-hidden='true'
//                           >
//                             <option data-select2-id={6}>Sort by latest</option>
//                             <option>Sort by popularity</option>
//                             <option>Sort by average rating</option>
//                             <option>Sort by price: low to high</option>
//                             <option>Sort by price: high to low</option>
//                           </select>
//                         </div>
//                       </div>
//                     </div>

//                     <div className='row'>
//                       {this.state.ploaded ? (
//                         products ? (
//                           products.map((product) => (
//                             <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 '>
//                               <div className='list'>
//                                 {console.log(product)}

//                                 <ProducBoxMartFury
//                                   params={{
//                                     product: product,
//                                     visible: this.state.visible,
//                                     loaded: this.state.loaded,
//                                   }}
//                                   handleCancel={this.handleCancel}
//                                   onNotify={this.onNotify}
//                                   onAddItem={this.onAddItem}
//                                   onQtyIncrese={this.onQtyIncrese}
//                                   onQtyReduce={this.onQtyReduce}
//                                   onFinish={this.onFinish}
//                                 />

//                                 <hr />

//                                 <br />
//                               </div>
//                             </div>
//                           ))
//                         ) : (
//                           <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                             <br />
//                             <Skeleton active avatar paragraph={{ rows: 4 }} />
//                           </div>
//                         )
//                       ) : (
//                         <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                           <br />
//                           <Skeleton active avatar paragraph={{ rows: 4 }} />
//                         </div>
//                       )}
//                     </div>

//                     <div>
//                       <ReactPaginate
//                         previousLabel={"Previous"}
//                         nextLabel={"Next"}
//                         breakLabel={"..."}
//                         breakClassName={"break-me"}
//                         pageCount={
//                           this.state.productscount / this.state.perpage
//                         }
//                         marginPagesDisplayed={2}
//                         pageRangeDisplayed={5}
//                         onPageChange={this.handlePageClick}
//                         containerClassName={"pagination"}
//                         subContainerClassName={"pages pagination"}
//                         activeClassName={"active"}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               );

//             case "Spiceway":
//               return (
//                 <>
//                   <div className='hero-banner inner products'>
//                     <div className='hero-text-wrapper'>
//                       <h3>Our Products</h3>
//                       <h6>
//                         All Products are hygienically packaged with the help of
//                         our technically advanced packaging machine. An air-tight
//                         packing is done with caution to let it be moisture free.
//                         This keeps our spices fresh and bacteria free.
//                       </h6>
//                     </div>
//                   </div>

//                   <div className='section products'>
//                     <div className='container-fluid'>
//                       <div className='search'>
//                         <div className='row justify-content-md-center'>
//                           <div className='col-12 col-md-8 col-lg-6'>
//                             <Form
//                               onSubmit={this.searchcNow}
//                               className='ps-form--quick-search'
//                             >
//                               <div className='input-group mb-3'>
//                                 <div className='input-group-prepend'>
//                                   <select
//                                     className='form-control'
//                                     id='searchcat'
//                                   >
//                                     <option value='' selected='selected'>
//                                       All
//                                     </option>

//                                     {categories.map((category) => (
//                                       <option
//                                         className='level-0'
//                                         value={
//                                           category.ProductCategory.Category
//                                         }
//                                       >
//                                         {category.ProductCategory.Category}
//                                       </option>
//                                     ))}
//                                   </select>
//                                 </div>
//                                 <input
//                                   className='form-control'
//                                   type='text'
//                                   id='search2'
//                                   aria-label='Search Products'
//                                   placeholder='Search Products'
//                                 />
//                                 <div className='input-group-append'>
//                                   {" "}
//                                   <button
//                                     onClick={this.searchcNow}
//                                     className='btn btn-default'
//                                   >
//                                     Go
//                                   </button>{" "}
//                                 </div>
//                               </div>
//                             </Form>
//                           </div>
//                         </div>
//                       </div>

//                       <div className='row'>
//                         <div className='col-md-4 col-lg-3 col-xl-2'>
//                           <div class='list-group d-none d-md-block'>
//                             <LeftSpicewayMenu
//                               parentCallback={this.handleCallback}
//                               params={{
//                                 catid: this.state.catid,
//                                 catname: this.state.category,
//                               }}
//                             />
//                           </div>

//                           <div className='dropdown d-sm-block d-md-none menu-prd-cat'>
//                             <button
//                               className='btn btn-secondary dropdown-toggle'
//                               type='button'
//                               id='dropdownMenu2'
//                               data-toggle='dropdown'
//                               aria-expanded='false'
//                             >
//                               Product Category
//                             </button>
//                             <div
//                               className='dropdown-menu'
//                               aria-labelledby='dropdownMenu2'
//                             >
//                               {this.state.categories.map((category) => (
//                                 <a
//                                   className='dropdown-item'
//                                   type='button'
//                                   href={
//                                     "/group/" +
//                                     category.ProductCategory.ProductCategoryID +
//                                     "/" +
//                                     category.ProductCategory.Category
//                                   }
//                                 >
//                                   {" "}
//                                   {category.ProductCategory.Category}
//                                 </a>
//                               ))}
//                             </div>
//                           </div>
//                         </div>

//                         <div className='col-md-8 col-lg-9 col-xl-10'>
//                           <div className='row'>
//                             <div className='col'>
//                               <h4 className='prd-cat-title'>
//                                 {this.state.category}
//                               </h4>
//                             </div>
//                           </div>

//                           <div className='row'>
//                             {this.state.ploaded ? (
//                               products ? (
//                                 products.map((product) => (
//                                   <>
//                                     <ProducBoxSpiceway
//                                       params={{
//                                         product: product,
//                                         visible: this.state.visible,
//                                         loaded: this.state.loaded,
//                                       }}
//                                       handleCancel={this.handleCancel}
//                                       onNotify={this.onNotify}
//                                       onAddItem={this.onAddItem}
//                                       onQtyIncrese={this.onQtyIncrese}
//                                       onQtyReduce={this.onQtyReduce}
//                                       onFinish={this.onFinish}
//                                     />
//                                   </>
//                                 ))
//                               ) : (
//                                 <>
//                                   <Skeleton
//                                     active
//                                     avatar
//                                     paragraph={{ rows: 4 }}
//                                   />
//                                 </>
//                               )
//                             ) : (
//                               <>
//                                 <Skeleton
//                                   active
//                                   avatar
//                                   paragraph={{ rows: 4 }}
//                                 />
//                               </>
//                             )}
//                           </div>
//                         </div>

//                         <div className='col-12 col-md-12 col-lg-12'>
//                           <ReactPaginate
//                             previousLabel={"Previous"}
//                             nextLabel={"Next"}
//                             breakLabel={"..."}
//                             breakClassName={"break-me"}
//                             pageCount={
//                               this.state.productscount / this.state.perpage
//                             }
//                             marginPagesDisplayed={2}
//                             pageRangeDisplayed={5}
//                             pageClassName={"page-item"}
//                             pageLinkClassName={"page-link"}
//                             previousClassName={"page-link"}
//                             nextClassName={"page-link"}
//                             onPageChange={this.handlePageClick}
//                             containerClassName={"pagination"}
//                             subContainerClassName={"pages pagination"}
//                             activeClassName={"active"}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               );
//             case "MeatShop":
//               return (
//                 <>
//                   <div className='hero-banner-inner products'>
//                     <div className='hero-banner-inner-text'>
//                       <h1>{this.state.category}</h1>
//                     </div>
//                   </div>

//                   <div className='container breadcrumb-container'>
//                     <div className='row'>
//                       <div className='col-sm-12'>
//                         <div className='breadcrumb-in'>
//                           <ul className='site-breadcrumb'>
//                             <li>
//                               <a href='/'>Home</a>
//                             </li>
//                             <li>
//                               <a href='/products'>Products</a>
//                             </li>
//                             <li class='current'>{this.state.category}</li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className='section productlisting'>
//                     <div className='container'>
//                       <div className='item-count'>
//                         <h6>{this.state.productscount} Items Found</h6>
//                       </div>

//                       <div className='row'>
//                         <div className='col-md-4 col-lg-3 col-xl-2'>
//                           <LeftMeatShopMenu
//                             parentCallback={this.handleCallback}
//                             params={{
//                               catid: this.state.catid,
//                               catname: this.state.category,
//                             }}
//                           />
//                         </div>

//                         <div className='col-md-8 col-lg-9 col-xl-10'>
//                           <div className='row'>
//                             {this.state.ploaded ? (
//                               products ? (
//                                 products.map((product) => (
//                                   <ProducBoxMeatShop
//                                     params={{
//                                       product: product,
//                                       visible: this.state.visible,
//                                       loaded: this.state.loaded,
//                                     }}
//                                     handleCancel={this.handleCancel}
//                                     onNotify={this.onNotify}
//                                     onAddItem={this.onAddItem}
//                                     onQtyIncrese={this.onQtyIncrese}
//                                     onQtyReduce={this.onQtyReduce}
//                                     onFinish={this.onFinish}
//                                   />
//                                 ))
//                               ) : (
//                                 <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                                   <br />
//                                   <Skeleton
//                                     active
//                                     avatar
//                                     paragraph={{ rows: 4 }}
//                                   />
//                                 </div>
//                               )
//                             ) : (
//                               <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                                 <br />
//                                 <Skeleton
//                                   active
//                                   avatar
//                                   paragraph={{ rows: 4 }}
//                                 />
//                               </div>
//                             )}
//                           </div>

//                           <div className='load-more'>
//                             <ReactPaginate
//                               previousLabel={"Previous"}
//                               nextLabel={"Next"}
//                               breakLabel={"..."}
//                               breakClassName={"break-me"}
//                               pageCount={
//                                 this.state.productscount / this.state.perpage
//                               }
//                               marginPagesDisplayed={2}
//                               pageRangeDisplayed={5}
//                               onPageChange={this.handlePageClick}
//                               containerClassName={"pagination"}
//                               subContainerClassName={"pages pagination"}
//                               activeClassName={"active"}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               );

//             case "Stationary":
//               return (
//                 <>
//                   <div className='hero-banner-inner products'>
//                     <div className='hero-banner-inner-text'>
//                       <h1>{this.state.category}</h1>
//                     </div>
//                   </div>

//                   <div className='container breadcrumb-container'>
//                     <div className='row'>
//                       <div className='col-sm-12'>
//                         <div className='breadcrumb-in'>
//                           <ul className='site-breadcrumb'>
//                             <li>
//                               <a href='/'>Home</a>
//                             </li>
//                             <li>
//                               <a href='/products'>Products</a>
//                             </li>
//                             <li class='current'>{this.state.category}</li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className='section productlisting'>
//                     <div className='container'>
//                       <div className='item-count'>
//                         <h6>{this.state.productscount} Items Found</h6>
//                       </div>

//                       <div className='row'>
//                         <div className='col-md-4 col-lg-3 col-xl-2'>
//                           <LeftMeatShopMenu
//                             parentCallback={this.handleCallback}
//                             params={{
//                               catid: this.state.catid,
//                               catname: this.state.category,
//                             }}
//                           />
//                         </div>

//                         <div className='col-md-8 col-lg-9 col-xl-10'>
//                           <div className='row'>
//                             {this.state.ploaded ? (
//                               products ? (
//                                 products.map((product) => (
//                                   <ProducBoxMeatShop
//                                     params={{
//                                       product: product,
//                                       visible: this.state.visible,
//                                       loaded: this.state.loaded,
//                                     }}
//                                     handleCancel={this.handleCancel}
//                                     onNotify={this.onNotify}
//                                     onAddItem={this.onAddItem}
//                                     onQtyIncrese={this.onQtyIncrese}
//                                     onQtyReduce={this.onQtyReduce}
//                                     onFinish={this.onFinish}
//                                   />
//                                 ))
//                               ) : (
//                                 <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                                   <br />
//                                   <Skeleton
//                                     active
//                                     avatar
//                                     paragraph={{ rows: 4 }}
//                                   />
//                                 </div>
//                               )
//                             ) : (
//                               <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
//                                 <br />
//                                 <Skeleton
//                                   active
//                                   avatar
//                                   paragraph={{ rows: 4 }}
//                                 />
//                               </div>
//                             )}
//                           </div>

//                           <div className='load-more'>
//                             <ReactPaginate
//                               previousLabel={"Previous"}
//                               nextLabel={"Next"}
//                               breakLabel={"..."}
//                               breakClassName={"break-me"}
//                               pageCount={
//                                 this.state.productscount / this.state.perpage
//                               }
//                               marginPagesDisplayed={2}
//                               pageRangeDisplayed={5}
//                               onPageChange={this.handlePageClick}
//                               containerClassName={"pagination"}
//                               subContainerClassName={"pages pagination"}
//                               activeClassName={"active"}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               );

//             default:
//               return (
//                 <>
//                   <Row>
//                     <Col sm={12}>
//                       <Breadcrumb>
//                         <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
//                         <Breadcrumb.Item href='/products/'>
//                           Products by Category
//                         </Breadcrumb.Item>
//                       </Breadcrumb>
//                     </Col>
//                     <LeftMenu
//                       parentCallback={this.handleCallback}
//                       params={{
//                         catid: this.state.catid,
//                         catname: this.state.category,
//                       }}
//                     />

//                     <Col sm={9}>
//                       <Col sm={12}>
//                         <Row>
//                           <Col sm={8}>
//                             <h4>{this.state.category}</h4>
//                             <p>
//                               <strong> {this.state.productscount}</strong>{" "}
//                               Products found
//                             </p>
//                           </Col>
//                           <Col sm={4} className='float-right'>
//                             <Form.Item name='select' label='Sort by:'>
//                               <Select
//                                 placeholder='Please sort by'
//                                 onChange={handleChange}
//                                 defaultValue='BrowseRelevance'
//                               >
//                                 <option value='PriceAsc'>
//                                   {" "}
//                                   Price Low to High{" "}
//                                 </option>
//                                 <option value='PriceDesc'>
//                                   {" "}
//                                   Price High to Low{" "}
//                                 </option>
//                                 <option value='CUPAsc'>
//                                   {" "}
//                                   Unit Price Low to High{" "}
//                                 </option>
//                                 <option value='BrowseRelevance'>
//                                   Relevance{" "}
//                                 </option>
//                               </Select>
//                             </Form.Item>
//                           </Col>
//                         </Row>
//                       </Col>
//                       <Row>
//                         <Col>
//                           <div className='list'></div>
//                         </Col>
//                       </Row>

//                       <Row>
//                         {this.state.ploaded ? (
//                           products ? (
//                             products.map((product) => (
//                               <Col xs={6} md={6} lg='3'>
//                                 <div className='list'>
//                                   <LazyLoad>
//                                     <div className='card'>
//                                       <div md={{ span: 3, offset: 3 }}>
//                                         {product.Offers[0] ? (
//                                           product.Offers[0].Offer.OfferType ==
//                                           3 ? (
//                                             <>
//                                               {" "}
//                                               <span class='mulitistock'>
//                                                 MULTI BUY
//                                               </span>
//                                             </>
//                                           ) : (
//                                             ""
//                                           )
//                                         ) : (
//                                           ""
//                                         )}
//                                         {product.IsOutOfStock ? (
//                                           <>
//                                             {" "}
//                                             <span class='outofstock'>
//                                               OUT OF STOCK
//                                             </span>
//                                           </>
//                                         ) : (
//                                           ""
//                                         )}{" "}
//                                         <FontAwesomeIcon icon={faHeart} />
//                                       </div>

//                                       <a
//                                         key={"pid" + product.ProductID}
//                                         href={
//                                           "/product/" +
//                                           product.Name.replace(/ /g, "-")
//                                             .replace("/", "-")
//                                             .toLowerCase() +
//                                           "/" +
//                                           product.ProductID
//                                         }
//                                       >
//                                         {product.OnLineImages.PrimaryImages !==
//                                         null ? (
//                                           <div style={{ width: "auto" }}>
//                                             {/* {this.state.loaded ? null :
//                                       <div className="divLoader">
//                                         <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
//                                       Loading
//                                       </div>
//                                     } */}
//                                             <img
//                                               src={
//                                                 product.OnLineImages
//                                                   .PrimaryImages.Main
//                                               }
//                                               alt={product.Name}
//                                               onLoad={() =>
//                                                 this.setState({ loaded: true })
//                                               }
//                                               style={
//                                                 this.state.loaded
//                                                   ? {}
//                                                   : { display: "none" }
//                                               }
//                                             />
//                                             <input
//                                               type='hidden'
//                                               id={
//                                                 "prodprimimage" +
//                                                 product.ProductID
//                                               }
//                                               value={
//                                                 product.OnLineImages
//                                                   .PrimaryImages.Main
//                                               }
//                                             />
//                                           </div>
//                                         ) : (
//                                           <div>
//                                             <img
//                                               src='/images/noimage.jpg'
//                                               alt={product.Name}
//                                             />
//                                             <input
//                                               type='hidden'
//                                               id={
//                                                 "prodprimimage" +
//                                                 product.ProductID
//                                               }
//                                               value=''
//                                             />
//                                           </div>
//                                         )}
//                                       </a>

//                                       <h4>{product.Name}</h4>
//                                       <p>
//                                         <b>Item Code:</b>
//                                         <br /> {product.ProductRefNo}
//                                       </p>

//                                       <div className='row'>
//                                         <div
//                                           className={
//                                             product.Discount != 0
//                                               ? "priceleft col-sm-6"
//                                               : "priceleft col-sm-12"
//                                           }
//                                         >
//                                           <p className='price'>
//                                             <sup className='pricex'>
//                                               {localStorage.getItem(
//                                                 "ecurrencyCode"
//                                               )}
//                                             </sup>
//                                             <sub>
//                                               {
//                                                 String(
//                                                   product.SellingPricesArray
//                                                     .SellingPrice *
//                                                     localStorage.getItem(
//                                                       "ecurrencyratio"
//                                                     )
//                                                 ).split(".")[0]
//                                               }
//                                               <input
//                                                 type='hidden'
//                                                 id={
//                                                   "prodprice" +
//                                                   product.ProductID
//                                                 }
//                                                 value={
//                                                   product.SellingPricesArray
//                                                     .SellingPrice
//                                                 }
//                                               />
//                                             </sub>
//                                             <sup>
//                                               {String(
//                                                 product.SellingPricesArray
//                                                   .SellingPrice *
//                                                   localStorage.getItem(
//                                                     "ecurrencyratio"
//                                                   )
//                                               ).split(".")[1] != null
//                                                 ? String(
//                                                     (
//                                                       product.SellingPricesArray
//                                                         .SellingPrice *
//                                                       localStorage.getItem(
//                                                         "ecurrencyratio"
//                                                       )
//                                                     ).toFixed(2)
//                                                   ).split(".")[1]
//                                                 : "00"}
//                                             </sup>
//                                           </p>
//                                         </div>
//                                         {product.Discount != 0 ? (
//                                           <div className='priceright col-sm-6'>
//                                             was{" "}
//                                             {localStorage.getItem(
//                                               "ecurrencyCode"
//                                             )}
//                                             {parseInt(
//                                               product.SellingPricesArray
//                                                 .SellingPrice
//                                             ) *
//                                               parseInt(
//                                                 localStorage.getItem(
//                                                   "ecurrencyratio"
//                                                 )
//                                               ) +
//                                               parseInt(product.Discount)}{" "}
//                                             <br />
//                                             <span className='dispricetxt'>
//                                               save{" "}
//                                               {localStorage.getItem(
//                                                 "ecurrencyCode"
//                                               )}
//                                               {product.Discount}
//                                             </span>
//                                           </div>
//                                         ) : (
//                                           ""
//                                         )}
//                                       </div>

//                                       {product.SellingPricesArray.UnitChartid >
//                                       0 ? (
//                                         <Table
//                                           striped
//                                           bordered
//                                           hover
//                                           size='sm'
//                                           width='50%'
//                                         >
//                                           <thead>
//                                             <tr>
//                                               <th>Pack Size</th>
//                                               <th>Weight</th>
//                                               <th>Type</th>
//                                             </tr>
//                                           </thead>
//                                           <tbody>
//                                             {product.UnitCharts.length > 0 ? (
//                                               product.UnitCharts.map((elu) => (
//                                                 <tr>
//                                                   <td>{elu.Quantity}</td>
//                                                   <td>{product.Weight}</td>
//                                                   <td>{elu.UnitTypeName}</td>
//                                                 </tr>
//                                               ))
//                                             ) : (
//                                               <tr>
//                                                 <td>1</td>
//                                                 <td>{product.Weight}</td>
//                                                 <td>EA</td>
//                                               </tr>
//                                             )}
//                                           </tbody>
//                                         </Table>
//                                       ) : product.Weight ? (
//                                         product.Weight
//                                       ) : (
//                                         ""
//                                       )}

//                                       {product.IsOutOfStock ? (
//                                         <div className='text-center'>
//                                           {" "}
//                                           <p className='inputandbtn'>
//                                             <button
//                                               className='notify'
//                                               onClick={this.onNotify.bind(
//                                                 this,
//                                                 product.ProductID,
//                                                 product.Name,
//                                                 product.MaxOnlineOrderQty
//                                               )}
//                                               id={product.ProductID}
//                                             >
//                                               <FontAwesomeIcon
//                                                 icon={faEnvelope}
//                                               />
//                                               Notify
//                                             </button>
//                                           </p>{" "}
//                                           <Modal
//                                             show={this.state.visible}
//                                             onHide={this.handleCancel}
//                                             centered
//                                             aria-labelledby='example-modal-sizes-title-sm'
//                                           >
//                                             <Modal.Header closeButton>
//                                               <Modal.Title id='example-modal-sizes-title-sm'>
//                                                 Notify
//                                               </Modal.Title>
//                                             </Modal.Header>
//                                             <Modal.Body>
//                                               <Form
//                                                 onFinish={onFinish}
//                                                 name='basic'
//                                                 initialValues={{
//                                                   remember: true,
//                                                 }}
//                                               >
//                                                 <Form.Item
//                                                   label='Email Address'
//                                                   name='email'
//                                                   rules={[
//                                                     {
//                                                       required: true,
//                                                       type: "email",
//                                                     },
//                                                   ]}
//                                                 >
//                                                   <Input placeholder='Email Address' />
//                                                 </Form.Item>

//                                                 <Form.Item>
//                                                   <Input
//                                                     name='prdt'
//                                                     type='hidden'
//                                                     id='prdt'
//                                                     value={product.ProductID}
//                                                   />
//                                                   <Button
//                                                     type='primary'
//                                                     htmlType='submit'
//                                                     ref='btn'
//                                                     danger
//                                                   >
//                                                     Submit
//                                                   </Button>
//                                                 </Form.Item>
//                                               </Form>
//                                             </Modal.Body>
//                                           </Modal>
//                                         </div>
//                                       ) : (
//                                         <p className='inputandbtn'>
//                                           <NumericInput
//                                             min={1}
//                                             max={
//                                               product.MaxOnlineOrderQty === 0
//                                                 ? 10000
//                                                 : product.MaxOnlineOrderQty
//                                             }
//                                             precision={0}
//                                             className='inputqty'
//                                             id={"prodqty" + product.ProductID}
//                                             value={1}
//                                             snap
//                                           />
//                                           <button
//                                             onClick={this.onAddItem.bind(
//                                               this,
//                                               "add",
//                                               product.ProductID,
//                                               product.Name,
//                                               product.MaxOnlineOrderQty,
//                                               product.ProductCategoryID,
//                                               product.CurrentOffer.ID
//                                                 ? product.CurrentOffer.OfferProducts.map(
//                                                     (prt) => prt.ProductID
//                                                   ).join(",")
//                                                 : "",
//                                               product.CurrentOffer.ID
//                                                 ? product.CurrentOffer.Offer
//                                                     .OfferQty
//                                                 : "",
//                                               product.CurrentOffer.ID
//                                                 ? product.CurrentOffer.Offer
//                                                     .OfferPrice
//                                                 : ""
//                                             )}
//                                             id={product.ProductID}
//                                           >
//                                             <FontAwesomeIcon
//                                               icon={faShoppingCart}
//                                             />
//                                             <span>ADD</span>
//                                           </button>
//                                         </p>
//                                       )}
//                                     </div>
//                                     <br />
//                                   </LazyLoad>
//                                 </div>
//                               </Col>
//                             ))
//                           ) : (
//                             <Col sm={12}>
//                               <br />
//                               <Skeleton active avatar paragraph={{ rows: 4 }} />
//                             </Col>
//                           )
//                         ) : (
//                           <Col sm={12}>
//                             <br />
//                             <Skeleton active avatar paragraph={{ rows: 4 }} />
//                           </Col>
//                         )}
//                       </Row>

//                       <Col>
//                         <ReactPaginate
//                           previousLabel={"Previous"}
//                           nextLabel={"Next"}
//                           breakLabel={"..."}
//                           breakClassName={"break-me"}
//                           pageCount={
//                             this.state.productscount / this.state.perpage
//                           }
//                           marginPagesDisplayed={2}
//                           pageRangeDisplayed={5}
//                           onPageChange={this.handlePageClick}
//                           containerClassName={"pagination"}
//                           subContainerClassName={"pages pagination"}
//                           activeClassName={"active"}
//                         />
//                       </Col>
//                     </Col>
//                   </Row>
//                 </>
//               );
//           }
//         })()}
//       </>
//     );
//   }
// }

// export default Application;
