import React from "react";

const ImageWidget = (props) => {
  return (
    <div className={props.params.className} style={{ height: '100%', left: '0 !important' }}>
      {
        props.params.ItemData && props.params.ItemData !== "" && (
            <img
              alt=""
              src={props.params.ItemData}
              draggable="false"
              style={{ width: "100%", height: "100%" }}
            />

        )
      }
    </div>
  );
}

export default ImageWidget;