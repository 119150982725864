import React, { useState } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from "lodash";
import LayoutInclude from "./default/layout-include";
import axios from "axios";
import config from "react-global-configuration";
import { Form, Button, Row, Col } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { async } from "witmeg-ui-system";


const ResponsiveReactGridLayout = WidthProvider(Responsive);

function extractTranslateFromTransform(transform) {

  let translateValue = null;
  let translate = transform.match(/translate\(.*\)/)?.[0];
  if (translate) {
    translateValue = {};
    if (translate.indexOf(",") !== -1) {
      translateValue.x = parseFloat(
        translate.substring(translate.indexOf("(") + 1, translate.indexOf(","))
      );
      translateValue.y = parseFloat(
        translate.substring(translate.indexOf(",") + 1)
      );
    } else {
      translateValue.x = translateValue.y = parseFloat(
        translate.substring(translate.indexOf("(") + 1)
      );
    }
  }
  return translateValue;
}


class ResponsiveLocalStorageLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    margin: [0, 0],
    isDraggable: false,
    isResizable: false,
    // cols: { lg: 12, md: 12, sm: 12, xs: 2, xxs: 2 },
    // cols: { lg: 96, md: 6, sm: 6, xs: 4, xxs: 2 },
    // rowHeight: 39,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageid: "",
      id: "",
      pages: [],
      menus: [],
      layout: [],
      widgets: [],
      items: [],
      windowHeight: 0,
      windowWidth: 0,
      ploading: true,
      newCounter: 0,
      pageID: null
    };
  }

  resize = () => {
    if (window.innerWidth < 1681) {
      console.log("md");
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var whight = ulElems[i].style.height;
            ulElems[i].getElementsByClassName("w-100")[0].style.height = whight;
          }
        }
      }, 1000);
    }

    if (window.innerWidth < 1200) {
      // console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          var newh = ulElems[i].style.height.replace("px", "") - 10;
          ulElems[i].style.height = newh + "px";
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height =
              newh + "px";
          }
        }
      }, 1000);
    }

    if (window.innerWidth <= 1199) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style = "";
          ulElems[i].classList.add("newLayoutList");
          ulElems[i].style.position = "relative";
          ulElems[i].style.height = "100%";
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove(
            "react-grid-item",
            "cssTransforms",
            "react-resizable-hide",
            "react-resizable"
          );
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }
  };

  onBreakpointChange = () => { };

  handleFetchData = async () => {
    console.log('page rendered==>> ', this?.props?.params?.pageid)
    // if (this?.state?.pageID) {
    if (this?.props?.params?.pageid) {
      console.log('pageID==>> ', this?.props?.params?.pageid)

      let PageID = this?.props?.params?.pageid;

      axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
        isLoginServerRequest: false,
        serviceName: 'retailpacx',
      }).then(async response => {
        axios.post(
          `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/page`,
          {
            ID: "",
            PageID: PageID,
            CompanyID: config.get("companyID"),
            Start: 0,
            Limit: 1000,
          },
          {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          }
        ).then(async response => {
          console.log('PAGES ==>', response.data.Result)
          if (response.data.Result.length > 0) {
            var x = response.data.Result.Desktop.map(function (i, key, list) {
              return {
                i: [i.ID, i.ItemHeading, i.Item, i.ItemData, i.Type],
                x: i.X,
                y: i.Y,
                w: i.Width,
                h: i.Height,
                add: i === list.length - 1,
              };
            });
            console.log("IA M X", x);
            this.setState({
              layout: x,
              // loading: true,
            });
          } else {
            // this.setState({ loading: false });
          }
        })
      })
    } else {
      axios
        .post(process.env.REACT_APP_TOKENSERVICE_URL, {
          isLoginServerRequest: false,
          serviceName: "retailpacx",
        })
        .then(async (response) => {
          await axios
            .post(
              `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/company`,
              {
                ID: "",
                PageID: '',
                CompanyID: config.get("companyID"),
                Start: 0,
                Limit: 1000,
              },
              {
                headers: {
                  Authorization: `Bearer ${response.data.token}`,
                },
              }
            )
            .then(async (response) => {

              console.log("home === > ", response.data.Result);
              if (response.data.Result.Desktop) {
                let homeResponse = response.data.Result.Desktop.filter(
                  (hl) => hl.PageID === this?.props?.params?.pageid
                );

                this.setState({
                  layout: homeResponse.map(function (i, key, list) {
                    return {
                      i: JSON.stringify([
                        i.ID,
                        i.ItemHeading,
                        i.Item,
                        i.ItemData,
                        i.Type,
                      ]),
                      x: i.X,
                      y: parseInt(i.Y),
                      w: i.Width,
                      h: i.Height,
                      // add: i === list.length - 1,
                    };
                  })
                })
                // console.log("IA M X", x);

              } else {
                // this.setState({ loading: false });
              }
            });
        });
    }


  }

  async componentDidUpdate(prevProps) {
    console.log("prevProps==>>", prevProps)
    console.log("this.props==>>", this.props)
    if (this?.props?.params?.pageid !== prevProps?.params?.pageid) {

      // var url = window.location.href.substring(
      //   window.location.href.lastIndexOf("/") + 1
      // );

      console.log("I AM PAGE PROPS", this.props.params.pageid)


      await this.setState({ pageID: this.props.params.pageid })


      // this.handleFetchData();
    }
  }


  componentDidMount() {

    window.addEventListener("resize", this.resize);

    // const id = this?.props?.history?.location?.pathname;

    // console.log("I AM ID", id)
    // var idx = await id?.split('/')[2];
    console.log("this.props==>>", this.props)
    console.log("I AM PAGE PROPS", this?.props?.params?.pageid)

    var url = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    this.setState({ pageID: this?.props?.params?.pageid })
    this.handleFetchData();



    document.title = config.get("companyPageTitle");

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = 'https://stackoverflow.com/favicon.ico';

    if (window.innerWidth < 1681) {
      console.log("md");
      var adjest = 0;

      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var hh = ulElems[i].style.height;
            ulElems[i].getElementsByClassName("w-100")[0].style.height = hh;
          }
        }
      }, 1000);
    }

    if (window.innerWidth < 1200) {
      // console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        var theight = 0;
        for (var i = 0; i < ulElems.length; i++) {
          console.log(ulElems[i].clientHeight / 39);

          theight = theight + ulElems[i].clientHeight;
          console.log(ulElems[i].style.height.replace("px", ""));
          var newh = ulElems[i].style.height.replace("px", "") - 10;

          ulElems[i].style.height = newh + "px";

          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height =
              newh + "px";
          }
        }
      }, 1000);
    }

    if (window.innerWidth <= 1199) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style = "";
          ulElems[i].classList.add("newLayoutList");
          ulElems[i].style.position = "relative";
          ulElems[i].style.height = "100%";
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove(
            "react-grid-item",
            "cssTransforms",
            "react-resizable-hide",
            "react-resizable"
          );
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  onPageLoad() {
    //  alert('this.props.params.pageid')
  }

  generateDOM(el) {

    const i = el.i;
    return (
      <div key={i} data-grid={{ x: el.x, y: el.y, w: el.w, h: el.h }}>
        <LayoutInclude params={{ layoutid: i[0], width: el.x }} />
      </div>
    );
  }


  render() {
    return (
      <div>
        <>
          {(() => {
            switch (config.get("Theme")) {
              case "Apparel-casi":
                return (
                  <>
                    {/* {this.state.ploading ? (
                    <div className='divLoader'>
                      <LoadingOutlined
                        style={{ fontSize: "56px", color: "#08c" }}
                      />
                      <br />
                      Loading
                    </div> */}
                    {/* // ) : ( */}
                    <>{_.map(this.state.layout, (el) => this.generateDOM(el))}</>
                    {/* // )} */}
                  </>
                );
              default:
                return (
                  <>
                    <div>
                      <Row>
                        <Col sm={12}>
                          {/* {this.state.ploading ? (
                          <div className='divLoader'>
                            <LoadingOutlined
                              style={{ fontSize: "56px", color: "#08c" }}
                            />
                            <br />
                            Loading
                          </div> */}
                          {/* // ) : ( */}
                          <ResponsiveReactGridLayout
                            cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                            allowOverlap={false}
                            rowHeight={30}
                            verticalCompact='vertical'
                            measureBeforeMount={false}
                            isDraggable={false}
                            isResizable={false}
                            useCSSTransforms={false}
                            compactType={false}
                            preventCollision={true}
                            margin={[0, 0]}
                            className='layout'
                            {...this.props}
                          >
                            {_.map(this.state.layout, (el) => this.generateDOM(el))}
                          </ResponsiveReactGridLayout>
                          {/* // )} */}
                        </Col>
                      </Row>
                    </div>
                  </>
                );
            }
          })()}
        </>
      </div>
    );
  }
}
export default withRouter(ResponsiveLocalStorageLayout)