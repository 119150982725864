import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";
const Page = (props) => (
  <li>
    {" "}
    <a href={"/pages/" + props.page.slug}>{props.page.pagetitle}</a>
  </li>
);

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {
    var primaryTextStyle =
      "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle =
      "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle =
      "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

    fetch(config.get("backendURL") + "menus/footer/" + config.get("companyID"))
      .then((response) => response.json())
      .then((data) => this.onSetFooter(data));

    axios
      .post(process.env.REACT_APP_TOKENSERVICE_URL, {
        isLoginServerRequest: false,
        serviceName: "retailpacx",
      })
      .then(function (response) {
        axios
          .post(
            `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retaul/company/id`,
            {
              ID: "632b069adda41efdc5f15b44",
              OrganisationID: "",
              SubDomain: "",
              CustomerDomain: "",
              Start: 0,
              Limit: 0,
              IsSearchApplicable: false,
              Search: {
                Key: "",
                Value: "",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          )
          .then((response) => {
            this.setState({
              Name: response.data.Name,
              AddressLine1: response.data.AddressLine1,
              AddressLine2: response.data.AddressLine2,
              City: response.data.City,
              StateProvinceRegion: response.data.StateProvinceRegion,
              PostZipCode: response.data.PostZipCode,
              Country: response.data.Country,
              Email: response.data.Email,
              Phone: response.data.Phone,
            });
          });
      });

    // axios
    //   .get(config.get("backendURL") + "company/" + "632b069adda41efdc5f15b44")
    //   .then((response) => {
    //     this.setState({
    //       Name: response.data.Name,
    //       AddressLine1: response.data.AddressLine1,
    //       AddressLine2: response.data.AddressLine2,
    //       City: response.data.City,
    //       StateProvinceRegion: response.data.StateProvinceRegion,
    //       PostZipCode: response.data.PostZipCode,
    //       Country: response.data.Country,
    //       Email: response.data.Email,
    //       Phone: response.data.Phone,
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  onSetFooter = (result) => {
    this.setState({ menus: result });
    // console.log(result)
    sessionStorage.setItem("footer", JSON.stringify(result));
  };

  render() {
    return (
      <>
        <hr />

        {/* Footer Area*/}
        <div className='site-footer'>
          <div className='row'>
            <div className='col-sm-5'>
              <h6 className='text-footer'>Contact Info</h6>
              <div className='contact-info'>
                <ul>
                  <li> {this.state.Name ? this.state.Name : ""}</li>
                  {this.state.AddressLine1 ? (
                    <>
                      <li> {this.state.AddressLine1} </li>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.AddressLine2 ? (
                    <>
                      <li> {this.state.AddressLine2} </li>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.City ? (
                    <>
                      {" "}
                      <li>{this.state.City} </li>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.StateProvinceRegion ? (
                    <>
                      {" "}
                      <li>{this.state.StateProvinceRegion} </li>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.PostZipCode ? (
                    <>
                      {" "}
                      <li>{this.state.PostZipCode} </li>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.Country ? (
                    <>
                      {" "}
                      <li>{this.state.Country}</li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
                <ul className='cont-direct'>
                  <li>{this.state.Email}</li>
                  <li className='font-weight-bold'>{this.state.Phone}</li>
                </ul>
                <ul className='social-media-links'>
                  <li>
                    <a
                      href='https://www.facebook.com/pg/Casipillai/'
                      target='_blank'
                    >
                      <i className='fab fa-facebook-square' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/casipillaidesignercollection/'
                      target='_blank'
                    >
                      <i className='fab fa-instagram-square' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.youtube.com/user/angel83'
                      target='_blank'
                    >
                      <i className='fab fa-youtube-square' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://twitter.com/casipillai?lang=en'
                      target='_blank'
                    >
                      <i className='fab fa-twitter-square' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.pinterest.co.uk/Casipillai/'
                      target='_blank'
                    >
                      <i className='fab fa-pinterest-square' />
                    </a>
                  </li>
                </ul>
                <p className='cards-text'>We are using safe payments for:</p>

                <ul className='pay-cards'>
                  <li>
                    <span className='visa_cd'>
                      <i className='fab fa-cc-visa' />
                    </span>
                  </li>
                  <li>
                    <span className='mast_cd'>
                      <i className='fab fa-cc-mastercard' />
                    </span>
                  </li>
                  <li>
                    <span className='amex_cd'>
                      <i className='fab fa-cc-amex' />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-sm-7'>
              <h6 className='text-footer'>Locate Us</h6>
              <div className='map-responsive'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.4910829746104!2d-0.3721880843402085!3d51.577562479646666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761367a6c59aa9%3A0x52f62dd5280d3ec6!2sCasipillai%20Designer%20Collection%20Limited!5e0!3m2!1sen!2slk!4v1633810866533!5m2!1sen!2slk'
                  width={400}
                  height={300}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading='lazy'
                />
              </div>
            </div>
          </div>
          <div className='row mt20'>
            <div className='col-sm-12'>
              <hr className='footer-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='copytext'>
                © Casipillai Designer Collection. All Rights Reserved
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='user-agreement'>
                <a href='#'>Terms of Use</a>
                <a href='#'>Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Area*/}
      </>
    );
  }
}
