import React from 'react'
import { useSelector } from 'react-redux';
import PaymentDetailsForm from '../ResturatnWidget/Common/PaymentDetailsForm';
import RetailPaymentDetailsForm from './RetailPaymentDetailsForm';
export default function PaymentDetails() {
    const companyDataState = useSelector((state) => state.companyData);
    return (
        <>
            {companyDataState.companyDetails.websiteType === "E-Commerce" ?
                // <div style={{ maxWidth: '1650px', margin: 'auto' }} className='eco-retailcart-summary'>
                //     PaymentDetails
                // </div>
                <RetailPaymentDetailsForm />
                :
                <PaymentDetailsForm />
            }
        </>
    )
}
