import React from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Divider, Modal, Avatar, Form, Input, Button, Checkbox } from "antd";
import config from "react-global-configuration";
import jwt from "jsonwebtoken";
import LoginMartFury from "../themes/martfury/login.jsx";
import LoginApparel from "../themes/apparel/login.jsx";
import LoginApparelCasi from "../themes/apparel-casi/login.jsx";
import { renderEmail } from "postonents";
import VerificationEmail from "../email/VerificationEmail";
import _ from "lodash";

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem("loggedin") === "true";
    this.state = { disabled: false, ractive: false, lactive: true };
  }

  componentDidMount() {
    document.title = "Login | " + config.get("companyPageTitle");
  }

  render() {
    const onTaggle = (values) => {
      if (values === "register") {
        this.setState({ ractive: true, lactive: false });
      } else if (values === "login") {
        this.setState({ lactive: true, ractive: false });
      }
    };

    const onFinish = (values) => {
      this.setState({ disabled: true });

      //this.refs.btn.setAttribute("disabled", "disabled");
      document.getElementById("btn").setAttribute("disabled", "disabled");
      const requestOptions = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + config.get("LoginAuthorizationKey"),
        },
        body: JSON.stringify({
          UserName: values.username,
          Password: values.password,
          OrganizationID: config.get("OrganisationID"),
        }),
      };

      //  console.log(requestOptions);
      fetch(config.get("API_URL_cust") + "login", requestOptions)
        .then((response) => response.json())
        .then(function (data) {
          if (data.Status === false) {
            Modal.error({
              title: "Invalid Login",
              content: "Invalid username or password",
              onOk() {
                window.location.href = "/account/login";
              },
            });
          } else {
            sessionStorage.setItem("AccessToken", data.Result.AccessToken);
            sessionStorage.setItem("RefreshToken", data.Result.RefreshToken);
            sessionStorage.setItem("loggedin", "true");

            const expireTime =
              new Date().getTime() + config.get("CustjwtExp") * 1000;

            var payload = {
              aud: config.get("CustjwtAud"),
              exp: expireTime,
              iss: config.get("CustjwtIss"),
              usertoken: data.Result.AccessToken,
            };

            var signOptions = {
              algorithm: "RS256",
            };

            var privateKey = config.get("LoginprivateKey");
            var token = jwt.sign(payload, privateKey, signOptions);
            //console.log(token);

            const requestUserDtlsOptions = {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + token,
              },
              body: JSON.stringify({
                Email: values.username.toLowerCase(),
                OrganizationID: config.get("OrganisationID"),
              }),
            };

            fetch(config.get("API_URL_cust") + "fetch", requestUserDtlsOptions)
              .then((response) => response.json())
              .then(function (data) {
                //console.log(data.Result);

                sessionStorage.setItem("username", data.Result.UserName);
                sessionStorage.setItem("userfirstname", data.Result.FirstName);
                sessionStorage.setItem("useremail", values.username);
                sessionStorage.setItem("userlastname", data.Result.LastName);
                sessionStorage.setItem("UserID", data.Result.UserID);
                sessionStorage.setItem(
                  "OriginatedOrganizationID",
                  data.Result.OriginatedOrganizationID
                );
                localStorage.setItem(
                  "fullUserDetails",
                  JSON.stringify(data.Result)
                );

                window.location.href = "/checkout";
              });
          }
        });
    };

    const onRegFinish = (values) => {
      this.setState({ disabled: true });
      //this.refs.btn.setAttribute("disabled", "disabled");
      const requestOptions = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + config.get("LoginAuthorizationKey"),
        },
        body: JSON.stringify({
          Email: values.Email,
          OrganizationID: config.get("OrganisationID"),
        }),
      };
      // console.log(requestOptions);
      fetch(config.get("API_URL_cust") + "find", requestOptions)
        .then((response) => response.json())
        .then(function (data) {
          //   console.log(data);

          if (data.Status === false) {
            sessionStorage.setItem("tmpEmail", values.Email);

            let date = new Date();
            let DigitCode = _.random(100000, 999999);
            values.ExpireDate = date.setHours(
              date.getHours() + 24,
              date.getMinutes(),
              0,
              0
            );
            let token = encrypt(JSON.stringify(DigitCode));

            const data = {
              verificationToken: token,
              companyPageTitle: config.get("companyPageTitle"),
              SenderEmail: config.get("SenderEmail"),
              companyLogoCDN: config.get("companyLogoCDN"),
              DigitCode: DigitCode,
              email: values.Email,
            };
            const emailBody = renderEmail(VerificationEmail, {
              lang: "en",
              data,
            });

            sessionStorage.setItem("verificationtoken", token);
            sessionStorage.setItem("tmpEmail", values.Email);

            async function postData() {
              const ecoEmail = {
                to: values.Email,
                from:
                  config.get("companyPageTitle") +
                  "<" +
                  config.get("SenderEmail") +
                  ">",
                subject: "Your verification code",
                html: emailBody,
              };
              const response = await fetch(
                config.get("backendURL") + "mail/send",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(ecoEmail),
                }
              );
              return response.json();
            }

            postData().then((data) => {
              //  console.log(data)
              if (data === "SUCCESS") {
                Modal.success({
                  title: "Please verify your account",
                  content:
                    "Verification email has been sent to " + values.Email,
                  onOk() {
                    window.location.href = "/account/register/verify/code";
                  },
                });
              } else {
                Modal.error({
                  title: "Oops!! Something wrong",
                  content:
                    "We are sorry, Something wrong with the registration process. Please try again later.",
                  onOk() {
                    window.location.href = "/account/register";
                  },
                });
              }
            });
          } else {
            let secondsToGo = 3;
            const modal = Modal.success({
              title: "You have already an account with us",
              content: `Please login to continue the shopping`,
            });
            const timer = setInterval(() => {
              secondsToGo -= 1;
              modal.update({
                content: `Please login to continue the shopping`,
              });
            }, 1000);
            setTimeout(() => {
              clearInterval(timer);
              modal.destroy();
              window.location.href = "/account/login";
            }, secondsToGo * 1000);
          }
        });

      //console.log('Received values of form: ', values);
    };

    if (this.loggedIn) {
      return <Redirect to='/account/' />;
    }

    function encrypt(data) {
      var CryptoJS = require("crypto-js");
      var key = "U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt";
      var cipherText = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        key
      ).toString();
      return cipherText;
    }

    return (
      <>
        {(() => {
          switch (config.get("Theme")) {
            case "Martfury":
              return (
                <LoginMartFury
                  params={{
                    disabled: this.state.disabled,
                    lactive: this.state.lactive,
                    ractive: this.state.ractive,
                  }}
                  onTaggle={onTaggle}
                  onFinish={onFinish}
                  onRegFinish={onRegFinish}
                />
              );
            case "Apparel":
              return (
                <LoginApparel
                  params={{
                    disabled: this.state.disabled,
                    lactive: this.state.lactive,
                    ractive: this.state.ractive,
                  }}
                  onTaggle={onTaggle}
                  onFinish={onFinish}
                  onRegFinish={onRegFinish}
                />
              );
            case "Apparel-casi":
              return (
                <LoginApparelCasi
                  params={{
                    disabled: this.state.disabled,
                    lactive: this.state.lactive,
                    ractive: this.state.ractive,
                  }}
                  onTaggle={onTaggle}
                  onFinish={onFinish}
                  onRegFinish={onRegFinish}
                />
              );
            default:
              return (
                <div style={{ backgroundColor: "#f5f5f5" }}>
                  <Row>
                    <Col sm={12}>
                      <Breadcrumb>
                        <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href='account/login'>
                          Login
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>
                    <Col sm={1}></Col>
                    <Col sm={4}>
                      <div className='fade alert alert-light show'>
                        <Row className='justify-content-md-center'>
                          <Col sm={12}>
                            <Divider orientation='left'>
                              <h5>ACCOUNT LOG IN</h5>
                            </Divider>
                            <Form
                              onFinish={onFinish}
                              name='basic'
                              initialValues={{ remember: true }}
                            >
                              <Form.Item
                                label='Username'
                                name='username'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your username!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <Form.Item
                                label='Password'
                                name='password'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your password!",
                                  },
                                ]}
                              >
                                <Input.Password />
                              </Form.Item>

                              <Form.Item>
                                <Form.Item
                                  name='remember'
                                  valuePropName='checked'
                                  noStyle
                                >
                                  <Checkbox>Remember me</Checkbox>
                                </Form.Item>

                                <a
                                  className='login-form-forgot float-right'
                                  href='/account/forgot'
                                >
                                  Forgot password
                                </a>
                              </Form.Item>

                              <Form.Item
                                name='messagebox'
                                label='Please wait'
                                hasFeedback
                                style={{ display: "none" }}
                                validateStatus='validating'
                                help='The information is being validated...'
                              ></Form.Item>

                              <Form.Item>
                                <Button
                                  type='primary'
                                  htmlType='submit'
                                  id='btn'
                                  block
                                  danger
                                >
                                  {this.state.disabled
                                    ? "Please wait..."
                                    : "Login"}
                                </Button>
                              </Form.Item>
                            </Form>
                            Don't have an account?{" "}
                            <a href='/account/register'>Create one now</a>{" "}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={2} className='or-icon'>
                      <Avatar
                        style={{
                          backgroundColor: "#393939",
                          verticalAlign: "middle",
                        }}
                        size='large'
                        gap='1'
                      >
                        or
                      </Avatar>
                    </Col>
                    <Col sm={4}>
                      <div className='fade alert alert-light show'>
                        <Row className='justify-content-md-center'>
                          <Col sm={12}>
                            <Divider orientation='left'>
                              <h5>GUEST CHECKOUT</h5>
                            </Divider>
                            <Form
                              name='basic'
                              initialValues={{ remember: true }}
                            >
                              <Form.Item
                                label='Email Address'
                                name='username'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your username!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <Form.Item>
                                <Button type='primary' htmlType='submit' block>
                                  Checkout as guest
                                </Button>
                              </Form.Item>
                            </Form>
                            You can checkout without creating an account. You'll
                            have a chance to create an account later.
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
          }
        })()}
      </>
    );
  }
}

export default Application;
