import { Space } from 'antd/lib'
import React, { useState, useEffect } from 'react'
import { Carousel, Row, Col, Typography } from 'witmeg-ui-system'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProductItem from './ProductItem'
import axios from 'axios';
import config from 'react-global-configuration';
import { Skeleton, Pagination } from 'antd';

export default function ProductsPage(props) {
    let history = useHistory()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [itemCount, setItemCount] = useState(12);
    const [currentPage, setCurrentPage] = useState(1)
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [loadingCategories, setLoadingCategories] = useState(true)
    const [productList, setProductList] = useState([])
    const [categories, setCategories] = useState([])

    const settingsState = useSelector((state) => state.companyData.settings)

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);

        if (settingsState.OrgID && settingsState.SalesLocationID) {
            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };
                // let OrgID = "621738ca7bfb741e995d15b0" //config.get("OrganisationID")

                let countUrl = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/count/${settingsState.OrgID}/${settingsState.SalesLocationID}`;
                axios.post(
                    countUrl,
                    {},
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setItemCount(Number(data.data.Result))
                        }
                    })

                let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${settingsState.OrgID}/${settingsState.SalesLocationID}/0/12`;
                console.log("featured url", url)
                axios.post(
                    url,
                    {},
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setProductList(data.data.Result)
                            setLoadingProducts(false)
                        }
                    })


                axios.get(`${process.env.REACT_APP_rest_API_productregistry}/onlinecategories/${settingsState.OrgID}`, requestOptions).then((categoryData) => {
                    // console.log("featured categoryData", categoryData)
                    if (categoryData.data.Status) {
                        setCategories(categoryData.data.Result)
                        setLoadingCategories(false)
                    }
                })

            });
        }



        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };

    }, [settingsState])

    const fetchProducts = (pageNumber) => {
        axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServerRequest: false,
            serviceName: "productRegistry",
        }).then(async function (authresponse) {
            setLoadingProducts(true)
            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authresponse.data.token}`,
                }
            };

            let OrgID = settingsState.OrgID //config.get("OrganisationID")
            let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${OrgID}/${settingsState.SalesLocationID}/${(pageNumber - 1) * 12}/12`;
            console.log("featured url", url)
            axios.post(
                url,
                {},
                requestOptions,
            )
                .then((data) => {
                    console.log("featured res", data)
                    if (data.data.Status) {
                        setProductList(data.data.Result)
                        setLoadingProducts(false)
                    }
                })
        })
    }


    const onChangePageNumber = (page, pageSize) => {
        setCurrentPage(page)
        fetchProducts(page)
        props.updateQueryParams({ 'page': page })
    }

    const loadProductByCategories = (ProductCategory) => {
        history.push({
            pathname: '/group/' + ProductCategory.ProductCategoryID,
        });
    }

    return (
        <>
            <div style={{ maxWidth: '1650px', margin: 'auto' }}>
                <Typography type="title" level={3}></Typography>
                <div>
                    <Row>
                        <Col lg={6} md={8} sm={24} xs={24}>

                            {loadingCategories ?
                                <div>
                                    <br />
                                    <Skeleton.Input />
                                    <br /> <br />
                                    <Skeleton.Input />
                                    <br /> <br />
                                    <Skeleton.Input />
                                </div>
                                : <>
                                    {categories.map(c => {
                                        return (
                                            <div style={{ padding: '8px', borderBottom: '1px solid #eee', width: '100%', cursor: 'pointer' }} onClick={() => loadProductByCategories(c.ProductCategory)}>
                                                <h5>
                                                    {c.ProductCategory.Category}
                                                </h5>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </Col>

                        <Col lg={18} md={16}>
                            {loadingProducts ?
                                <>
                                    <br />
                                    <Row gutter={16}>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                    </Row>
                                </> :
                                <Row>

                                    {productList.map((p) => {
                                        return (
                                            <Col key={p.ID} xl={6} lg={8} md={12} sm={12} xs={24}>
                                                <div >
                                                    <ProductItem product={p} isFeatured={false} isOffers={false} />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>

                            }
                            <Row>
                                <Col>
                                    <Pagination current={currentPage} total={itemCount} defaultPageSize={12} onChange={onChangePageNumber} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <br />
        </>
    )
}

