import React, { Component } from 'react';
import { render } from 'react-dom';
import { withStyles } from "@material-ui/styles";
import { Modal } from 'antd';
import { Row, Col } from 'react-bootstrap';
import './app.css';
import "react-image-lightbox/style.css";

import {
  Magnifier,
  SideBySideMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";
import Lightbox from "react-image-lightbox";


const styles = theme => ({
  root: {}
});

class MyReactImageMagnify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, arr: [], ProductImage: this.props.params.ProductMainImage, photoIndex: 0,
      isOpen: false
    };
  }
  handleBoolChange = key => e => {
    const value = Boolean(e.target.value);
    this.setState(() => ({ [key]: value }));
  };

  handleActivationChange = key => e => {
    const value = e.target.value;
    this.setState(() => ({ [key]: value }));
  };
  get srcSet() {
    return [
      `${this.props.params.ProductMainImage} 1200w`,
    ].join(', ');
  }


  componentDidMount() {
    const SpecialImages = this.props.params.SpecialImages;
    var arr = [];
    var json = this.props.params.ProductMainImages;
    Object.keys(json).forEach(function (key, index) {
      arr.push({ "original": json[key] });
    });


    if (SpecialImages) {
      var json = this.props.params.SpecialImages;
      Object.keys(json).forEach(function (key, index) {
        var json2 = json[key];
        Object.keys(json2).forEach(function (key2, index2) {
          arr.push({ "original": json2[key2].ImgURL });
        });
      });
    }
    this.setState({
      photoIndex: arr.findIndex(item => item.original === decodeURIComponent(this.props.params.ProductMainImage))
    });


  }

  onClickData(url) {

    if (window.innerWidth <= 780) {
      this.setState({
        isOpen: true
      });
    } else {

      this.setState({
        visible: true
      });

    }


  }
  onClickImage(url) {
    this.setState({
      ProductImage: url
    });
  }
  handleCancel = () => {
    this.setState({ visible: false });
    setTimeout(function () { window.scrollTo(0, 0); }, 10);
  };

  render() {
    const SpecialImages = this.props.params.SpecialImages;
    const { photoIndex, isOpen } = this.state;

    var arr = [];
    var json = this.props.params.ProductMainImages;
    Object.keys(json).forEach(function (key, index) {
      arr.push({ "original": json[key] });
    });


    if (SpecialImages) {
      var json = this.props.params.SpecialImages;
      Object.keys(json).forEach(function (key, index) {
        var json2 = json[key];
        Object.keys(json2).forEach(function (key2, index2) {
          arr.push({ "original": json2[key2].ImgURL });
        });
      });
    }



    const { mouseActivation, touchActivation, dragToMove, alwaysInPlace,
      overlayOpacity,
      switchSides,
      fillAvailableSpace,
      fillAlignTop,
      fillGapLeft,
      fillGapRight,
      fillGapTop,
      fillGapBottom } = this.state;
    return (
      <div className="fluid">
        <Modal

          destroyOnClose={true}
          visible={this.state.visible}
          focusTriggerAfterClose="false"
          closable={true}
          onCancel={this.handleCancel}
          footer={null}
          zIndex="99000"
          width='80%'
        >


          {
            this.props.params.productsdtlsbyid.map(product =>



              <Row>

                <Col sm={12}>
                  <h1>{product.Name}</h1>
                </Col>
                <Col sm={6}>
                  <div >

                    <Magnifier
                      className="input-position"
                      imageSrc={this.state.ProductImage}
                      largeImageSrc={this.state.ProductImage}
                      alwaysInPlace={true}
                      mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
                      touchActivation={TOUCH_ACTIVATION.TAP} // Optional
                      dragToMove={false}
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="thumbnail">
                    {
                      arr.map(img =>
                        <>

                          <a href="#">
                            <img src={img.original} onClick={this.onClickImage.bind(this, img.original)} alt="" />
                          </a>&nbsp;
                        </>
                      )
                    }
                  </div>

                </Col>
              </Row>



            )
          }
        </Modal>
        <div className="fluid__image-container">
          <img src={this.props.params.ProductMainImage} onClick={this.onClickData.bind(this, this.props.params.ProductMainImage)} />



          {isOpen && (
            <Lightbox
              mainSrc={arr[photoIndex].original}
              nextSrc={arr[(photoIndex + 1) % arr.length].original}
              prevSrc={arr[(photoIndex + arr.length - 1) % arr.length].original}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + arr.length - 1) % arr.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % arr.length
                })
              }
            />
          )}

        </div>


      </div>

    );
  }
}

export default withStyles(styles)(MyReactImageMagnify);
