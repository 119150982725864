import React, { useState } from 'react';
import { Stack, Typography, Button, Radio, Row, Col, Label, InputBox, Select, Spin } from 'witmeg-ui-system';
import paymentDetailBG from "../../../../../assets/Images/payment-detail.svg"
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData, getRestaurantMenuAvailablePath, LOCAL_STORAGE_ITEMS_NAMES } from '../../../utilities/helper';
import { createRestaurantMenuOrder, getOrderTypeListDataForRestaurant } from '../../../utilities/apiRequests/neutriPosServerRequests';
import _ from 'lodash';
import { sendEmail } from '../../../utilities/apiRequests/emailRegistryServerRequests';
import { syncRestaurantMenuCartDetails } from '../../../../../redux/resturantWidgetSlice';
import { RESTAURANT_SETTINGS_TYPES } from '../../../utilities/restaurantMenuUtil';
import { useEffect } from 'react';
import AdeynPayment from '../../../../../shop/adeynPayment';

const PaymentDetailsForm = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const restaurantDataState = useSelector((state) => state.resturantData);
  const companyData = useSelector((state) => state.companyData.companyDetails);

  const [pageState, setPageState] = useState({
    months: [],
    years: [],
    payment: {
      isCard: false,
      selectedMonth: null,
      selectedYear: null,
      cardDetails: {
        cardNumber: '',
        cardName: '',
        security: {
          month: '',
          year: '',
          cvvCode: '',
        }
      }
    },
    hasError: false,
    isLoading: false,
  });

  const [menuPath, setMenuPath] = useState('/');

  useEffect(() => {
    const _setData = async () => {
      const _menuPathResponse = await getRestaurantMenuAvailablePath();
      setMenuPath(_menuPathResponse);
    }
    _setData();
  }, []);

  const onPaymentMethodChange = (_value) => setPageState(prevState => ({ ...prevState, payment: { ...prevState.payment, isCard: _value } }));

  const onBackToMenu = () => history.push(menuPath);

  const getSelectedOrderTypeIDs = async ({ defaultOrderType, organizationID, cloudLocationID }) => {
    let _selectedOrderTypeData = {
      mainOrderTypeID: 0,
      subOrderTypeID: 0,
    }

    const _orderData = await getOrderTypeListDataForRestaurant({
      OrganizationID: organizationID,
      CloudLocationID: cloudLocationID,
    });

    if (!_orderData) return _selectedOrderTypeData;

    let _selectedOrderValues = null;
    for (const __key in RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT) {
      if (RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.hasOwnProperty(__key) && RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT[__key].name === defaultOrderType) {
        _selectedOrderValues = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT[__key];
        break;
      }
    }

    const _mainOrderData = _.find(_orderData, { OrderType: _selectedOrderValues.typeName });
    const _subOrderData = _.find(_mainOrderData.SubOrderTypes, { SubType: 'ONLINE' });

    _selectedOrderTypeData = {
      mainOrderTypeID: _mainOrderData.LocalID,
      subOrderTypeID: _subOrderData.LocalID,
    };

    return _selectedOrderTypeData;
  }

  const generateOrderPlacementRequest = async () => {
    const _currentDateTime = new Date().toISOString();
    let _cartItems = [];

    const { additions, items, orderTypes, subTotal, total, cloudLocationID, organizationID, currency } = restaurantDataState.cartDetails;
    const defaultOrderType = restaurantDataState.cartDetails.defaultOrderType.replace("table", "dineIn");

    let _customerData = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS);
    if (!_customerData) {
      _customerData = await getLocalStorageData("fullUserDetails");
    }
    const _selectedOrderTypeData = await getSelectedOrderTypeIDs({ cloudLocationID, organizationID, defaultOrderType });

    for (const __item of items) {
      let _salesPrice = 0;
      let _vatRate = 0;

      switch (defaultOrderType) {
        case 'delivery':
          _salesPrice = __item.selection.prices.delivery;
          _vatRate = __item.selection.vat.deliveryVat.rate;
          break;
        case 'collection':
          _salesPrice = __item.selection.prices.collection;
          _vatRate = __item.selection.vat.collectionVat.rate;
          break;
        case 'table':
          _salesPrice = __item.selection.prices.dineIn;
          _vatRate = __item.selection.vat.dineInVat.rate;
          break;
        default:
          _salesPrice = 0;
          _vatRate = 0;
      }

      const __baseProductData = {
        ProductID: __item.selection.localProductID,
        CloudProductID: __item.selection.productID,
        SalesQuantity: __item.selection.quantity,
        SalesPrice: _salesPrice,
        LineDiscount: 0,
        GroupId: __item.selection.groupID,
        SalesOrderDetailTypeId: 0,
        CreatedDate: _currentDateTime,
        ModifiedDate: _currentDateTime,
        VatRate: _vatRate,
        Remark: '',
        Instructions: [],
        CustomerInstructions: __item.selection.customerInstructions,
        IsOrderBreak: 0,
      };

      let __productAddons = null;

      let __productUnitChartItem = null;

      if (__item.selection.unitChartItem && __item.selection.unitChartItem.unitChartTypeID) {
        __productUnitChartItem = {
          UnitChartId: 0,
          Quantity: __item.selection.unitChartItem.unitQuantity,
          UnitTypeName: __item.selection.unitChartItem.typeName,
        };
      }

      if (__item.selection.addons && __item.selection.addons.items && __item.selection.addons.items.length > 0) {
        __productAddons = [];
        for (const ___addonItem of __item.selection.addons.items) {
          const ___newAddonItem = {
            AddonProductId: ___addonItem.localProductID,
            CloudAddonProductID: ___addonItem.productID,
            AddonProductRefNo: '',
            GroupName: ___addonItem.groupName,
            Name: ___addonItem.name,
            OnlineName: ___addonItem.onlineName,
            AttachmentId: ___addonItem.localAttachmentID,
            GroupItemName: ___addonItem.groupItemName,
            OnlineItemName: ___addonItem.onlineItemName,
            AddonQty: ___addonItem.quantity,
            AddonPrice: ___addonItem.prices[defaultOrderType],
          };

          __productAddons.push(___newAddonItem);
        }
      }

      const __productData = {
        ...__baseProductData,
        MnuAddons: __productAddons,
        UnitChartItems: __productUnitChartItem,
      };

      _cartItems.push(__productData);
    }

    const __getCustomerAddress = (__data) => {
      return {
        AddressLine1: __data?.line1 ?? '',
        AddressLine2: __data?.line2 ?? '',
        State: __data?.state ?? '',
        City: __data?.city ?? '',
        Zipcode: __data?.postcode ?? '',
        Country: __data?.country ?? '',
      };
    }

    const _customerDetails = {
      FirstName: _customerData.firstName,
      LastName: _customerData.lastName,
      MobileNo: _customerData.mobileNo,
      Email: _customerData.emailAddress,
      BillingAddress: __getCustomerAddress(_customerData.customerAddress),
      DeliveryAddress: __getCustomerAddress(!_customerData.isDeliveryAddressSame ? _customerData.deliveryAddress : _customerData.customerAddress),
      IsCustomerAddressSameAsDelivery: _customerData.isDeliveryAddressSame,
    };

    const _paymentDetails = {
      PaymentMode: 'Pending',
      PaymentStatus: 'PayLater',
      IsTipAmount: false,
      TipAmount: 0,
      CardPaymentTypeid: 0,
      DeliveryChargeValue: defaultOrderType === 'delivery' ? additions.deliveryCharges?.cost || 0 : 0,
      DiscountValue: 0,
    };

    // const _orderTypesData = await getRestaurantMenuSettingsData({
    //   SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
    //   CloudLocationID: cloudLocationID,
    // });

    // const _paymentTypes = await getRestaurantMenuSettingsData({
    //     SettingType: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
    //     CloudLocationID: cloudLocationID,
    // });

    // const _selectedOrderTypeData = _orderTypesData.find(_item => _item.OrderTypeName === defaultOrderType);

    // const _paymentTypeData = _paymentTypes.find(_item => _item.OrderTypeName === defaultOrderType);
    // const _cashPaymentData = _paymentTypeData.PaymentMethods.find(_item => _item.DisplayName === 'Cash');

    const _uniqueID = crypto.randomUUID().replace(/-/gi, '');

    const _orderDetails = {
      CustomerID: 0,
      DeliveryDate: _currentDateTime,
      SalesDate: _currentDateTime,
      TotalInvoiceValue: total[defaultOrderType].amount,
      CreatedDate: _currentDateTime,
      ModifiedDate: _currentDateTime,
      LocalOrderTypeID: _selectedOrderTypeData.mainOrderTypeID,
      LocalSubOrderTypeID: _selectedOrderTypeData.subOrderTypeID,
      NoOfTablePeople: 0,
      TillNo: '',
      OrderNote: '',
      CurrencyType: currency,
      OrganizationID: companyData.organizationID,
      CloudLocationID: cloudLocationID,
      Items: _cartItems,
      Customer: _customerDetails,
      PromoCode: additions.promoCode,
      TemplateName: '',
      LocalTableID: 0,
      UniqueID: _uniqueID,
      ApplicationID: '5eafee41997205d7a36d8793',
      OrderProviderID: '628f0fc905f269e68a5fc83c',
      ..._paymentDetails,
    };

    return _orderDetails;
  }

  const generateEmailRequest = async (_orderID) => {
    const { additions, items, orderTypes, defaultOrderType, subTotal, total, cloudLocationID, currency } = restaurantDataState.cartDetails;

    const correctedOrderType = defaultOrderType.replace("table", "dineIn");
    let _customerData = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS);
    if (!_customerData) {
      _customerData = await getLocalStorageData("fullUserDetails");
    }

    let _orderItems = [];

    for (let __itemCount = 0; __itemCount < items.length; __itemCount++) {
      const _orderIndex = `${('0' + (__itemCount + 1)).slice(-2)}.`;
      let __addonItems = [];
      if (items[__itemCount].selection.addons) {
        for (const ___addonItem of items[__itemCount].selection.addons.items) {
          __addonItems.push({
            AddonName: ___addonItem.name,
            AddonQty: String(___addonItem.quantity),
          });
        }
      }

      const _productItemName = `${items[__itemCount].selection.name}${items[__itemCount].selection.unitChartItem ? ` (${items[__itemCount].selection.unitChartItem.typeName})` : ''}`;

      let __newItem = {
        Index: _orderIndex,
        ItemName: _productItemName,
        Quantity: String(items[__itemCount].selection.quantity),
        Amount: items[__itemCount].selection.total[correctedOrderType].amount.toFixed(2),
        RetailpacxAddonItems: __addonItems,
        Instructions: [items[__itemCount].selection.customerInstructions]
      };

      _orderItems.push(__newItem);
    }

    let _orderDetails = {
      OrderID: _orderID,
      SubTotal: subTotal[correctedOrderType].amount.toFixed(2),
      VATAmount: total[correctedOrderType].vatAmount.toFixed(2),
      TotalAmount: total[correctedOrderType].amount.toFixed(2),
      OrderDate: new Date().toLocaleDateString(),
    };

    if (correctedOrderType === 'delivery') {
      _orderDetails = {
        ..._orderDetails,
        OrderType: 'Delivery',
        DeliveryCharge: (additions.deliveryCharges?.cost || 0).toFixed(2),
      }
    }

    if (correctedOrderType === 'collection') _orderDetails = { ..._orderDetails, OrderType: 'Takeaway' };
    if (correctedOrderType === 'dineIn') _orderDetails = { ..._orderDetails, OrderType: 'Dine-in' };

    const _customerDetails = {
      CustomerName: `${_customerData.firstName} ${_customerData.lastName}`,
      AddressLine1: `${_customerData.customerAddress.line1},${_customerData.customerAddress.line2 ? ` ${_customerData.customerAddress.line2},` : ''}`,
      AddressLine2: `${_customerData.customerAddress.city}, ${_customerData.customerAddress.state}, ${_customerData.customerAddress.postcode}`,
      Country: _customerData.customerAddress.country,
    };

    const _emailRecipients = {
      Name: _customerDetails.CustomerName,
      Email: _customerData.emailAddress,
    };

    let _companyAddress = `${companyData.address.line1}, `;
    _companyAddress = `${_companyAddress}${companyData.address.line2 !== '' ? `${companyData.address.line2}, ` : ''}`;
    _companyAddress = `${_companyAddress}${companyData.address.city}, ${companyData.address.state}, ${companyData.address.postcode}, ${companyData.address.country}`;

    const _companyDetails = {
      RestaurantName: companyData.shopName,
      BigLogoURL: companyData.bigLogoURL,
      SmallLogoURL: companyData.smallLogoURL,
      CompanyURL: companyData.siteURL,
      CompanyAddress: _companyAddress,
      CompanyEmail: companyData.email,
      CompanyContactNumber: String(companyData.contactNumber),
    };

    return {
      TemplateID: 18,
      EmailRecipients: _emailRecipients,
      TemplateInput: {
        ..._customerDetails,
        ..._companyDetails,
        ..._orderDetails,
        RetailpacxOrderItems: _orderItems,
      },
      ServiceOrigin: 'Witmeg',
    };
  };

  const onPlaceOrderClick = async () => {
    if (!restaurantDataState.cartDetails || !restaurantDataState.cartDetails.items || restaurantDataState.cartDetails.items.length === 0) return;

    if (!pageState.payment.isCard) {
      setPageState((prevState) => ({ ...prevState, isLoading: true }));

      const _orderRequest = await generateOrderPlacementRequest();
      const _response = await createRestaurantMenuOrder(_orderRequest);

      if (_response) {
        const _emailRequest = await generateEmailRequest(_response);
        await sendEmail(_emailRequest);

        await localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA);
        await localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS);
        dispatch(syncRestaurantMenuCartDetails());
        history.push(`order-success/${_response}`);
      }
      setPageState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <div className='order-confirmation-page'>
      <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text="Back to menu" iconSize={16} variant="subtle" onClick={onBackToMenu} />
      <Stack horizontalAlign="space_between">
        <Typography className="eco-mb-32 eco-order-confirm-title" type="title" level={1} color="gray_darkest">Payment Detail</Typography>
      </Stack>
      <div style={{ border: '1px solid #C6C7D2', borderRadius: '8px' }} className="eco-mb-16" >
        <div style={{ backgroundColor: '#F0F1F4', borderRadius: '8px 8px 0px 0px', padding: '15px 30px' }}><Typography type="text">Payment Method</Typography></div>
        <div style={{ padding: '20px 30px' }}>
          <Row>
            <Col span={24}>
              <Row className="eco-mb-24" gutter={[0, 32]}>
                <Col lg={12} sm={24} xs={24}>
                  <Row gutter={[0, { xs: 32 }]}>
                    <Col lg={12} sm={24} xs={24}>
                      <Radio value="card-payment" checked={pageState.payment.isCard} onChange={(e) => onPaymentMethodChange(true)}>Card Payment</Radio>
                    </Col>
                    <Col lg={12} sm={24} xs={24}>
                      <Radio value="cash-payment" checked={!pageState.payment.isCard} onChange={(e) => onPaymentMethodChange(false)}>Pay Later</Radio>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={12} sm={24} xs={24}>
              {pageState.payment.isCard &&
                <Row gutter={[0, 32]}>
                  <Col span={24}>
                    <AdeynPayment />
                  </Col>
                </Row>
              }
            </Col>
            <Col span={12} className="payment-details-bg">
              <img src={paymentDetailBG} />
            </Col>
          </Row>
        </div>
      </div>
      <Row style={{ marginTop: '50px' }}>
        <Col span={24}>
          <Stack horizontalAlign="center" style={{ textAlign: 'center' }}>
            <Typography type="text" style={{ textAlign: 'center' }} >  {pageState.isLoading ? (<Spin style={{ marginRight: '10px' }} />) : ''}</Typography>
          </Stack>
          <Stack horizontalAlign="h_end">
            <Button className="full-width-btn" variant="primary" onClick={onPlaceOrderClick} disabled={pageState.isLoading}>
              Confirm and Place Order
            </Button>
          </Stack>

        </Col>
      </Row>
    </div>
  )
}

export default PaymentDetailsForm