import React from "react";
// import { WidthProvider, Responsive } from "react-grid-layout";
// import ReactGridLayout from "react-grid-layout";
import LayoutInclude from "./default/layout-include";
import ShankarLayoutInclude from "./sharkar/layout-include";
import config from "react-global-configuration";
import { Row, Col } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import { getCompanyAllHomeLayouts, getCompanyAllPagesInfo, getPageHomeLayout } from "./default/utilities/apiRequests/witmegRetailPaxServerRequests";
import _ from 'lodash';
import { getOrderTypeData, getRestaurantMenuCartData, getRestaurantQRCodeSettings } from "./default/utilities/restaurantMenuUtil";
import SelectOrderTypePrompt from "./default/WidgetCompoentens/ResturatnWidget/Common/SelectOrderTypePrompt";
import { connect } from "react-redux";
import { setCloudLocationData, setDefaultCloudLocation, setOrderSettingsData, setRestaurantOrganizationID, setRestaurantQRCodeURLs, setRestaurantUrlOrder, syncRestaurantMenuCartDetails } from "../redux/resturantWidgetSlice";
import { LOCAL_STORAGE_ITEMS_NAMES, getOrderType, setLocalStorageData } from "./default/utilities/helper";
import { getRestaurantMenuSettingsData } from "./default/utilities/apiRequests/neutriPosServerRequests";
import { RESTAURANT_SETTINGS_TYPES } from './default/utilities/restaurantMenuUtil';
import './home.css';
import RestaurantWidget from "./default/WidgetCompoentens/ResturatnWidget";
import FontPicker from "font-picker-react";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";

function extractTranslateFromTransform(transform) {
  let translateValue = null;
  let translate = transform.match(/translate\(.*\)/)?.[0];
  if (translate) {
    translateValue = {};
    if (translate.indexOf(",") !== -1) {
      translateValue.x = parseFloat(
        translate.substring(translate.indexOf("(") + 1, translate.indexOf(","))
      );
      translateValue.y = parseFloat(
        translate.substring(translate.indexOf(",") + 1)
      );
    } else {
      translateValue.x = translateValue.y = parseFloat(
        translate.substring(translate.indexOf("(") + 1)
      );
    }
  }
  return translateValue;
}

class ResponsiveLocalStorageLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",

    // cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    // cols: { lg: 96, md: 6, sm: 6, xs: 4, xxs: 2 },
    // rowHeight: 30,
    // compactType: false,
    // preventCollision: true,
    // verticalCompact: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      pages: [],
      menus: [],
      layout: [],
      widgets: [],
      items: [],
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      newCounter: 0,
      pageID: null,
      customerOrderChoice: null,
      redirectUrl: '',

      menuItemData: null,
    };
  }

  onBreakpointChange = () => { };

  /* Check URL data and returns them */
  getURLData = () => {
    const { orderType, cloudLocationId, organizationId } = getOrderType(null)
    const _urlData = { cloudLocationID: cloudLocationId, orderType, organizationId };
    this.props.setRestaurantUrlOrder(_urlData);
    return _urlData;

    // const _params = _.split(this.props.location.pathname, "/");
    // if (_params.length > 4 && (_params[4] === 'delivery' || _params[4] === 'table' || _params[4] === 'takeaway')) {
    //   const _orderType = _params[4] === 'takeaway' ? 'collection' : _params[4];
    //   const _urlData = { cloudLocationID: _params[3], orderType: _orderType };

    //   this.props.setRestaurantUrlOrder(_urlData);
    //   return _urlData;
    // }

    // return null;
  }

  checkRestaurantMenuData = async (_data) => {
    /* Checks if `Restaurant Menu` available in the layout */
    const _restaurantMenu = _.find(_data, { Item: "resturant menu" });

    /* If `Restaurant Menu` available */
    if (_restaurantMenu && _restaurantMenu.ItemData && _restaurantMenu.ItemData.trim() !== "") {
      /* Checks whether URL has data */
      const _urlData = this.getURLData();

      /* Parse `Restaurant Menu` layout data */
      const _parsedItemData = JSON.parse(_restaurantMenu.ItemData);
      let _cloudLocations = [_parsedItemData.CloudLocations[0]];
      let _defaultCloudLocation = _parsedItemData.CloudLocations[0];
      const _organizationID = _parsedItemData.OrganizationID;

      /* If URL has data, initialize it cloud location */
      if (_urlData && _urlData.cloudLocationID && _urlData.orderType) {
        _defaultCloudLocation = _.find(_parsedItemData.CloudLocations, { CloudLocationID: _urlData.cloudLocationID });
      }

      /* Get `Location settings` for the Organization */
      const _locationSettings = await getRestaurantMenuSettingsData({
        SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
        OrganizationID: _organizationID,
      });

      /* If Organization has `Location Settings` */
      if (_locationSettings && _locationSettings.length > 0 && _locationSettings[0].AvailableLocations && _locationSettings[0].AvailableLocations.length > 0) {
        _cloudLocations = _locationSettings[0].AvailableLocations.map(__location => ({
          CloudLocationID: __location.CloudLocationID,
          CloudLocationName: __location.LocationName,
        }));
        const _defaultSetting = _.find(_locationSettings[0].AvailableLocations, { IsDefaultLocation: true });

        /* If URL doesn't have data, set `Location Settings` Default location */
        if (!_urlData || !_urlData.cloudLocationID || !_urlData.orderType || !_defaultCloudLocation) {
          _defaultCloudLocation = {
            CloudLocationID: _defaultSetting.CloudLocationID,
            CloudLocationName: _defaultSetting.LocationName
          };
        }
      }

      /* Initialize `Default Location`, `Cloud Locations`, `Organization ID` */
      this.props.initCloudLocationData({ resturantCloudLocations: _cloudLocations });
      this.props.initDefaultCloudLocation({ defualtSelectedLocation: _defaultCloudLocation });
      this.props.initRestaurantOrganizationID({ organizationID: _organizationID });

      /* Get `Order Type Settings` data */
      const _orderTypeData = await getOrderTypeData(_cloudLocations);

      /* If `Order Type Settings` are available */
      if (_orderTypeData && _orderTypeData[0].length > 0) {
        const _defaultOrderType = _.find(_orderTypeData[0], { IsDefault: true });
        const _settingsDefaultOrder = { orderType: _defaultOrderType.OrderTypeName, cloudLocationID: _defaultCloudLocation.CloudLocationID };
        this.props.setOrderSettingsData({ orderSettings: _orderTypeData, settingsDefaultOrder: _settingsDefaultOrder });

        const _orderTypeNames = _orderTypeData[0].map(_item => _item.OrderTypeName);

        const cartData = await getRestaurantMenuCartData();
        await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, {
          ...cartData,
          defaultOrderType: (_urlData && _urlData.orderType) ? _urlData.orderType : _defaultOrderType.OrderTypeName,
          orderTypes: _orderTypeNames,
        });
        this.props.reloadRestaurantMenuCartDetails();

        if (!_urlData || !_urlData.orderType) {
          if (_defaultOrderType.OrderTypeName === 'customer-choice') {
            this.setState({
              customerOrderChoice: {
                cloudLocationID: _defaultCloudLocation.CloudLocationID,
              },
            });

            const _qrCodeSettings = await getRestaurantQRCodeSettings(_defaultCloudLocation.CloudLocationID, _organizationID);
            if (_qrCodeSettings) {
              this.props.initRestaurantQRCodeURLs({ qrCodeURLs: _qrCodeSettings.qrURLs });
            }
          }
        }

      }
    }
  }

  handleFetchData = async () => {
    const _response = await getCompanyAllHomeLayouts({
      ID: "",
      PageID: "",
      CompanyID: config.get("companyID"),
      Start: 0,
      Limit: 1000,
    });

    if (_response.Desktop) {
      /* Checks whether URL has data */
      const _urlData = this.getURLData();

      let _layoutData;

      if (_urlData && _urlData.cloudLocationID && _urlData.orderType) {
        const _restaurantMenuPage = _.find(_response.Desktop, { Item: "resturant menu" });
        _layoutData = _response.Desktop.filter((_layout) => _layout.PageID === _restaurantMenuPage.PageID);
      } else {
        _layoutData = _response.Desktop.filter((_layout) => _layout.PageID === "000000000000000000000000");
      }

      await this.checkRestaurantMenuData(_layoutData);
      let _menuData = null;
      const _isRestaurantMenu = _.find(_layoutData, { Item: "resturant menu" });
      if (_isRestaurantMenu && _isRestaurantMenu.ItemData) {
        const _menuDataParsed = JSON.parse(_isRestaurantMenu.ItemData);
        if (_menuDataParsed.selectedCard && _menuDataParsed.selectedCard === 'embedded') {
          _menuData = _isRestaurantMenu.ItemData;
        }
      }

      this.setState({
        menuItemData: _menuData,
        layout: _layoutData?.map(function (i, key, list) {
          return {
            i: JSON.stringify([
              i.ID,
              i.ItemHeading,
              i.Item,
              i.ItemData,
              i.Type,
            ]),
            x: i.X,
            y: parseInt(i.Y),
            w: i.Width,
            h: i.Height,
            id: i.ID,
            // add: i === list.length - 1,
          };
        })
      })
    } else {
      // this.setState({ loading: false });
    }
  };

  handleFetchPageData = async () => {
    if (this?.state?.pageID) {
      let PageID = await this.state.pageID;

      const _response = await getPageHomeLayout({
        ID: "",
        PageID: PageID,
        CompanyID: config.get("companyID"),
        Start: 0,
        Limit: 1000,
      });

      if (_response.Desktop && _response.Desktop.length > 0) {
        await this.checkRestaurantMenuData(_response.Desktop);

        let _menuData = null;
        const _isRestaurantMenu = _.find(_response.Desktop, { Item: "resturant menu" });
        if (_isRestaurantMenu && _isRestaurantMenu.ItemData) {
          const _menuDataParsed = JSON.parse(_isRestaurantMenu.ItemData);
          if (_menuDataParsed.selectedCard && _menuDataParsed.selectedCard === 'embedded') {
            _menuData = _isRestaurantMenu.ItemData;
          }
        }

        this.setState({
          menuItemData: _menuData,
          layout: _response.Desktop.map(function (i, key, list) {
            return {
              i: JSON.stringify([
                i.ID,
                i.ItemHeading,
                i.Item,
                i.ItemData,
                i.Type,
              ]),
              x: i.X,
              y: parseInt(i.Y),
              w: i.Width,
              h: i.Height,
              id: i.ID,
              // add: i === list.length - 1,
            };
          })
        })
      } else {
        // this.setState({ loading: false });
      }
    }
  }

  resize = () => {

    if (window.innerWidth < 1682 && window.innerWidth > 1024) {
      console.log("md");
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      for (var i = 0; i < ulElems.length; i++) {
        console.log(ulElems[i].clientHeight / 39);
        ulElems[i].style.height = "auto";

        if (adjest === 0) {
          if (extractTranslateFromTransform(ulElems[i].style.transform)?.y > 0) {
            adjest =
              adjest +
              extractTranslateFromTransform(ulElems[i].style.transform)?.y;
            var cvx = extractTranslateFromTransform(
              ulElems[i].style.transform
            ).x;
            var cvy =
              extractTranslateFromTransform(ulElems[i].style.transform)?.y - 20;
            var xis = "translate(" + cvx + "px, " + cvy + "px)";

            console.log(xis);
            ulElems[i].style.transform = xis;
          }
        }
      }
    }
    if (window.innerWidth <= 1199) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      setTimeout(function () {
        for (var c = 0; c < ulElems.length; c++) {
          ulElems[c].style = "";
          ulElems[c].classList.add("newLayoutList");
          ulElems[c].style.position = "relative";
          ulElems[c].style.height = "100%";
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove(
            "react-grid-item",
            "cssTransforms",
            "react-resizable-hide",
            "react-resizable"
          );
          if (document.querySelector("layout")) {
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
        }
      }, 1000);
    }

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });

    //  console.log(window.innerWidth)
  };

  async componentDidMount() {
    window.addEventListener("resize", this.resize);

    if (Object.keys(this.props.match.params).length >= 1 && this.props.match.path === "/pages/:id") {
      const id = this.props.location.pathname;

      var idx = await id.split('/')[2];
      if (idx) {

        const _response = await getCompanyAllPagesInfo({
          ID: "",
          CompanyID: config.get('companyID')
        });

        let pageID = _response.filter((w) => w.DraftSlug === idx);
        await this.setState({ pageID: pageID[0]?.ID });

        await this.handleFetchPageData();

      }
    } else {
      await this.handleFetchData();
    }

    // const timestamp = Math.floor(Date.now() / 1000) + 1000;

    // var payload = {
    //   aud: config.get("SalesjwtAud"),
    //   exp: timestamp,
    //   iss: config.get("SalesjwtIss"),
    // };

    // var signOptions = {
    //   algorithm: "RS256",
    // };

    // var privateKey = config.get("SalesprivateKey");
    // var token = jwt.sign(payload, privateKey, signOptions);


    if (window.innerWidth < 1681) {
      console.log("md");
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var hh = ulElems[i].style.height;
            ulElems[i].getElementsByClassName("w-100")[0].style.height = hh;
          }
        }
      }, 1000);
    }

    if (window.innerWidth < 1200) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          console.log(ulElems[i].clientHeight / 39);
          console.log(ulElems[i].style.height.replace("px", ""));
          var newh = ulElems[i].style.height.replace("px", "") - 10;

          ulElems[i].style.height = newh + "px";
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height =
              newh + "px";
          }
        }
      }, 1000);
    }
    if (window.innerWidth <= 1199) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style = "";
          ulElems[i].classList.add("newLayoutList");
          ulElems[i].style.position = "relative";
          ulElems[i].style.height = "100%";
          if (document.querySelector("layout")) {
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }

        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove(
            "react-grid-item",
            "cssTransforms",
            "react-resizable-hide",
            "react-resizable"
          );
          if (document.querySelector("layout")) {
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
        }
      }, 1000);
    }
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  generateDOM(el) {
    const i = el.i;
    const rowHeight = 30;
    const eXtraProps = {};

    return (
      // <div 
      //   className="ecom-widget-wrapper" 
      //   style={{ marginLeft: '15px',  overflow: "hidden", position:'absolute', top:rowHeight*el.y, left:`${(el.x/12)*100}%` }} 
      //   key={i} 
      // >
      <div lg={{ span: el.w }} md={{ span: el.w }} style={{ height: rowHeight * el.h, position: 'absolute', top: rowHeight * el.y, left: `${(el.x / 12) * 100}%`, width: `${(el.w / 12) * 100}%` }}>
        {/* <div  lg={{ span: el.w }} md={{ span: el.w}} style={{height:JSON.parse(i)[1] ==="image"? "auto":rowHeight*el.h, position:'absolute', top:rowHeight*el.y, left:`${(el.x/12)*100}%`, width:`${(el.w/12)*100}%`}}> */}
        <LayoutInclude params={{ pageID: this.state.pageID, layoutid: JSON.parse(i)[0], width: el.x }} theme={this.props.theme} />
      </div>
      // </div>
    );
  }

  generatMobileDOM(el) {
    console.log("EL=====>>>", el)
    const i = el.i;
    const rowHeight = 30;
    const eXtraProps = {};
    return (
      // <div 
      //   className="ecom-widget-wrapper" 
      //   style={{ marginLeft: '15px',  overflow: "hidden", position:'absolute', top:rowHeight*el.y, left:`${(el.x/12)*100}%` }} 
      //   key={i} 
      // >
      <Col lg={{ span: el.w }} md={{ span: el.w }} style={{ minHeight: JSON.parse(i)[1] === "image" || JSON.parse(i)[1] === "Image with Text" ? "100%" : rowHeight * el.h }}>


        <LayoutInclude params={{ pageID: this.state.pageID, layoutid: JSON.parse(i)[0], width: el.x }} />
      </Col>
      // </div>
    );
  }



  render() {
    let pageHeight = this.state.layout && this.state.layout.length > 0 ? (this.state.layout.sort((c, d) => d.y - c.y)[0].h + this.state.layout.sort((c, d) => d.y - c.y)[0].y) * 30 : 2000
    if (this.state.redirectUrl !== '') {
      return <Redirect to={this.state.redirectUrl} />
    }

    return (
      <div style={{ width: '100%', maxWidth: '1650px', margin: 'auto' }}>
        <div style={{ display: 'none' }}>
          <FontPicker apiKey={API_KEY} limit={100} pickerId="category" />
        </div>
        {
          !this.state.customerOrderChoice ? (
            this.state.menuItemData ?
              <RestaurantWidget params={{ ItemData: this.state.menuItemData }} /> :
              // <ReactGridLayout
              //   onBreakpointChange={this.onBreakpointChange}
              //   // breakpoints={{ lg: 1681, md: 1024, sm: 768, xs: 320, xxs: 0 }}
              //   breakpoints={{ lg: 1024, md: 768, sm: 768, xs: 480, xxs: 0 }}
              //   cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
              //   allowOverlap={false}
              //   rowHeight={30}
              //   verticalCompact={true}
              //   // measureBeforeMount={false}
              //   isDraggable={false}
              //   isResizable={false}
              //   useCSSTransforms={false}
              //   compactType={null}
              //   preventCollision={true}
              //   margin={[0, 0]}
              //   // autoSize={true}
              //   {...this.props}
              //   className='eco-layout-desktop'>
              //   { this.state.layout && this.state.layout.length > 0 && this.state.layout.map(el => this.generateDOM(el)) }
              // </ReactGridLayout>
              <div className="main-page-layout">

                {this.state.windowWidth > 1024 ?
                  <div style={{ width: '100%', height: pageHeight, position: 'relative' }}>
                    {this.state.layout && this.state.layout.length > 0 && this.state.layout
                      .filter(l => JSON.parse(l.i)[2] !== "Blank Space")
                      .sort((c, d) => c.y - d.y).sort((a, b) => {
                        if (a.y === b.y) {
                          return a.x < b.x ? -1 : 1

                        } else {
                          return a.y < b.y ? -1 : 1
                        }
                      }).map(el => this.generateDOM(el))}
                  </div>


                  : <Row>
                    {this.state.layout && this.state.layout.length > 0 && this.state.layout.sort((c, d) => c.y - d.y).sort((a, b) => {
                      if (a.y === b.y) {
                        return a.x < b.x ? -1 : 1

                      } else {
                        return a.y < b.y ? -1 : 1
                      }
                    }).map(el => this.generatMobileDOM(el))}
                  </Row>
                }
                {/* <Row>
                  { this.state.layout && this.state.layout.length > 0 && this.state.layout.sort((c,d) => c.y -d.y).sort((a,b) => {
                    if(a.y===b.y) {
                      return a.x<b.x?-1:1
                    
                    } else {
                      return a.y<b.y?-1:1
                    }
                  }).map(el => this.generateDOM(el)) }
                  </Row> */}
              </div>
          ) :
            <SelectOrderTypePrompt cloudLocationID={this.state.customerOrderChoice.cloudLocationID} />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  //SET TO REDUX STORE
  setRestaurantUrlOrder: (data) => dispatch(setRestaurantUrlOrder(data)),
  setOrderSettingsData: (data) => dispatch(setOrderSettingsData(data)),
  reloadRestaurantMenuCartDetails: (data) => dispatch(syncRestaurantMenuCartDetails(data)),
  initRestaurantQRCodeURLs: (data) => dispatch(setRestaurantQRCodeURLs(data)),
  initCloudLocationData: (data) => dispatch(setCloudLocationData(data)),
  initDefaultCloudLocation: (data) => dispatch(setDefaultCloudLocation(data)),
  initRestaurantOrganizationID: (data) => dispatch(setRestaurantOrganizationID(data)),
})

export default connect(null, mapDispatchToProps)(withRouter(ResponsiveLocalStorageLayout));