import lodash from "lodash";
import { APIError, errorLogger } from "./errorHandlers";

import apiEndpointPaths from "./apiEndpointPaths";
import axios from "axios";
import { generateEntitlementRegistry, generateTokenForRetailPax, generateTokenForSalesRegistry } from "./tokenGenerators";

/* 
  Get `Company` details by `Domain`
*/
export async function getCompanyDetailsByDomain(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyByDomain;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Settings By Org ID
*/
export async function getSettingsByOrgId(OrgID) {
  try {

    let token = await generateTokenForSalesRegistry();

    const apiReqUrl = apiEndpointPaths.salesRegistry.getSettingsByOrgId;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "GET",
      url: `${apiReqUrl}/${OrgID}/0`,
      headers,
    });


    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
/* 
  Get All Home Layouts that assigns to a Company ID
*/
export async function getCompanyAllHomeLayouts(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyAllHomeLayouts;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get All home Layouts that assigns to a Page ID
*/
export async function getPageHomeLayout(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getPageHomeLayout;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Home Layout by ID
*/
export async function getHomeLayoutByID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutById;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get All Pages info that assigns for a Company ID
*/
export async function getCompanyAllPagesInfo(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyAllPagesInfo;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Pages info by ID
*/
export async function getPageInfoByID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForRetailPax();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getPageInfoById;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get `Tiers details`
*/
export async function getTierDetails(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getTierDetails;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// Get Vertical List

export async function getVerticalListPlan(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getVerticalPlan;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "GET",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const Result = resObj.data.Result;
    console.log('Result==>> ', Result)
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function getFrequencyDetails(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getFrequencyDetails;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "GET",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Vertical Plan Features
    ex: `Witmeg-Retail`, `Witmeg-Hospitality`
*/
export async function getVerticalPlanFeatures(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getProductFeatures;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
