String.prototype.toCapitalFirst = function (_text) {
  if (!_text) _text = this; 

  let _newText = '';
  const _splitTextArray = _text.toLowerCase().split(' ');

  for (const ___word of _splitTextArray) {
    let __newWord = ___word.charAt(0).toUpperCase() + ___word.slice(1);
    const __textFormat = /^[a-z]*$/i;

    if (!___word.charAt(0).match(__textFormat) && ___word.charAt(1) && ___word.charAt(1) !== '') {
      __newWord = ___word.charAt(0) + ___word.charAt(1).toUpperCase() + ___word.slice(2);
    }

    _newText = `${_newText} ${__newWord}`;
  }

  // _newText.slice(0, -1);
  return _newText.trim();
};