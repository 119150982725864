import React, { Component } from "react";
import { Modal, Select } from "antd";
import config from "react-global-configuration";
import Image from "./image.component";
import axios from "axios";
import jwt from "jsonwebtoken";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faInfoCircle,
  faUserShield,
  faUserLock,
  faUserEdit,
  faShoppingCart,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;
function handleCurrency(value) {
  //console.log(`selected ${value}`);

  localStorage.setItem("ecurrencyid", JSON.parse(value).CurrencyID);
  localStorage.setItem("ecurrencyratio", 1 * JSON.parse(value).CurrencyRate);
  localStorage.setItem("ecurrencyCode", JSON.parse(value).CurrencySymbol);
  localStorage.setItem("ecurrencythreeCode", JSON.parse(value).CurrencyCode);
  localStorage.setItem("dcurrency", "true");
  window.location.reload();
}
function getFaviconEl() {
  return document.getElementById("favicon");
}
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basketitem: [],
      menusize: 0,
      company: [],
      categories: [],
      orgcurrency: [],
      menus: [],
      pages: [],
      defCCode: localStorage.getItem("ecurrencythreeCode") || "GBP",
      defCSymbl: localStorage.getItem("ecurrencyCode") || "£",
    };
  }
  viewCart = (e) => {
    window.location.href = "/cart/";
  };

  CheckOut = (e) => {
    window.location.href = "/checkout/";
  };
  resize = () => {
    if (window.innerWidth > 1681) {
      this.setState({ menusize: 5 });
    } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
      this.setState({ menusize: 2 });
    } else {
      this.setState({ menusize: 3 });
    }
  };
  componentDidMount() {
    setTimeout(() => {
      if (config.get("Theme") === "Stationary") {
        require("./css/stationary.css");
      }
    }, 100);

    const favicon = getFaviconEl(); // Accessing favicon element
    document.title = config.get("companyPageTitle");
    favicon.href =
      "https://cdn.neurolage.com/retailpacx/" +
      config
        .get("CDNPath")
        .replace(/ /g, "")
        .replace("&", "")
        .replace(/(^\&)|,/g, "")
        .toLowerCase() +
      "/" +
      config.get("favicon");

    axios
      .get(config.get("backendURL") + "menus/main/" + config.get("companyID"))
      .then((response) => {
        const requestOne = axios
          .get(
            config.get("backendURL") +
            "pages/byid/" +
            response.data[0]._id +
            "/" +
            config.get("companyID")
          )
          .then((response) => {
            this.setState({ pages: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("SalesprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedSetting = sessionStorage.getItem("setting");
    //if (!cachedSetting) {
    axios
      .get(
        config.get("API_salesregistry") +
        "setting/" +
        config.get("OrganisationID") +
        "/26",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.data.Result[0].IPLookup) {
          const cachedipresponse = localStorage.getItem("ipresponse");
          if (!cachedipresponse) {
            axios
              .get(
                "https://api.ipstack.com/check?access_key=5e7152f63b2d02398b551ee66730bbfa"
              )
              .then((ipresponse) => {
                localStorage.setItem(
                  "ipresponse",
                  JSON.stringify(ipresponse.data)
                );
              });
          }
        }
        sessionStorage.setItem("setting", JSON.stringify(response.data.Result));
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
    //   .then(response => {
    //     // console.log('response')
    //     this.setState({ orgcurrency: response.data.Result });
    //     this.state.orgcurrency.map(oc => {
    //       // console.log(oc);

    //       if (JSON.parse(localStorage.getItem('ipresponse')).currency.code === oc.CurrencyCode && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;

    //       } else if (oc.IsBaseCurrency === true && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;
    //       }

    //     })
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })

    this.resize();

    axios
      .post(process.env.REACT_APP_TOKENSERVICE_URL, {
        isLoginServerRequest: false,
        serviceName: "retailpacx",
      })
      .then(function (response) {
        axios
          .post(
            `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/company/id`,
            {
              ID: "632b069adda41efdc5f15b44",
              OrganisationID: "",
              SubDomain: "",
              CustomerDomain: "",
              Start: 0,
              Limit: 0,
              IsSearchApplicable: false,
              Search: {
                Key: "",
                Value: "",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          )
          .then((response) => {
            this.setState({
              Name: response.data.Name,
              AddressLine1: response.data.AddressLine1,
              AddressLine2: response.data.AddressLine2,
              City: response.data.City,
              StateProvinceRegion: response.data.StateProvinceRegion,
              PostZipCode: response.data.PostZipCode,
              Country: response.data.Country,
              Phone: response.data.Phone,
              Fax: response.data.Fax,
              Email: response.data.Email,
              OpenningHours: response.data.OpenningHours,
            });
          });
      });

    // axios
    //   .get(config.get("backendURL") + "company/" + config.get("companyID"))
    //   .then((response) => {
    //     this.setState({
    //       Name: response.data.Name,
    //       AddressLine1: response.data.AddressLine1,
    //       AddressLine2: response.data.AddressLine2,
    //       City: response.data.City,
    //       StateProvinceRegion: response.data.StateProvinceRegion,
    //       PostZipCode: response.data.PostZipCode,
    //       Country: response.data.Country,
    //       Phone: response.data.Phone,
    //       Fax: response.data.Fax,
    //       Email: response.data.Email,
    //       OpenningHours: response.data.OpenningHours,
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    var hour = new Date().getHours();
    this.setState({
      greet:
        "Good " +
        ((hour < 12 && "Morning") || (hour < 18 && "Afternoon") || "Evening"),
    });

    const cachedProduct = localStorage.getItem("cart");
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }
  }
  handlePageClick = (value) => {
    this.props.parentPageCallback(value);
  };
  onLogout = () => {
    sessionStorage.setItem("loggedin", false);
    sessionStorage.setItem("username", "");
    localStorage.setItem("fullUserDetails", "");
    sessionStorage.setItem("deliverycost", "");
    window.location.reload();
  };
  searchcNow = (event) => {
    event.preventDefault();

    if (document.getElementById("search").value) {
      window.location.href =
        "/search/" + document.getElementById("search").value;
    } else {
      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  render() {
    this.state.offer = this.state.basketitem
      .reduce(
        (accumulator, currentofferValue) =>
          accumulator +
          parseFloat(
            currentofferValue.OfferAmount *
            localStorage.getItem("ecurrencyratio")
          ),
        0
      )
      .toFixed(2);
    this.state.total = this.state.basketitem
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(
            currentValue.ProductQty *
            currentValue.ProductPrice *
            localStorage.getItem("ecurrencyratio")
          ),
        0
      )
      .toFixed(2);
    this.state.cartqty = this.state.basketitem.reduce(
      (accumulator, currentqtyValue) =>
        accumulator + parseFloat(currentqtyValue.ProductQty),
      0
    );

    const { orgcurrency, defCCode, defCSymbl } = this.state;
    return (
      <>
        <div className='container-fluid header'>
          <input
            className='form-control'
            type='hidden'
            id='search'
            placeholder="I'm shopping for..."
          />
          <div className='header-top'>
            <div className='nav-btn'>
              <button
                className='navbar-toggler collapsed'
                type='button'
                data-toggle='collapse'
                data-target='#app-navbar'
                aria-controls='app-navbar'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <i className='fas fa-times' />
              </button>
            </div>

            <div className='brand'>
              <NavLink exact to='/' className='navbar-brand'>
                <Image
                  params={{
                    ItemData: config.get("companyLogo"),
                    className: "img-fluid brand-logo",
                  }}
                />
              </NavLink>
              <h6 className='acl-long'>
                {this.state.Name ? this.state.Name : ""}
              </h6>
              <h6 className='acl-small'>
                {this.state.Name ? this.state.Name : ""}
              </h6>
            </div>

            <div className='search-wrapper search-large'>
              <form className='form-wrapper'>
                <div className='input-group serachbar'>
                  <label for='Search' className='visuallyhidden'>
                    Search:
                  </label>
                  <input
                    type='text'
                    id='Search'
                    className='form-control'
                    placeholder='Search'
                    aria-label='Search'
                    aria-describedby='button-search'
                  />
                  <a href='#' className='search-icon'>
                    {" "}
                    <i
                      className='fa-solid fa-magnifying-glass'
                      aria-hidden='true'
                    ></i>
                  </a>
                </div>
              </form>
            </div>

            <div className='login-right-wrapper'>
              <ul className='nav'>
                {sessionStorage.getItem("loggedin") === "true" ? (
                  <>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle login-register drop-down-icon-hide'
                        data-toggle='dropdown'
                        href='/account/member'
                        role='button'
                        aria-expanded='false'
                      >
                        <span className='user-icon'>
                          <i className='fa-solid fa-user'></i>
                        </span>
                        <span className='user-name'>
                          {this.state.greet},{" "}
                          {sessionStorage.getItem("username")}
                        </span>
                      </a>
                      <div className='dropdown-menu dropdown-menu-right'>
                        <p className='loggedin-user'>
                          {this.state.greet},{" "}
                          {sessionStorage.getItem("username")}
                        </p>
                        <div className='hr-sep'>
                          <hr />
                        </div>
                        <a
                          className='dropdown-item user-actions'
                          href='#'
                          onClick={this.onLogout.bind()}
                        >
                          <span>
                            <i className='fa-solid fa-right-from-bracket'></i>
                          </span>
                          Logout
                        </a>
                        <a
                          className='dropdown-item user-actions'
                          href='/account/member'
                        >
                          <span>
                            <i class='fa-solid fa-gear'></i>
                          </span>
                          Account Info
                        </a>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className='nav-item  '>
                      <a
                        className='nav-link  login-register drop-down-icon-hide'
                        href='/account/login'
                        role='button'
                        aria-expanded='false'
                      >
                        <span className='user-icon'>
                          <i className='fa-solid fa-user'></i>
                        </span>
                        <span className='user-name'>Sign in / Register</span>
                      </a>
                    </li>
                  </>
                )}

                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle shopping-cart drop-down-icon-hide'
                    data-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  >
                    <span className='shopping-cart-items'>
                      <i className='fa-solid fa-cart-shopping'></i>
                    </span>

                    <span className='cart-count'>
                      {" "}
                      <i id='cartcount'>{this.state.cartqty}</i>{" "}
                    </span>
                  </a>
                  <div className='dropdown-menu dropdown-menu-right'>
                    <div className='view-cart-wrapper'>
                      {this.state.basketitem.length ? (
                        this.state.basketitem.map((item) => (
                          <>
                            <div className='cart-item'>
                              <div className='cart-item-image'>
                                {item.ProductImage !== "" ? (
                                  <img src={item.ProductImage} width='80' />
                                ) : (
                                  <img
                                    src='/images/noimage.jpg'
                                    alt='.'
                                    width='80'
                                  />
                                )}
                              </div>
                              <div className='cart-item-text'>
                                <p>{item.ProductName}</p>
                                <div className='cart-item-qty'>
                                  <p className='cart-item-qty'>
                                    {localStorage.getItem("ecurrencyCode")}{" "}
                                    {(
                                      item.ProductQty *
                                      item.ProductPrice *
                                      localStorage.getItem("ecurrencyratio")
                                    ).toFixed(2)}{" "}
                                    x {item.ProductQty}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <span>
                          <h6>Your cart is empty</h6>
                          <p>
                            Find hundred of quality products at bargain prices
                            everyday!
                          </p>
                        </span>
                      )}

                      {this.state.basketitem.length ? (
                        <>
                          <p className='cart-sub-total'>
                            <span>Sub Total:</span>
                            {localStorage.getItem("ecurrencyCode")}{" "}
                            {this.state.total}
                          </p>
                          {this.state.offer != 0 ? (
                            <>
                              <h4>
                                Offer Discount:
                                <strong>
                                  {localStorage.getItem("ecurrencyCode")} -
                                  {parseFloat(this.state.offer).toFixed(2)}
                                </strong>
                              </h4>
                            </>
                          ) : (
                            ""
                          )}
                          <h3>
                            Total:
                            <strong>
                              {localStorage.getItem("ecurrencyCode")}{" "}
                              {parseFloat(
                                this.state.total - this.state.offer
                              ).toFixed(2)}
                            </strong>
                          </h3>

                          <button class='view-cart-btn' onClick={this.viewCart}>
                            View cart
                          </button>
                          <button class='checkout-btn' onClick={this.CheckOut}>
                            Checkout
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='header-bottom'>
            <div className='search-wrapper search-small'>
              <form className='form-wrapper'>
                <div className='input-group serachbar'>
                  <label for='Search' className='visuallyhidden'>
                    Search:
                  </label>
                  <input
                    type='text'
                    id='Search'
                    className='form-control'
                    placeholder='Search'
                    aria-label='Search'
                    aria-describedby='button-search'
                  />
                  <a href='#' className='search-icon'>
                    {" "}
                    <i
                      className='fa-solid fa-magnifying-glass'
                      aria-hidden='true'
                    ></i>
                  </a>
                </div>
              </form>
            </div>

            <nav className='navbar navbar-expand-md navbar-light'>
              <div className='container-fluid'>
                <div className='navbar-collapse collapse' id='app-navbar'>
                  <ul className='navbar-nav m-app-nav'>
                    <li className='nav-item'>
                      <NavLink
                        exact
                        to='/'
                        className='nav-link'
                        activeClassName='active'
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink exact to='/products' className='nav-link'>
                        Our Products
                      </NavLink>
                    </li>

                    {this.state.pages
                      .slice(0, this.state.menusize)
                      .map((currentpage) => (
                        <li className='nav-item'>
                          {" "}
                          <NavLink
                            exact
                            to={"/pages/" + currentpage.slug}
                            className='nav-link'
                          >
                            {" "}
                            {currentpage.pagetitle}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}
