import { createSlice } from "@reduxjs/toolkit";

export const companyDataSlice = createSlice({
  name: "Company Details",
  initialState: {
    companyDetails: {
      organizationID: '',
      companyID: '',
      shopName: '',
      bigLogoURL: '',
      smallLogoURL: '',
      siteURL: '',
      address: '',
      email: '',
      contactNumber: '',
    },
    settings: {}
  },

  reducers: {
    setCompanyData: (state, action) => {
      state.companyDetails = { ...action.payload };
    },
    setSettingsData: (state, action) => {
      state.settings = { ...action.payload };
    },
  },
});

export const { setCompanyData, setSettingsData } = companyDataSlice.actions;

export default companyDataSlice.reducer;