import React from 'react';


import config from 'react-global-configuration';
import axios from 'axios';
import BannerImage from "./bannerimage";
import Carousel from 'react-bootstrap/Carousel';
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { carouselitems: [] };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.params.ItemData != "") {
      var tempArray;
      tempArray = decodeURIComponent(this.props.params.ItemData);

      var newArray = JSON.parse(tempArray)
      console.log("I AM CAROUSEL", newArray.data)

      this.setState({ carouselitems: newArray.data })

    }
  }



  render() {


    return (
      <div className={this.props.params.className} style={{height: '100%', overflow: 'hidden'}}>

<Carousel style={{height: '50%'}}  indicators = {false} >
{this.state.carouselitems &&
            this.state.carouselitems.map((data, index) => (
              <Carousel.Item className='carousel-img'  style={{objectFit: 'cover'}} >
                <img
                  src={data?.imageUrl}
                  style={{height: '100%', objectFit: 'cover' }}
                  draggable='false'
                />
              </Carousel.Item>
            ))}
            </Carousel>
         
        {/* <Carousel  >



          {


            this.state.carouselitems.map((carousel, index) => (

              <Carousel.Item key={'Carousel' + index}>
                <img
                  className="d-block w-100"
                  src={carousel?.imageUrl}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>{carousel?.heading} </h3>
                  <p>{carousel?.desc}</p>
                </Carousel.Caption>
              </Carousel.Item>

            ))}






        </Carousel> */}
      </div>
    )
  }
}

export default App;
