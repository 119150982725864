import React from 'react';
import { Col } from 'react-bootstrap';
import { Menu, Slider, Checkbox, Select, Form, Radio } from 'antd';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import moment from 'moment';
// import 'antd/dist/antd.css';
import FilterBox from '../default/filter.component.jsx';
import $ from 'jquery';
const { SubMenu } = Menu;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chkweightdisabled: false, CArray: [], readmore: 'ReadMore', MoreLess: 'Show More »', filterpricerange: [20, 122], filterdpricerange: [0, 1000], categories: [], category: [], categoryNamea: '', filterd: false, filterdweight: [], filterdsize: [], filterdcolour: [], filterdbrand: [], brandlist: [], firstload: false };

  }


  componentDidMount() {
    const category = this.props.params.catname;
    const e = this.props.params.catid;

    console.log(this.props.params.catid)


    this.setState({ category: [Number(e)] })
    this.setState({ categoryName: category })


    this.props.parentCallback('', '', false, category, '', '')

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        "OrgID": config.get('OrganisationID'),
        "ProductName": this.props.params.catid,
        "Skip": 0,
        "Limit": 12,
        "LocationID": (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID)
      })
    };



    fetch(config.get('rest_API_productregistry') + '/category/count/' + config.get('OrganisationID') + '/' + e, requestOptions)
      .then(responsecount => responsecount.json())
      .then(datacount => this.setState({ productscount: datacount.Result }));

    fetch(config.get('rest_API_productregistry_V2') + '/pagination/name', requestOptions)
      .then(response => response.json())
      .then(async data => {
        console.log(data)
        const products = data.Result;
        const ProductFilter = data.ProductFilter;
        sessionStorage.setItem('ProductFilter', JSON.stringify(ProductFilter));
        this.setState({ products: products, ploaded: true, ProductFilter: data.ProductFilter, filterd: true })

        let checker = (arr, target) => target.some(v => arr.includes(v));

        var offeri = [];
        console.log(data)
        offeri = data.ProductFilter.BrandIDs;


        if (offeri) {
          let op = this.state.brands.map(({ BrandID }) => BrandID)

          let x = JSON.stringify(this.state.brands)
          if (checker(op, offeri)) {

            const brandItems = [];

            var newArray = offeri.map(function (el, index) {

              var cindex = JSON.parse(x).findIndex(a => a.BrandID === el);

              var brandDtl = {
                BrandName: JSON.parse(x)[cindex]["BrandName"],
                BrandID: JSON.parse(x)[cindex]["BrandID"]
              }

              brandItems.push(brandDtl)
            })
            this.setState({ brandlist: brandItems })
            //  console.log(brandItems)
          }
        }

        this.setState({ filterdpricerange: [0, 1000] })
        this.props.parentCallback(products, ProductFilter, false, category, this.state.productscount, e)
      })



    const requestCategories = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };

    const cachedCategory = sessionStorage.getItem('sessioncategories');
    if (cachedCategory) {
      this.setState({ categories: JSON.parse(cachedCategory) });
    } else {
      fetch(config.get('rest_API_productregistry') + '/onlinecategories/' + config.get('OrganisationID'), requestCategories)
        .then(response => response.json())
        .then(data => this.onSetCategoryResult(data.Result, data.Result));
    }

    const requestBrands = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };
    fetch(config.get('rest_API_productregistry') + '/onlinebrands/' + config.get('OrganisationID'), requestBrands)
      .then(response => response.json())
      .then(data => this.onSetBrandResult(data.Result, data.Result));


  }
  onSetCategoryResult = (result, key) => {
    this.setState({ categories: result })
    sessionStorage.setItem('sessioncategories', JSON.stringify(result));
  };

  onSetBrandResult = (result, key) => {
    //console.log(result)
    this.setState({ brands: result })
    sessionStorage.setItem('menubrands', JSON.stringify(result));
  };

  handleProductsClick = (e, category) => {

    this.setState({ category: e })
    this.setState({ categoryName: category })
    window.history.replaceState(null, "New Page Title", '/group/' + e + '/' + category)

    $("html, body").animate({ scrollTop: $(".container-fluid").offset().top - 40 }, "slow");

    this.props.parentCallback('', '', false, category, '', '')

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };



    fetch(config.get('rest_API_productregistry') + '/category/count/' + config.get('OrganisationID') + (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID) + '/' + e, requestOptions)
      .then(responsecount => responsecount.json())
      .then(datacount => this.setState({ productscount: datacount.Result }));


    fetch(config.get('rest_API_productregistry_V2') + '/category/pagination/' + config.get('OrganisationID') + '/' + (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID) + '/' + e + '/0/12', requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        const ProductFilter = data.ProductFilter;
        sessionStorage.setItem('ProductFilter', JSON.stringify(ProductFilter));
        this.setState({ products: products, ploaded: true, ProductFilter: data.ProductFilter, PriceRanges: data.ProductFilter.PriceRanges, filterd: true, firstload: true })


        let checker = (arr, target) => target.some(v => arr.includes(v));

        var offeri = [];
        offeri = data.ProductFilter.BrandIDs;

        if (offeri) {

          let op = this.state.brands.map(({ BrandID }) => BrandID)

          let x = JSON.stringify(this.state.brands)
          if (checker(op, offeri)) {

            const brandItems = [];

            var newArray = offeri.map(function (el, index) {

              var cindex = JSON.parse(x).findIndex(a => a.BrandID === el);

              var brandDtl = {
                BrandName: JSON.parse(x)[cindex]["BrandName"],
                BrandID: JSON.parse(x)[cindex]["BrandID"]
              }

              brandItems.push(brandDtl)
            })
            this.setState({ brandlist: brandItems })
            //  console.log(brandItems)
          }
        }

        this.setState({ filterdpricerange: [0, 1000] })
        this.props.parentCallback(products, ProductFilter, false, category, this.state.productscount, e)
      })


  };

  handleFilter = (type, value) => {
    var brand = '';
    if (type === 'Brand') {
      brand = value
    } else {
      brand = this.state.filterdbrand
    }

    var weight = '';
    if (type === 'Weight') {
      weight = value
    } else {
      weight = this.state.filterdweight
    }

    var colour = '';
    if (type === 'Colour') {
      colour = value
    } else {
      colour = this.state.filterdcolour
    }
    var size = '';
    if (type === 'Size') {
      size = value
    } else {
      size = this.state.filterdsize
    }


    var price = '';
    if (type === 'Price') {
      price = [value[0], value[1]]
    } else {
      price = this.state.filterdpricerange
    }



    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    var CArray;


    if (this.state.filterdcolour) {
      CArray = {
        "CustomArrays": {
          ...this.state.CArray
        }
      }

    } else {
      CArray = {}
    }

    var bdy = {
      "MaxPrice": price[1],
      "MinPrice": price[0],
      "Weight": weight,
      "ProductCategoryIDs": this.state.category,
      "ProductSubCategoryIDs": [],
      "BrandIDs": brand,
      ...CArray
    }

    console.log(bdy)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(bdy)
    };

    console.log(requestOptions)

    fetch(config.get('rest_API_productregistry_V2') + '/multiselection/smartfilter/count/' + config.get('OrganisationID') + '/' + (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID), requestOptions)
      .then(responsecount => responsecount.json())
      .then(datacount => this.setState({ productscount: datacount.Result }));
    //console.log(config.get('rest_API_productregistry_V2') + '/smartfilter/count/' + config.get('OrganisationID'))

    fetch(config.get('rest_API_productregistry_V2') + '/multiselection/smartfilter/pagination/' + config.get('OrganisationID') + '/' + (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID) + '/0/12', requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        const ProductFilter = data.ProductFilter;
        console.log(data)

        this.setState({ products: products, ploaded: true, filterd: true, firstload: true })
        sessionStorage.setItem('ProductFilter', JSON.stringify(ProductFilter));
        this.props.parentCallback(products, ProductFilter, true, this.state.categoryName, this.state.productscount, this.state.category)
      })

  };

  handleMore = (e, data) => {
    if (this.state.readmore === 'ReadLess') {
      this.setState({ readmore: 'ReadMore', MoreLess: 'Show More »' })
    } else {
      this.setState({ readmore: 'ReadLess', MoreLess: '« Show Less' })
    }

  };
  onColourChange = (e) => {
    this.setState({ filterdcolour: e })
    this.handleFilter('Colour', e)
  };
  onSizeChange = e => {
    this.setState({ filterdsize: e })
    this.handleFilter('Size', e)
  };

  handleChange = e => {
    this.setState({ filterdweight: e })
    this.handleFilter('Weight', e)
  };

  handleBrandChange = e => {
    this.setState({ filterdbrand: e })

    this.handleFilter('Brand', e)
  };

  handleCustomCheckboxChange = (e, ControlName) => {

    const CArray = this.state.CArray;

    CArray[e] = ControlName

    this.setState({ CArray: CArray })
    this.handleFilter('CArray', e)
  };

  onChange = value => {
    this.setState({ filterdpricerange: [value[0], value[1]] })
    this.handleFilter('Price', value)
  };



  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };
  render() {

    const { products } = this.state;
    const { categories, brands } = this.state;

    return (
      <>
        {(() => {

          switch (config.get('Theme')) {
            case 'Martfury':
              return (
                <>

                  <div className="ps-layout__left">


                    <aside className="widget widget_shop">



                      {
                        this.state.PriceRanges || this.state.filterd === true ? (

                          <FilterBox handleChange={this.handleChange} handleCustomCheckboxChange={this.handleCustomCheckboxChange} onSizeChange={this.onSizeChange} onColourChange={this.onColourChange} handleMore={this.handleMore} handleBrandChange={this.handleBrandChange} handleFilter={this.handleFilter} handleProductsClick={this.handleProductsClick} handleClick={this.handleClick} onChange={this.onChange} params={{ ProductFilter: this.state.ProductFilter, brands: this.state.brands, categories: this.state.categories, PriceRanges: this.state.PriceRanges, filterdpricerange: this.state.filterdpricerange, filterd: this.state.filterd, category: this.state.category, categoryName: this.state.categoryName, brandlist: this.state.brandlist, readmore: this.state.readmore, MoreLess: this.state.MoreLess }} />

                        ) : (
                          <>
                            <ul className="ps-list--categories">

                              {this.state.categories.map(category =>
                                <li className="menu-item-has-children"><a onClick={this.handleProductsClick.bind(this, category.ProductCategory.ProductCategoryID, category.ProductCategory.Category)}  >  {category.ProductCategory.Category}  </a></li>
                              )}
                            </ul>
                          </>
                        )

                      }

                    </aside>

                  </div>

                </>

              )
            default:
              return (
                <Col sm={3} className="hidden-xs">


                  <>
                    <ul className="ps-list--categories">

                      {this.state.categories.map(category =>
                        <li className="menu-item-has-children"><a href={'/group/' + category.ProductCategory.ProductCategoryID + '/' + category.ProductCategory.Category}   >  {category.ProductCategory.Category}  </a></li>
                      )}
                    </ul>
                  </>

                </Col>
              )
          }

        })()}
      </>

    )
  }
}

export default Application;