import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Theme from "./themes/default";
import "../node_modules/witmeg-ui-system/dist/styles.css"
import { Provider } from "react-redux";
import store from './redux/rootreducer'
if (process.env.NODE_ENV === "production") {
    console.log = function () { };
}
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Theme />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.unregister();
