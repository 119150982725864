import { List, Avatar, Space, Tag } from 'antd';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import React from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import { UserOutlined } from '@ant-design/icons';
import Truncate from 'react-truncate';

const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);

const { CheckableTag } = Tag;

const Blogs = (props) => (


    <div >

    

        <List
            itemLayout="vertical"
            size="large"
            pagination={{
                onChange: page => {
                    console.log(page);
                },
                pageSize: 3,
            }}
            dataSource={props.params.listData}

            renderItem={item => (
                <List.Item
                    key={item.title}
                    actions={[
                        <IconText icon={StarOutlined} text="0" key="list-vertical-star-o" />,
                        <IconText icon={LikeOutlined} text="0" key="list-vertical-like-o" />,
                        <IconText icon={MessageOutlined} text="0" key="list-vertical-message" />,
                    ]}
                    extra={
                        <img
                            width={272}
                            alt=""
                            src={'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + item.featuredimage}
                        />
                    }
                >


                    <List.Item.Meta
                        avatar={<Avatar icon={<UserOutlined />} src={item.avatar} />}
                        title={<a href={'/articles/' + item.slug}><h3>{item.title}</h3></a>}
                        description={item.tags.join(", ")}
                    />
                    <Truncate lines={3} ellipsis={<span>... <a href={'/articles/' + item.slug}>Read more</a></span>}>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Truncate>


                </List.Item>
            )}
        />
    </div>


);
export default Blogs;
