import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageMagnify from "./reactimagemagnify";

class MyImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = { imgURL: this.props.params.ProductMainImage , productsdtlsbyid:this.props.params.productsdtlsbyid, arr:[]};
  }

  onClickData(event, index) {
    
    this.setState({ imgURL: event.target.src });
    console.log(event.target.src)
  }

  myRenderItem(item) {
    const SpecialImages = this.state.imgURL;
    var imgURL;
    if (SpecialImages.Main) {
      imgURL = SpecialImages.Main;
    } else {
      imgURL = this.state.imgURL;
    }
    return <ReactImageMagnify params={{ ProductMainImage: imgURL, ProductMainImages: this.props.params.ProductMainImage, SpecialImages: this.props.params.SpecialImages ,productsdtlsbyid: this.state.productsdtlsbyid, arr:this.state.arr }} />;
  }

  render() {

    const SpecialImages = this.props.params.SpecialImages;
    
    const properties = {
      thumbnailPosition: "left",
      useBrowserFullscreen: false,
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: false,
      onThumbnailClick: this.onClickData.bind(this),
      renderItem: this.myRenderItem.bind(this),
      items: [
      ]
    };

 
    var json = this.props.params.ProductMainImage;
    Object.keys(json).forEach(function (key, index) {
      properties.items.push({ "original": json[key], "thumbnail": json[key] });
     
    });


    if (SpecialImages) {
      var json = this.props.params.SpecialImages;
      Object.keys(json).forEach(function (key, index) {
        var json2 = json[key];
        Object.keys(json2).forEach(function (key2, index2) {
          properties.items.push({ "original": json2[key2].ImgURL, "thumbnail": json2[key2].ImgURL });
           
        });
      });
    }
 
    return <>
       
       
             <ImageGallery {...properties} />   
        
       </>;

    
  }
}

export default MyImageGallery;
