import React, { useState, useEffect } from 'react'
import { Stack, Typography, Tag, Icon, Button, Toggle, Spin, Select } from 'witmeg-ui-system';
import { PlusCircleOutlined, MinusOutlined } from "@ant-design/icons";
 
import globalValues from './utilities/apiRequests/globalValues';
import './priceplan.css'
import { getFrequencyDetails, getTierDetails, getVerticalListPlan, getVerticalPlanFeatures } from './utilities/apiRequests/witmegRetailPaxServerRequests';
 
const PricePlanWidget = () => {

    const [frequencies, setFrequencies] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [verticalPlan, setVerticalPlan] = useState([]);
    const [selectedVerticalPlan, setSelectedVerticalPlan] = useState(null);
    const [tierDetails, setTierDetails] = useState();
    const [featureList, setFeatureList] = useState([]);
    const [featureDataRows, setFeatureDataRows] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const toggleShowMore = () => setShowMore((prevState) => !prevState);

    const fetchFrequencyData = async () => {
      const frequencyData = await getFrequencyDetails();
      if (frequencyData) {
        setFrequencies(frequencyData);
        setSelectedFrequency(frequencyData[0]);
      }
    };

    const fetchVerticalPlansData = async () => {
      const verticalPlansData = await getVerticalListPlan();
      if (verticalPlansData) {
        const planOptions = verticalPlansData?.map((data) => {
          return {
            value: {_id: data._id,AppID: data.PrimaryAppID},
            label: data.VerticalName,
          };
        });
        setSelectedVerticalPlan(planOptions[0]);
        setVerticalPlan(planOptions);
      }
    };

    const fetchFeatures = async () => {
      const featuresData = await getVerticalPlanFeatures({
        CurrencyType: 'GBP',
        FrequencyID: selectedFrequency._id,
        VerticalID: selectedVerticalPlan.value._id,
      });

      return featuresData ?? null;
    };

    const fetchTierDetails = async () => {
      const tierData = await getTierDetails({
        CurrencyType: 'GBP',
        FrequencyID: selectedFrequency._id,
        VerticalID: selectedVerticalPlan.value._id,
      });

      return tierData ?? null;
    };

    /* Fetch initial data: Frequencies & Vertical Plans */
    useEffect(async () => await Promise.all([fetchFrequencyData(), fetchVerticalPlansData()]), []);

    /* Runs when `Selected Frequency` or `Selected Vertical Plan` changed */
    useEffect(async () => {
      /* Check if both `selectedFrequency` & `selectedVerticalPlan` are available */
      if (selectedFrequency && selectedVerticalPlan) {
        setIsLoading(true);

        /* Fetch `All features` & `All tier details` */
        const paymentPlanFeatures = await Promise.all([fetchFeatures(), fetchTierDetails()]);

        /* Compare `All features` with `Tier features` */
        const comparedFeatures = await featuresListComparison(paymentPlanFeatures[0], paymentPlanFeatures[1]);

        if (paymentPlanFeatures[0]) setFeatureList(paymentPlanFeatures[0]);
        if (paymentPlanFeatures[1]) setTierDetails(paymentPlanFeatures[1]);
        if (comparedFeatures) setFeatureDataRows(comparedFeatures);

        setIsLoading(false);
      }
    }, [selectedVerticalPlan, selectedFrequency]);

    /* Compares `Tier features` with `All features` */
    const featuresListComparison = async (features, tiers) => {
      if (!features || features.length === 0) return;
      //   if (!tier.Catalogues || tier.Catalogues.length === 0) return;

      /* Feature data row list */
      const featureRows = [];

      /* Loops through all `features` */
      for (let feature of features) {
        if (!feature.IsActive) continue;

        let rowData = {
          title: feature.MarketingTerm,
          type: feature.Type,
          values: [],
        };

        /* Feature row value compared with tiers */
        let tierFeatureValues = [];

        /* Loops through all `tiers` */
        for (let tier of tiers) {
          //   if (!tier.Catalogues || tier.Catalogues.length === 0) return;

          let tierFeatureValue = { isRecommended: tier.Tier.IsRecommendedTier };

          /* If tier doesn't have `Catalogues`, set as empty */
          if (!tier.Catalogues || tier.Catalogues.length === 0) {
            tierFeatureValue = {
              ...tierFeatureValue,
              isEmpty: true,
            };
            tierFeatureValues.push(tierFeatureValue);
            continue;
          }

          /* Check if whether feature is available in `tier` */
          const tierValue = tier.Catalogues.find(
            (_t) => _t.FeatureID === feature._id
          );

          /* If `feature` is not available, set as empty */
          if (!tierValue) {
            tierFeatureValue = {
              ...tierFeatureValue,
              isEmpty: true,
            };
            tierFeatureValues.push(tierFeatureValue);
            continue;
          }

          if (!tierValue.IsVisible) continue;

          /* `Type` => `Features` */
          if (tierValue.Type === 'Features') {
            tierFeatureValue = {
              ...tierFeatureValue,
              isEmpty: false,
            };
            tierFeatureValues.push(tierFeatureValue);
            continue;
          }

          /* `Type` => `Optional` */
          if (tierValue.Type === 'Optional') {
            continue;
          }

          /* `Type` => `Default` */
          if (tierValue.Type === 'Default') {
            continue;
          }
        }

        rowData = { ...rowData, values: tierFeatureValues };
        featureRows.push(rowData);
      }

      return featureRows;
    };

    /* Opens the link in new tab */
    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };

    const handleFrequencyToggle = (value) => {
      setSelectedFrequency(value ? frequencies[1] : frequencies[0]);
    };

    function handleSelectPricePlan(data) {
      setSelectedVerticalPlan(data);
    }

    // Redirect To Control Center
    const handleChangePage = (tier) => {
      openInNewTab(`${globalValues.baseURLS.REACT_APP_CONTROL_CENTER_BASE_URL}signup-process-landing-page/${selectedVerticalPlan.value._id}/${tier.Tier._id}/GBP/${selectedFrequency._id}`);
    };

    return (
        <>
            <div className="pricing-plan-page">
                <Stack direction="column" verticalAlign="v_center">
                    <Typography type="title" level={5} pattern="bold" className="eco-mb-24">Join the Innovation</Typography>
                    <Typography type="title" level={1} pattern="bold" className="eco-mb-24">Pricing Plan</Typography>

                    <div style={{ width: "30%" }} className="eco-mb-24">
                        <Stack horizontalAlign="space_evenly">

                            {/* `Frequencies` toggle button */}
                            {frequencies && frequencies.length > 0 && (
                                <>
                                    <Tag
                                        label={frequencies[0].FrequencyName}
                                        variant="blue"
                                        className={ frequencies[0] === selectedFrequency ? "org-tag" : "org-tag-update" }/>
                                    <Toggle
                                        onChange={(e) => handleFrequencyToggle(e.target.checked)}
                                        className="on-toggle-btn" />
                                    <Tag
                                        label={frequencies[1].FrequencyName}
                                        variant="blue"
                                        className={ frequencies[1] === selectedFrequency ? "org-tag" : "org-tag-update" } />
                                </>
                            )}

                            <Tag label="SAVE 20%" variant="green" className="tier-tag" />
                        </Stack>
                    </div>

                    <div className="eco-mb-24">
                        <Typography type="title" level={4} color="gray_darker">Flexible pricing plans and dedicated solutions for businesses</Typography>
                        <div className='eco-mt-12'>
                            <Select type='options-with-links' options={verticalPlan} value={selectedVerticalPlan ?? {}} isMulti={false} onChange={(e) => handleSelectPricePlan(e)} />
                        </div>
                    </div>
                </Stack>

                <br />

                {isLoading ? <Spin className="price-spinner" size="large" /> : <div className='table-container'>
                    <table className="pricing-plan-table">

                        {/* Payment Plan title */}
                        <tr>
                            <th>&nbsp;</th>
                            {
                                tierDetails && tierDetails.map((item,index)=>(
                                    <>
                                        <th style={{ borderTop: '1px solid #cecece' }} className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                                            {item.Tier.IsRecommendedTier ?
                                                <Tag label={item.Tier.TierName} variant="green" className="tier-tag-card" /> :
                                                <Typography alignment="center" type="text" color="gray_base">{item.Tier.TierName}</Typography>
                                            }
                                        </th>
                                    </>
                                ))
                            }
                        </tr>

                        {/* Payment Plan cost */}
                        <tr>
                            <th>&nbsp;</th>
                            {
                                tierDetails && tierDetails.map((item,index)=>(
                                    <>
                                        <th className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                                            <Typography alignment="center" type="title" level={1} pattern="bold" color={item.Tier.IsRecommendedTier ? "white_base" : "gray_darkest"} >
                                                £ &nbsp;{item.TierTotal}
                                                {/* {timePeriod === false &&
                                                    p?.catalogues?.reduce((t, ob) => { return (t + ob.Cost.Monthly) }, 0)}

                                                {timePeriod === true &&
                                                    p?.catalogues?.reduce((t, ob) => { return (t + ob.Cost.Yearly) }, 0)} */}
                                            </Typography>
                                        </th>
                                    </>
                                ))
                            }
                        </tr>

                        {/* Payment Plan frequency: Per month / Per annual */}
                        <tr>
                            <th>&nbsp;</th>
                            {
                                tierDetails && tierDetails.map((item,index)=>(
                                    <>
                                        <th className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}><Typography alignment="center" type="title" level={5} color={item.Tier.IsRecommendedTier ? "white_base" : "gray_darkest"} >Per month</Typography></th>
                                    </>
                                ))
                            }
                        </tr>

                        {/* Features list */}
                        {
                            featureDataRows && featureDataRows.length > 0 &&
                                featureDataRows.slice(0, showMore? featureDataRows.length :7 ).map((row)=>(
                                    <tr>
                                        <td style={{ textAlign: 'left', width: '30%', padding: '10px 5px' }}>
                                            {row.title}
                                        </td>

                                        {
                                            row.values.map(item=> (
                                                <td style={{ textAlign: 'center', width: '10%' }} className={item.isRecommended ? 'selected-tier' : ''}>
                                                    {
                                                        item.isEmpty?
                                                        <Icon name="minus" size={16} color={item.isRecommended ? 'white' : ''} /> :
                                                        <Icon name="check" size={16} color={item.isRecommended ? 'white' : ''} />
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                        }

                        {/* Payment Plan features preview */}
                        {/* {paymentPlans[paymentPlans.length - 1]?.catalogues?.map((a, i) => {
                            return (
                                <tr>
                                    <td style={{ textAlign: 'left', width: '30%', padding: '10px 5px' }}>
                                        {a.ProductInfo.Name}
                                    </td>

                                    {paymentPlans.map((p, index) => {
                                        return (
                                            <td style={{ textAlign: 'center', width: '10%' }} className={selectedIndex === index ? 'selected-tier' : ''}>
                                                {
                                                    a.IsDefault === true && a.Type != "App" &&
                                                        <Icon name="check" size={16} color={selectedIndex === index ? 'white' : ''} />
                                                        ||
                                                        
                                                        a.Type === "App" && a.IsDefault === false && 
                                                            <Typography>
                                                                {timePeriod === false && a?.Cost?.Monthly}
                                                                {timePeriod === true && a?.Cost?.Yearly}  {a?.Cost?.AdditionalUsageInfo ? "/" && a?.Cost?.AdditionalUsageInfo : ""}
                                                            </Typography>
                                                        
                                                        ||
                                                        
                                                        a.Type != "App" && a.IsDefault === false &&
                                                            <Icon name="minus" size={16} color={selectedIndex === index ? 'white' : ''} />
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })} */}

                        {/* Buy now buttons */}
                        <tr>
                            <th>&nbsp;</th>
                            {
                                tierDetails && tierDetails.map((item)=>(
                                    <th className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                                        <Button
                                            category="text-icon"
                                            text={item.Tier.IsRecommendedTier ? "Buy Now" : "Buy Now"}
                                            icon={item.Tier.IsRecommendedTier ? "check" : "arrow-right"}
                                            className={item.Tier.IsRecommendedTier ? "current-plan-btn" : "select-plan-btn"}
                                            color="blue_base"
                                            iconSize={16}
                                            onClick={()=>handleChangePage(item)}
                                        />
                                    </th>
                                ))
                            }
                        </tr>

                    </table>

                    {/* Show more/less button */}
                    <Stack className="eco-mt-12" horizontalAlign="h_center">
                        <Button variant="primary" onClick={toggleShowMore} color='white' category="text" >
                            {showMore ? ( <><MinusOutlined className='plus_icon_dash' /> See Less Features List</> ) : ( <><PlusCircleOutlined className='plus_icon_dash' /> See Full Features List</>) }
                        </Button>
                    </Stack>

                </div>}
            </div>
        </>
    )
}

export default PricePlanWidget;