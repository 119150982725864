import React from 'react';
import { Divider, Modal, Avatar, Form, Input, Button, Checkbox } from 'antd';
import config from 'react-global-configuration';
import AccountLeftMenu from "./account-left-menu";
import { ProgressBar } from 'react-bootstrap';

const AccountLeftMenuApparel = (props) => (

    
      <>
     <div className="ps-section__right">
                <div className="ps-section--account-setting">
                  <div className="ps-section__header">
                    <h3>YOUR ACCOUNT { sessionStorage.getItem('userfirstname').toUpperCase() + " " + sessionStorage.getItem('userlastname').toUpperCase()} <span className="homeMessage">( <a href="/account/login" onClick={props.onLogout.bind()}>Log Out</a> )</span></h3>
                  </div>
                 
                    
                  <div className="ps-section__content">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-user" />
               
              <a href="/account/my-info"><h4>Your Info</h4><p>Update your details.</p></a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-papers" />
            <a href="/account/order-history"><h4>Your Orders</h4><p>Check your order status or see past orders.</p></a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-map-marker" />
            <a href="/account/addresses"><h4>Addresses</h4><p>Manage your addresses.</p></a> 
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-heart" />
            <a href="/account/my-wishlists"><h4>Your Wishlist</h4><p>View, modify and shop from your lists.</p></a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-store" />
            <a href="/account/payment"><h4>Payment Settings</h4><p>Manage your payment settings.</p></a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 ">
            <div className="ps-block--icon-box"><i className="icon-power-switch" />
            <a href="/account/login" onClick={props.onLogout.bind()}><h4>Logout</h4><p>Logout from our system.</p></a>
            </div>
          </div>
           
        </div>
    

                  </div>
                </div>
              </div>
      
     </>

   

);
export default AccountLeftMenuApparel;
