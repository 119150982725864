import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Tooltip, Modal, Drawer, Button, Divider, Select } from "antd";
import config from "react-global-configuration";
import Image from "./image.component";
import axios from "axios";
import jwt from "jsonwebtoken";

const { Option } = Select;

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menusize: 0,
      clicked: "",
      brands: [],
      menus: [],
      pages: [],
      company: [],
      categories: [],
      basketitem: [],
      orgcurrency: [],
      defCCode: localStorage.getItem("ecurrencythreeCode") || "GBP",
      defCSymbl: localStorage.getItem("ecurrencyCode") || "£",
    };
  }
  resize = () => {
    if (window.innerWidth > 1681) {
      this.setState({ menusize: 5 });
    } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
      this.setState({ menusize: 2 });
    } else {
      this.setState({ menusize: 3 });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.resize);
    this.resize();

    var header = document.getElementById("navigation");
    var sticky = header.offsetTop;

    var hour = new Date().getHours();
    this.setState({ greet: "Hello" });

    var header = document.getElementById("navbar");

    const favicon = getFaviconEl(); // Accessing favicon element
    if (config.get("Theme") === "Apparel") {
      require("./css/style.css");
    }

    favicon.href =
      "https://cdn.neurolage.com/retailpacx/" +
      config
        .get("CDNPath")
        .replace(/ /g, "")
        .replace("&", "")
        .replace(/(^\&)|,/g, "")
        .toLowerCase() +
      "/" +
      config.get("favicon");

    axios
      .get(config.get("backendURL") + "menus/main/" + config.get("companyID"))
      .then((response) => {
        //  console.log(response.data[0]._id )

        const requestOne = axios
          .get(
            config.get("backendURL") +
            "pages/byid/" +
            response.data[0]._id +
            "/" +
            config.get("companyID")
          )
          .then((response) => {
            //console.log(response)
            this.setState({ pages: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    const cachedProduct = localStorage.getItem("cart");
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }

    const cachedLogo = localStorage.getItem("cachedLogo");
    if (cachedLogo === "") {
      localStorage.setItem("cachedLogo", config.get("companyLogo"));
    }
    document.title = config.get("companyPageTitle");

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("SalesprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedSetting = sessionStorage.getItem("setting");

    // axios
    //   .get(
    //     config.get("API_salesregistry") +
    //       "setting/" +
    //       config.get("OrganisationID") +
    //       "/26",
    //     { headers: { Authorization: "Bearer " + token } }
    //   )
    //   .then((response) => {
    //     if (response.data.Result[0].IPLookup) {
    //       const cachedipresponse = localStorage.getItem("ipresponse");
    //       if (!cachedipresponse) {
    //         axios
    //           .get(
    //             "https://api.ipstack.com/check?access_key=5e7152f63b2d02398b551ee66730bbfa"
    //           )
    //           .then((ipresponse) => {
    //             localStorage.setItem(
    //               "ipresponse",
    //               JSON.stringify(ipresponse.data)
    //             );
    //           });
    //       }
    //     }
    //     sessionStorage.setItem("setting", JSON.stringify(response.data.Result));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
    //   .then(response => {
    //     //console.log(response)
    //     this.setState({ orgcurrency: response.data.Result });
    //     this.state.orgcurrency.map(oc => {
    //     console.log(JSON.parse(localStorage.getItem('ipresponse')).currency.code);

    //     if (JSON.parse(localStorage.getItem('ipresponse')).currency.code==oc.CurrencyCode && !localStorage.getItem('dcurrency')) {
    //       localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //       localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //       localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //       localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //       return true;

    //     }else if (oc.IsBaseCurrency === true && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;
    //       }

    //     })
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })

    var payload2 = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var privateKey2 = config.get("ProductprivateKey");
    var token2 = jwt.sign(payload2, privateKey2, signOptions);

    const requestCategories = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token2,
      },
    };

    //console.log(requestCategories)
    // console.log(config.get('rest_API_productregistry') + '/onlinecategories/' + config.get('OrganisationID'))
    const cachedCategory = sessionStorage.getItem("menucategories");
    if (cachedCategory) {
      this.setState({ categories: JSON.parse(cachedCategory) });
    } else {
      fetch(
        config.get("rest_API_productregistry") +
        "/onlinecategories/" +
        config.get("OrganisationID"),
        requestCategories
      )
        .then((response) => response.json())
        .then((data) => this.onSetCategoryResult(data.Result, data.Result));
    }

    const requestBrands = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token2,
      },
    };

    const cachedMenu = sessionStorage.getItem("menubrands");
    if (cachedMenu) {
      this.setState({ brands: JSON.parse(cachedMenu) });
    } else {
      fetch(
        config.get("rest_API_productregistry") +
        "/onlinebrands/" +
        config.get("OrganisationID"),
        requestBrands
      )
        .then((response) => response.json())
        .then((data) => this.onSetBrandResult(data.Result, data.Result));
    }
  }

  state = {
    visible: false,
    visible1: false,
    visible2: false,
    childrenDrawer: false,
    searchvisible: false,
  };

  onSetBrandResult = (result, key) => {
    //  console.log(result)
    this.setState({ brands: result });
    sessionStorage.setItem("menubrands", JSON.stringify(result));
  };

  onSetCategoryResult = (result, key) => {
    this.setState({ categories: result });
    sessionStorage.setItem("menucategories", JSON.stringify(result));
  };
  componentWillUnmount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    document.getElementById("icartcount").innerHTML =
      document.getElementById("cartcount").innerHTML;
  };

  onCartClick = () => {
    const cachedProduct = localStorage.getItem("cart");
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }

    this.setState({ clicked: "clicked" });
  };

  onLogout = () => {
    sessionStorage.setItem("loggedin", false);
    sessionStorage.setItem("username", "");
    localStorage.setItem("fullUserDetails", "");
    sessionStorage.setItem("deliverycost", "");
    window.location.reload();
  };

  handlePageClick = (value) => {
    this.props.parentPageCallback(value);
  };
  handleCurrency = (value) => {
    //console.log(`selected ${value}`);

    localStorage.setItem("ecurrencyid", JSON.parse(value).CurrencyID);
    localStorage.setItem("ecurrencyratio", 1 * JSON.parse(value).CurrencyRate);
    localStorage.setItem("ecurrencyCode", JSON.parse(value).CurrencySymbol);
    localStorage.setItem("ecurrencythreeCode", JSON.parse(value).CurrencyCode);
    localStorage.setItem("dcurrency", "true");
    window.location.reload();
  };

  searchcNow = (event) => {
    event.preventDefault();

    if (document.getElementById("search").value) {
      window.location.href =
        "/search/" + document.getElementById("search").value;
    } else {
      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  searchNow = (event) => {
    event.preventDefault();

    if (document.getElementById("searchnow").value) {
      window.location.href =
        "/search/" + document.getElementById("searchnow").value;
    } else {
      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  showSearchDrawer = () => {
    this.setState({
      searchvisible: true,
    });
  };

  showDrawer = () => {
    const cachedCart = localStorage.getItem("cart");
    this.setState({
      visible: true,
      basketitem: JSON.parse(cachedCart),
    });
  };

  showDrawer1 = () => {
    this.setState({
      visible1: true,
    });
  };
  showDrawer2 = () => {
    this.setState({
      visible2: true,
    });
  };

  onSearchClose = () => {
    this.setState({
      searchvisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onClose1 = () => {
    this.setState({
      visible1: false,
    });
  };
  onClose2 = () => {
    this.setState({
      visible2: false,
    });
  };

  clickButton = (direction) => () => {
    this.setState({ direction });
  };
  render() {
    this.state.offer = this.state.basketitem
      .reduce(
        (accumulator, currentofferValue) =>
          accumulator +
          parseFloat(
            currentofferValue.OfferAmount *
            localStorage.getItem("ecurrencyratio")
          ),
        0
      )
      .toFixed(2);
    this.state.total = this.state.basketitem
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(
            currentValue.ProductQty *
            currentValue.ProductPrice *
            localStorage.getItem("ecurrencyratio")
          ),
        0
      )
      .toFixed(2);
    this.state.cartqty = this.state.basketitem.reduce(
      (accumulator, currentqtyValue) =>
        accumulator + parseFloat(currentqtyValue.ProductQty),
      0
    );
    const { basketitem, total, cartqty, categories, brands, offer } =
      this.state;
    const isBasketNotEmpty = this.state.basketitem.length;

    let button;

    return (
      <>
        <header className='header header--1' data-sticky='true'>
          <div className='header__top'>
            <div className='ps-container'>
              <div className='header__left'>
                <div className='menu--product-categories'>
                  <div className='menu__toggle'>
                    <i className='icon-menu' />
                    <span> Shop by Department</span>
                  </div>
                  <div className='menu__content'></div>
                </div>
                <a href='/'>
                  {" "}
                  <Image
                    params={{
                      ItemData: config.get("companyLogo"),
                      className: "col-sm-12",
                    }}
                  />{" "}
                </a>
              </div>
              <div className='header__center'>
                <Form
                  onSubmit={this.searchcNow}
                  className='ps-form--quick-search'
                >
                  <div className='form-group--icon'>&nbsp;</div>
                  <input
                    className='form-control'
                    type='text'
                    id='search'
                    placeholder="I'm shopping for..."
                  />
                  <button onClick={this.searchcNow}>Search</button>
                </Form>
              </div>
              <div className='header__right'>
                <div className='header__actions'>
                  <a className='header__extra' href='#'>
                    <i className='icon-heart' />
                    <span>
                      <i>0</i>
                    </span>
                  </a>
                  <div className='ps-cart--mini'>
                    <a
                      className='header__extra'
                      href='#'
                      onMouseEnter={this.onCartClick.bind()}
                      onClick={this.onCartClick.bind()}
                    >
                      <i className='icon-bag2' />
                      <span>
                        <i id='cartcount'>{this.state.cartqty}</i>
                      </span>
                    </a>
                    <div className={this.state.clicked + " ps-cart__content"}>
                      <div className='ps-cart__items'>
                        {this.state.basketitem.length ? (
                          this.state.basketitem.map((item) => (
                            <div
                              className='ps-product--cart-mobile'
                              key={item.ProductID}
                            >
                              <div className='ps-product__thumbnail'>
                                {item.ProductImage !== "" ? (
                                  <img src={item.ProductImage} width='80' />
                                ) : (
                                  <img
                                    src='/images/noimage.jpg'
                                    alt='.'
                                    width='80'
                                  />
                                )}
                              </div>
                              <div className='ps-product__content'>
                                <a
                                  href={
                                    "/product/" +
                                    item.ProductName.replace(
                                      / /g,
                                      "-"
                                    ).toLowerCase() +
                                    "/" +
                                    item.ProductID
                                  }
                                >
                                  {item.ProductName}{" "}
                                </a>
                                <small>
                                  {item.ProductQty} x{" "}
                                  {localStorage.getItem("ecurrencyCode")}{" "}
                                  {(
                                    item.ProductPrice *
                                    localStorage.getItem("ecurrencyratio")
                                  ).toFixed(2)}
                                </small>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span>
                            <h6>Your cart is empty</h6>
                            <p>
                              Find hundred of quality products at bargain prices
                              everyday!
                            </p>
                          </span>
                        )}
                      </div>

                      <div className='ps-cart__footer'>
                        <h4>
                          Sub Total:
                          <strong>
                            {localStorage.getItem("ecurrencyCode")}{" "}
                            {this.state.total}
                          </strong>
                        </h4>
                        {this.state.offer != 0 ? (
                          <>
                            <h4>
                              Offer Discount:
                              <strong>
                                {localStorage.getItem("ecurrencyCode")} -
                                {parseFloat(this.state.offer).toFixed(2)}
                              </strong>
                            </h4>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        <h3>
                          Total:
                          <strong>
                            {localStorage.getItem("ecurrencyCode")}{" "}
                            {parseFloat(
                              this.state.total - this.state.offer
                            ).toFixed(2)}
                          </strong>
                        </h3>
                        <figure>
                          <a className='ps-btn' href='/cart'>
                            View Cart
                          </a>
                          <a className='ps-btn' href='/cart'>
                            Checkout
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='ps-block--user-header'>
                    <div className='ps-block__left'>
                      <i className='icon-user' />
                    </div>
                    <div className='ps-block__right'>
                      {sessionStorage.getItem("loggedin") === "true" ? (
                        <a href='/account/member'>
                          {sessionStorage.getItem("username")}
                        </a>
                      ) : (
                        <a href='/account/login'>Login</a>
                      )}

                      {sessionStorage.getItem("loggedin") === "true" ? (
                        <a href='/account/login' onClick={this.onLogout.bind()}>
                          Logout
                        </a>
                      ) : (
                        <a href='/account/register'>Register</a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className='navigation' id='navigation'>
            <div className='ps-container'>
              <div className='navigation__left'>
                <div className='menu--product-categories'>
                  <div className='menu__toggle'>
                    <i className='icon-menu' />
                    <span> Shop by Categories</span>
                  </div>
                  <div className='menu__content'>
                    <ul className='menu--dropdown'>
                      {categories.map((category) => (
                        <li>
                          <a
                            href={
                              "/group/" +
                              category.ProductCategory.ProductCategoryID +
                              "/" +
                              category.ProductCategory.Category
                            }
                          >
                            <i className='icon-star' />{" "}
                            {category.ProductCategory.Category}
                          </a>{" "}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='navigation__right'>
                <ul className='menu'>
                  <li>
                    <a href='/'>Home</a>
                    <span />
                  </li>
                  <li className='menu-item-has-children has-mega-menu'>
                    <a href='/products'>Shop</a>
                    <span className='sub-toggle' />
                    <div className='mega-menu'>
                      <div className='mega-menu__column'>
                        <h4>
                          By Categories
                          <span className='sub-toggle' />
                        </h4>
                        <ul className='mega-menu__list'>
                          {categories.slice(0, 15).map((category) => (
                            <li>
                              <a
                                href={
                                  "/group/" +
                                  category.ProductCategory.ProductCategoryID +
                                  "/" +
                                  category.ProductCategory.Category
                                }
                              >
                                {" "}
                                {category.ProductCategory.Category}
                              </a>{" "}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='mega-menu__column'>
                        <h4>
                          &nbsp;
                          <span className='sub-toggle' />
                        </h4>
                        <ul className='mega-menu__list'>
                          {categories.slice(15, 30).map((category) => (
                            <li>
                              <a
                                href={
                                  "/group/" +
                                  category.ProductCategory.ProductCategoryID +
                                  "/" +
                                  category.ProductCategory.Category
                                }
                              >
                                {" "}
                                {category.ProductCategory.Category}
                              </a>{" "}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                  {this.state.pages
                    .slice(0, this.state.menusize)
                    .map((currentpage) => (
                      <li>
                        <a
                          onClick={this.handlePageClick.bind(
                            this,
                            currentpage.slug
                          )}
                        >
                          {currentpage.pagetitle}
                        </a>
                      </li>
                    ))}
                  {this.state.pages.length > 3 ? (
                    <li className='showmore menu-item-has-children has-mega-menu'>
                      <a href='#'>More</a>
                      <span className='sub-toggle' />
                      <div className='mega-menu'>
                        <div className='mega-menu__column'>
                          <ul className='mega-menu__list'>
                            {this.state.pages
                              .slice(this.state.menusize - 1, 100)
                              .map((currentpage) => (
                                <li>
                                  <a
                                    onClick={this.handlePageClick.bind(
                                      this,
                                      currentpage.slug
                                    )}
                                  >
                                    {currentpage.pagetitle}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <ul className='navigation__extra'>
                  {sessionStorage.getItem("loggedin") === "true" ? (
                    <li>
                      <a href='/account/member'>
                        {this.state.greet} {sessionStorage.getItem("username")}
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a href='/account/login'>Sign In</a>
                    </li>
                  )}

                  {sessionStorage.getItem("loggedin") === "true" ? (
                    <li>
                      <a href='/account/login' onClick={this.onLogout.bind()}>
                        Logout
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a href='/account/register'>Register</a>
                    </li>
                  )}
                  <li>
                    <div className='ps-dropdown'>
                      <a href='#'>
                        {this.state.defCCode + "(" + this.state.defCSymbl + ")"}
                      </a>
                      <ul className='ps-dropdown-menu'>
                        {this.state.orgcurrency.map((ocd) => (
                          <li>
                            <a
                              onClick={this.handleCurrency.bind(
                                this,
                                JSON.stringify(ocd)
                              )}
                            >
                              {ocd.CurrencyCode} ({ocd.CurrencySymbol})
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <header className='header header--mobile' data-sticky='true'>
          <div className='header__top'>
            <div className='header__left'>
              <p>Welcome to Online Shopping Store !</p>
            </div>
            <div className='header__right'>
              <ul className='navigation__extra'>
                {sessionStorage.getItem("loggedin") === "true" ? (
                  <li>
                    <a href='/account/member'>
                      {this.state.greet}, {sessionStorage.getItem("username")}
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href='/account/login'>Sign In</a>
                  </li>
                )}

                {sessionStorage.getItem("loggedin") === "true" ? (
                  <li>
                    <a href='/account/login' onClick={this.onLogout.bind()}>
                      Logout
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href='/account/register'>Register</a>
                  </li>
                )}
                <li>
                  <div className='ps-dropdown'>
                    <a href='#'>
                      {this.state.defCCode + "(" + this.state.defCSymbl + ")"}
                    </a>
                    <ul className='ps-dropdown-menu'>
                      {this.state.orgcurrency.map((ocd) => (
                        <li>
                          <a
                            onClick={this.handleCurrency.bind(
                              this,
                              JSON.stringify(ocd)
                            )}
                          >
                            {ocd.CurrencyCode} ({ocd.CurrencySymbol})
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='navigation--mobile'>
            <div className='navigation__left'>
              <div className='mobile-col-sm-2'>
                <a
                  className='navigation__item ps-toggle--sidebar'
                  onClick={this.showDrawer1.bind()}
                  href='#menu-mobile'
                >
                  <i className='icon-menu' />
                  <span> </span>
                </a>
              </div>
              <div className='mobile-col-sm-10'>
                <a href='/'>
                  {" "}
                  <Image
                    params={{
                      ItemData: config.get("companyLogo"),
                      className: "",
                    }}
                  />{" "}
                </a>
              </div>
            </div>
            <div className='navigation__right'>
              <div className='header__actions'>
                <div className='mobile-col-sm-2'>
                  {" "}
                  <div className='ps-cart--mini'>
                    <a
                      className='header__extra'
                      href='#'
                      onMouseEnter={this.onCartClick.bind()}
                      onClick={this.onCartClick.bind()}
                    >
                      <i className='icon-bag2' />
                      <span>
                        <i id='icartcount'>{this.state.cartqty}</i>
                      </span>
                    </a>
                    <div className={this.state.clicked + " ps-cart__content"}>
                      <div className='ps-cart__items'>
                        {this.state.basketitem.length ? (
                          this.state.basketitem.map((item) => (
                            <div
                              className='ps-product--cart-mobile'
                              key={item.ProductID}
                            >
                              <div className='ps-product__thumbnail'>
                                {item.ProductImage !== "" ? (
                                  <img src={item.ProductImage} width='80' />
                                ) : (
                                  <img
                                    src='/images/noimage.jpg'
                                    alt='.'
                                    width='80'
                                  />
                                )}
                              </div>
                              <div className='ps-product__content'>
                                <a
                                  href={
                                    "/product/" +
                                    item.ProductName.replace(
                                      / /g,
                                      "-"
                                    ).toLowerCase() +
                                    "/" +
                                    item.ProductID
                                  }
                                >
                                  {item.ProductName}{" "}
                                </a>
                                <small>
                                  {item.ProductQty} x{" "}
                                  {localStorage.getItem("ecurrencyCode")}{" "}
                                  {(
                                    item.ProductPrice *
                                    localStorage.getItem("ecurrencyratio")
                                  ).toFixed(2)}
                                </small>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span>
                            <h6>Your cart is empty</h6>
                            <p>
                              Find hundred of quality products at bargain prices
                              everyday!
                            </p>
                          </span>
                        )}
                      </div>
                      <div className='ps-cart__footer'>
                        <h4>
                          Sub Total:
                          <strong>
                            {localStorage.getItem("ecurrencyCode")}{" "}
                            {this.state.total}
                          </strong>
                        </h4>
                        {this.state.offer != 0 ? (
                          <>
                            <h4>
                              Offer Discount:
                              <strong>
                                {localStorage.getItem("ecurrencyCode")} -
                                {parseFloat(this.state.offer).toFixed(2)}
                              </strong>
                            </h4>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        <h3>
                          Total:
                          <strong>
                            {localStorage.getItem("ecurrencyCode")}{" "}
                            {parseFloat(
                              this.state.total - this.state.offer
                            ).toFixed(2)}
                          </strong>
                        </h3>
                        <figure>
                          <a className='ps-btn' href='/cart'>
                            View Cart
                          </a>
                          <a className='ps-btn' href='/cart'>
                            Checkout
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mobile-col-sm-2'>
                  {" "}
                  <a
                    className='navigation__item ps-toggle--sidebar'
                    onClick={this.showSearchDrawer.bind()}
                    href='#search-sidebar'
                  >
                    <i className='icon-magnifier' />
                    <span> </span>
                  </a>
                </div>

                <div className='mobile-col-sm-2'>
                  {sessionStorage.getItem("loggedin") === "true" ? (
                    <a href='/account/member'>
                      <i className='icon-user' />
                    </a>
                  ) : (
                    <a href='/account/login'>
                      <i className='icon-user' />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div>
          <Drawer
            title='Shopping Cart'
            placement='left'
            width={440}
            closable={true}
            getContainer={false}
            onClose={this.onClose}
            visible={this.state.visible}
            key='left'
          >
            <div className='' id='cart-mobile'>
              <div className='navigation__content'>
                <div className='ps-cart--mobile'>
                  <div className={this.state.clicked + " ps-cart__content"}>
                    <div className='ps-cart__items'>
                      {this.state.basketitem.length ? (
                        this.state.basketitem.map((item) => (
                          <div
                            className='ps-product--cart-mobile'
                            key={item.ProductID}
                          >
                            <div className='ps-product__thumbnail'>
                              {item.ProductImage !== "" ? (
                                <img src={item.ProductImage} width='80' />
                              ) : (
                                <img
                                  src='/images/noimage.jpg'
                                  alt='.'
                                  width='80'
                                />
                              )}
                            </div>
                            <div className='ps-product__content'>
                              <a
                                href={
                                  "/product/" +
                                  item.ProductName.replace(
                                    / /g,
                                    "-"
                                  ).toLowerCase() +
                                  "/" +
                                  item.ProductID
                                }
                              >
                                {item.ProductName}{" "}
                              </a>
                              <small>
                                {item.ProductQty} x{" "}
                                {localStorage.getItem("ecurrencyCode")}{" "}
                                {(
                                  item.ProductPrice *
                                  localStorage.getItem("ecurrencyratio")
                                ).toFixed(2)}
                              </small>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>
                          <h6>Your cart is empty</h6>
                          <p>
                            Find hundred of quality products at bargain prices
                            everyday!
                          </p>
                        </span>
                      )}
                    </div>

                    <div className='ps-cart__footer'>
                      <h4>
                        Sub Total:
                        <strong>
                          {localStorage.getItem("ecurrencyCode")}{" "}
                          {this.state.total}
                        </strong>
                      </h4>
                      {this.state.offer != 0 ? (
                        <>
                          <h4>
                            Offer Discount:
                            <strong>
                              {localStorage.getItem("ecurrencyCode")} -
                              {parseFloat(this.state.offer).toFixed(2)}
                            </strong>
                          </h4>
                        </>
                      ) : (
                        ""
                      )}{" "}
                      <h3>
                        Total:
                        <strong>
                          {localStorage.getItem("ecurrencyCode")}{" "}
                          {parseFloat(
                            this.state.total - this.state.offer
                          ).toFixed(2)}
                        </strong>
                      </h3>
                      <figure>
                        <a className='ps-btn' href='/cart'>
                          View Cart
                        </a>
                        <a className='ps-btn' href='/cart'>
                          Checkout
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            title='Menu'
            placement='left'
            closable={true}
            width={440}
            getContainer={false}
            onClose={this.onClose1}
            visible={this.state.visible1}
            key='left'
          >
            <div class='' id='navigation-mobile'>
              <div className='  ps-panel__content'>
                <ul className='menu--mobile'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li>
                    <a href='/products'>
                      Shop
                      <ul className='menu--mobile'>
                        {categories.slice(0, 15).map((category) => (
                          <li>
                            <a
                              href={
                                "/group/" +
                                category.ProductCategory.ProductCategoryID +
                                "/" +
                                category.ProductCategory.Category
                              }
                            >
                              {" "}
                              {category.ProductCategory.Category}
                            </a>{" "}
                          </li>
                        ))}
                      </ul>
                    </a>
                  </li>
                  {this.state.pages.map((currentpage) => (
                    <li>
                      <a href={"/pages/"}>
                        {/* + currentpage.ID */}
                        {currentpage.pagetitle}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Drawer>
          <Drawer
            title='Search'
            placement='left'
            closable={true}
            width={440}
            getContainer={false}
            onClose={this.onSearchClose}
            visible={this.state.searchvisible}
            key='left'
          >
            <div className='ps-panel__header'>
              <Form onSubmit={this.searchNow} className='ps-form--quick-search'>
                <div className='form-group--icon'>
                  <i className='icon-chevron-down' />
                </div>
                <input
                  className='form-control'
                  type='text'
                  id='searchnow'
                  placeholder="I'm shopping for..."
                />
                <button onClick={this.searchNow}>Search</button>
              </Form>
            </div>
            <div className='navigation__content' />
          </Drawer>

          <Drawer
            title='Categories'
            placement='left'
            width={440}
            closable={true}
            getContainer={false}
            onClose={this.onClose2}
            visible={this.state.visible2}
            key='left'
          >
            <div className='ps-panel__content'>
              <ul className='menu--mobile'>
                {categories.slice(0, 15).map((category) => (
                  <li>
                    <a
                      href={
                        "/group/" +
                        category.ProductCategory.ProductCategoryID +
                        "/" +
                        category.ProductCategory.Category
                      }
                    >
                      {" "}
                      {category.ProductCategory.Category}
                    </a>{" "}
                  </li>
                ))}
              </ul>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
