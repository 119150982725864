import React, { Component } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import { Modal, Drawer, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faInfoCircle,
  faUserShield,
  faUserLock,
  faUserEdit,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { LogoutOutlined } from "@ant-design/icons";
import config from "react-global-configuration";
import jwt from "jsonwebtoken";
import moment from "moment";

const Page = (props) => (
  <Nav.Link href={"/pages/" + props.page.slug}>
    {props.page.pagetitle.toUpperCase()}
  </Nav.Link>
);

export default class Navbar2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: "",
      pages: [],
      categories: [],
      brands: [],
      basketitem: [],
    };
  }

  componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedProduct = localStorage.getItem("cart");
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }

    axios
      .post(process.env.REACT_APP_TOKENSERVICE_URL, {
        isLoginserverRequest: false,
        serviceName: "rettailpacx",
      })
      .then(function (response) {
        const requestMenu = axios.post(config.get("backendURL") + "");
      });
    const requestMenu = axios
      .get(config.get("backendURL") + "menus/main/" + config.get("companyID"))
      .then((menuresponse) => {
        const requestOne = axios
          .get(
            config.get("backendURL") +
            "pages/byid/" +
            menuresponse.data[0]._id +
            "/" +
            config.get("companyID")
          )
          .then((response) => {
            // console.log(response.data)
            this.setState({ pages: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    var hour = new Date().getHours();
    this.setState({
      greet:
        "Good " +
        ((hour < 12 && "Morning") || (hour < 18 && "Afternoon") || "Evening"),
    });

    const requestCategories = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    const cachedCategory = sessionStorage.getItem("menucategories");
    if (cachedCategory) {
      this.setState({ categories: JSON.parse(cachedCategory) });
    } else {
      fetch(
        config.get("rest_API_productregistry") +
        "/onlinecategories/" +
        config.get("OrganisationID"),
        requestCategories
      )
        .then((response) => response.json())
        .then((data) => this.onSetCategoryResult(data.Result, data.Result));
    }

    console.log(sessionStorage.getItem("menucategories"));
    const requestBrands = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    const cachedMenu = sessionStorage.getItem("menubrands");
    if (cachedMenu) {
      this.setState({ brands: JSON.parse(cachedMenu) });
    } else {
      fetch(
        config.get("rest_API_productregistry") +
        "/onlinebrands/" +
        config.get("OrganisationID"),
        requestBrands
      )
        .then((response) => response.json())
        .then((data) => this.onSetBrandResult(data.Result, data.Result));
    }
  }

  state = { visible: false, childrenDrawer: false };

  pageList() {
    return this.state.pages.map((currentpage) => {
      return <Page page={currentpage} key={currentpage._id} />;
    });
  }
  onLogout = () => {
    sessionStorage.setItem("loggedin", false);
    sessionStorage.setItem("username", "");
    localStorage.setItem("fullUserDetails", "");
    sessionStorage.setItem("deliverycost", "");
    window.location.reload();
  };
  onSetCategoryResult = (result, key) => {
    this.setState({ categories: result });
    sessionStorage.setItem("menucategories", JSON.stringify(result));
  };
  onSetBrandResult = (result, key) => {
    this.setState({ brands: result });
    sessionStorage.setItem("menubrands", JSON.stringify(result));
  };

  showDrawer = () => {
    const cachedCart = localStorage.getItem("cart");
    this.setState({
      visible: true,
      basketitem: JSON.parse(cachedCart),
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    this.state.total = this.state.basketitem
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(
            currentValue.ProductQty *
            currentValue.ProductPrice *
            localStorage.getItem("ecurrencyratio")
          ),
        0
      )
      .toFixed(2);
    this.state.cartqty = this.state.basketitem.reduce(
      (accumulator, currentqtyValue) =>
        accumulator + parseFloat(currentqtyValue.ProductQty),
      0
    );
    const { basketitem, total, cartqty } = this.state;

    const { categories } = this.state;
    const { brands } = this.state;
    const isBasketNotEmpty = this.state.basketitem.length;
    let button;
    return (
      <Navbar expand='lg'>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Nav.Link href='/'>HOME</Nav.Link>
            <Nav.Link href='/products/'>SHOP NOW</Nav.Link>
            <Nav.Link href='/new/'>NEW ARRIVALS</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
