import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import ReactHtmlParser from "react-html-parser";

class App extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { vimage: [], loaded: false, itemdata: '' };
  }
  componentDidMount() {
    this._isMounted = true;


    axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    }).then(async (response) => {
      await axios.post(
        `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
        {
          ID: this.props.params.ID,
          PageID: "",
          CompanyID: "",
          Start: 0,
          Limit: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      ).then(async (result) => {
        console.log("Editor Text Result ====>", result.data.Result);
        this.setState({
          itemdata: result.data.Result.ItemData
        })
      });
    })
    // axios.get(config.get('backendURL') + 'homelayout/byid/' + this.props.params.id)
    // .then(response => {
    //   console.log(response)
    //   this.setState({
    //     itemdata: response.data.itemdata
    //   })
    // })
    // .catch(function (error) {
    //   console.log(error);
    // })

  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    console.log("Widget_Text", this.props.params.ItemData)

    return (
      <>
        {ReactHtmlParser(
          this.props.params.ItemData
        )}
        {/* <span dangerouslySetInnerHTML={{ __html: this.props.params.ItemData }} /> */}
      </>

    )
  }
}

export default App;
