import React, {useState, useEffect} from 'react';
import axios from 'axios';

const Button = (props) => {
  const [buttonData, setButtonData] = useState({});

  useEffect(()=>{
    axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    }).then(async (response) => {
      await axios.post(`${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
        {
          ID: props.params.ID,
          PageID: "",
          CompanyID: "",
          Start: 0,
          Limit: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      ).then(async (result) => {
        setButtonData(JSON.parse(result.data.Result[0]?.ItemData))
      });
    })
  }, [])

  const onButtonClick = () => {
    if(buttonData && buttonData.buttonWebURL && buttonData.buttonWebURL.siteURL && buttonData.buttonWebURL.siteURL !== ""){
      const _openIn = buttonData.buttonWebURL.openInNewWindow ? '_blank' : '_self';
      window.open(buttonData.buttonWebURL.siteURL, _openIn);
    }
  }

  return (
    <div
      className={props.params.className}
      onClick={onButtonClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: buttonData?.btnColor,
        height: '100%',
      }}>
      <button
        style={{
          borderRadius: buttonData?.border ? buttonData?.border + 'px' : '',
          fontStyle: buttonData?.txtItalic ? 'italic' : '',
          textDecoration: buttonData?.txtUnderline ? 'underline' : '',
          border: 'none',
          color: buttonData?.btnTxtColor,
          background: buttonData?.btnColor,
          fontSize: buttonData?.txtSize + 'px',
          fontWeight: `${buttonData.txtBold ? "700" : '500'}`,
        }}>
        {buttonData.buttonTxt}
      </button>
    </div>
  );
}

export default Button;
