import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { useEffect, useState } from 'react';
import Axios from 'axios'
import apiEndpointPaths from '../themes/default/utilities/apiRequests/apiEndpointPaths';
import { LOCAL_STORAGE_ITEMS_NAMES } from '../themes/default/utilities/helper';

const AdeynPayment = () => {
    const [paymentMethods, setPaymentMethods] = useState(null)
    const [storeData, setStoreData] = useState(null)
    const [clientApiRequestRef, setClientApiRequestRef] = useState("")
    const [cartReference, setCartReference] = useState("")
    const [transactionReference, setTransactionReference] = useState("")
    const [userDetails, setUserDetails] = useState(null)
    const [cartData, setCartData] = useState(null)

    console.log(userDetails)

    useEffect(() => {
        setClientApiRequestRef(new Date().valueOf().toString())
        setCartReference(new Date().valueOf().toString())
        setTransactionReference(new Date().valueOf().toString())
        setUserDetails(JSON.parse(localStorage.getItem("fullUserDetails")))
        setCartData(JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA)))
    }, [])

    useEffect(() => {
        const getPaymentTokens = async () => {
            const apiReqUrl = apiEndpointPaths.payment.fetchToken
            const requestBody = {
                clientAPIRequestRef: clientApiRequestRef,
                shopperReference: userDetails.UserID,
                shopperEmail: userDetails.Email,
                ShopperName: `${userDetails.FirstName} ${userDetails.LastName}`,
                telephoneNumber: "+442039004141",
                cloudLocationID: cartData.cloudLocationID,
                CountryCode: "GB"
            }

            const resObj = await Axios({
                method: "POST",
                url: apiReqUrl,
                data: requestBody,
            });
            console.log('Tokens are ', resObj)
            setPaymentMethods(resObj.data.Result)


        }
        if (userDetails) {
            getPaymentTokens()

        }
    }, [userDetails])


    useEffect(() => {
        const getPaymentStores = async () => {
            const apiReqUrl = apiEndpointPaths.payment.getStores
            const requestBody = {
                OrganizationID: cartData.organizationID,
                cloudLocationID: cartData.cloudLocationID,
            }
            const response = await Axios({
                method: "POST",
                url: apiReqUrl,
                data: requestBody,
            });
            if (response && response.data && response.data.Result && response.data.Result[0].StoreDatas.length > 0) {
                const store = response.data.Result[0].StoreDatas.find(storeData => storeData.StoreType === "Ecommerce")
                setStoreData(store)
            }
        }
        if (userDetails && cartData)
            getPaymentStores()
    }, [userDetails, cartData])

    useEffect(() => {
        const getPaymentMethods = async () => {
            const apiReqUrl = apiEndpointPaths.payment.getPaymentMethods
            const requestBody = {
                clientAPIRequestRef: clientApiRequestRef,
                cloudLocationID: cartData.cloudLocationID,
                store: storeData.Reference
            }
            const response = await Axios({
                method: "POST",
                url: apiReqUrl,
                data: requestBody,
            });

            setPaymentMethods(response.data.Result)
        }
        // if (storeData)
        //     getPaymentMethods()
    }, [storeData])

    const makeInstantPayment = async (data) => {
        const { paymentMethod } = data
        const paymentRequestBody = {
            amount: {
                currency: "GBP",
                value: 1000
            },
            cloudLocationID: cartData.cloudLocationID,
            reference: cartReference,
            siteTransactionRef: transactionReference,
            returnUrl: "http://google.com",
            store: storeData.Reference,
            countryCode: "GB",
            shopperEmail: userDetails.Email,
            ShopperName: `${userDetails.FirstName} ${userDetails.LastName}`,
            telephoneNumber: "+442039004141",
            paymentMethod,
            isTokenized: true,
            APISource: "Web",
            shopperReference: userDetails.UserID
        }

        const apiReqUrl = apiEndpointPaths.payment.instantPayment
        return await Axios({
            method: "POST",
            url: apiReqUrl,
            data: paymentRequestBody,
        });
    }

    const makeTokenPayment = async (data) => {
        const { paymentMethod } = data
        const paymentRequestBody = {
            amount: {
                currency: "GBP",
                value: 1000
            },
            cloudLocationID: cartData.cloudLocationID,
            reference: cartReference,
            siteTransactionRef: transactionReference,
            store: storeData.Reference,
            countryCode: "GB",
            shopperEmail: userDetails.Email,
            ShopperName: `${userDetails.FirstName} ${userDetails.LastName}`,
            telephoneNumber: "+442039004141",
            paymentMethod,
            isTokenized: true,
            APISource: "Web",
            shopperReference: userDetails.UserID,
            clientAPIRequestRef: clientApiRequestRef,
            recurringProcessingModel: "UnscheduledCardOnFile",
            paymentMethod: {
                type: paymentMethod.type,
                storedPaymentMethodId: "NPS8G2S83G9DXWT5"
            }
        }

        const apiReqUrl = apiEndpointPaths.payment.payWithToken
        return await Axios({
            method: "POST",
            url: apiReqUrl,
            data: paymentRequestBody,
        });
    }

    useEffect(() => {
        const initCheckout = async () => {

            const configuration = {
                paymentMethodsResponse: paymentMethods, // The `/paymentMethods` response from the server.
                clientKey: "test_Y5BQLR6VRJA3HK6EF2MJ4KQXO4CVLWNH", // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
                locale: "en-US",
                environment: "test",
                analytics: {
                    enabled: true // 
                },
                onSubmit: (state, dropin) => {
                    console.log(state, state.data.storePaymentMethod)
                    // Global configuration for onSubmit
                    // Your function calling your server to make the `/payments` request
                    if (state.data.storePaymentMethod === false) {
                        makeInstantPayment(state.data)
                            .then(response => {
                                console.log(response)
                                if (response.data.Result?.action) {
                                    // Drop-in handles the action object from the /payments response
                                    dropin.handleAction(response.data.Result.action);
                                } else {
                                    // Your function to show the final result to the shopper
                                    // showFinalResult(response);
                                }
                            })
                            .catch(error => {
                                throw Error(error);
                            });
                    }
                    else {
                        makeTokenPayment(state.data)
                            .then(response => {
                                console.log(response)
                                if (response.data.Result?.action) {
                                    // Drop-in handles the action object from the /payments response
                                    dropin.handleAction(response.data.Result.action);
                                } else {
                                    // Your function to show the final result to the shopper
                                    // showFinalResult(response);
                                }
                            })
                            .catch(error => {
                                throw Error(error);
                            });
                    }
                },
                onAdditionalDetails: (state, dropin) => {
                    // Your function calling your server to make a `/payments/details` request
                    // makeDetailsCall(state.data)
                    //     .then(response => {
                    //         if (response.action) {
                    //             // Drop-in handles the action object from the /payments response
                    //             dropin.handleAction(response.action);
                    //         } else {
                    //             // Your function to show the final result to the shopper
                    //             // showFinalResult(response);
                    //         }
                    //     })
                    //     .catch(error => {
                    //         throw Error(error);
                    //     });
                },
                paymentMethodsConfiguration: {
                    card: { // Example optional configuration for Cards
                        hasHolderName: true,
                        holderNameRequired: true,
                        enableStoreDetails: true,
                        hideCVC: false, // Change this to true to hide the CVC field for stored cards
                        name: 'Credit or debit card',
                    }
                }
            };

            const checkout = await AdyenCheckout(configuration);
            const dropin = checkout
                .create('dropin', {
                    // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
                    openFirstPaymentMethod: false
                })
                .mount('#dropin-container');

        }
        if (paymentMethods)
            initCheckout()
    }, [paymentMethods])



    return <div id="dropin-container"></div>
}

export default AdeynPayment