import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import placeholderImage from '../../../assets/Images/image-placeholder.png'
import { Tag, Button, Input } from 'witmeg-ui-system'
import {addToRetailCart} from '../../../lib/retailCart'
import {updateRetailCart} from '../../../redux/retailDataSlice'
import './ProductItemStyles.css'

export default function ProductItem({product, isFeatured}) {
    const [itemCount, setItemCount] = useState(1)
    const dispatch =useDispatch()
    const changeProductCount = (opt) => {
        if(opt==="add") {
            setItemCount(Number(itemCount)+1)
        } else {
            setItemCount(itemCount>1? (Number(itemCount)-1) :1)
        }
    } 

    const handleAddToCart = async () => {
        let cartData = await addToRetailCart(product, itemCount)
        localStorage.setItem("retailCart", JSON.stringify(cartData)) 
        dispatch(updateRetailCart(cartData))
        setItemCount(1)
    }

    return (
        <div className="shankar-product-item" style={{ margin: '8px', padding: '8px', maxWidth: '360px' }}>
            <div style={{ minHight: '320px', padding: '8px' }}>
                <a href={`/product/${product.ProductID}`}><img style={{ width: '100%' }} src={product?.OnLineImages?.PrimaryImages?.Main || placeholderImage} alt="Product Image" /></a>
            </div>

            <div style={{ minHeight: '150px', padding: '8px' }}>
                {isFeatured ? <Tag label="Featured" variant="red" /> : null}
                <p className='product-item-price eco-mt-12'>£ {product?.SellingPricesArray?.SellingPrice || ''}</p>
                <a href={`/product/${product.ProductID}`}><p className='shankar-product-name'>{product.Name}</p></a>
                
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='item-counter-container'>
                    <Button onClick={() => changeProductCount('remove')} >-</Button>
                    <Input type="number" style={{  textAlign: 'center' }} value={itemCount} />
                    <Button onClick={() => changeProductCount('add')}>+</Button>
                </div>

            </div>
            <div style={{ width: '120px', margin: 'auto', marginTop: '12px' }}>
                <Button className="product-item-addbtn" customStyles={{ backgroundColor: '#fcb800', fontSize: '16px', color: '#000', fontWeight: '700', width: '100%', border: 'none', height:'24px', marginBottom:'8px' }} onClick={handleAddToCart}>Add To Cart</Button>
            </div>
            <div className='product-item-unit-table'>
                <table>
                    <tr>
                        <th>Pack Size</th>
                        <th>Weight</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
