import { Space } from 'antd/lib'
import React, { useState, useEffect } from 'react'
import { Carousel, Card, Typography, Spin } from 'witmeg-ui-system';
import { useSelector } from 'react-redux'
import ProductItem from './ProductItem'
import axios from 'axios';
import config from 'react-global-configuration';

export default function PromotedProducts({ title, params }) {
    const settingsState = useSelector((state) => state.companyData.settings)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [featuredProductList, setFeaturedProductList] = useState([])

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    useEffect(() => {
        if (settingsState.OrgID && settingsState.SalesLocationID) {
            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                setLoadingProducts(true)
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };
                let url = config.get("rest_API_productregistry") + "/" + params.Type + "/" + settingsState.OrgID + "/" + settingsState.SalesLocationID + "/0/20"
                console.log("featured url", url)
                axios.get(
                    url,
                    requestOptions
                )
                    .then((data) => {
                        setLoadingProducts(false)
                        if (data.data.Status) {
                            setFeaturedProductList(data.data.Result)
                        }
                    })
            });
        }
    }, [params, settingsState])


    return (
        <>
            <div>
                <Typography type="title" level={3}>{title}</Typography>
                {featuredProductList.length > 0 ?
                    <div>

                        <Carousel slidesToShow={Math.min(featuredProductList.length, 4)} slidesToScroll={1} infinite={true} rows={1}>

                            {featuredProductList.map((p) => {
                                return (
                                    <div key={p.ID}>
                                        <ProductItem product={p} isFeatured={params.Type === "featured"} isOffers={params.Type === "offers"} />
                                    </div>
                                )
                            })}
                        </Carousel>

                    </div>
                    : <div style={{background:'#fafafa', textAlign:'center', padding:'64px'}}>
                        <h1 style={{color:'#aaa'}}>No {title} available </h1>
                    </div>
                }
            </div>
            <br />
        </>
    )
}
