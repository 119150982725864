import { Space } from 'antd/lib'
import React, { useState, useEffect } from 'react'
import { Carousel, Row, Col, Typography, } from 'witmeg-ui-system'
import { useSelector, useDispatch } from 'react-redux'
import ProductItem from './ProductItem'
import axios from 'axios';
import config from 'react-global-configuration';
import { Skeleton, Pagination } from 'antd';
import {setSearchCriteria} from '../../../redux/retailDataSlice'

export default function SearchPage({ name, categoryId, searchParams, updateQueryParams }) {
    // const searchParams = new URLSearchParams(document.location.search)
    const [itemCount, setItemCount] = useState(12);
    const [currentPage, setCurrentPage] = useState(1)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [productList, setProductList] = useState([])
    const settingsState = useSelector((state) => state.companyData.settings)
    const dispatch=useDispatch()

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };


    useEffect(() => {
        let currentPageNumber = 1
        if (searchParams.get('page')) {
            currentPageNumber = Number(searchParams.get('page'))
            setCurrentPage(Number(searchParams.get('page')))
        }

        dispatch(setSearchCriteria({
            name: name || '',
            categoryId:categoryId ? Number(categoryId) : null
        }))

        window.addEventListener('resize', updateScreenWidth);

        if (settingsState.OrgID && settingsState.SalesLocationID) {

            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };
                // let OrgID = "621738ca7bfb741e995d15b0" //config.get("OrganisationID")
                let OrgID = settingsState.OrgID //config.get("OrganisationID")
                let SalesLocationID = settingsState.SalesLocationID

                let countUrl = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/count/${OrgID}/${SalesLocationID}`;
                axios.post(
                    countUrl,
                    {
                        name: name || '',
                        ProductCategoryIDs: Number(categoryId) || null
                    },
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setItemCount(Number(data.data.Result))
                        }
                    })


                let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${OrgID}/${SalesLocationID}/${(currentPageNumber - 1) * 12}/12`;
                console.log("featured url", url)
                axios.post(
                    url,
                    {
                        name: name || '',
                        ProductCategoryIDs: Number(categoryId) || null
                    },
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setProductList(data.data.Result)
                            setLoadingProducts(false)
                        }
                    })



            });

        }



        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };

    }, [settingsState]);

    const fetchProducts = (pageNumber) => {
        axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServerRequest: false,
            serviceName: "productRegistry",
        }).then(async function (authresponse) {
            setLoadingProducts(true)
            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authresponse.data.token}`,
                }
            };

            // let OrgID = "621738ca7bfb741e995d15b0" //config.get("OrganisationID")
            let OrgID = settingsState.OrgID //config.get("OrganisationID")
            let SalesLocationID = settingsState.SalesLocationID

            let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${OrgID}/${SalesLocationID}/${(pageNumber - 1) * 12}/12`;
            console.log("featured url", url)
            axios.post(
                url,
                {
                    name: name || '',
                    ProductCategoryIDs: Number(categoryId) || null
                },
                requestOptions,
            )
                .then((data) => {
                    console.log("featured res", data)
                    if (data.data.Status) {
                        setProductList(data.data.Result)
                        setLoadingProducts(false)
                    }
                })
        })
    }

    const onChangePageNumber = (page, pageSize) => {
        setCurrentPage(page)
        fetchProducts(page)
        updateQueryParams({ 'page': page })
    }


    return (
        <>
            <div style={{ maxWidth: '1650px', margin: 'auto' }}>
                <Typography type="title" level={3}></Typography>
                <div>

                    {loadingProducts ?
                        <>
                            <br />
                            <Row gutter={16}>
                                <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                            </Row>
                        </> :
                        <Row>

                            {productList.map((p) => {
                                return (
                                    <Col key={p.ID} lg={6} md={8} sm={12} xs={24}>
                                        <div >
                                            <ProductItem product={p} isFeatured={false} isOffers={false} />
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>

                    }

                </div>
                <br />
                <Pagination current={currentPage} total={itemCount} defaultPageSize={12} onChange={onChangePageNumber} />
            </div>
            <br />
        </>
    )
}

