import React from 'react';
import config from 'react-global-configuration';
import {Button, Steps, Result } from 'antd';
import {
    CloseOutlined
  } from '@ant-design/icons';


const { Step } = Steps;

class Application extends React.Component {
    constructor(props) {
        super(props);
      }
  
    componentDidMount() {

    //  localStorage.setItem('cart', '');
        document.title = "Order Success |" + config.get('companyPageTitle')
    }


    render() {


        return (
            <div style={{backgroundColor: "#f5f5f5"}}>
               <div className="fade alert alert-light show">

<Steps current={2}>
    <Step title="DELIVERY" description="Delivery details." />
    <Step title="PAYMENT / PLACE ORDER" description="Payment details." />
    <Step title="THANK YOU" description="For choosing us." />
</Steps>
</div>
           <Result
    status="success"
    title="Your Order has been Successfully placed!"
    subTitle={'Order number: '+ sessionStorage.getItem('ordernumber')}    extra={[
      <Button type="primary" key="console" href="/">
        Home
      </Button>,
      <Button href="/products">Shop Again</Button>,
    ]}
  />
            </div>
        )
    }
}

export default Application;