import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faShoppingCart,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Skeleton, message } from "antd";
import config from "react-global-configuration";
import jwt from "jsonwebtoken";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const key = "updatable";

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { products: [], basketitem: [], basketqty: [], loaded: false };
  }

  componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        Message:
          'query{productsbycategory(input:{orgID:"' +
          config.get("OrganisationID") +
          '",categoryID:19}) {ID,Name,ProductID,ProductCategoryID,ProductSubCategoryID,BarCode,ProductRefNo,ReorderLevel,MaxReorderLevel,Discount,CreatedDate,  CreatedBy,ModifiedDate,ModifiedBy,IsDeleted,BrandID,HasWeight,Level1_Price,Level2_Price,Level3_Price,Level4_Price,PriceChangeDate,MaxDiscount,IsReorderLevelMonitor,PriceChangeSource,IsActive,  CostPricesArray{CostPrice,UnitTypeID,UnitChartid,LocationID},SellingPricesArray{SellingPrice,SalesUnitTypeID,UnitChartid,LocationID},BinLocData{LocationID,BinName,IsSelected},OrgID,Weight,CustomArray,OnlineImages,OnLineConfig,UnitCharts{UnitChartID,UnitTypeName,Quantity,UnitTypeID}}}',
      }),
    };

    const cachedProduct = sessionStorage.getItem("sssessionproducts");
    if (cachedProduct) {
      this.setState({ products: JSON.parse(cachedProduct) });
    } else {
      fetch(config.get("API_productregistry"), requestOptions)
        .then((response) => response.json())
        .then((data) =>
          this.onSetProductResult(
            data.productsbycategory,
            data.productsbycategory
          )
        );
    }

    const cachedCart = localStorage.getItem("cart");
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }
  }

  onChangeValue = (event) => {
    this.setState({ value: "1" });
    // document.getElementsByClassName("basketadded").style.visibility = "hidden"
  };

  onAddItem = (ProductID, ProductName, ProductCategoryID) => {
    let prodqty = "";
    const prodprice = document.getElementById("prodprice" + ProductID).value;
    const prodprimimage = document.getElementById(
      "prodprimimage" + ProductID
    ).value;

    var index = this.state.basketitem.findIndex(
      (a) => a.ProductID === ProductID
    );

    if (index !== -1) {
      const basketitem = this.state.basketitem;
      basketitem[index]["ProductQty"] =
        parseFloat(basketitem[index]["ProductQty"]) +
        parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem("cart", JSON.stringify(basketitem));
    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value;
      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
          (category) =>
            category.ProductCategory.ProductCategoryID === ProductCategoryID
        ).ProductCategory.VatRate,
      };

      this.setState((state) => {
        {
          basketitem: state.basketitem.push(ProductsDtl);
          localStorage.setItem("cart", JSON.stringify(state.basketitem));
        }
      });
    }

    message.loading({ content: "Adding...", key });
    setTimeout(() => {
      message.success({ content: "Added to the cart!", key, duration: 2 });
    }, 800);

    document.getElementById("cartcount").innerHTML =
      parseInt(document.getElementById("cartcount").innerHTML) +
      parseInt(prodqty);
  };

  onSetProductResult = (result, key) => {
    this.setState({ products: result });
    sessionStorage.setItem("sssessionproducts", JSON.stringify(result));
  };

  render() {
    const { products } = this.state;
    const { basketitem } = this.state;
    return (
      <Row>
        <Col sm={12}>
          <Row>
            <Col>
              <div className='list'>
                <h2>SPECIAL SALE PRODUCTS</h2>
              </div>
            </Col>
          </Row>

          <Row>
            {products.length ? (
              products.map((product) => (
                <Col xs={6} md={6} lg='2'>
                  <div className='list'>
                    <LazyLoad>
                      <div className='card'>
                        <div md={{ span: 3, offset: 3 }}>
                          <FontAwesomeIcon icon={faHeart} />
                        </div>
                        {JSON.parse("[" + product.OnlineImages + "]").map(
                          (images) => (
                            <a
                              key={"pid" + product.ProductID}
                              href={
                                "/product/" +
                                product.Name.replace(/ /g, "-").toLowerCase() +
                                "/" +
                                product.ProductID
                              }
                            >
                              {images.PrimaryImages !== null ? (
                                <div style={{ width: "auto" }}>
                                  {/* {this.state.loaded ? null :
          <div className="divLoader">
          <LoadingOutlined  style={{ fontSize: '56px', color: '#08c' }} /><br/>
          Loading
           </div>
        } */}
                                  <img
                                    src={images.PrimaryImages.Main}
                                    alt={product.Name}
                                    onLoad={() =>
                                      this.setState({ loaded: true })
                                    }
                                    style={
                                      this.state.loaded
                                        ? {}
                                        : { display: "none" }
                                    }
                                  />
                                  <input
                                    type='hidden'
                                    id={"prodprimimage" + product.ProductID}
                                    value={images.PrimaryImages.Main}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <img
                                    src='/images/noimage.jpg'
                                    alt={product.Name}
                                  />
                                  <input
                                    type='hidden'
                                    id={"prodprimimage" + product.ProductID}
                                    value=''
                                  />
                                </div>
                              )}
                            </a>
                          )
                        )}
                        <h4>{product.Name}</h4>
                        <p>
                          <b>Item Code:</b>
                          <br /> {product.ProductRefNo}
                        </p>

                        {product.SellingPricesArray.map((SellingPrices) => (
                          <div className='row'>
                            <div
                              className={
                                product.Discount != 0
                                  ? "priceleft col-sm-6"
                                  : "priceleft col-sm-12"
                              }
                            >
                              <p className='price'>
                                <sup className='pricex'>
                                  {localStorage.getItem("ecurrencyCode")}
                                </sup>
                                <sub>
                                  {
                                    String(
                                      SellingPrices.SellingPrice *
                                        localStorage.getItem("ecurrencyratio")
                                    ).split(".")[0]
                                  }
                                  <input
                                    type='hidden'
                                    id={"prodprice" + product.ProductID}
                                    value={SellingPrices.SellingPrice}
                                  />
                                </sub>
                                <sup>
                                  {String(
                                    SellingPrices.SellingPrice *
                                      localStorage.getItem("ecurrencyratio")
                                  ).split(".")[1] != null
                                    ? String(
                                        (
                                          SellingPrices.SellingPrice *
                                          localStorage.getItem("ecurrencyratio")
                                        ).toFixed(2)
                                      ).split(".")[1]
                                    : "00"}
                                </sup>
                              </p>
                            </div>
                            {product.Discount != 0 ? (
                              <div className='priceright col-sm-6'>
                                was {localStorage.getItem("ecurrencyCode")}
                                {parseInt(SellingPrices.SellingPrice) *
                                  parseInt(
                                    localStorage.getItem("ecurrencyratio")
                                  ) +
                                  parseInt(product.Discount)}{" "}
                                <br />
                                <span className='dispricetxt'>
                                  save {localStorage.getItem("ecurrencyCode")}
                                  {product.Discount}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}

                        <Table striped bordered hover size='sm' width='50%'>
                          <thead>
                            <tr>
                              <th>Pack Sizzzze</th>
                              <th>Weight</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product.UnitCharts.map((elu) => (
                              <tr>
                                <td>{elu.Quantity}</td>
                                <td>{product.Weight}</td>
                                <td>{elu.UnitTypeName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <p className='inputandbtn'>
                          <NumericInput
                            min={1}
                            max={50}
                            className='inputqty'
                            id={"prodqty" + product.ProductID}
                            value={1}
                          />
                          <button
                            onClick={this.onAddItem.bind(
                              this,
                              product.ProductID,
                              product.Name,
                              product.ProductCategoryID
                            )}
                            id={product.ProductID}
                          >
                            <FontAwesomeIcon icon={faShoppingCart} />
                            <span>ADD</span>{" "}
                          </button>
                        </p>
                      </div>
                      <br />
                    </LazyLoad>
                  </div>
                </Col>
              ))
            ) : (
              <Col sm={12}>
                <br />
                <Skeleton active avatar paragraph={{ rows: 4 }} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Application;
