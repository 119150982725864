import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faEnvelope,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table, Modal } from "react-bootstrap";
import {
  Skeleton,
  message,
  Button,
  Form,
  Input,
  Descriptions,
  Collapse,
} from "antd";
import Grid from "@material-ui/core/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "./imagegallery";
import ProductTag from "./product-tag";
import config from "react-global-configuration";
import jwt from "jsonwebtoken";
import { renderEmail } from "postonents";
import NotifyEmail from "../email/NotifyEmail";
import { CaretRightOutlined } from "@ant-design/icons";
import ProductDetailsMartFury from "../themes/martfury/productdetails.jsx";
import ProductDetailsSpiceway from "../themes/spiceway/productdetails.jsx";
import ProductDetailsApparel from "../themes/apparel/productdetails.jsx";
import ProductDetailsApparelCasi from "../themes/apparel-casi/productdetails.jsx";
import ProductDetailsMeatShop from "../themes/meatshop/productdetails.jsx";
const key = "updatable";

const { Panel } = Collapse;

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsdtlsbyid: [],
      productstagdtlsbyid: [],
      basketitem: [],
      basketqty: [],
      myArr: [],
      regexp: /^[0-9\b]+$/,
    };
  }

  async componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");

    var token = jwt.sign(payload, privateKey, signOptions);

    let OrgID = "621738ca7bfb741e995d15b0" //config.get("OrganisationID")

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        OrgID: OrgID,
        ProductID: parseInt(this.props.match.params.id),
        LocationID: 52//JSON.parse(sessionStorage.getItem("setting"))[0].SalesLocationID,
      }),
    };

    fetch(config.get("rest_API_productregistry") + "/productid", requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        const products = data.Result;
        this.setState({ ploaded: false });
        const listofproducts = await Promise.all(
          data.Result.map(
            async (product) => await this.ProductsOffer(product, data)
          )
        );
        //console.log(listofproducts)
        this.setState({ productsdtlsbyid: listofproducts, ploaded: true });
      });

    //console.log(requestOptions)

    const cachedCart = localStorage.getItem("cart");
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }
  }

  onChangeValue = (event) => {
    this.setState({ value: "1" });
    document.getElementsByClassName("basketadded").style.visibility = "hidden";
  };
  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = (e) => {
    //  console.log(e);
    this.setState({
      visible: false,
    });
  };

  ProductOfferbyOffer = async (offerdetails) => {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");

    var token = jwt.sign(payload, privateKey, signOptions);

    const result = [];
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        OrgID: config.get("OrganisationID"),
        ProductID: offerdetails.ProductID,
        LocationID: JSON.parse(sessionStorage.getItem("setting"))[0]
          .SalesLocationID,
      }),
    };

    return await fetch(
      config.get("rest_API_productregistry") + "/productid",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        result.push(data.Result[0]);
        return result;
      });
  };

  ProductsOffer = async (product) => {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    //console.log(product)

    const result = [];

    if (product.Offers[0] !== undefined) {
      return await fetch(
        config.get("rest_API") +
          "offers/offerbyid/" +
          config.get("OrganisationID") +
          "/" +
          product.Offers[0].OfferProducts.OfferID,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (data) => {
          result.push({ ...product, offerdetails: data.Result[0] });

          const listofproducts = await Promise.all(
            data.Result[0].OfferProducts.map(
              async (product) => await this.ProductOfferbyOffer(product, data)
            )
          );

          console.log("listofproducts");
          console.log(listofproducts);

          this.setState({ offerproducts: listofproducts, ploaded: true });

          return result[0];
        });
    } else {
      return product;
    }
  };

  onAddItem = (
    buyoradd,
    ProductID,
    ProductName,
    MaxOnlineOrderQty,
    ProductCategoryID,
    offerItems,
    offerqty,
    offerprice
  ) => {
    let prodqty = "";

    const basketitem = this.state.basketitem;

    const prodprice = document.getElementById("prodprice" + ProductID).value;
    const prodprimimage = document.getElementById(
      "prodprimimage" + ProductID
    ).value;

    var index = this.state.basketitem.findIndex(
      (a) => a.ProductID === ProductID
    );
    prodqty = document.getElementById("prodqty" + ProductID).value;

    if (!this.state.regexp.test(prodqty)) {
      message.error("Please enter only valid number for the product quantity.");
      return;
    }

    if (index !== -1) {
      const aprodqty = basketitem[index]["ProductQty"];

      if (
        MaxOnlineOrderQty != 0 &&
        MaxOnlineOrderQty < parseInt(prodqty) + parseInt(aprodqty)
      ) {
        message.error(
          "Please add less than " +
            MaxOnlineOrderQty +
            " items in total to cart"
        );
        return;
      } else if (prodqty > 1000) {
        message.error(
          "You are not allowed to add more than 1000 " +
            ProductName +
            ", if you need more than 1000 please contact us."
        );
        document.getElementById("prodqty" + ProductID).value = 1;
        return;
      }

      basketitem[index]["ProductQty"] =
        parseFloat(basketitem[index]["ProductQty"]) +
        parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem("cart", JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML =
        parseInt(document.getElementById("cartcount").innerHTML) +
        parseInt(aprodqty);
    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value;

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error(
          "Please add less than " +
            MaxOnlineOrderQty +
            " items in total to cart"
        );
        return;
      } else if (prodqty > 1000) {
        message.error(
          "You are not allowed to add more than 1000 " +
            ProductName +
            ", if you need more than 1000 please contact us."
        );
        document.getElementById("prodqty" + ProductID).value = 1;
        return;
      }

      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
          (category) =>
            category.ProductCategory.ProductCategoryID === ProductCategoryID
        ).ProductCategory.VatRate,
      };

      //  console.log(ProductsDtl)
      this.setState((state) => {
        {
          basketitem: state.basketitem.push(ProductsDtl);
          localStorage.setItem("cart", JSON.stringify(state.basketitem));
        }
      });
    }

    setTimeout(() => {
      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");

      let checker = (arr, target) => target.some((v) => arr.includes(v));

      let op = JSON.parse(localStorage.getItem("cart")).map(
        ({ ProductID }) => ProductID
      );

      var offer = 0;

      if (checker(op, offeri)) {
        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {
          var cindex = JSON.parse(localStorage.getItem("cart")).findIndex(
            (a) => a.ProductID === el
          );
          //  console.log(cindex)

          if (cindex !== -1) {
            totalitems =
              totalitems +
              parseFloat(
                JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
              );
            totalamounts =
              totalamounts +
              parseFloat(
                JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
              ) *
                parseFloat(
                  JSON.parse(localStorage.getItem("cart"))[cindex][
                    "ProductPrice"
                  ]
                );
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
        //console.log('remainder: ' + remainder)
        offer =
          parseFloat(totalamounts - prodprice * remainder) -
          parseFloat(quotient * offerprice);
        //console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(
          (a) => a.ProductID === ProductID
        );

        basketitem[clickedindex]["OfferAmount"] = offer;

        if (totalitems < offerqty) {
          basketitem[clickedindex]["OfferAmount"] = 0;
        }

        this.setState({ basketitem: basketitem });
        localStorage.setItem("cart", JSON.stringify(basketitem));

        //console.log(localStorage.getItem('cart'))
      }
    }, 100);

    message.loading({ content: "Adding...", key });
    setTimeout(() => {
      message.success({ content: "Added to the cart!", key, duration: 2 });
      if (buyoradd === "buy") {
        window.location.href = "/cart";
      }
    }, 800);
  };

  onSetProductResult = (result, key) => {
    this.setState({ productsdtlsbyid: result });
  };

  onSetProductTagResult = (result, key) => {
    let producttagarray = "[" + JSON.stringify(result) + "]";
    this.setState({ productstagdtlsbyid: JSON.parse(producttagarray) });
    sessionStorage.setItem(
      "sessiontagproductsdtls" + this.props.match.params.id,
      "[" + JSON.stringify(result) + "]"
    );
  };

  onQtyIncrese = (ProductID) => {
    document.getElementById("prodqty" + ProductID).value =
      parseFloat(document.getElementById("prodqty" + ProductID).value) + 1;
  };
  onQtyReduce = (ProductID) => {
    if (document.getElementById("prodqty" + ProductID).value > 1) {
      document.getElementById("prodqty" + ProductID).value =
        parseFloat(document.getElementById("prodqty" + ProductID).value) - 1;
    }
  };

  render() {
    const onFinish = (values) => {
      const Notifydata = {
        companyPageTitle: config.get("companyPageTitle"),
        SenderEmail: config.get("SenderEmail"),
        companyLogoCDN: config.get("companyLogoCDN"),
        prdtName: document.getElementById("prdt").value,
      };

      const emailBody = renderEmail(NotifyEmail, { lang: "en", Notifydata });
      async function postData() {
        const ecoEmail = {
          to:
            config.get("companyPageTitle") +
            "<" +
            config.get("SenderEmail") +
            ">",
          bcc: "suren.sivalingam@ecologital.com",
          from: values.email,
          subject: "Product Notification Request",
          html: emailBody,
        };

        const response = await fetch(config.get("backendURL") + "mail/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(ecoEmail),
        });
        return response.json();
      }

      postData().then((data) => {
        message.success(
          "Email has been sent to " + config.get("companyPageTitle")
        );
        this.handleCancel();
      });
    };

    const { productsdtlsbyid, productstagdtlsbyid } = this.state;
    const { basketitem } = this.state;
    return (
      <>
        {(() => {
          switch (config.get("Theme")) {
            case "Martfury":
              return (
                <ProductDetailsMartFury
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            case "Apparel":
              return (
                <ProductDetailsApparel
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            case "Apparel-casi":
              return (
                <ProductDetailsApparelCasi
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            case "Spiceway":
              return (
                <ProductDetailsSpiceway
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            case "MeatShop":
              return (
                <ProductDetailsMeatShop
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            case "Stationary":
              return (
                <ProductDetailsMeatShop
                  params={{
                    productsdtlsbyid: this.state.productsdtlsbyid,
                    visible: this.state.visible,
                    offerproducts: this.state.offerproducts,
                    ploaded: this.state.ploaded,
                    id: this.props.match.params.id,
                  }}
                  onFinish={onFinish}
                  onAddItem={this.onAddItem}
                  onQtyIncrese={this.onQtyIncrese}
                  handleCancel={this.handleCancel}
                  onNotify={this.onNotify}
                  handleCancel={this.handleCancel}
                  onQtyReduce={this.onQtyReduce}
                />
              );
            default:
              return (
                <>
                  <Row>
                    <Col sm={12}>
                      <Breadcrumb>
                        <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href='/products/'>
                          Products Details
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>
                    <Col sm={12}>
                      {this.state.ploaded ? (
                        productsdtlsbyid ? (
                          productsdtlsbyid.map((product) => (
                            <Row>
                              {product.OnLineImages.PrimaryImages !== null ? (
                                <>
                                  <Col sm={5}>
                                    <Grid container spacing={4}>
                                      <Grid item xs={12}>
                                        <ImageGallery
                                          params={{
                                            ProductMainImage:
                                              product.OnLineImages
                                                .PrimaryImages,
                                            SpecialImages:
                                              product.OnLineImages
                                                .SpecialImages,
                                            productsdtlsbyid: productsdtlsbyid,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        item
                                        xs={12}
                                        direction='column'
                                      >
                                        <Grid item>
                                          <div id='myPortal' />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col sm={5}>
                                    <div md={{ span: 3, offset: 3 }}>
                                      {product.Offers[0] ? (
                                        product.Offers[0].Offer.OfferType ==
                                        3 ? (
                                          <>
                                            {" "}
                                            <span class='mulitistock'>
                                              MULTI BUY
                                            </span>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {product.IsOutOfStock ? (
                                        <>
                                          {" "}
                                          <span class='outofstock'>
                                            OUT OF STOCK
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}{" "}
                                      <FontAwesomeIcon icon={faHeart} />
                                    </div>
                                    <div
                                      id='bigImg'
                                      style={{ position: "absolute" }}
                                    ></div>
                                    <input
                                      type='hidden'
                                      id={"prodprimimage" + product.ProductID}
                                      value={
                                        product.OnLineImages.PrimaryImages.Main
                                      }
                                    />
                                    <h4>{product.Name}</h4>
                                    <p>
                                      <b>Item Code:</b>
                                      <br /> {product.ProductRefNo}
                                    </p>

                                    <Table
                                      striped
                                      bordered
                                      hover
                                      size='sm'
                                      width='50%'
                                    >
                                      <thead>
                                        <tr>
                                          <th>Pack Size</th>
                                          <th>Weight</th>
                                          <th>Type</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {product.UnitCharts.length > 0 ? (
                                          product.UnitCharts.map((elu) => (
                                            <tr>
                                              <td>{elu.Quantity}</td>
                                              <td>{product.Weight}</td>
                                              <td>{elu.UnitTypeName}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td>1</td>
                                            <td>{product.Weight}</td>
                                            <td>EA</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>

                                    {product.Offers[0] ? (
                                      product.Offers[0].Offer.OfferType === 3 ? (
                                        <>
                                          <Collapse
                                            bordered={true}
                                            expandIcon={({ isActive }) => (
                                              <CaretRightOutlined
                                                rotate={isActive ? 90 : 0}
                                              />
                                            )}
                                            className='multioffer'
                                          >
                                            <Panel
                                              header='Multi Buy Offers - Click to View'
                                              key='1'
                                              className='site-collapse-custom-panel'
                                            >
                                              <b class='text-danger'>
                                                {
                                                  product.Offers[0].Offer
                                                    .OfferTypeDesc
                                                }
                                              </b>
                                              <br />
                                              <b class='text-success'>
                                                {
                                                  product.Offers[0].Offer
                                                    .PromotionName
                                                }
                                              </b>
                                              <br />
                                              <br />

                                              {this.state.offerproducts.length >
                                              2 ? (
                                                <>
                                                  <b>
                                                    Buy Any{" "}
                                                    {
                                                      product.Offers[0].Offer
                                                        .PromotionQty
                                                    }{" "}
                                                    of these
                                                  </b>
                                                </>
                                              ) : (
                                                <>
                                                  <b>
                                                    Buy{" "}
                                                    {
                                                      product.Offers[0].Offer
                                                        .PromotionQty
                                                    }{" "}
                                                    of these
                                                  </b>
                                                </>
                                              )}
                                              <p>
                                                {this.state.offerproducts.map(
                                                  (PO) => (
                                                    <>
                                                      {PO[0].Name != null ? (
                                                        <>
                                                          <a
                                                            href={
                                                              "/product/" +
                                                              PO[0].Name.replace(
                                                                / /g,
                                                                "-"
                                                              )
                                                                .replace(
                                                                  "/",
                                                                  "-"
                                                                )
                                                                .toLowerCase() +
                                                              "/" +
                                                              PO[0].ProductID
                                                            }
                                                          >
                                                            {PO[0].Name} -{" "}
                                                            {PO[0].ProductRefNo}
                                                          </a>
                                                          <br />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </p>
                                            </Panel>
                                          </Collapse>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {product.ProductOnlineDescription ? (
                                      <>
                                        <Descriptions title='Product Information'>
                                          <Descriptions.Item>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  product.ProductOnlineDescription,
                                              }}
                                            />
                                          </Descriptions.Item>
                                        </Descriptions>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col sm={2}>
                                    <Grid container spacing={4}>
                                      <Grid item xs={12}>
                                        <div>
                                          <img
                                            src='/images/noimage.jpg'
                                            alt={product.Name}
                                            height='220'
                                          />
                                          <input
                                            type='hidden'
                                            id={
                                              "prodprimimage" +
                                              product.ProductID
                                            }
                                            value=''
                                          />{" "}
                                        </div>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        item
                                        xs={12}
                                        direction='column'
                                      >
                                        <Grid item>
                                          <div id='myPortal' />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col sm={8}>
                                    <h4>{product.Name}</h4>
                                    <p>
                                      <b>Item Code:</b>
                                      <br /> {product.ProductRefNo}
                                    </p>

                                    <Table
                                      striped
                                      bordered
                                      hover
                                      size='sm'
                                      width='50%'
                                    >
                                      <thead>
                                        <tr>
                                          <th>Pack Size</th>
                                          <th>Weight</th>
                                          <th>Type</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {product.UnitCharts.length > 0 ? (
                                          product.UnitCharts.map((elu) => (
                                            <tr>
                                              <td>{elu.Quantity}</td>
                                              <td>{product.Weight}</td>
                                              <td>{elu.UnitTypeName}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td>1</td>
                                            <td>{product.Weight}</td>
                                            <td>EA</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>

                                    {product.Offers[0] ? (
                                      product.Offers[0].Offer.OfferType === 3 ? (
                                        <>
                                          <Collapse
                                            bordered={true}
                                            expandIcon={({ isActive }) => (
                                              <CaretRightOutlined
                                                rotate={isActive ? 90 : 0}
                                              />
                                            )}
                                            className='multioffer'
                                          >
                                            <Panel
                                              header='Multi Buy Offers - Click to View'
                                              key='1'
                                              className='site-collapse-custom-panel'
                                            >
                                              <b class='text-danger'>
                                                {
                                                  product.Offers[0].Offer
                                                    .OfferTypeDesc
                                                }
                                              </b>
                                              <br />
                                              <b class='text-success'>
                                                {
                                                  product.Offers[0].Offer
                                                    .PromotionName
                                                }
                                              </b>
                                              <br />
                                              <br />
                                              <p>
                                                <b>
                                                  Buy Any{" "}
                                                  {
                                                    product.Offers[0].Offer
                                                      .PromotionQty
                                                  }{" "}
                                                  of these
                                                </b>
                                                <br />

                                                {this.state.offerproducts.map(
                                                  (PO) => (
                                                    <>
                                                      <a
                                                        href={
                                                          "/product/" +
                                                          PO[0].Name.replace(
                                                            / /g,
                                                            "-"
                                                          )
                                                            .replace("/", "-")
                                                            .toLowerCase() +
                                                          "/" +
                                                          PO[0].ProductID
                                                        }
                                                      >
                                                        {PO[0].Name} -{" "}
                                                        {PO[0].ProductRefNo}
                                                      </a>
                                                      <br />
                                                    </>
                                                  )
                                                )}
                                              </p>
                                            </Panel>
                                          </Collapse>
                                          <br />
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {product.ProductOnlineDescription ? (
                                      <>
                                        <Descriptions title='Product Information'>
                                          <Descriptions.Item>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  product.ProductOnlineDescription,
                                              }}
                                            />
                                          </Descriptions.Item>
                                        </Descriptions>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </>
                              )}

                              <Col sm={2}>
                                <div className='card details'>
                                  <div md={{ span: 3, offset: 3 }}>
                                    {product.Offers[0] ? (
                                      product.Offers[0].Offer.OfferType === 3 ? (
                                        <>
                                          {" "}
                                          <span class='mulitistock'>
                                            MULTI BUY
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {product.IsOutOfStock ? (
                                      <>
                                        {" "}
                                        <span class='outofstock'>
                                          OUT OF STOCK
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}{" "}
                                    <FontAwesomeIcon icon={faHeart} />
                                  </div>

                                  <div className='row'>
                                    <div
                                      className={
                                        product.Discount != 0
                                          ? "priceleft col-sm-6"
                                          : "priceleft col-sm-12"
                                      }
                                    >
                                      <p className='price'>
                                        <sup className='pricex'>
                                          {localStorage.getItem(
                                            "ecurrencyCode"
                                          )}
                                        </sup>
                                        <sub>
                                          {
                                            String(
                                              product.SellingPricesArray
                                                .SellingPrice *
                                                localStorage.getItem(
                                                  "ecurrencyratio"
                                                )
                                            ).split(".")[0]
                                          }
                                          <input
                                            type='hidden'
                                            id={"prodprice" + product.ProductID}
                                            value={
                                              product.SellingPricesArray
                                                .SellingPrice
                                            }
                                          />
                                        </sub>
                                        <sup>
                                          {String(
                                            product.SellingPricesArray
                                              .SellingPrice *
                                              localStorage.getItem(
                                                "ecurrencyratio"
                                              )
                                          ).split(".")[1] != null
                                            ? String(
                                                (
                                                  product.SellingPricesArray
                                                    .SellingPrice *
                                                  localStorage.getItem(
                                                    "ecurrencyratio"
                                                  )
                                                ).toFixed(2)
                                              ).split(".")[1]
                                            : "00"}
                                        </sup>
                                      </p>
                                    </div>
                                    {product.Discount != 0 ? (
                                      <div className='priceright col-sm-6'>
                                        was{" "}
                                        {localStorage.getItem("ecurrencyCode")}
                                        {parseInt(
                                          product.SellingPrices.SellingPrice
                                        ) *
                                          parseInt(
                                            localStorage.getItem(
                                              "ecurrencyratio"
                                            )
                                          ) +
                                          parseInt(product.Discount)}{" "}
                                        <br />
                                        <span className='dispricetxt'>
                                          save{" "}
                                          {localStorage.getItem(
                                            "ecurrencyCode"
                                          )}
                                          {product.Discount}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  {product.IsOutOfStock ? (
                                    <div className='text-center'>
                                      {" "}
                                      <p className='inputandbtn'>
                                        <button
                                          className='notify'
                                          onClick={this.onNotify.bind(
                                            this,
                                            product.ProductID,
                                            product.Name,
                                            product.MaxOnlineOrderQty
                                          )}
                                          id={product.ProductID}
                                        >
                                          <FontAwesomeIcon icon={faEnvelope} />
                                          Notify
                                        </button>
                                      </p>{" "}
                                      <Modal
                                        show={this.state.visible}
                                        onHide={this.handleCancel}
                                        centered
                                        aria-labelledby='example-modal-sizes-title-sm'
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title id='example-modal-sizes-title-sm'>
                                            Notify
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <Form
                                            onFinish={onFinish}
                                            name='basic'
                                            initialValues={{ remember: true }}
                                          >
                                            <Form.Item
                                              label='Email Address'
                                              name='email'
                                              rules={[
                                                {
                                                  required: true,
                                                  type: "email",
                                                },
                                              ]}
                                            >
                                              <Input placeholder='Email Address' />
                                            </Form.Item>

                                            <Form.Item>
                                              <Input
                                                name='prdt'
                                                type='hidden'
                                                id='prdt'
                                                value={product.ProductID}
                                              />
                                              <Button
                                                type='primary'
                                                htmlType='submit'
                                                ref='btn'
                                                danger
                                              >
                                                Submit
                                              </Button>
                                            </Form.Item>
                                          </Form>
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                  ) : (
                                    <p className='inputandbtn'>
                                      <NumericInput
                                        min={1}
                                        max={
                                          product.MaxOnlineOrderQty === 0
                                            ? 10000
                                            : product.MaxOnlineOrderQty
                                        }
                                        precision={0}
                                        className='inputqty'
                                        id={"prodqty" + product.ProductID}
                                        value={1}
                                        snap
                                      />
                                      <button
                                        onClick={this.onAddItem.bind(
                                          this,
                                          "add",
                                          product.ProductID,
                                          product.Name,
                                          product.MaxOnlineOrderQty,
                                          product.ProductCategoryID,
                                          product.offerdetails
                                            ? product.offerdetails.OfferProducts.map(
                                                (prt) => prt.ProductID
                                              ).join(",")
                                            : "",
                                          product.offerdetails
                                            ? product.offerdetails.Offer
                                                .PromotionQty
                                            : "",
                                          product.offerdetails
                                            ? product.offerdetails.Offer.Price
                                            : ""
                                        )}
                                        id={product.ProductID}
                                      >
                                        <FontAwesomeIcon
                                          icon={faShoppingCart}
                                        />
                                        <span>ADD</span>
                                      </button>
                                    </p>
                                  )}
                                </div>
                              </Col>

                              <Col sm={12}>
                                {product.ProductTag ? (
                                  <ProductTag
                                    params={{
                                      id: this.props.match.params.id,
                                      tagname: product.ProductTag,
                                      ProductID: product.ProductID,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <Col sm={12}>
                            <br />
                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                          </Col>
                        )
                      ) : (
                        <Col sm={12}>
                          <br />
                          <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </Col>
                      )}
                    </Col>
                  </Row>
                </>
              );
          }
        })()}
      </>
    );
  }
}

export default Application;
