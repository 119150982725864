import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Stack, Button, Icon, Typography } from 'witmeg-ui-system';
import { getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helper';
import { getProductItemDataForPopup } from '../../../utilities/restaurantMenuUtil';
import '../../../utilities/stringOverride';
import UpdateCartPopup from '../Common/UpdateCartPopup';
import ShowMoreText from 'react-show-more-text';

/*
  Layout: 4
    Shows menu items in two columns without images
*/
export default function TwoColumnWithoutImageLayout({ isPopup = false }) {

  const [addToCartModalState, setAddToCartModalState] = useState({ data: null, visible: false });

  const { orderSettings, menuData: { selectedMainMenuData, uiCustomizations }, currency } = useSelector((state) => state.resturantData);

  const selectedOrderType = orderSettings.urlOrder?.orderType ?? orderSettings.settingsDefaultOrder?.orderType;

  const currencyFormat = getCurrencyFormat(currency);

  const onProductItemClick = async (data) => {
    const _productData = await getProductItemDataForPopup(data);
    if (_productData) setAddToCartModalState({ data: _productData, visible: true });
  };

  const onPopupClose = () => setAddToCartModalState({ data: null, visible: false });

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.SubMenu && selectedMainMenuData.SubMenu.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
        return (
          <>
            {/* Shows if `Sub Menu` enabled in settings */}
            {uiCustomizations.subMenu.settings.subMenuEnabled && <label className='eco-menu-heading' style={uiCustomizations.subMenu.style}>{data?.SubMenuName.toCapitalFirst()}</label>}


            {data.MenuProductData.map((data, index) => {
              const productPrice = getRestaurantMenuProductPrice(data.ProductData, selectedOrderType);
              const isSelected = (addToCartModalState.data && addToCartModalState.data.id === data.ID) ? true : false;
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en


              return (
                <Col span={12} style={{ padding: '10px', }}>
                  <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                      <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                        <div>
                          <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                        </div>
                        <div>
                          <Typography type="title" level={5} style={{ padding: '5px 0 0', height: '6.5rem', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                      <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                      <Button variant="primary"
                        onClick={() => onProductItemClick(data)}>
                        <label>Add to Cart</label>
                        {/* <label className='mobile-but'><Icon name="plus" size={24} /></label> */}
                      </Button>
                    </div>
                  </div>
                </Col>
                // <Col
                //   lg={isPopup ? 12 : 12} sm={24} md={24} xs={24}
                //   className="layout-product-item eco-pt-20 eco-pb-24"
                //   style={{ borderRadius: '4px', paddingLeft: '0px', paddingRight: '0px', background: isSelected ? 'rgb(241 241 241 / .3)' : '' }}>
                //   <Row align={isPopup ? 'middle' : 'start'} gutter={isPopup ? [{ lg: 64 }, 16] : [16, 16]} style={{ marginLeft: '0px', marginRight: '0px' }}>
                //     <Col lg={5} md={5} sm={5} xs={5}>
                //       <div style={{ backgroundImage: `url(${productImage})`, }} className="product-image"></div>
                //     </Col>
                //     <Col lg={15} md={15} sm={15} xs={15} style={{ paddingLeft: '5px' }}>
                //       <Stack direction="column" verticalAlign="v_start" className="eco-menu-title-col">
                //         <div className='product-name-label'>
                //           <label style={uiCustomizations.productItem.name}>
                //             {data.ProductName.toCapitalFirst()}
                //           </label>
                //         </div>
                //         {data.ProductData?.ServicePriceInfo?.Price !== null &&
                //         <div className='product-price-label'><label style={uiCustomizations.productItem.name} className={isPopup ? '' : 'ceo-mb-24'}>{currencyFormat(productPrice)}</label></div>}
                //         <div className='product-description'>
                //           <label style={uiCustomizations.productItem.description}>
                //             <ShowMoreText
                //               lines={2}
                //               more="More"
                //               less="Less"
                //               expanded={false}
                //               width={360}
                //               truncatedEndingComponent={"..."}>
                //                 {data.ProductData.ItemDescription.Translations.en.toCapitalFirst()}
                //             </ShowMoreText>
                //           </label>
                //         </div>
                //       </Stack>
                //     </Col>
                //     <Col className="eco-menu-add-ctl" lg={4} md={4} sm={4} xs={4} align='right'>
                //       <Button
                //         variant="primary" className="eco-add-to-cart-label" 
                //         onClick={() => onProductItemClick(data)}>
                //         <label className='desktop-but'>Add to Cart</label>
                //         <label className='mobile-but'><Icon name="plus" size={24}  /></label>
                //       </Button>
                //     </Col>
                //   </Row>
                // </Col>
              )
            })}
            <hr />
          </>
        )
      })}
      {
        addToCartModalState.data && addToCartModalState.data.ProductName !== "" && addToCartModalState.visible &&
        <UpdateCartPopup showPopup={addToCartModalState.visible} data={addToCartModalState.data} onPopupClose={onPopupClose} />
      }
    </Row>
  );
}
