import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRestaurantMenuData, setRestaurantMenuUICustomizations } from '../../../../redux/resturantWidgetSlice';
import { getRestaurantMenuUICustomizations } from '../../utilities/restaurantMenuCustomizationsUtil';
import CustomTabs from './Common/CustomTabs';
import FontPicker from 'font-picker-react';
import PopupWrapper from './PopupWrapper';
import './Layouts/layout.css';
import OneColumnWithImageLayout from './Layouts/OneColumnWithImage.Layout';
import TwoColumnWithImageLayout from './Layouts/TwoColumnWithImage.Layout';
import OneColumnWithoutImageLayout from './Layouts/OneColumnWithoutImage.Layout';
import TwoColumnWithoutImageLayout from './Layouts/TwoColumnWithoutImage.Layout';
import TwoColumnWithoutImageRowLayout from './Layouts/TwoColumnWithoutImageRow.Layout';
import OneColumnCenterLayout from './Layouts/OneColumnCenter.Layout';
import { Spin } from 'witmeg-ui-system';
import SkeletonMenuLoader from './Common/SkeletonMenuLoader';

const RestaurantWidget = (props) => {
  const dispatch = useDispatch();
  const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";

  const { menuData: { isLoading }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);

  const [widgetState, setWidgetState] = useState({
    isPopup: null,
    layout: null,
    customizations: null,
    themeAvailable: false,
    isInitialLoad: true,
  });

  useEffect(() => {
    if (props.params.ItemData) {
      const _parsedItemData = JSON.parse(props.params.ItemData);
      const _menuUICustomizations = getRestaurantMenuUICustomizations(_parsedItemData);
      let _isThemeAvailable = false;

      if(_parsedItemData.selectedTheme !== 'removeTheme')  _isThemeAvailable = true;

      dispatch(setRestaurantMenuUICustomizations({ uiCustomizations: _menuUICustomizations }));

      setWidgetState({
        customizations: _parsedItemData,
        isPopup: _parsedItemData?.selectedCard === 'popup' ? true : false,
        layout: _parsedItemData?.selectedLayout,
        themeAvailable: _isThemeAvailable,
      });
    }
  }, []);

  const _fetchData = async () => {
    dispatch(fetchAllRestaurantMenuData({
      CloudLocationID: defualtSelectedLocation.CloudLocationID,
      OrganizationID: organizationID,
    }));
  }

  useEffect(() => {
    setWidgetState(prevState => ({
      ...prevState,
      isInitialLoad: false,
    }));
    _fetchData();
  }, [defualtSelectedLocation]);

  useEffect(() => {
    if(widgetState.isInitialLoad) {
      setWidgetState(prevState => ({
        ...prevState,
        isInitialLoad: false,
      }));
    }
  }, [isLoading]);

  const LoadingSpinner = () => (
    <div className='eco-restaurant-menu-loader'>
      <Spin size="large" />
    </div>
  );

  return (
    <>
      <div style={{ display: 'none' }}>
        <FontPicker apiKey={API_KEY} limit={100} pickerId="category" />
      </div>
      <PopupWrapper isPopup={widgetState.isPopup}>
        {widgetState.isInitialLoad && isLoading ?
          <SkeletonMenuLoader /> :
          (
            <CustomTabs isPopup={widgetState.isPopup}>
              {isLoading ?
                <SkeletonMenuLoader /> : (
                {
                  layout1: <OneColumnWithImageLayout isPopup={widgetState.isPopup} />,
                  layout2: <OneColumnWithoutImageLayout isPopup={widgetState.isPopup} />,
                  layout3: <TwoColumnWithImageLayout isPopup={widgetState.isPopup} />,
                  layout4: <TwoColumnWithoutImageLayout isPopup={widgetState.isPopup} />,
                  layout5: <TwoColumnWithImageLayout isPopup={widgetState.isPopup} />,
                  layout6: <OneColumnWithImageLayout isPopup={widgetState.isPopup} />,
                }[widgetState.layout]
              )}
            </CustomTabs>
          )
        }
      </PopupWrapper>
    </>
  );
}

export default RestaurantWidget;
