import { calculateProductTotals } from "./restaurantMenuUtil";

const generateUnitChartItemData = ({ productData, quantity = 1}) => {
  const _unitChartItemData = {
    unitChartTypeID: productData.unitChartTypeID,
    typeName: productData.typeName,
    configs: productData.configs,
    unitQuantity: productData.unitQuantity,
  };

  const _vat = productData.vat;
  const _prices = productData.prices;

  const _subTotal = {
    delivery: getProductTotalCalculations(_prices.delivery, quantity, _vat, _vat.deliveryVat.rate),
    collection: getProductTotalCalculations(_prices.collection, quantity, _vat, _vat.collectionVat.rate),
    dineIn: getProductTotalCalculations(_prices.dineIn, quantity, _vat, _vat.dineInVat.rate),
  };

  const _generatedProductData = {
    vat: _vat,
    prices: _prices,
    unitChartItem: _unitChartItemData,
    subTotal: _subTotal,
    quantity: quantity,
  };

  return _generatedProductData;
}

const generateAddonItemData = ({ productData, quantity = 1, unitChartTypeID = null }) => {
  const _prices = productData.prices;
  const _vat = productData.vat;

  const _subTotal = {
    delivery: getProductTotalCalculations(_prices.delivery, quantity, _vat, _vat.deliveryVat.rate),
    collection: getProductTotalCalculations(_prices.collection, quantity, _vat, _vat.collectionVat.rate),
    dineIn: getProductTotalCalculations(_prices.dineIn, quantity, _vat, _vat.dineInVat.rate),
  };

  const _generatedProductData = {
    productID: productData.productID,
    name: productData.name,
    prices: _prices,
    addonID: productData.addonID,
    configs: productData.configs,
    quantity: quantity,
    vat: _vat,
    subTotal: _subTotal,
    unitChartTypeID
  };

  return _generatedProductData;
}

const combineAddonItems = (addonList) => {
  if (addonList && addonList.length > 0) {
    const _addonsTotal = calculateTotalOfSubTotals(addonList);

    return {
      items: addonList,
      subTotal: _addonsTotal,
    };
  }

  return null;
};

const changeProductQuantity = ({ prices, vat, newQuantity }) => {
  const _subTotal = {
    delivery: getProductTotalCalculations(prices.delivery, newQuantity, vat, vat.deliveryVat.rate),
    collection: getProductTotalCalculations(prices.collection, newQuantity, vat, vat.collectionVat.rate),
    dineIn: getProductTotalCalculations(prices.dineIn, newQuantity, vat, vat.dineInVat.rate),
  };

  return {
    quantity: newQuantity,
    subTotal: _subTotal,
  };
}

const getProductTotalCalculations = (_price, _qty, _vat, _otVat) => {
  return calculateProductTotals(_price, _qty, _vat.isGeneralVat ? _vat.generalVat.rate : _otVat, _vat.isInclusive);
}

const calculateTotalOfSubTotals = (_subTotals) => {
  let _deliveryTotal = { amount: 0, vatAmount: 0 };
  let _collectionTotal = { amount: 0, vatAmount: 0 };
  let _dineInTotal = { amount: 0, vatAmount: 0 };

  if (_subTotals && _subTotals.length > 0) {
    for (const __item of _subTotals) {
      _deliveryTotal = {
        amount: _deliveryTotal.amount + __item.subTotal.delivery.amount,
        vatAmount: _deliveryTotal.vatAmount + __item.subTotal.delivery.vatAmount,
      };
      _collectionTotal = {
        amount: _collectionTotal.amount + __item.subTotal.collection.amount,
        vatAmount: _collectionTotal.vatAmount + __item.subTotal.collection.vatAmount,
      };
      _dineInTotal = {
        amount: _dineInTotal.amount + __item.subTotal.dineIn.amount,
        vatAmount: _dineInTotal.vatAmount + __item.subTotal.dineIn.vatAmount,
      };
    }
  }

  return {
    collection: _collectionTotal,
    dineIn: _dineInTotal,
    delivery: _deliveryTotal,
  };
};

export const restaurantCartUtil = {
  generateUnitChartItemData,
  generateAddonItemData,
  combineAddonItems,
  changeProductQuantity,
};