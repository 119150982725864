import React, { useEffect, useState } from "react";
import config from "react-global-configuration";
import axios from "axios";
import { Modal } from "antd";
import ContactEmail from "../email/ContactEmail";
import { renderEmail } from "postonents";
import './lib.css'
import { Typography, Row, Col, InputBox, Label, Button, Alert, TextArea, Message } from "witmeg-ui-system";
// import { Input } from 'antd';
// const { TextArea } = Input;
import { sendMail } from "../apiRequests/witmegRetailPaxServerRequests";

const Contact = ({ props }) => {
  const [message, setMessage] = useState("")
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
 const [isLoading, setIsLoading] = useState(false)

  const setField = (field, value) => {
    let currentForm = JSON.parse(JSON.stringify(form));
    setForm(Object.assign(currentForm, { [field]: value }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  //Form Validation
  const findFormErrors = () => {

    //Validation Feilds
    const {name, email, message, phonenumber} = form;
    const newErrors = {};
    var regexValidation = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    //Validation Messages
    if(!name || name.trim() === "" )
      newErrors.name = "Name is required"
    
    if(!email || email.trim() === "" ){
      newErrors.email = "Email is required"
    } else   if(regexValidation.test(email) === false) 
  {  newErrors.email = "Please enter an validete email"}
  
   

    if(!phonenumber || phonenumber.trim() === "") 
      newErrors.phonenumber = "Phone number is required"

      if(!message || message.trim() === "") 
      newErrors.message = "Message is required"
    


    return newErrors
  }


 
 
  
  const sendMessage = async () => {
    setIsLoading(true)
    const newErrors = findFormErrors()
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      setIsLoading(false)
    } else {
      const companyEmail = config.get('SenderEmail');
      try {
          const reqBody = {            
                  Channel:"retailpacx",
                  ServiceOrigin:"retailpacx",
                  EmailType: 'Standard',
                  TemplateName: 'You received a message',
                  ToName: 'company',
                  To: companyEmail,
                  FromName: form?.name,
                  From: form?.email,
                  Subject: 'An user contacted you',
                  PlainTextContent: message,
                  HtmlContent: `<h3>Here is you Message</h3>`
                    };
  
              const resData = await sendMail(reqBody);
  
              if(resData.Status === true){
                setIsLoading(false)
                Message.success("Email has been sent sucessfully");
              } else {
                  console.log('went wrong')
              }
          } 
           catch (error) {
        console.log(error);
      }
    }
  };



  return (
    <>
 

      <div style={{ height: '100%'}}>
        <Typography
          level={2}
          textTransform='capitalize'
          className='eco-mb-24'
          alignment="left"
          pattern="bold"
          style={{ fontSize: '28px' }}
        >
          Contact Form
        </Typography>        
        <Row gutter={[16, 16]} style={{ marginBottom: '5px' }}>
          <Col lg={12} sm={24} xs={24}>
            <Row className="form-row">
              <Col span={24}>
                <div className="form-lbl-wrapper" style={{ marginBottom: '-14px' }}>
                  <Label required>Name</Label>
                </div>
              </Col>
              <Col lg={24} sm={24} xs={24}>
                <InputBox
                  type="text"
                  onChange ={(e) => setField("name", e.target.value)}
                  value  ={form.name}
                  error = {errors.name}
                  placeholder = "Please enter a name"
                   />
                     {errors.name && (
              <span style={{marginBottom: "10px"}} className='help-text-with-errors'>{errors.name}</span>
            )}
              </Col>
            </Row>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Row className="form-row">
              <Col span={24}>
                <div className="form-lbl-wrapper" style={{ marginBottom: '-14px' }}>
                  <Label required>Phone</Label>
                </div>
              </Col>
              <Col span={24}>
                <InputBox
                  type="number"
                  onChange ={(e) => setField("phonenumber", e.target.value)}
                  value  ={form.phonenumber}
                  error = {errors.phonenumber}
                placeholder ="Please enter a phone number"
                  
                   />
                     {errors.phonenumber && (
              <span style={{marginBottom: "10px"}} className='help-text-with-errors'>{errors.phonenumber}</span>
            )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '6px' }}>
          <Col span={24}>
            <div className="form-lbl-wrapper" style={{ marginBottom: '-14px' }}>
              <Label required>Email</Label>
            </div>
          </Col>
          <Col span={24}>
            <InputBox
              type="text"
            placeholder = "please enter an email address"
              onChange ={(e) => setField("email", e.target.value)}
              value  ={form.email}
              error = {errors.email}
              
              />
               {errors.email && (
              <span style={{marginBottom: "10px"}} className='help-text-with-errors'>{errors.email}</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="form-lbl-wrapper" style={{ marginBottom: '-5px' }}>
              <Label required>Message</Label>
            </div>
          </Col>
          <Col span={24}>
            <TextArea 
              rows={6}
              type="text"
              onChange ={(e) => {setField("message", e.target.value); setMessage(e.target.value)}}
             placeholder = "Get in touch with us. Send us a message using the form below and we will get back to you shortly"
              error = {errors.message}
              // error='has error'
              value={message}
              // onChange={(e) => {handleChangeInput(e.target.value, "Message"); setMessage(e.target.value)}} 
              />
               {errors.message && (
              <span style={{marginBottom: "10px"}} className='help-text-with-errors'>{errors.message}</span>
            )}
          </Col>
        </Row>
  
        <Button  category={isLoading ? "load-text" : "text"} text = "Get in touch" onClick = {() => sendMessage()} className= {isLoading ? "publish-btn" : 'submit__btn'} type='submit'  
           >
         Get in touch
        </Button>
      </div>
    </>)
}

export default Contact;