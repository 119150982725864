import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import config from "react-global-configuration";
import Axios from "axios";


// const productsByOffer = async (product, token) => {

//   const requestOptions = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ` + token,
//     },
//   };

//   const result = [];

//   if (product.Offers[0] !== undefined) {
//     return await fetch(
//       config.get("rest_API") +
//         "offers/offerbyid/" +
//         config.get("OrganisationID") +
//         "/" +
//         product.Offers[0].OfferProducts.OfferID,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then(async (data) => {
//         result.push(data.Result[0]);

//         // console.log( result)

//         return result;
//       });
//   } else {
//     return product;
//   }
// };

const productsOffer = async (settings, product, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + token,
    },
    body: JSON.stringify({
      OrgID: settings.OrgID,
      ProductID: parseInt(product.ProductID),
      LocationID: settings.SalesLocationID
    }),
  };

  const result = [];

  return await fetch(
    process.env.REACT_APP_rest_API_productregistry + "/productid",
    requestOptions
  )
    .then((response) => response.json())
    .then(async (data) => {
      console.log(data);

      // const listofproducts = await Promise.all(
      //   data.Result.map(
      //     async (product) => await this.productsByOffer(product, token)
      //   )
      // );

      result.push({
        ...product,
        Name:data.Result[0].Name,
        productDetails: data.Result[0],
        // offerdetails: listofproducts[0],
      });

      //console.log(result[0])
      //this.setState({ offerproducts: listofproducts, ploaded: true })

      // this.setState({ offerproducts: listofproducts, ploaded: true })

      return result[0];
    });
};

export const fetchCartData = createAsyncThunk('retailCart/fetchCartData', async (settings, thunkAPI) => {
  const productRegistryToken=await Axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: "productRegistry",
  });
  const currentState = thunkAPI.getState().retailData.cart

  const listofproducts = await Promise.all(
    currentState.map(
      async (product) => await productsOffer(settings, product, productRegistryToken.data.token)
    )
  );
  return listofproducts
});

export const retailDataSlice = createSlice({
  name: "Retail Details",
  initialState: {
    searchCriteria: {
        name: '',
        categoryId: ''
    },
    cart: localStorage.getItem('retailCart')?JSON.parse(localStorage.getItem('retailCart')):[],
    fullUserDetails: JSON.parse(localStorage.getItem('fullUserDetails'))
  },

  reducers: {
    setSearchCriteria: (state, action) => {
      state.searchCriteria = { ...action.payload };
    },
    updateRetailCart:(state, action) => {
      state.cart =  action.payload ;
    },
  },

  extraReducers: (builder) => {
    // Handle the fulfilled action from fetchDataFromSliceA
    builder.addCase(fetchCartData.pending, (state, action) => {
      state.cartUpdating = true;
      // state.cart = action.payload;
    }).addCase(fetchCartData.fulfilled, (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
    }).addCase(fetchCartData.rejected, (state, action) => {
      state.cartUpdating = false;
      state.cartError = action.error.message;
    });
  },

});

export const { setSearchCriteria, updateRetailCart } = retailDataSlice.actions;

export default retailDataSlice.reducer;