import { List, Typography, Space, Tag, Card, Avatar } from 'antd';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment'
import config from 'react-global-configuration';
import { UserOutlined } from '@ant-design/icons';
import Truncate from 'react-truncate';
import ReactHtmlParser from "react-html-parser";
const { Meta } = Card;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const { Title, Paragraph, Text, Link } = Typography;
const gridStyle = {
  width: '100%'
};
const { CheckableTag } = Tag;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listData: [], selectedTags: [], loading: false };
  }
  // componentDidMount() {

  //   if (config.get('Theme') === 'ComingSoon') {
  //     require('../comingsoon/css/style.css');
  //   }


  //   this.props.params.ItemData ? (
  //     axios.get(config.get('backendURL') + 'articles/bycategory/' + this.props.params.ItemData + '/' + config.get('companyID'))
  //       .then(response => {
  //         console.log(response)
  //         this.setState({
  //           loading: true,
  //           listData: response.data,
  //           alltags: JSON.stringify(response.data.map((d) => d.tags.join(",")) + ",").substring(1).slice(0, -2)
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   ) : (
  //     axios.get(config.get('backendURL') + 'articles/bycomid/' + config.get('companyID'))
  //       .then(response => {
  //         console.log(response)
  //         this.setState({
  //           loading: true,
  //           listData: response.data,
  //           alltags: JSON.stringify(response.data.map((d) => d.tags.join(",")) + ",").substring(1).slice(0, -2)
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   )
  //   this._isMounted = true;
  // }

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    var url = '';
    if (nextSelectedTags.length > 0) {
      url = config.get('backendURL') + 'articles/bytags/' + nextSelectedTags + '/' + config.get('companyID')
    } else {
      url = config.get('backendURL') + 'articles/bycomid/' + config.get('companyID')
    }
    axios.get(url)
      .then(response => {
        console.log(response)


        this.setState({
          loading: true,
          listData: response.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
    this.setState({ selectedTags: nextSelectedTags });
  }
  render() {
    // const { listData, selectedTags, alltags, loading } = this.state;

    // this.props.params.ItemData.includes('font-size')

    // let blogText = this.props.params.ItemData

    // let parser = new DOMParser();
    // let doc = parser.parseFromString(blogText, "text/html");
    // let p = doc.querySelector("p");
    // if (p) {
    //   p.style.textAlign = "left";
    // }

    // let span = doc.createElement("span");

    // while (p?.firstChild) {
    //   span.appendChild(p?.firstChild);
    // }
    // p?.appendChild(span);

    // if (!span.style.fontSize) {
    //   span.style.fontSize = "1.4rem";
    // }

    // let modifiedHTMLString = p?.outerHTML;
    console.log('modifiedHTMLString==>> ', this.props.params.ItemData);

    return (

      <><div>
        {ReactHtmlParser(
          this.props.params?.ItemData.replace("\r \n", "<br>")
        )}
      </div><div className={this.props.params.className} style={{ height: '100%', }}>
          <p dangerouslySetInnerHTML={{ __html: this.props.params?.ItemData }} />
        </div></>

    )
  }
}

export default App;



