import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import config from 'react-global-configuration';
import { Row, Col } from 'witmeg-ui-system'
const photos = [];

function App(props) {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  // const ItemData = props.params.ItemData.split(',')

  let photos = []
  var newArray
  // ItemData.map((img, index) => {
  //   let dataArr = [];
  //   dataArr["src"] = 'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()+'/'+img.split(':')[0]
  //   dataArr["width"] = img.split(':')[1].split("x")[0]
  //   dataArr["height"] = img.split(':')[1].split("x")[1]

  //   photos.push(dataArr)
  // })

  console.log(props)
  // const photos = dataArr
  const [items, setItems] = useState(photos);


  if (props.params.ItemData != null && props.params.ItemData) {
    var tempArray;

    tempArray = decodeURIComponent(props.params.ItemData);

    newArray = JSON.parse(tempArray);

    console.log(newArray)
  }
  return (
    <div style={{
      width: "100%",
      height: "100%"
    }}>
      {/* <Gallery photos={photos} /> */}
      <Row gutter={[{lg:16,sm:0,xs:0}, {lg:16,sm:0,xs:0}]} justify="space-evenly" style={{ height: '100%' }}>
        {newArray && newArray.data.map((data, index) => (
          <Col style={{ position: "relative" }} lg={8} sm={24} xs={24}>

            <img
              alt=""
              src={data?.imageUrl}
              draggable="false"
              style={{ width: "100%", height: "100%" }}
            />

          </Col>
        ))
        }

      </Row>
    </div>
  );
}

export default App;
