import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Form, Button, Input, Divider } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import config from 'react-global-configuration';
import { renderEmail } from 'postonents';
import ForgotPasswordEmail from '../email/ForgotPasswordEmail';
import axios from 'axios';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};



class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false };
  }
  state = { visible: false, submitState: "Register Now" };

  componentDidMount() {
    document.title = "Forgor Password | " + config.get('companyPageTitle')
  }


  render() {
    const { submitState } = this.state;

    const onFinish = values => {
      this.setState({ disabled: true });
      this.refs.btn.setAttribute("disabled", "disabled");
      const requestOptions = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
        },
        body: JSON.stringify({ "UserEmail": values.Email, "OrganizationID": config.get('OrganisationID') })
      };
      // console.log(requestOptions);
      fetch(config.get('API_URL_cust') + 'resetlink', requestOptions)
        .then(response => response.json())
        .then(function (data) {
          console.log(data);

          if (data.Status === true) {
            const maildata = {
              PasswordLink: data.Result,
              companyPageTitle: config.get('companyPageTitle'),
              companyLogoCDN: config.get('companyLogoCDN'),
              SenderEmail: config.get('SenderEmail'),
              email: values.Email
            }

            const emailBody = renderEmail(ForgotPasswordEmail, { lang: 'en', maildata });

            async function postData() {
              const ecoEmail = {
                "to": values.Email,
                "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                "subject": "Forgot password request for " + config.get('companyPageTitle') + " Account",
                "html": emailBody
              };
              const response = await fetch(config.get('backendURL') + 'mail/send', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(ecoEmail)
              })
              return response.json();
            }

            postData()
              .then(data => {

                //  console.log(data)
                if (data === "SUCCESS") {
                  Modal.success({
                    title: 'Reset your account',
                    content: 'Password reset link has been sent to ' + values.Email,
                    onOk() { window.location.href = "/account/login" },
                  });
                }
                else {
                  Modal.error({
                    title: 'Oops!! Something wrong',
                    content: 'We are sorry, Something wrong with the reset process. Please try again later.',
                    onOk() { window.location.href = "/account/forgot" },
                  });
                }
              });


          } else {
            Modal.error({
              title: 'Oops!! Something wrong',
              content: 'The supplied email address does not exists. Please try again.',
              onOk() { window.location.href = "/account/forgot" },
            });
          }

        })


      //console.log('Received values of form: ', values);
    };

    return (
      <div className='account-pages-wrapper'>
        <Row>

          <Col sm={12} >
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/account">
                Your Account
              </Breadcrumb.Item> <Breadcrumb.Item href="/account/register">
                Forgot Password
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>
            <AccountLeftMenu />
          </Col>

          <Col sm={4}>
            <div className="fade alert alert-light show">



              <Row className="justify-content-md-center">

                <Col sm={12}>



                  <Divider orientation="left"><h5>FORGOT PASSWORD?</h5></Divider>

                  <Form

                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                  >



                    <Form.Item
                      name="Email"
                      label="Email"
                      rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                    >
                      <Input placeholder="Please enter email" />
                    </Form.Item>





                    <Form.Item
                      name="messagebox"
                      label="Please wait"
                      hasFeedback
                      style={{ display: 'none' }}
                      validateStatus="validating"
                      help="The information is being validated..."
                    >

                    </Form.Item>


                    <Form.Item  >
                      <Button type="primary" htmlType="submit" ref="btn" block danger >
                        {this.state.disabled ? 'Please wait...' : 'Reset Password'}
                      </Button>
                    </Form.Item>
                  </Form>
                  Already have an account? <a href="/account/login">Sign in</a>  </Col>
              </Row>





            </div>

          </Col>



        </Row>
      </div>
    )
  }
}

export default Registration;