import React, { Component } from "react";
import { Modal, Select } from "antd";
import config from "react-global-configuration";
import Image from "./image.component";
import axios from "axios";
import jwt from "jsonwebtoken";
import { NavLink } from "react-router-dom";

const { Option } = Select;
function handleCurrency(value) {
  //console.log(`selected ${value}`);

  localStorage.setItem("ecurrencyid", JSON.parse(value).CurrencyID);
  localStorage.setItem("ecurrencyratio", 1 * JSON.parse(value).CurrencyRate);
  localStorage.setItem("ecurrencyCode", JSON.parse(value).CurrencySymbol);
  localStorage.setItem("ecurrencythreeCode", JSON.parse(value).CurrencyCode);
  localStorage.setItem("dcurrency", "true");
  window.location.reload();
}
function getFaviconEl() {
  return document.getElementById("favicon");
}
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menusize: 0,
      company: [],
      categories: [],
      orgcurrency: [],
      menus: [],
      pages: [],
      defCCode: localStorage.getItem("ecurrencythreeCode") || "GBP",
      defCSymbl: localStorage.getItem("ecurrencyCode") || "£",
    };
  }

  resize = () => {
    if (window.innerWidth > 1681) {
      this.setState({ menusize: 5 });
    } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
      this.setState({ menusize: 2 });
    } else {
      this.setState({ menusize: 3 });
    }
  };
  componentDidMount() {
    setTimeout(() => {
      if (config.get("Theme") === "Spiceway") {
        require("./css/spiceway.css");
      }
    }, 100);

    const favicon = getFaviconEl(); // Accessing favicon element
    document.title = config.get("companyPageTitle");
    favicon.href =
      "https://cdn.neurolage.com/retailpacx/" +
      config
        .get("CDNPath")
        .replace(/ /g, "")
        .replace("&", "")
        .replace(/(^\&)|,/g, "")
        .toLowerCase() +
      "/" +
      config.get("favicon");

    axios
      .get(config.get("backendURL") + "menus/main/" + config.get("companyID"))
      .then((response) => {
        const requestOne = axios
          .get(
            config.get("backendURL") +
            "pages/byid/" +
            response.data[0]._id +
            "/" +
            config.get("companyID")
          )
          .then((response) => {
            this.setState({ pages: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("SalesprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedSetting = sessionStorage.getItem("setting");
    //if (!cachedSetting) {
    axios
      .get(
        config.get("API_salesregistry") +
        "setting/" +
        config.get("OrganisationID") +
        "/26",
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        if (response.data.Result[0].IPLookup) {
          const cachedipresponse = localStorage.getItem("ipresponse");
          if (!cachedipresponse) {
            axios
              .get(
                "https://api.ipstack.com/check?access_key=5e7152f63b2d02398b551ee66730bbfa"
              )
              .then((ipresponse) => {
                localStorage.setItem(
                  "ipresponse",
                  JSON.stringify(ipresponse.data)
                );
              });
          }
        }
        sessionStorage.setItem("setting", JSON.stringify(response.data.Result));
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
    //   .then(response => {
    //     // console.log('response')
    //     this.setState({ orgcurrency: response.data.Result });
    //     this.state.orgcurrency.map(oc => {
    //       // console.log(oc);

    //       if (JSON.parse(localStorage.getItem('ipresponse')).currency.code === oc.CurrencyCode && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;

    //       } else if (oc.IsBaseCurrency === true && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;
    //       }

    //     })
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })

    this.resize();
  }
  handlePageClick = (value) => {
    this.props.parentPageCallback(value);
  };

  searchcNow = (event) => {
    event.preventDefault();

    if (document.getElementById("search").value) {
      window.location.href =
        "/search/" + document.getElementById("search").value;
    } else {
      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  render() {
    const { orgcurrency, defCCode, defCSymbl } = this.state;
    return (
      <>
        <div className='header'>
          <input
            className='form-control'
            type='hidden'
            id='search'
            placeholder="I'm shopping for..."
          />

          <div className='header-nav'>
            <nav className='navbar navbar-expand-md navbar-light bg-white'>
              <div className='container'>
                <NavLink exact to='/' className='navbar-brand'>
                  <a href='/'>
                    {" "}
                    <Image
                      params={{
                        ItemData: config.get("companyLogo"),
                        className: "img-fluid brand-logo",
                      }}
                    />{" "}
                  </a>
                </NavLink>
                <button
                  className='navbar-toggler collapsed'
                  type='button'
                  data-toggle='collapse'
                  data-target='#app-navbar'
                  aria-controls='app-navbar'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <i className={"fas fa-times  "} />
                </button>
                <div className='navbar-collapse collapse' id='app-navbar'>
                  <ul className='navbar-nav m-app-nav'>
                    <li className='nav-item'>
                      <NavLink
                        exact
                        to='/'
                        className='nav-link'
                        activeClassName='active'
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink exact to='/products' className='nav-link'>
                        Our Products
                      </NavLink>
                    </li>

                    {this.state.pages
                      .slice(0, this.state.menusize)
                      .map((currentpage) => (
                        <li className='nav-item'>
                          {" "}
                          <NavLink
                            exact
                            to={"/pages/" + currentpage.slug}
                            className='nav-link'
                          >
                            {" "}
                            {currentpage.pagetitle}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}
