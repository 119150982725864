import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormControl,
  InputGroup,
  Navbar,
  Container,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, Modal, Drawer, Button, Divider, Select } from "antd";
import config from "react-global-configuration";
import Image from "./image.component";
import ClothingNavbar from "./navbar.component";
import axios from "axios";
import jwt from "jsonwebtoken";
import moment from "moment";
import {
  HeartOutlined,
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  UserOutlined,
  SearchOutlined,
  ShoppingOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const Page = (props) => (
  <li>
    <a href={"/pages/" + props.page.slug}>{props.page.pagetitle}</a>
  </li>
);

const { Option } = Select;
function handleCurrency(value) {
  //console.log(`selected ${value}`);

  localStorage.setItem("ecurrencyid", JSON.parse(value).CurrencyID);
  localStorage.setItem("ecurrencyratio", 1 * JSON.parse(value).CurrencyRate);
  localStorage.setItem("ecurrencyCode", JSON.parse(value).CurrencySymbol);
  localStorage.setItem("ecurrencythreeCode", JSON.parse(value).CurrencyCode);
  localStorage.setItem("dcurrency", "true");
  window.location.reload();
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      pages: [],
      company: [],
      categories: [],
      basketitem: [],
      orgcurrency: [],
      defCCode: localStorage.getItem("ecurrencythreeCode") || "GBP",
      defCSymbl: localStorage.getItem("ecurrencyCode") || "£",
    };
  }

  componentDidMount() {
    var header = document.getElementById("navbar");
    var sticky = header.offsetTop;

    window.onscroll = function () {
      if (window.scrollY > sticky) {
        header.classList.add("stuck");
      } else {
        header.classList.remove("stuck");
      }
    };

    const favicon = getFaviconEl(); // Accessing favicon element
    if (config.get("Theme") === "Cloths") {
      require("./css/style.css");
    }

    favicon.href =
      "https://cdn.neurolage.com/retailpacx/" +
      config
        .get("CDNPath")
        .replace(/ /g, "")
        .replace("&", "")
        .replace(/(^\&)|,/g, "")
        .toLowerCase() +
      "/" +
      config.get("favicon");

    axios
      .get(config.get("backendURL") + "menus/main/" + config.get("companyID"))
      .then((response) => {
        //  console.log(response.data[0]._id )

        const requestOne = axios
          .get(
            config.get("backendURL") +
            "pages/byid/" +
            response.data[0]._id +
            "/" +
            config.get("companyID")
          )
          .then((response) => {
            //console.log(response)
            this.setState({ pages: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    const cachedProduct = localStorage.getItem("cart");
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }

    const cachedLogo = localStorage.getItem("cachedLogo");
    if (cachedLogo === "") {
      localStorage.setItem("cachedLogo", config.get("companyLogo"));
    }
    document.title = config.get("companyPageTitle");

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("SalesprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedSetting = sessionStorage.getItem("setting");

    // axios.get(config.get('API_salesregistry') + 'setting/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
    //   .then(response => {

    //     sessionStorage.setItem('setting', JSON.stringify(response.data.Result));

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })

    // axios.get(config.get('API_salesregistry') + 'currencies/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
    //   .then(response => {
    //     // console.log('response')
    //     this.setState({ orgcurrency: response.data.Result });
    //     this.state.orgcurrency.map(oc => {
    //       // console.log(oc);

    //       if (oc.IsBaseCurrency === true && !localStorage.getItem('dcurrency')) {
    //         localStorage.setItem('ecurrencyid', oc.CurrencyID);
    //         localStorage.setItem('ecurrencyratio', 1 * oc.CurrencyRate);
    //         localStorage.setItem('ecurrencyCode', oc.CurrencySymbol);
    //         localStorage.setItem('ecurrencythreeCode', oc.CurrencyCode);
    //         return true;
    //       }

    //     })
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }

  state = { visible: false, childrenDrawer: false, searchvisible: false };

  searchcNow = (event) => {
    event.preventDefault();

    if (document.getElementById("search").value) {
      window.location.href =
        "/search/" + document.getElementById("search").value;
    } else {
      let secondsToGo = 5;
      const modal = Modal.warning({
        title: "Invalid or empty keyword",
        content: `Please enter a keyword to search`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Please enter a keyword to search`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  pageList() {
    return this.state.pages.map((currentpage) => {
      return <Page page={currentpage} key={currentpage._id} />;
    });
  }

  showSearchDrawer = () => {
    this.setState({
      searchvisible: true,
    });
  };

  showDrawer = () => {
    const cachedCart = localStorage.getItem("cart");
    this.setState({
      visible: true,
      basketitem: JSON.parse(cachedCart),
    });
  };
  onSearchClose = () => {
    this.setState({
      searchvisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  clickButton = (direction) => () => {
    this.setState({ direction });
  };
  render() {
    this.state.total = this.state.basketitem
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.ProductQty * currentValue.ProductPrice),
        0
      )
      .toFixed(2);
    this.state.cartqty = this.state.basketitem.reduce(
      (accumulator, currentqtyValue) =>
        accumulator + parseFloat(currentqtyValue.ProductQty),
      0
    );
    const { basketitem, total, cartqty } = this.state;
    const isBasketNotEmpty = this.state.basketitem.length;
    let button;

    if (isBasketNotEmpty) {
      button = (
        <div>
          <Row>
            <Col md={9} className='text-right'>
              <b>Total</b>
            </Col>
            <Col md={3}>
              <b> £ {this.state.total}</b>
            </Col>
          </Row>
          <hr />
          <hr />
          <Row>
            <Col md={1}></Col>
            <Col md={8}>
              <Button href='/cart/' key='cart' type='primary' block>
                View Cart
              </Button>
            </Col>
            <Col md={3}></Col>
          </Row>
        </div>
      );
    } else {
      button = (
        <div>
          <Button href='/products/' type='primary'>
            Start Shopping
          </Button>
        </div>
      );
    }

    return (
      <>
        <Row className='align-items-center'>
          <Col sm={7} lg={7}>
            <ul className='headbanner'>{this.pageList()}</ul>
          </Col>
          <Col sm={2} xs={7} lg={2}>
            <Select
              defaultValue={
                this.state.defCCode + "(" + this.state.defCSymbl + ")"
              }
              onChange={handleCurrency}
              className='select-after currencysel'
            >
              {this.state.orgcurrency.map((ocd) => (
                <Option value={JSON.stringify(ocd)}>
                  {ocd.CurrencyCode} ({ocd.CurrencySymbol})
                </Option>
              ))}
            </Select>
          </Col>
          <Col sm={3} xs={5} lg={3}>
            <ul className='headicons'>
              {config.get("SocialMedia") ? (
                JSON.parse(config.get("SocialMedia")).map((sm) => (
                  <>
                    <a href={sm.URL}>
                      <img src={sm.icons} alt={sm.Media} width='24' />
                    </a>
                    &nbsp;&nbsp;
                  </>
                ))
              ) : (
                <></>
              )}
            </ul>
          </Col>
          <Divider />
        </Row>

        <Navbar expand='lg' sticky='top' variant='light' id='navbar' bg='light'>
          <Row className='container-fluid'>
            <Col sm={2} xs={{ span: 3, order: 2 }} lg={{ span: 2, order: 1 }}>
              <a href='/'>
                {" "}
                <span className='classlogo'>
                  <Image
                    params={{
                      ItemData: "myfile-1603703107801.jpeg",
                      className: "",
                    }}
                  />
                </span>{" "}
                <Image
                  params={{
                    ItemData: config.get("companyLogo"),
                    className: "logo",
                  }}
                />
              </a>
            </Col>
            <Col sm={2} lg={{ span: 7, order: 2 }} xs={{ span: 3, order: 1 }}>
              <ClothingNavbar />
            </Col>

            <Col
              sm={4}
              lg={{ span: 3, order: 3 }}
              xs={{ span: 6, order: 3 }}
              className='align-right'
            >
              <ul className='headicons headcarts'>
                <li>
                  <a href='/account/login'>
                    {" "}
                    <Tooltip
                      placement='bottom'
                      color='#COCOCO'
                      title='My Account'
                    >
                      <UserOutlined
                        style={{ fontSize: "23px", color: "#000" }}
                      />
                    </Tooltip>
                  </a>
                </li>
                <li>
                  <a onClick={this.showSearchDrawer}>
                    <Tooltip placement='bottom' color='#COCOCO' title='Search'>
                      <SearchOutlined
                        style={{ fontSize: "23px", color: "#000" }}
                      />
                    </Tooltip>
                  </a>
                </li>
                <li>
                  <a href='/account/login'>
                    <Tooltip
                      placement='bottom'
                      color='#COCOCO'
                      title='Wishlist'
                    >
                      <HeartOutlined
                        style={{ fontSize: "23px", color: "#000" }}
                      />
                    </Tooltip>
                  </a>
                </li>
                <li>
                  <a onClick={this.showDrawer}>
                    <Tooltip
                      placement='bottom'
                      color='#COCOCO'
                      title={
                        "You have " +
                        this.state.cartqty +
                        " items in your Shopping Bag"
                      }
                    >
                      <ShoppingOutlined
                        style={{ fontSize: "23px", color: "#000" }}
                      />
                    </Tooltip>
                    <sup id='cartcount'>{this.state.cartqty}</sup>
                  </a>
                </li>
              </ul>

              <Drawer
                title='Search Our Store'
                placement='top'
                closable={true}
                height='170'
                zIndex='9999'
                onClose={this.onSearchClose}
                visible={this.state.searchvisible}
                key='top'
              >
                <Form onSubmit={this.searchcNow}>
                  <InputGroup size='lg' className='mb-3'>
                    <FormControl
                      placeholder="I'm looking for…"
                      aria-label="I'm looking for…"
                      aria-describedby='basicaddon2'
                      id='search'
                      name='search'
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id='basicaddon2'>
                        <a onClick={this.searchcNow}>
                          <FontAwesomeIcon icon={faSearch} />
                        </a>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Drawer>

              <Drawer
                title='My Cart'
                width={520}
                closable={false}
                onClose={this.onClose}
                zIndex='10000'
                visible={this.state.visible}
              >
                <div>
                  {this.state.basketitem.length ? (
                    this.state.basketitem.map((item) => (
                      <Row key={item.ProductID}>
                        <Col md={2}>
                          {item.ProductImage !== "" ? (
                            <img src={item.ProductImage} width='80' />
                          ) : (
                            <img src='/images/noimage.jpg' alt='.' width='80' />
                          )}
                        </Col>
                        <Col xs={7} md={6}>
                          {item.ProductName}
                        </Col>
                        <Col xs={1} md={2}>
                          {item.ProductQty}
                        </Col>
                        <Col md={2}>
                          £ {(item.ProductQty * item.ProductPrice).toFixed(2)}
                        </Col>
                        <Col md={12}>
                          <hr></hr>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <span>
                      <h6>Your cart is empty</h6>
                      <p>
                        Find hundred of quality products at bargain prices
                        everyday!
                      </p>
                    </span>
                  )}
                  {button}
                </div>
              </Drawer>
            </Col>
          </Row>
        </Navbar>

        <Row>
          <Col className='announcement-bar'>
            <span class='announcement-text'>Free shipping within UK</span>

            <span class='announcement-link-text'> On all orders over £300</span>
          </Col>
        </Row>
      </>
    );
  }
}
