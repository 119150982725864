import React from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Divider, Collapse, Modal } from "antd";
import AccountLeftMenu from "./account-left-menu";
import config from "react-global-configuration";
import axios from "axios";
import jwt from "jsonwebtoken";
import ProductItems from "../lib/productbyid";
import { ShopOutlined, DeleteOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem("loggedin") === "true";
    this.state = { orders: [], basketitem: [], sorders: [] };
  }

  componentDidMount() {
    localStorage.setItem("cart", "");
    document.title = "Order History | " + config.get("companyPageTitle");
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("SalesprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    fetch(
      config.get("API_salesregistry") +
      "customerproforma/" +
      sessionStorage.getItem("OriginatedOrganizationID") +
      "/" +
      sessionStorage.getItem("UserID"),
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => this.setState({ sorders: data }));

    // axios.post("https://token.throbox.com/tokenservice", {
    //   isLoginServerRequest: false,
    //   serviceName: "retailpacx",
    // }).then(function(response){
    //   axios.get(config.get('backendURL') + 'orders/bycloudcustomerid/' + JSON.parse(localStorage.getItem('fullUserDetails')).UserID)
    //   .then(response => {

    //     this.setState({
    //       orders: response.data
    //     })
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   })
    // }).catch(function (error) {
    //   console.log(error);
    // })

    const cachedCart = localStorage.getItem("cart");
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }

    //  localStorage.setItem('cart', '');
  }
  onDeleteItem = (items) => {
    Modal.confirm({
      title: "Please confirm",
      content: "Are you sure you want to delete this order details?",
      onOk() {
        const updateorderoptions = {
          url: config.get("backendURL") + "orders/update/" + items,
          method: "POST",
          data: { OrderStatus: "Deleted" },
        };
        console.log(items);

        axios(updateorderoptions)
          .then((response) => {
            Modal.success({
              title: "Order details has been deleted",
              content: "Your pending order details has been deleted",
              onOk() {
                window.location.href = "/account/order-history";
              },
            });
          })
          .catch((err) => { });
      },
    });
  };
  onAddItem = (items, id) => {
    let prodqty = "";
    var ProductsDtl = [];
    var newState = {};
    var Productscart = [];
    const basketitem = this.state.basketitem;

    var SavedCart = JSON.parse(items).map(function (key, i) {
      console.log(
        JSON.parse(
          localStorage.getItem("savedcart" + JSON.parse(items)[i].ProductID)
        ).Result[0].OnLineImages
      );

      ProductsDtl = {
        ProductID: JSON.parse(items)[i].ProductID,
        ProductName: JSON.parse(
          localStorage.getItem("savedcart" + JSON.parse(items)[i].ProductID)
        ).Result[0].Name,
        ProductQty: JSON.parse(items)[i].SalesQuantity,
        ProductPrice: JSON.parse(items)[i].SalesPrice,
        ProductImage: JSON.parse(
          localStorage.getItem("savedcart" + JSON.parse(items)[i].ProductID)
        ).Result[0].OnLineImages.PrimaryImages
          ? JSON.parse(
            localStorage.getItem("savedcart" + JSON.parse(items)[i].ProductID)
          ).Result[0].OnLineImages.PrimaryImages.Main
          : "/images/noimage.jpg",
        VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
          (category) =>
            category.ProductCategory.ProductCategoryID ===
            JSON.parse(items)[i].ProductCategoryID
        ).ProductCategory.VatRate,
      };

      newState[i] = ProductsDtl;
    });

    console.log(newState);
    this.setState((state) => {
      {
        basketitem: state.basketitem.push(newState[0]);
        localStorage.setItem("cart", JSON.stringify(state.basketitem));
      }
    });
    const updateorderoptions = {
      url: config.get("backendURL") + "orders/update/" + id,
      method: "POST",
      data: { OrderStatus: "Deleted" },
    };
    console.log(items);

    axios(updateorderoptions)
      .then((response) => {
        Modal.success({
          title: "Your pending orders",
          content:
            "Your order will be sent to cart, you can add more items with this order and chekout.",
          onOk() {
            window.location.href = "/cart";
          },
        });
      })
      .catch((err) => { });
  };

  render() {
    const { basketitem } = this.state;
    const { sorders } = this.state;
    if (!this.loggedIn) {
      return <Redirect to='/account/login' />;
    }

    return (
      <div className='account-pages-wrapper'>
        <Row>
          <Col sm={12}>
            <Breadcrumb>
              <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
              <Breadcrumb.Item href='/account/member'>
                Your Account
              </Breadcrumb.Item>{" "}
              <Breadcrumb.Item>Order History</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>
            <AccountLeftMenu />
          </Col>

          <Col sm={9}>
            <div className='fade alert alert-light show'>
              <Row className='justify-content-md-center'>
                <Col sm={12}>
                  {this.state.orders.filter(
                    (item) => item.OrderStatus === "Order Mail Sent"
                  ).length > 0 ||
                    this.state.orders.filter(
                      (item) => item.OrderStatus === "Deleted"
                    ).length > 0 ||
                    this.state.orders.filter(
                      (item) => item.OrderStatus === "Order Sent to Server"
                    ).length > 0 ? (
                    ""
                  ) : (
                    <Divider orientation='left'>
                      <h5>PENDING ORDERS</h5>
                    </Divider>
                  )}

                  {this.state.orders.map((order, index) =>
                    order.OrderStatus != "Order Mail Sent" &&
                      order.OrderStatus != "Deleted" &&
                      order.OrderStatus != "Order Sent to Server" ? (
                      <Collapse>
                        <Panel
                          header={
                            "Order Number: " + order.ProformaSales.CartReference
                          }
                          key={index}
                          extra={<p>Pending Order</p>}
                        >
                          <div className='fade alert alert-light show'>
                            <Row className='justify-content-md-center'>
                              <Col sm={12}>
                                <h5>ORDER SUMMARY </h5>
                              </Col>
                              <Col md={8}>
                                Following order has failed due to some technical
                                issues. Please continue checkout to proceed your
                                order.
                              </Col>

                              <Col sm={2}>
                                <a
                                  onClick={this.onAddItem.bind(
                                    this,
                                    JSON.stringify(
                                      this.state.orders[index]
                                        .ProformaInvoiceDetail
                                    ),
                                    order._id
                                  )}
                                  className='float-right'
                                >
                                  <ShopOutlined /> <b>Checkout</b>
                                </a>{" "}
                              </Col>
                              <Col sm={2}>
                                <a
                                  onClick={this.onDeleteItem.bind(
                                    this,
                                    order._id
                                  )}
                                  className='float-right'
                                >
                                  <DeleteOutlined /> <b>Delete</b>
                                </a>{" "}
                              </Col>

                              <Col sm={12}>
                                <div className='fade alert alert-light show'>
                                  <Row>
                                    <Col>
                                      <Row>
                                        <Col md={7}>
                                          <b>Product Name</b>
                                        </Col>
                                        <Col md={1}>
                                          <b>Qty</b>
                                        </Col>
                                        <Col md={2}>
                                          <b>Unit Price</b>
                                        </Col>
                                        <Col md={2} className='text-right'>
                                          <b>Price</b>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {this.state.orders[
                                    index
                                  ].ProformaInvoiceDetail.map((item) => (
                                    <Row>
                                      <Col md={12}>
                                        <ProductItems
                                          params={{
                                            ProductID: item.ProductID,
                                            SalesQuantity: item.SalesQuantity,
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </Col>
                              <Col sm={12}>
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        </Panel>
                      </Collapse>
                    ) : (
                      <Row>
                        <Col sm={12}></Col>{" "}
                      </Row>
                    )
                  )}

                  <Divider orientation='left'>
                    <h5>SUCCESSFUL ORDERS</h5>
                  </Divider>

                  {this.state.orders.map((order, index) =>
                    order.OrderStatus === "Order Mail Sent" ||
                      order.OrderStatus === "Deleted" ||
                      order.OrderStatus === "Order Sent to Server" ? (
                      <Collapse>
                        <Panel
                          header={
                            "Order Number: " + order.ProformaSales.CartReference
                          }
                          key={index}
                          extra={<p>{order.OrderStatus}</p>}
                        >
                          <div className='fade alert alert-light show'>
                            <Row className='justify-content-md-center'>
                              <Col sm={12}>
                                <h5>ORDER SUMMARY </h5>
                              </Col>
                              <Col md={8}>
                                Following order has failed due to some technical
                                issues. Please continue checkout to proceed your
                                order.
                              </Col>

                              <Col sm={2}>
                                <a
                                  onClick={this.onAddItem.bind(
                                    this,
                                    JSON.stringify(
                                      this.state.orders[index]
                                        .ProformaInvoiceDetail
                                    ),
                                    order._id
                                  )}
                                  className='float-right'
                                >
                                  <ShopOutlined /> <b>Checkout</b>
                                </a>{" "}
                              </Col>
                              <Col sm={2}>
                                <a
                                  onClick={this.onDeleteItem.bind(
                                    this,
                                    order._id
                                  )}
                                  className='float-right'
                                >
                                  <DeleteOutlined /> <b>Delete</b>
                                </a>{" "}
                              </Col>

                              <Col sm={12}>
                                <div className='fade alert alert-light show'>
                                  <Row>
                                    <Col>
                                      <Row>
                                        <Col md={7}>
                                          <b>Product Name</b>
                                        </Col>
                                        <Col md={1}>
                                          <b>Qty</b>
                                        </Col>
                                        <Col md={2}>
                                          <b>Unit Price</b>
                                        </Col>
                                        <Col md={2} className='text-right'>
                                          <b>Price</b>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {this.state.orders[
                                    index
                                  ].ProformaInvoiceDetail.map((item) => (
                                    <Row>
                                      <Col md={12}>
                                        <ProductItems
                                          params={{
                                            ProductID: item.ProductID,
                                            SalesQuantity: item.SalesQuantity,
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </Col>
                              <Col sm={12}>
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        </Panel>
                      </Collapse>
                    ) : (
                      <Row>
                        <Col sm={12}>No order(s) found </Col>{" "}
                      </Row>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Application;
