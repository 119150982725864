import React, { useState, useEffect } from 'react'
import { Button, Stack, Typography, Radio, Row, Col, Popup } from 'witmeg-ui-system'
import { Form, Input, Select, Button as AntButton, Modal } from 'antd'
import { useHistory } from 'react-router'
import axios from 'axios';
import config from 'react-global-configuration';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

// const { Option } = Select;
export default function AddressSelection({ userDetails }) {
    let history = useHistory();
    const [selectedDeliveryAddress, setselectedDeliveryAddress] = useState(null)
    const [deliveryAddressList, setDeliveryAddressList] = useState([])
    const [selectedAddressId, setSelectedAddressId] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [modalVisible, setModalVisible] = useState(false)

    const [selectedCountry, setSelectedCountry] = useState('UK')

    useEffect(() => {
        if (userDetails && userDetails.Addresses && userDetails.Addresses.length > 0) {
            let deliveryAddressListArr = userDetails.Addresses.filter(d => d.AddressType === "Delivery")
            setDeliveryAddressList(deliveryAddressListArr)
            setselectedDeliveryAddress(deliveryAddressList[0])
            setSelectedAddressId(0)
        }
    }, userDetails)

    const handleChangeRadio = (i) => {
        setselectedDeliveryAddress(deliveryAddressList[i])
        setSelectedAddressId(i)
        setErrorMessage('')
    }

    const validateAddressSelected = () => {
        setErrorMessage('')
        if (selectedDeliveryAddress) {
            history.push("/payment-details")
        } else {
            setErrorMessage("Please Add Or Select Delivery Address")
        }
    }

    const onPopupClose = () => {
        setModalVisible(false)
    }

    const showModal = () => {
        setModalVisible(true)
    }
    const onCreateAddress = async (values) => {
        // console.log('Success:', values);
        
        let fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));

        fullUserDetails["Addresses"].push({ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.Email })

        localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

        var token = await axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServerRequest: false,
            serviceName: "orgregistry",
            usertoken: sessionStorage.getItem('AccessToken')
        });
        //   console.log(token);

        const requestUserDtlsOptions = {
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            },
            body: JSON.stringify(fullUserDetails)
        };
        //console.log(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID)
        //console.log(fullUserDetails)
        fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
            .then(response => response.json())
            .then(function (data) {
                //  console.log(data.Result);
                Modal.success({
                    title: 'Address Details',
                    content: 'Address has been added, Please choose a address',
                    onOk() { window.location.href = "/checkout" },
                });
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const selectRegion = (val) => {
        console.log("Region VAL", val)
    }

    const selectCountry = (val) => {
        console.log("Country VAL", val)
        setSelectedCountry(val)
    }

    const selectAddress = (val) => {
        console("selectAddress", val)
    }

    return (
        <>
            <div>
                <Typography type="title" level={1} className="eco-mb-32 eco-order-confirm-title" color="gray_darkest">Order Confirmation Details</Typography>
                <div>
                    <Typography type="title" level={2} className="eco-mb-32" color="gray_darkest">SELECT OR CREATE A DELIVERY ADDRESS</Typography>
                    <Row>
                        <Col span={12}>
                            <Typography type="title" level={5} className="eco-mb-32" color="gray_lighter">USE A SAVED ADDRESS</Typography>
                            {deliveryAddressList.map((address, i) => {
                                return (
                                    <Radio value={i} key={`del_add_${i}`} selected={selectedAddressId === i} onChange={() => handleChangeRadio(i)}>
                                        {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                        {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                        {address.City ? (<>{address.City}<br /></>) : ''}
                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                    </Radio>
                                )
                            })}
                        </Col>
                        <Col span={12}>
                            <Button onClick={showModal}>Create New Address CC</Button>


                        </Col>
                    </Row>
                </div>
                <div className="eco-mt-32">
                    <Row>
                        <Col span={12}>
                            <p style={{ color: 'red' }}>{errorMessage}</p>
                        </Col>
                        <Col span={12}>
                            <Button
                                fullWidth
                                className="proceed-payment-button"
                                category="icon-text"
                                icon="cash"
                                text="Proceed to Payment"
                                variant="primary"
                                color="white"
                                onClick={validateAddressSelected}
                                style={{ background: '#363B41' }} />
                        </Col>
                    </Row>
                </div>
            </div>

            <Popup
                modalVisible={modalVisible}
                modalTitle="NEW DELIVERY ADDRESS INFORMATION"
                mask={true}
                centered
                onCancel={onPopupClose}
            >
                <div style={{ height: '72vh', overflowY: 'auto' }}>
                    <Form layout="vertical"
                        name="register"

                        onFinish={onCreateAddress}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="SelectAddress"
                            extra="Please click below add new button to save the address"
                            label="Select an existing address to add"
                        >

                            <Select
                                onChange={(val) => selectAddress(this, val)}
                            >
                                {

                                    JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                        return (
                                            <>

                                                {
                                                    <>

                                                        {address.AddressType === "Billing" ? (

                                                            <Select.Option value={index}>
                                                                {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                            </Select.Option>

                                                        ) : ''
                                                        }
                                                    </>


                                                }
                                            </>
                                        );
                                    })
                                }


                            </Select>

                        </Form.Item>


                        <Form.Item
                            name="AddressType"
                            label="Address Type"
                            initialValue="Delivery"
                            rules={[{ required: true, message: 'Please enter a address type' }]}
                        >

                            {/* <Select>

                                <Option value="Delivery">Delivery</Option>

                            </Select> */}

                        </Form.Item>


                        <Form.Item
                            name="Address"
                            label="Address"
                            rules={[{ required: true, message: 'Please enter address' }]}
                        >
                            <Input id="Address" placeholder="Please enter address" />
                        </Form.Item>

                        <Form.Item
                            name="Address2"
                            label="Address 2"
                            rules={[{ required: false, message: 'Please enter address 2' }]}
                        >
                            <Input placeholder="Please enter address 2" />
                        </Form.Item>

                        <Form.Item
                            name="City"
                            label="City"
                            rules={[{ required: true, message: 'Please enter city' }]}
                        >
                            <Input placeholder="Please enter city" />
                        </Form.Item>

                        <Form.Item
                            name="StateProvinceRegion"
                            label="State / Province / Region"
                            rules={[{ required: true, message: 'Please enter state / province / region' }]}
                        >
                            <RegionDropdown
                                country={selectedCountry}
                                // value={props.params.region}
                                countryValueType="short"
                                classes="ant-input"
                                onChange={(val) => selectRegion(val)} />
                        </Form.Item>

                        <Form.Item
                            name="PostZipCode"
                            label="Post / Zip Code"
                            rules={[{ required: true, message: 'Please enter post / zip code' }]}
                        >
                            <Input placeholder="Please enter post / zip code" />
                        </Form.Item>

                        <Form.Item
                            name="Country"
                            label="Country"
                            rules={[{ required: true, message: 'Please enter country' }]}
                        >
                            <CountryDropdown
                                value={selectedCountry}
                                valueType="short"
                                classes="ant-input"
                                onChange={(val) => selectCountry(val)} />
                        </Form.Item>

                        <Form.Item
                            name="Phone"
                            label="Phone"
                            rules={[{ required: true, message: 'Please enter valid phone' }]}
                        >
                            <Input placeholder="Please enter phone" />
                        </Form.Item>

                        <Form.Item
                            name="Fax"
                            label="Fax"
                            rules={[{ message: 'Please enter valid fax' }]}
                        >
                            <Input placeholder="Please enter fax" />
                        </Form.Item>

                        <Form.Item
                            name="Email"
                            label="Email"
                            rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                        >
                            <Input placeholder="Please enter email" />
                        </Form.Item>
                        <Form.Item>
                            <AntButton type="primary" htmlType="submit" >
                                Save
                            </AntButton>
                        </Form.Item>
                    </Form>
                </div>
                {/* <Stack  className="eco-mt-28 eco-m-8">
                        <Button variant="primary" className="eco-mr-20 eco-pl-16 eco-pr-16" >
                            Save
                        </Button>
                        <Button variant="outline" className="eco-pl-16 eco-pr-16" onClick={onPopupClose}>
                            Cancel
                        </Button>
                    </Stack> */}
            </Popup>


        </>
    )
}
