import React from "react";
import config from "react-global-configuration";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Tooltip } from "witmeg-ui-system";
class App extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { vimage: [], loaded: false, imageName: "" };
  }
  componentDidMount() {
    this._isMounted = true;
    console.log("PROPS", this.props.params);



    axios
      .post(process.env.REACT_APP_TOKENSERVICE_URL, {
        isLoginServerRequest: false,
        serviceName: "retailpacx",
      })
      .then(async (response) => {
        await axios
          .post(
            `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
            {
              ID: this.props.params.ID,
              PageID: "",
              CompanyID: "",
              Start: 0,
              Limit: 1000,
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          )
          .then(async (result) => {
            console.log("I AM REUSLT IMAGE ====>", result.data.Result);
          });
      });

    // axios.get(config.get('backendURL')+'homelayout/image/' + this.props.params.ItemData)
    // .then(response => {
    //   //console.log((response.data)[0].img)
    //   this.setState({
    //     vtype: (response.data)[0].img.contentType,
    //     imageName: (response.data)[0].meta_data.filename,
    //     vimage: (response.data)[0].img.data.toString()
    //   })
    // })
    // .catch(function (error) {
    //   console.log(error);
    // })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const iconDataToParse = this.props?.params?.ItemData
    const iconPropsObj = JSON.parse(iconDataToParse);
    console.log("IconWidget properties==>> ", iconPropsObj);

    return (
      <>
        <Tooltip title={iconPropsObj.toolTip ? iconPropsObj.toolTip : ''}>
          <Row style={{ height: '100%' }}>
            <Col span={24} style={{ position: "relative" }} >
              {/* <h6>{iconPropsObj?.toolTip}</h6> */}
              <a href={iconPropsObj.Link}>
                <img
                  alt=""
                  src={iconPropsObj.imageUrlSrc}
                  draggable="false"
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
              <p style={{ fontSize: iconPropsObj?.textFontSize + 'px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{iconPropsObj?.iconText}</p>
            </Col>
          </Row>
        </Tooltip>
      </>
    );
  }
}

export default App;
