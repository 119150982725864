import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import placeholderImage from '../../../assets/Images/image-placeholder.png'
import { Tag, Button, Input } from 'witmeg-ui-system'
import {addToRetailCart} from '../../../lib/retailCart'
import {updateRetailCart} from '../../../redux/retailDataSlice'
import './ProductItemStyles.css'

export default function ProductItem({ product, isFeatured }) {
    const [itemCount, setItemCount] = useState(1)

    const dispatch =useDispatch()

    const changeProductCount = (opt) => {
        if (opt === "add") {
            setItemCount(Number(itemCount) + 1)
        } else {
            setItemCount(itemCount > 1 ? (Number(itemCount) - 1) : 1)
        }
    }

    const handleAddToCart = async () => {
        let cartData = await addToRetailCart(product, itemCount)
        localStorage.setItem("retailCart", JSON.stringify(cartData)) 
        dispatch(updateRetailCart(cartData))
        setItemCount(1)
    }

    return (
        <div style={{ margin: '8px', padding: '8px', backgroundColor: '#f6f6f6', boxShadow: '3px 3px 5px rgba(100, 100, 100, 0.3)', border: '1px solid #efefef', maxWidth: '360px' }}>
            <div style={{ minHight: '320px', padding: '8px' }}>
                <a href={`/product/${product.ProductID}`}><img style={{ width: '100%' }} src={product?.OnLineImages?.PrimaryImages?.Main || placeholderImage} alt="Product Image" /></a>
            </div>

            <div style={{ minHeight: '150px', padding: '8px' }}>
                {isFeatured ? <Tag label="Featured" variant="red" /> : null}
                <a href={`/product/${product.ProductID}`}><h2>{product.Name}</h2></a>
                <p>{product?.SellingPricesArray?.SellingPrice || ''} £</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', maxWidth: '200px', margin: 'auto' }}>
                    <Button onClick={() => changeProductCount('remove')} >-</Button><Input type="number" style={{ width: '48px', textAlign: 'center' }} value={itemCount} /><Button onClick={() => changeProductCount('add')}>+</Button>
                </div>

            </div>
            <div style={{ width: '120px', margin: 'auto', marginTop: '12px' }}>
                <Button className="product-item-addbtn" customStyles={{ backgroundColor: '#fcb800', fontSize: '20px', color: '#fff', fontWeight: '600', width: '100%', border: 'none' }} onClick={handleAddToCart}>Add To Cart</Button>
            </div>
            <div className='product-item-unit-table'>
                <table>
                    <tr>
                        <th>Pack Size</th>
                        <th>Weight</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
