import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import LogoSmall from './images/logo_small.jpg';
 
export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {

    var primaryTextStyle = "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle = "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle = "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

    console.group("Work with us!");
    console.log("%cEcologital%c is hiring.", primaryTextStyle, tertiaryTextStyle);
    console.log("%c Go to%c https://www.ecologital.com %cto apply!", tertiaryTextStyle, secondaryTextStyle, tertiaryTextStyle);
    console.groupEnd();
 
      fetch(config.get('backendURL') + 'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  render() {
    return (
<>


<div className="section footer">

        <div className="container">
            
            <div className='footer-address'>

                <div className="brand-logo">
                    <img src={LogoSmall} className="img-fluid brand-logo" alt="logo"/>
                </div>
                <div className='biz-address'>
                    <p className='biz-name'>spiceway wholesale ltd</p>
                    <ul className='biz-add-line'>
           <li>{this.state.Name ? this.state.Name : ''}</li>
          {this.state.AddressLine1 ? (<li> {this.state.AddressLine1} </li>) : ''}
          {this.state.AddressLine2 ? (<li> {this.state.AddressLine2} </li>) : ''}
          {this.state.City ? (<li> {this.state.City} </li>) : ''}
          {this.state.StateProvinceRegion ? (<li> {this.state.StateProvinceRegion} </li>) : ''}
          {this.state.PostZipCode ? (<li> {this.state.PostZipCode} </li>) : ''}
          {this.state.Country ? (<li> {this.state.Country} </li>) : ''} 
          {this.state.Fax ? (<li> <b>Fax</b> : {this.state.Fax} </li>) : ''} 
          {this.state.Email ? (<li> <b>Email</b> : {this.state.Email} </li>) : ''} 
          {this.state.OpenningHours ? (<li> <b>Openning Hours</b> : {this.state.OpenningHours} </li>) : ''} 
                    </ul>
                    <p className='biz-phone'>Phone: {this.state.Phone}</p>
                </div>

            </div>

            <div className='footer-social'>
                <div className='social-link'>

              
                    <a className="facebook" href="#">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a className="instagram" href="#">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a className="twitter" href="#">
                        <i className="fa-brands fa-twitter-square"></i>
                    </a>
                    <a className="twitter" href="#">
                    <i className="fa-brands fa-youtube"></i>
                    </a>
                </div>


                <div className='policy'>
                    <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms of Use</a></li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
      
      </>
    );
  }
}