import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faShoppingCart,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table, Modal } from "react-bootstrap";
import LeftMenu from "../themes/default/leftmenusearch.component";
import LeftMartFuryMenu from "../themes/martfury/leftmenu.component";
import LeftSpicewayMenu from "../themes/spiceway/leftmenu.component";
import LeftApparelMenu from "../themes/apparel/leftmenu.component";
import jwt from "jsonwebtoken";
import LazyLoad from "react-lazyload";
import { renderEmail } from "postonents";
import { Skeleton, message, Button, Form, Input, Select } from "antd";
import config from "react-global-configuration";
import moment from "moment";
import NotifyEmail from "../email/NotifyEmail";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import ProducBoxMartFury from "../shop/product-box";
import ProducBoxApparel from "../shop/product-box";
import { LoadingOutlined } from "@ant-design/icons";
import ProducBoxSpiceway from "../themes/spiceway/product-box";
import ProductsPage from "../themes/default/EcommerceWidgets/ProductsPage";
import ShankarProductsPage from "../themes/sharkar/EcommerceWidgets/ProductsPage";

const key = "updatable";
function handleChange(value) {
  console.log(`selected ${value}`);
}

class Application extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     visible: false,
  //     categories: [],
  //     category: "Shop Now",
  //     catid: "",
  //     ploaded: true,
  //     productscount: [],
  //     perpage: 12,
  //     products: [],
  //     basketitem: [],
  //     basketqty: [],
  //     loaded: false,
  //     regexp: /^[0-9\b]+$/,
  //   };
  // }

  // componentDidMount() {
  //   const timestamp = Math.floor(Date.now() / 1000) + 1000;

  //   this.setState({ ploaded: false });

  //   //console.log(config.get('ShopNowItems'))
  //   var payload = {
  //     aud: config.get("ProductjwtAud"),
  //     exp: timestamp,
  //     iss: config.get("ProductjwtIss"),
  //   };

  //   var signOptions = {
  //     algorithm: "RS256",
  //   };
  //   var ShopNowItems = [];
  //   // ShopNowItems = "[" + config.get("ShopNowItems") + "]";

  //   //  console.log(ShopNowItems)
  //   var privateKey = config.get("ProductprivateKey");
  //   var token = jwt.sign(payload, privateKey, signOptions);
  //   console.log("productregistry_V2 TOKEn",  token)

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + token,
  //     },
  //     body: JSON.stringify({
  //       OrgID: config.get("OrganisationID"),
  //       LocationID: '52'
  //       // ProductIDs: JSON.parse(ShopNowItems),
  //       // LocationID: JSON.parse(sessionStorage.getItem("setting"))[0].SalesLocationID,
  //     }),
  //   };

  //   fetch(
  //     process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${config.get("OrganisationID")}/${'52'}/0/12`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       const products = data.Result;
  //       this.setState({ products: products, ploaded: true });
  //     });

  //   const cachedCart = localStorage.getItem("cart");
  //   if (cachedCart) {
  //     this.setState({ basketitem: JSON.parse(cachedCart) });
  //   } else {
  //     const myArray = [];
  //     this.setState({ basketitem: myArray });
  //     localStorage.setItem("cart", JSON.stringify(myArray));
  //   }

  //   const requestCategories = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + token,
  //     },
  //   };

  //   const cachedCategory = sessionStorage.getItem("menucategories");
  //   if (cachedCategory) {
  //     this.setState({ categories: JSON.parse(cachedCategory) });
  //   } else {
  //     fetch(
  //       config.get("rest_API_productregistry") +
  //         "/onlinecategories/" +
  //         config.get("OrganisationID"),
  //       requestCategories
  //     )
  //       .then((response) => response.json())
  //       .then((data) => this.onSetCategoryResult(data.Result, data.Result));
  //   }
  // }

  // onSetCategoryResult = (result, key) => {
  //   this.setState({ categories: result });
  //   sessionStorage.setItem("menucategories", JSON.stringify(result));
  // };

  // handlePageClick = (data) => {
  //   $("html, body").animate(
  //     { scrollTop: $(".container-fluid").offset().top - 40 },
  //     "slow"
  //   );

  //   this.setState({ ploaded: false });

  //   let selected = data.selected;
  //   let offset = Math.ceil(selected * this.state.perpage);

  //   // console.log(offset)

  //   const timestamp = Math.floor(Date.now() / 1000) + 1000;
  //   var payload = {
  //     aud: config.get("ProductjwtAud"),
  //     exp: timestamp,
  //     iss: config.get("ProductjwtIss"),
  //   };

  //   var signOptions = {
  //     algorithm: "RS256",
  //   };

  //   var privateKey = config.get("ProductprivateKey");
  //   var token = jwt.sign(payload, privateKey, signOptions);
  //   // console.log(token)

  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + token,
  //     },
  //   };

  //   fetch(
  //     config.get("rest_API_productregistry_V2") +
  //       "/category/pagination/" +
  //       config.get("OrganisationID") +
  //       "/" +
  //       JSON.parse(sessionStorage.getItem("setting"))[0].SalesLocationID +
  //       "/" +
  //       this.state.catid +
  //       "/" +
  //       offset +
  //       "/" +
  //       this.state.perpage,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       const products = data.Result;
  //       this.setState({ products: products, ploaded: true });
  //     });
  // };

  // onChangeValue = (event) => {
  //   this.setState({ value: "1" });
  // };

  // onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
  //   this.setState({
  //     visible: true,
  //   });
  // };
  // handleCancel = (e) => {
  //   this.setState({
  //     visible: false,
  //   });
  // };

  // onAddItem = (
  //   buyoradd,
  //   ProductID,
  //   ProductName,
  //   MaxOnlineOrderQty,
  //   ProductCategoryID,
  //   offerItems,
  //   offerqty,
  //   offerprice
  // ) => {
  //   let prodqty = "";

  //   const basketitem = this.state.basketitem;

  //   const prodprice = document.getElementById("prodprice" + ProductID).value;
  //   const prodprimimage = document.getElementById(
  //     "prodprimimage" + ProductID
  //   ).value;

  //   var index = this.state.basketitem.findIndex(
  //     (a) => a.ProductID === ProductID
  //   );
  //   prodqty = document.getElementById("prodqty" + ProductID).value;

  //   if (!this.state.regexp.test(prodqty)) {
  //     message.error("Please enter only valid number for the product quantity.");
  //     return;
  //   }

  //   if (index !== -1) {
  //     const aprodqty = basketitem[index]["ProductQty"];

  //     if (
  //       MaxOnlineOrderQty != 0 &&
  //       MaxOnlineOrderQty < parseInt(prodqty) + parseInt(aprodqty)
  //     ) {
  //       message.error(
  //         "Please add less than " +
  //           MaxOnlineOrderQty +
  //           " items in total to cart"
  //       );
  //       return;
  //     } else if (prodqty > 1000) {
  //       message.error(
  //         "You are not allowed to add more than 1000 " +
  //           ProductName +
  //           ", if you need more than 1000 please contact us."
  //       );
  //       document.getElementById("prodqty" + ProductID).value = 1;
  //       return;
  //     }

  //     basketitem[index]["ProductQty"] =
  //       parseFloat(basketitem[index]["ProductQty"]) +
  //       parseFloat(document.getElementById("prodqty" + ProductID).value);
  //     this.setState({ basketitem: basketitem });
  //     localStorage.setItem("cart", JSON.stringify(basketitem));
  //     document.getElementById("cartcount").innerHTML =
  //       parseInt(document.getElementById("cartcount").innerHTML) +
  //       parseInt(aprodqty);
  //   } else {
  //     prodqty = document.getElementById("prodqty" + ProductID).value;

  //     if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
  //       message.error(
  //         "Please add less than " +
  //           MaxOnlineOrderQty +
  //           " items in total to cart"
  //       );
  //       return;
  //     } else if (prodqty > 1000) {
  //       message.error(
  //         "You are not allowed to add more than 1000 " +
  //           ProductName +
  //           ", if you need more than 1000 please contact us."
  //       );
  //       document.getElementById("prodqty" + ProductID).value = 1;
  //       return;
  //     }

  //     document.getElementById("cartcount").innerHTML =
  //       parseInt(document.getElementById("cartcount").innerHTML) +
  //       parseInt(prodqty);
  //     var ProductsDtl = {
  //       ProductID: ProductID,
  //       ProductName: ProductName,
  //       ProductQty: prodqty,
  //       ProductPrice: prodprice,
  //       ProductImage: prodprimimage,
  //       OfferAmount: 0,
  //       VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
  //         (category) =>
  //           category.ProductCategory.ProductCategoryID === ProductCategoryID
  //       ).ProductCategory.VatRate,
  //     };

  //     //  console.log(ProductsDtl)
  //     this.setState((state) => {
  //       {
  //         basketitem: state.basketitem.push(ProductsDtl);
  //         localStorage.setItem("cart", JSON.stringify(state.basketitem));
  //       }
  //     });
  //   }

  //   setTimeout(() => {
  //     //offer
  //     var offeri = [];
  //     offeri = JSON.parse("[" + offerItems + "]");

  //     let checker = (arr, target) => target.some((v) => arr.includes(v));

  //     let op = JSON.parse(localStorage.getItem("cart")).map(
  //       ({ ProductID }) => ProductID
  //     );

  //     var offer = 0;

  //     if (checker(op, offeri)) {
  //       var totalitems = 0;
  //       var totalamounts = 0;
  //       var newArray = offeri.map(function (el) {
  //         var cindex = JSON.parse(localStorage.getItem("cart")).findIndex(
  //           (a) => a.ProductID === el
  //         );
  //         //  console.log(cindex)

  //         if (cindex !== -1) {
  //           totalitems =
  //             totalitems +
  //             parseFloat(
  //               JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
  //             );
  //           totalamounts =
  //             totalamounts +
  //             parseFloat(
  //               JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
  //             ) *
  //               parseFloat(
  //                 JSON.parse(localStorage.getItem("cart"))[cindex][
  //                   "ProductPrice"
  //                 ]
  //               );
  //           basketitem[cindex]["OfferAmount"] = 0;
  //         }
  //       });

  //       var quotient = Math.floor(totalitems / offerqty);
  //       var remainder = totalitems % offerqty;
  //       //console.log('total:' + totalamounts)
  //       //console.log('offer total:' + quotient * offerprice)
  //       //console.log('remainder: ' + remainder)
  //       offer =
  //         parseFloat(totalamounts - prodprice * remainder) -
  //         parseFloat(quotient * offerprice);
  //       //console.log('singleprice:' + prodprice * remainder)

  //       var clickedindex = this.state.basketitem.findIndex(
  //         (a) => a.ProductID === ProductID
  //       );

  //       basketitem[clickedindex]["OfferAmount"] = offer;

  //       if (totalitems < offerqty) {
  //         basketitem[clickedindex]["OfferAmount"] = 0;
  //       }

  //       this.setState({ basketitem: basketitem });
  //       localStorage.setItem("cart", JSON.stringify(basketitem));

  //       //console.log(localStorage.getItem('cart'))
  //     }
  //   }, 100);

  //   message.loading({ content: "Adding...", key });
  //   setTimeout(() => {
  //     message.success({ content: "Added to the cart!", key, duration: 2 });
  //     if (buyoradd === "buy") {
  //       window.location.href = "/cart";
  //     }
  //   }, 800);
  // };

  // onQtyIncrese = (ProductID) => {
  //   document.getElementById("prodqty" + ProductID).value =
  //     parseFloat(document.getElementById("prodqty" + ProductID).value) + 1;
  // };
  // onQtyReduce = (ProductID) => {
  //   if (document.getElementById("prodqty" + ProductID).value > 1) {
  //     document.getElementById("prodqty" + ProductID).value =
  //       parseFloat(document.getElementById("prodqty" + ProductID).value) - 1;
  //   }
  // };
  // handleCallback = (childData, ploaded, category, productscount, catid) => {
  //   //console.log(childData)
  //   this.setState({
  //     products: childData,
  //     category: category,
  //     productscount: productscount,
  //     catid: catid,
  //   });
  // };

  render() {
  //   const onFinish = (values) => {
  //     const Notifydata = {
  //       companyPageTitle: config.get("companyPageTitle"),
  //       SenderEmail: values.email,
  //       prdtName: document.getElementById("prdt").value,
  //       companyLogoCDN: config.get("companyLogoCDN"),
  //       prdtURL: document.getElementById("prdtURL").value,
  //     };

  //     const emailBody = renderEmail(NotifyEmail, { lang: "en", Notifydata });
  //     async function postData() {
  //       const ecoEmail = {
  //         to:
  //           config.get("companyPageTitle") +
  //           "<" +
  //           config.get("SenderEmail") +
  //           ">",
  //         bcc: "suren.sivalingam@ecologital.com",
  //         from: values.email,
  //         subject: "Product Notification Request",
  //         html: emailBody,
  //       };

  //       const response = await fetch(config.get("backendURL") + "mail/send", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(ecoEmail),
  //       });
  //       return response.json();
  //     }

  //     postData().then((data) => {
  //       message.success(
  //         "Email has been sent to " + config.get("companyPageTitle")
  //       );
  //       this.handleCancel();
  //     });
  //   };

  //   const { products, productscount, visible } = this.state;
  //   const { basketitem } = this.state;
  //   const { categories } = this.state;

    return (
      <>
      {this.props.theme==="Shankar"?
      <ShankarProductsPage />
      :<ProductsPage />
    }

                {/* <div className='ps-layout--shop'>
                  <LeftMartFuryMenu
                    parentCallback={this.handleCallback}
                    params={{ catname: "", catid: "" }}
                  />
                  <div className='ps-layout__right'>
                    <div className='ps-shopping ps-tab-root'>
                      <div className='ps-block__header'>
                        <h3>{this.state.category}</h3>
                      </div>

                      <div className='ps-shopping__header'>
                        <p></p>
                        <div className='ps-shopping__actions'>
                          <select
                            className='ps-select select2-hidden-accessible'
                            data-placeholder='Sort Items'
                            data-select2-id={4}
                            tabIndex={-1}
                            aria-hidden='true'
                          >
                            <option data-select2-id={6}>Sort by latest</option>
                            <option>Sort by popularity</option>
                            <option>Sort by average rating</option>
                            <option>Sort by price: low to high</option>
                            <option>Sort by price: high to low</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      {this.state.ploaded ? (
                        products ? (
                          products.map((product) => (
                            <div className='col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 '>
                              <div className='list'>
                                <ProducBoxMartFury
                                  params={{
                                    product: product,
                                    visible: this.state.visible,
                                    loaded: this.state.loaded,
                                  }}
                                  handleCancel={this.handleCancel}
                                  onNotify={this.onNotify}
                                  onAddItem={this.onAddItem}
                                  onQtyIncrese={this.onQtyIncrese}
                                  onQtyReduce={this.onQtyReduce}
                                  onFinish={this.onFinish}
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
                            <br />
                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                          </div>
                        )
                      ) : (
                        <div className='col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 '>
                          <br />
                          <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </div>
                      )}
                    </div>

                    <div>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={
                          this.state.productscount / this.state.perpage
                        }
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div> */}

          
      </>
    );
  }
}

export default Application;
