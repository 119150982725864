import React from "react";

const SkeletonMenuLoader = () => {

  const MenuItemComponent = () => (
    <div className="eco-skeleton-menu-row">
      <div className="eco-skeleton-menu-row-left-side">
        <div className="skeleton-box eco-skeleton-image"></div>
        <div className="eco-skeleton-details">
          <div className="skeleton-box eco-skeleton-details-name"></div>
          <div className="skeleton-box eco-skeleton-details-description"></div>
        </div>
      </div>
      <div className="eco-skeleton-menu-row-right-side">
        <div className="skeleton-box eco-skeleton-price"></div>
        <div className="skeleton-box eco-skeleton-add-to-cart"></div>
      </div>
    </div>
  );

  return (
    <div className="eco-skeleton-menu-loader">
      <div className="eco-skeleton-menu-layout">
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
        <MenuItemComponent />
      </div>
    </div>
  );
}

export default SkeletonMenuLoader;