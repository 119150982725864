import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Accordion, Button, Message, Space, Typography } from 'witmeg-ui-system'

const AccordionWidget = (props) => {

  const [accordionData, setAccordionData] = useState([])

  useEffect(() => {
    axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    }).then(async (response) => {
      await axios.post(`${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/homelayout/id`,
      // await axios.post('https://retailpacx-dev.endpoints.neuralways.cloud.goog:5010/v1/retail/homelayout/id',
        {
          ID: props.params.ID,
          PageID: "",
          CompanyID: "",
          Start: 0,
          Limit: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      ).then(async (result) => {
        console.log("AccordionData==>> ", result.data.Result);
        setAccordionData(JSON.parse(result.data.Result[0]?.ItemData))
      });
    })
  }, [])

  console.log("AccordionData==>> ", accordionData?.accordianTopic)
  console.log("AccordionData==>> ", accordionData)


  return (
    <div>
            {accordionData  &&
                    <>
                        <Typography type="title" pattern="bold" textTransform="capitalize">{accordionData.accordianHeader}</Typography>
                        {accordionData?.accordian?.map((data, index) => {
                            return (
                                <Accordion
                                    theme='default'
                                    type='content_only'
                                    title={data.accordian_question}
                                    collapsed={true}
                                >
                                    <div style={{ width: "100%" }}>
                                        {data.accordian_answer}
                                    </div>
                                </Accordion>
                            )
                        })}
                    </>
                }
    </div>
  )
}

export default AccordionWidget