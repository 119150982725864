import React from 'react'
import {Button} from 'witmeg-ui-system'
import ReactHtmlParser from 'react-html-parser';
import Contact from '../../lib/contact';
export default function ImagewithContent(props) {
    const { params } = props

    console.log("PROPS===>>", props)

    const handleClickBtn = (data) => {
        let btnData=JSON.parse(data.styles);
        console.log("btnData====>>>", btnData)
        let buttonWebURL = btnData.buttonWebURL
        if(buttonWebURL.openInNewWindow) {
            window.open(buttonWebURL.siteURL, "_blank", "noreferrer");
        } else {
            window.location.href = buttonWebURL.siteURL;
        }
    }

    const renderSwitch = (param) => {
        switch (param.component.type) {
            case 'text':
                return <div>{ReactHtmlParser(param.itemData.data)}</div>;
            case 'button':
                return <div 
                            style={param.itemData && param.itemData.data && param.itemData.data.styles?JSON.parse(param.itemData.data.styles):{}} 
                            onClick={() => handleClickBtn(param.itemData.data)}
                        >
                            <Button  
                                variant="primary" 
                                customStyles={param.itemData && param.itemData.data && param.itemData.data.styles?JSON.parse(param.itemData.data.styles):{}} 
                               
                            >{param.itemData && param.itemData.data?ReactHtmlParser(param.itemData.data.text || param.itemData.data):""}</Button></div>;
            case 'image':
                return <div><img src={param.itemData.data} /></div>;
            case 'form':
                return <div><Contact
                params={params}
              /></div>;
            case 'space':
                return <div><img src={param.itemData.data} /></div>;
            default:
                return '';
        }
    }

    return (
        <div style={{
            backgroundImage: `url(${params.ItemData && params.ItemData!=="" ? JSON.parse(params.ItemData)?.imageData : ''})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '100%',
            height: 'auto'
        }}>
            <>
                {params.ItemData && params.ItemData!=="" && JSON.parse(params.ItemData).Layout ?
                    JSON.parse(params.ItemData).Layout.map((g, i) => {
                        return (<div className='imgwithcon-container' style={{ maxWidth: JSON.parse(params.ItemData)?.isFullWidth?'100%':'1320px'}}>{
                            g.children.map((c, j) => {
                                return (<div className='imgwithcon-item'>
                                    {
                                        c.children.map(cl => {
                                            return (<div style={ cl.size && cl.size.width && cl.size.height?{padding:8, width:cl.size.width, height:cl.size.height}:{padding:8}}>
                                                {renderSwitch(cl)}
                                            </div>)
                                        })
                                    }
                                </div>)
                            })
                        }</div>)
                    })
                    : null}
            </>

        </div>
    )
}
