import React, { useState } from 'react';
import { Affix, Layout, Menu, Input, Row, Col, Space, Select, Popover, Badge } from 'antd';
import { PopoverWithContent, Icon, Button } from 'witmeg-ui-system'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { setDefaultCloudLocation } from "../../redux/resturantWidgetSlice";
import CartSummaryPopup from "./WidgetCompoentens/common/CartSummaryPopup";
import { getCompanyAllPagesInfo } from "./utilities/apiRequests/witmegRetailPaxServerRequests";
import { useEffect } from "react";
import _ from "lodash";
import { isECommerceProcessPath } from "./utilities/helper";
import "@fontsource/inter";
import "@fontsource/jost";
import UpdateCartPopup from "./WidgetCompoentens/ResturatnWidget/Common/UpdateCartPopup";
import ICON_DINE_IN_CLOCK from "./images/icon-clock.svg";
import ICON_DINE_IN_TABLE from "./images/icon-dine-table.svg";
import {
    SearchOutlined,
    MenuOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import config from "react-global-configuration";
import './css/default-navbar.css';


const AppNavbar = ({ parentPageCallback }) => {
    let { name, categoryId } = useParams();
    const [menuVisible, setMenuVisible] = useState(true);
    const [searchKey, setSearchKey] = useState('')
    const [visibleLogOut, setVisibleLogOut] = useState(false)
    const dispatch = useDispatch();

    const [state, setState] = useState({
        menusize: 0,
        menus: "",
        pages: [],
        categories: [],
        brands: [],
        basketitem: [],
        // hasResturantWidget: null,
        cloudLocations: [],
        locationsDropdown: [],
        selectedLocation: {},
        visibleCartSummaryPopup: false,
        showDropdown: false,
        redirect: false,
        activeDropdown: null,

        locations: [],
        defaultLocation: {},

        updateCartItemPopup: {
            visible: false,
            data: null,
            index: null,
            editData: null,
        },
    });

    const [loadingMenu, setLoadingMenu] = useState(true);
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)

    const restaurantDataState = useSelector((state) => state.resturantData);
    const companyDataState = useSelector((state) => state.companyData);
    const retailDataState = useSelector((state) => state.retailData);

    const resize = () => {
        if (window.innerWidth > 1681) {
            setState((prevState) => ({ ...prevState, menusize: 5 }));
        } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
            setState((prevState) => ({ ...prevState, menusize: 2 }));
        } else {
            setState((prevState) => ({ ...prevState, menusize: 3 }));
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        setLoadingMenu(true);
        resize();

        const _initData = async () => {
            const __response = await getCompanyAllPagesInfo({
                ID: "",
                CompanyID: config.get("companyID"),
            });

            let __pages = __response
                .filter((z) => z.DraftMainPagesInfoID === "")
                .map((y) => {
                    return {
                        ...y,
                        children: __response.filter(
                            (a) => a.DraftMainPagesInfoID === y.ID
                        ),
                    };
                });
            setLoadingMenu(false);
            setState((prevState) => ({ ...prevState, pages: __pages }));
        };
        _initData();
    }, []);

    useEffect(() => {
        if (retailDataState.searchCriteria) {
            setSearchKey(retailDataState.searchCriteria.name)
            setSelectedCategory(retailDataState.searchCriteria.categoryId)
        }
    }, [retailDataState])


    useEffect(() => {
        if (
            restaurantDataState.resturantCloudLocations &&
            restaurantDataState.resturantCloudLocations.length > 0 &&
            restaurantDataState.defualtSelectedLocation
        ) {
            const _locations = restaurantDataState.resturantCloudLocations.map(
                (__location) => ({
                    label: __location.CloudLocationName,
                    value: __location.CloudLocationID,
                })
            );

            const _defaultLocation = _.find(_locations, {
                value: restaurantDataState.defualtSelectedLocation
                    .CloudLocationID,
            });

            setState((prevState) => ({
                ...prevState,
                locations: _locations,
                defaultLocation: _defaultLocation,
                selectedLocation: _defaultLocation,
            }));
        }
    }, [
        restaurantDataState.resturantCloudLocations,
        restaurantDataState.defualtSelectedLocation,
    ]);

    useEffect(() => {
        if (companyDataState && companyDataState.companyDetails && companyDataState.companyDetails.organizationID) {
            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };

                let OrgID = companyDataState.companyDetails.organizationID

                axios.get(`${process.env.REACT_APP_rest_API_productregistry}/onlinecategories/${OrgID}`, requestOptions).then((categoryData) => {
                    // console.log("featured categoryData", categoryData)
                    if (categoryData.data.Status) {
                        setCategories(categoryData.data.Result)
                    }
                })
            })
        }
    }, [companyDataState.companyDetails])

    const toggleCartSummaryPopover = () => {
        setState((prevState) => ({
            ...prevState,
            visibleCartSummaryPopup: !prevState.visibleCartSummaryPopup,
        }));
    };

    const changeUpdateCartItemPopupVisibility = (_visible, _data = null) => {
        if (_visible) {
            setState((prevState) => ({
                ...prevState,
                updateCartItemPopup: {
                    visible: true,
                    data: _data.data,
                    editData: _data.editData,
                    index: _data.index,
                },
            }));
            toggleCartSummaryPopover();
        } else {
            setState((prevState) => ({
                ...prevState,
                updateCartItemPopup: {
                    visible: false,
                    data: null,
                    editData: null,
                    index: null,
                },
            }));
        }
    };



    // const changeUpdateCartItemPopupVisibility = (_visible, _data = null) => {
    //     if (_visible) {
    //         setState((prevState) => ({
    //             ...prevState,
    //             updateCartItemPopup: {
    //                 visible: true,
    //                 data: _data.data,
    //                 editData: _data.editData,
    //                 index: _data.index,
    //             },
    //         }));
    //         toggleCartSummaryPopover();
    //     } else {
    //         setState((prevState) => ({
    //             ...prevState,
    //             updateCartItemPopup: {
    //                 visible: false,
    //                 data: null,
    //                 editData: null,
    //                 index: null,
    //             },
    //         }));
    //     }
    // };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleSearch = () => {
        let searchString = searchKey !== '' ? `/${searchKey}` : `/%20`
        window.location.href = '/search' + searchString + '/' + selectedCategory
    }

    const handleShopByCategory = (cat) => {
        window.location.href = '/group/' + cat.ProductCategory.ProductCategoryID
    }

    const handleLogout = () => {
        // Implement your logout logic here
        console.log('Logout logic goes here');
        sessionStorage.setItem('loggedin', false);
        sessionStorage.setItem('username', "");
        localStorage.setItem('fullUserDetails', "");
        sessionStorage.setItem('deliverycost', '');
        window.location.href = '/'
        setVisibleLogOut(false); // Close the popover after logout
    };

    return (
        <div style={{ width: '100%', maxWidth: '1650px' }}>
            <header className="fixed-header">
                <Row align="middle" >
                    <Col xs={6}>
                        <div className="logo">
                            {/* Your logo goes here */}
                            <a
                                className="eco-navbar-logo"
                                href={!restaurantDataState.hasResturantWidget && `/`}
                            >
                                <img
                                    style={{ maxHeight: "48px" }}
                                    src={config.get("companyLogo")}
                                    draggable="false"
                                />
                            </a>
                        </div>

                    </Col>
                    <Col xs={18} style={{ display: 'flex', justifyContent: 'space-between' }}>



                        <Space>
                            {companyDataState && companyDataState.companyDetails && companyDataState.companyDetails.websiteType === 'E-Commerce' ?
                                <>
                                    <div syyle={{ marginRight: '96px' }}>
                                        <Popover
                                            arrow={false}
                                            placement="bottomLeft"
                                            content={(
                                                <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                                    {categories.map(c => {
                                                        return (
                                                            <div key={c.ID} style={{ padding: '8px', borderBottom: '1px solid #eee', cursor: 'pointer' }} onClick={() => handleShopByCategory(c)}>{c.ProductCategory.Category}</div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        >
                                            <div><Space><Icon name="menu" size={16} /><a href="/products">Shop By Category</a></Space></div>
                                        </Popover>
                                    </div>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                </>
                                : null}
                            <a href="/"><span style={{ color: "black" }}> Home </span></a>

                            {!isECommerceProcessPath() &&
                                state.pages.map((pn, index) => (
                                    <span>
                                        {pn.children.length === 0 &&
                                            pn.ShowInMainMenu === true && (
                                                <a
                                                    href={`/pages/${pn.Slug}`}
                                                >
                                                    <span
                                                        style={{
                                                            color: "black",
                                                        }}
                                                    // onClick={(e) => {
                                                    //   e.stopPropagation();
                                                    //   if (pn.NoLink === false) handlePageClick(pn.ID, e);
                                                    // }}
                                                    >
                                                        {pn.PageName}
                                                    </span>
                                                </a>
                                            )}
                                    </span>
                                ))
                            }
                            {/* 
                            <a href="/about">About</a>

                            <a href="/contact">Contact</a> */}
                        </Space>
                        <Badge
                            className="eco-navbar-toggle"
                            count={
                                restaurantDataState.cartDetails &&
                                    restaurantDataState.cartDetails.items
                                    ? restaurantDataState.cartDetails
                                        .items.length
                                    : 100
                            }
                            size="small"
                            overflowCount={99}
                            style={{ transform: "translate(5%, -27%)", height: '18px', width: '18px' }}
                        >
                            <Popover
                                content={
                                    <CartSummaryPopup
                                        toggleVisibility={
                                            toggleCartSummaryPopover
                                        }
                                        updateCartItemCallback={
                                            changeUpdateCartItemPopupVisibility
                                        }
                                        websiteType={companyDataState.companyDetails.websiteType}
                                    />
                                }
                                title="Cart Summary"
                                trigger="click"
                                placement="bottomRight"
                                visible={state.visibleCartSummaryPopup}
                                onVisibleChange={() => {
                                    if (
                                        state.visibleCartSummaryPopup ===
                                        true
                                    ) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            visibleCartSummaryPopup: false,
                                        }));
                                    }
                                }}
                                overlayClassName="eco-order-summary eco-cart-summary"
                            >
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        category="icon"
                                        variant="link"
                                        children="cart"
                                        color="blue"
                                        onClick={toggleCartSummaryPopover}
                                    />
                                </div>
                            </Popover>
                        </Badge>
                    </Col>
                    {/* <Col xs={12} sm={4}>

                        <div className="menu-toggle">
                            {menuVisible ? (
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={toggleMenu}
                                    type="text"
                                />
                            ) : (
                                <Button
                                    icon={<MenuOutlined />}
                                    onClick={toggleMenu}
                                    type="text"
                                />
                            )}
                        </div>
                    </Col> */}
                </Row>
                {menuVisible && (
                    <Row align="middle" style={{ marginTop: '16px' }}>
                        {companyDataState && companyDataState.companyDetails && companyDataState.companyDetails.websiteType === 'E-Commerce' ?
                            <>
                                <Col xs={6} sm={3}>
                                    <Select style={{ minWidth: '100px', width: '100%' }} placeholder="Product Category" value={selectedCategory} onChange={(e) => setSelectedCategory(e)}>
                                        <Select.Option value={null}>All</Select.Option>
                                        {categories.map(c => {
                                            return (
                                                <Select.Option key={c.ID} value={c.ProductCategory.ProductCategoryID}>{c.ProductCategory.Category}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Col>

                                <Col xs={12} sm={6}>
                                    <Input placeholder="Search" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} prefix={<SearchOutlined />} />
                                </Col>
                                <Col xs={6} sm={3}>
                                    <Button onClick={handleSearch}>Search</Button>
                                </Col>
                            </ >
                            : <Col sm={12} xs={0}></Col>}

                        <Col xs={24} sm={12}>
                            {sessionStorage.getItem('loggedin') === 'true' ?
                                <div className="sign-in-menu">
                                    <Space>
                                        <a href="/account">{sessionStorage.getItem('userfirstname')} {sessionStorage.getItem('userlastname')}</a>
                                        <Popover
                                            content={<div>
                                                <p>Are you sure you want to logout?</p>
                                                <Button type="primary" onClick={handleLogout}>
                                                    Logout
                                                </Button>
                                            </div>
                                            }
                                            title="Logout"
                                            trigger="click"
                                            placement="bottomRight"
                                            visible={visibleLogOut}
                                            onVisibleChange={setVisibleLogOut}
                                        >
                                            <Button category="icon"
                                                variant="link"
                                                children="user"
                                            />
                                        </Popover>
                                    </Space>
                                </div>
                                : <div className="sign-in-menu">
                                    <Space>
                                        <a href="/account/login">Login</a>
                                        <a href="/account/register">Register</a>
                                        {/* <Button type="primary" size="small" onClick={()=> window.location.href = '/account/login'}>
                                            Sign In
                                        </Button>

                                        <Button type="default" size="small" onClick={()=> window.location.href = '/account/register'}>
                                            Sign Up
                                        </Button> */}
                                    </Space>
                                </div>
                            }
                        </Col>
                    </Row>
                )}
            </header>
        </div>
    );
};

export default AppNavbar;