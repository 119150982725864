import React from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, message, Divider, Button } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';

const key = 'updatable';

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';

  }

  componentDidMount() {
    document.title = "Credit Card Infirmation | " + config.get('companyPageTitle')
  }

  DeleteCCard = (id) => {

    Modal.confirm({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this credit card details?',
      onOk() {
        //      console.log(JSON.parse(localStorage.getItem('fullUserDetails')))
        var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));

        fullUserDetails["PaymentCardDetails"].splice(fullUserDetails["PaymentCardDetails"].indexOf(id), 1);


        localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));


        message.loading({ content: 'Deleting...', key, marginTop: '20vh' });
        setTimeout(() => {
          message.success({ content: 'Deleted successfully', key, duration: 2, marginTop: '20vh' });
        }, 1600);

        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
          "aud": config.get('CustjwtAud'),
          "exp": timestamp,
          "iss": config.get('CustjwtIss'),
          "usertoken": sessionStorage.getItem('AccessToken')
        };


        var signOptions = {
          "algorithm": "RS256"
        };

        var privateKey = config.get('LoginprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);
        // console.log(token);

        const requestUserDtlsOptions = {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + token,
          },
          body: JSON.stringify(fullUserDetails)
        };
        //  console.log(config.get('API_URL_cust') + 'update/'+config.get('OrganisationID')+'/'+fullUserDetails.UserID)
        //console.log(fullUserDetails)
        fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
          .then(response => response.json())
          .then(function (data) {
            //    console.log(data.Result);


            Modal.success({
              title: 'Credit card Details',
              content: 'Credit card details has been deleted',
              onOk() { window.location.href = "/account/payment" },
            });

          });


      },
    });

    //  console.log(id)
  };

  render() {

    if (!this.loggedIn) {
      return <Redirect to='/account/login' />;
    }

    return (
      <div className='account-pages-wrapper'>
        <Row>

          <Col sm={12} >
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/account/member">
                Your Account
              </Breadcrumb.Item> <Breadcrumb.Item>
                Payment Settings
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>
            <AccountLeftMenu />
          </Col>

          <Col sm={4}>
            <div className="fade alert alert-light show">
              <Row className="justify-content-md-center">

                <Col sm={12}>
                  <Divider orientation="left"><h5>CREDIT CARD INFORMATION</h5></Divider>
                  {
                    JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails ? (
                      JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.map((paymentdtl, index) => {
                        return (
                          <>

                            {paymentdtl.CardHolderName}<br />
                            {paymentdtl.CardType}<br />
                            {paymentdtl.CardNumber}<br />
                            {paymentdtl.ExpDate}<br />
                            <Button type="primary" onClick={this.DeleteCCard.bind(this, index)} >
                              Delete credit card details
                            </Button>
                            <hr />

                          </>
                        );
                      })
                    ) : (
                      <>No credit card details found</>
                    )}

                </Col>
              </Row>

            </div>

          </Col>



        </Row>
      </div>
    )
  }
}

export default Application;