import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import { Stack, Typography, Icon, Space, Button, Message, Row, Col } from 'witmeg-ui-system';
import {updateRetailCart} from '../../../../redux/retailDataSlice'
import { getCurrencyFormat, isECommerceProcessPath } from '../../utilities/helper';
import IMAGE_PLACEHOLDER from '../../../../assets/Images/image-placeholder.png';
export default function RetailCartSummary() {
    const dispatch = useDispatch();
    const history = useHistory()
    const retailDataState = useSelector((state) => state.retailData);

    const currencyFormat = getCurrencyFormat("GBP");


    const onCartItemDelete = async (item) => {
        const updatedCart = await retailDataState.cart.filter(r => r.ID !== item.ID)
        localStorage.setItem("retailCart", JSON.stringify(updatedCart))
        dispatch(updateRetailCart(updatedCart))
    }

    const onUpdateCartItemQuantity =async (i, opt) => {
        const updatedCart = await retailDataState.cart.map((item, index) => {
            return {
                ...item,
                quantity: i === index ?
                    opt === "plus" ?
                        Number(item.quantity + 1)
                        : item.quantity > 1 ? Number(item.quantity - 1) : 1
                    : item.quantity
            }
        })

        localStorage.setItem("retailCart", JSON.stringify(updatedCart))
        dispatch(updateRetailCart(updatedCart))
    }

    const isButtonsDisabled =false;

    const disabledButtonStyles = {
        background: isButtonsDisabled ? '#f4f4f4' : '',
        cursor: isButtonsDisabled ? 'no-drop' : 'pointer',
      };
    return (
        <div>
            {retailDataState.cart.map((item, index) => {
                return (
                    <Stack className="item eco-mb-20 " verticalAlign="v_start" key={`item_${item.ID}`}>

                        <Typography
                            style={{ backgroundImage: `url(${IMAGE_PLACEHOLDER})` }}
                            className="cart-summary-image">
                        </Typography>


                        <Stack direction="column" verticalAlign="v_start" className="eco-product-addon-row">

                            <Typography type="text" className="truncate-text eco-product-title">
                                {item.Name}
                            </Typography>

                            <Stack horizontalAlign="space_between" className="eco-product-addon-content">
                                <Space className="eco-product-addon">
                                    <div className="eco-product-addon-mins"
                                        style={{ ...disabledButtonStyles }}
                                        onClick={isButtonsDisabled ? null : () => onUpdateCartItemQuantity(index, 'minus')}>
                                        <Icon name="minus" size={16} />
                                    </div>
                                    <div className="eco-product-addon-count">
                                        <input className="input-order-amount" value={item.quantity} readOnly />
                                    </div>
                                    <div className="eco-product-addon-plus"
                                        style={{ ...disabledButtonStyles }}
                                        onClick={isButtonsDisabled ? null : () => onUpdateCartItemQuantity(index, 'plus')}>
                                        <Icon name="plus" size={16} />
                                    </div>
                                    <div>
                                        <Space className="eco-product-add" >
                                            <div className='eco-product-add-ctl'
                                                style={{ ...disabledButtonStyles }}
                                                onClick={isButtonsDisabled ? null : () => onCartItemDelete(item)}>
                                                <Icon name="delete" color="red" size={16} />
                                            </div>
                                            {/* <div className='eco-product-add-ctl'
                                                style={{ ...disabledButtonStyles }}
                                                onClick={isButtonsDisabled ? null : () => onCartItemEdit(index)}>
                                                <Icon name="pencil" size={16} />
                                            </div> */}
                                        </Space>
                                    </div>
                                </Space >
                                <Typography type="text" pattern="bold" className="eco-mb-12 eco-accordion-addon-price">
                                    {/* {currencyFormat(item.selection.total[selectedOrderType]?.amount)} */}
                                </Typography>
                            </Stack>
                        </Stack>




                    </Stack>
                )
            })}
        </div>
    )
}
