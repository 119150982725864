import React from 'react';
import { Table } from 'react-bootstrap';
import config from 'react-global-configuration';
import { CheckCircleTwoTone } from '@ant-design/icons';
import jwt from 'jsonwebtoken';
import { message } from 'antd';
import { Skeleton } from 'antd';
const key = 'updatable';

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { productstagdtlsbyid: [], basketitem: [], regexp: /^[0-9\b]+$/ };
  }

  ProductOfferbyOffer = async (offerdetails) => {
 
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');

    var token = jwt.sign(payload, privateKey, signOptions);

    const result = [];
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({ "OrgID": config.get('OrganisationID'), "ProductID": offerdetails.ProductID })
    };
 
    return await fetch(config.get('rest_API_productregistry') + '/productid', requestOptions)
    .then(response => response.json())
    .then(data => {
      result.push(data.Result[0])
 
      return result;
    });
  }

  ProductsOffer = async (product) => {

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };
    //console.log(product)

    const result = [];


    if (product.Offers[0] !== undefined) {
      return await fetch(config.get('rest_API')+'offers/offerbyid/' + config.get('OrganisationID') + '/' + product.Offers[0].OfferProducts.OfferID, requestOptions)
        .then(response => response.json())
        .then(async data => {
          result.push({ ...product, "offerdetails": data.Result[0] })

          const listofproducts =await Promise.all(data.Result[0].OfferProducts.map(async product => await this.ProductOfferbyOffer(product, data)))
         
          this.setState({ offerproducts: listofproducts, ploaded: true })
         
          return result[0];
        });
    } else {
      return product;
    }
  }

  componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({ "OrgID": config.get('OrganisationID')
      , "ProductTag": this.props.params.tagname 
      , "LocationID": (JSON.parse(sessionStorage.getItem('setting'))[0].SalesLocationID) 
    })
    };

    fetch(config.get('rest_API_productregistry') + '/tag', requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        this.setState({ ploaded: false })
        const listofproducts = await Promise.all(data.Result.map(async product => await this.ProductsOffer(product, data)))
        console.log(listofproducts)
        this.setState({ productstagdtlsbyid: listofproducts, ploaded: true })
      })

  }

  onChangeValue = event => {
    this.setState({ value: '1' });
    document.getElementsByClassName("basketadded").style.visibility = "hidden"
  };

  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  }

  onAddItem = (ProductID, ProductName, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice) => {
    let prodqty = '';

    const basketitem = this.state.basketitem;

    const prodprice = document.getElementById("prodprice" + ProductID).value
    const prodprimimage = document.getElementById("prodprimimage" + ProductID).value

    var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
    prodqty = document.getElementById("prodqty" + ProductID).value

    if (!this.state.regexp.test(prodqty)) {
      message.error('Please enter only valid number for the product quantity.');
      return;
    }

    if (index !== -1) {

      const aprodqty = basketitem[index]["ProductQty"];

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < (parseInt(prodqty) + parseInt(aprodqty))) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }


      basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) + parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem('cart', JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(aprodqty);

    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }

      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: JSON.parse(sessionStorage.getItem('menucategories')).find(category => category.ProductCategory.ProductCategoryID === ProductCategoryID).ProductCategory.VatRate
      }

      //  console.log(ProductsDtl)
      this.setState(state => {
        {
          basketitem: state.basketitem.push(ProductsDtl)
          localStorage.setItem('cart', JSON.stringify(state.basketitem));
        }
      });
    }

    setTimeout(() => {

      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");


      let checker = (arr, target) => target.some(v => arr.includes(v));



      // console.log(offerItems);

      //console.log(JSON.parse(localStorage.getItem('cart')));


      let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

      var offer = 0;

      if (checker(op, offeri)) {



        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {

          var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
          //  console.log(cindex)

          if (cindex !== -1) {
            totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
            totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
        //console.log('remainder: ' + remainder)
        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
        //console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

        basketitem[clickedindex]["OfferAmount"] = offer;

        if (totalitems < offerqty) {
          basketitem[clickedindex]["OfferAmount"] = 0;
        }

        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));

        //console.log(localStorage.getItem('cart'))

      }
    }, 100);

    message.loading({ content: 'Adding...', key });
    setTimeout(() => {
      message.success({ content: 'Added to the cart!', key, duration: 2 });
    }, 800);
  };


  onSetProductTagResult = (result, key) => {
    // console.log(result)
    let producttagarray = "[" + JSON.stringify(result) + "]"
    this.setState({ productstagdtlsbyid: JSON.parse(producttagarray) })
    // sessionStorage.setItem('sessiontagproductsdtls' + this.props.params.id, "[" + JSON.stringify(result) + "]");

  };

  onQtyIncrese = (ProductID) => {
    document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) + 1
    document.getElementById("subtotal" + ProductID).innerHTML = '£' + (document.getElementById("prodprice" + ProductID).value * document.getElementById("prodqty" + ProductID).value).toFixed(2)
  };
  onQtyReduce = (ProductID) => {
    if (document.getElementById("prodqty" + ProductID).value > 1) {
      document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) - 1
      document.getElementById("subtotal" + ProductID).innerHTML = localStorage.getItem('ecurrencyCode') + ' ' + (document.getElementById("prodprice" + ProductID).value * document.getElementById("prodqty" + ProductID).value).toFixed(2)
    }
  };


  render() {
    const { basketitem } = this.state;
    const { productstagdtlsbyid } = this.state;

    return (
      < >
     
        {
          Boolean(Array.isArray(this.state.productstagdtlsbyid) && this.state.productstagdtlsbyid.length) ? (

            <Table striped hover size="sm" width="100%">


              <thead>
                <tr>
                  <th>Name</th>
                  <th>Product Code</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Weight</th>
                  <th>Type</th>
                  <th>Sub-total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
     
                {

this.state.ploaded ? (
                  productstagdtlsbyid.map(producttag =>

                    <>
                      {
                        producttag.ProductID != this.props.params.ProductID ? (

                          <tr>
                            <td>
                              <a key={'pid' + producttag.ProductID} href={'/product/' + producttag.Name.replace(/ /g, '-').toLowerCase() + '/' + producttag.ProductID}> {producttag.Name}&nbsp;
      {
                                  producttag.UnitCharts.map(elu =>
                                    <>
                                      {elu.Quantity} X  {producttag.Weight}
    ({elu.UnitTypeName})
   </>
                                  )
                                }</a>

                              {producttag.Offers[0] ? (producttag.Offers[0].Offer.OfferType === 3 ? (<>
                                &nbsp;<span class="text-light bg-primary">&nbsp;MULTI BUY&nbsp;</span></>) : '') : ''}



                            </td>
                            <td><a key={'pid' + producttag.ProductID} href={'/product/' + producttag.Name.replace(/ /g, '-').toLowerCase() + '/' + producttag.ProductID}>{producttag.ProductRefNo}</a></td>
                            <td>


                               
                            {localStorage.getItem('ecurrencyCode')}
                        {String(producttag.SellingPricesArray.SellingPrice.toFixed(2))}
                                  <input type="hidden" id={'prodprice' + producttag.ProductID} value={producttag.SellingPricesArray.SellingPrice} />

                                  {
                                    producttag.Discount != 0 ?
                                      <><br />was {localStorage.getItem('ecurrencyCode')} {parseInt(producttag.SellingPricesArray.SellingPrice) + parseInt(producttag.Discount)} <span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{producttag.Discount}</span></>
                                      : ''
                                  }
                                 


                            </td>
                            <td>
                              <div className="quantity">
                                <button className="btn minus1" onClick={this.onQtyReduce.bind(this, producttag.ProductID)}>-</button>
                                <input className="quantity" id={'prodqty' + producttag.ProductID} min="0" name="form-0-quantity" value="1" type="number" />

                                <button className="btn add1" onClick={this.onQtyIncrese.bind(this, producttag.ProductID)}>+</button>

                              </div>

                            </td>

                            <td>{producttag.Weight}</td>
                            <td>

                              {
                                producttag.UnitCharts.map(elu =>
                                  <>

                                    {elu.UnitTypeName}
                                  </>
                                )
                              }
                            </td>
                            <td> <p id={'subtotal' + producttag.ProductID}>

                              
                                  {localStorage.getItem('ecurrencyCode')}
                        {String(producttag.SellingPricesArray.SellingPrice.toFixed(2))}
                                  <input type="hidden" id={'prodprice' + producttag.ProductID} value={producttag.SellingPricesArray.SellingPrice} />

                                  {
                                    producttag.Discount != 0 ?
                                      <><br />was {localStorage.getItem('ecurrencyCode')}{parseInt(producttag.SellingPricesArray.SellingPrice) + parseInt(producttag.Discount)} <span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{producttag.Discount}</span></>
                                      : ''
                                  }
                               
                            </p></td>
                            <td>
                              <p className="inputandbtn">
                                {
                                  producttag.OnLineImages.PrimaryImages !== null ? (
                                    <input type="hidden" id={'prodprimimage' + producttag.ProductID} value={producttag.OnLineImages.PrimaryImages.Main} />

                                  ) : (
                                      <input type="hidden" id={'prodprimimage' + producttag.ProductID} value='' />
                                    )
                                }
                                <button onClick={this.onAddItem.bind(this, producttag.ProductID, producttag.Name, producttag.MaxOnlineOrderQty, producttag.ProductCategoryID, producttag.offerdetails ? (producttag.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', producttag.offerdetails ? (producttag.offerdetails.Offer.PromotionQty) : '', producttag.offerdetails ? (producttag.offerdetails.Offer.Price) : '')} id={producttag.ProductID}>Add</button><span className="basketadded" id={'prodcart' + producttag.ProductID} style={{ visibility: 'hidden' }}> <CheckCircleTwoTone twoToneColor="#52c41a" /> Added to Cart</span></p>

                            </td>
                          </tr>


                        ) : ('')
                      }

                    </>

                  )
                 
                  ) : ( 
                    <Skeleton active avatar paragraph={{ rows: 4 }} />
                  )
                }
              </tbody>
            </Table>
          )
            : ''

        }
      </>
    )
  }
}

export default Application;