import React from 'react';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Form, Button, Input } from 'antd';
import { Table } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

const { loaded } = React
const ProducBoxMartFury = (props) => (
  <>
    <LazyLoad >

      <div className="ps-product ps-product--inner">
        <div className="ps-product__thumbnail">

          {
            props.params.product.OnLineImages.PrimaryImages !== null ?
              <div style={{ width: 'auto' }}>
                <a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} title="Quick View" >
                  <img src={props.params.product.OnLineImages.PrimaryImages.Main} alt={props.params.product.Name} /></a>
                <input type="hidden" id={'prodprimimage' + props.params.product.ProductID} value={props.params.product.OnLineImages.PrimaryImages.Main} />
              </div>
              : <div><a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} title="Quick View" >
                <img src="/images/no-image.jpg" alt={props.params.product.Name} /></a>
                <input type="hidden" id={'prodprimimage' + props.params.product.ProductID} value='' /></div>
          }


          {props.params.product.Offers[0] ? (props.params.product.Offers[0].Offer.OfferType === 3 ? (<><div className="ps-product__badge">MULTI BUY</div></>) : '') : ''}

          {props.params.product.IsOutOfStock ? (<> <div class="ps-product__badge out-stock">Out Of Stock</div></>) : ''}

          <ul className="ps-product__actions">
            {props.params.product.IsOutOfStock ? ('') : (<li><a onClick={props.onAddItem.bind(this, 'add', props.params.product.ProductID, props.params.product.Name, props.params.product.MaxOnlineOrderQty, props.params.product.ProductCategoryID, props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferQty) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferPrice) : '')} id={props.params.product.ProductID} data-toggle="tooltip" data-placement="top" title="Add to Cart"><i className="icon-bag2" /></a></li>
            )}


            <li><a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} title="Quick View" ><i className="icon-eye" /></a></li>

            <li><a href="#" data-toggle="tooltip" data-placement="top" title="Add to Whishlist"><i className="icon-heart" /></a></li>
            {/*  
                      <li><a href="#" data-toggle="tooltip" data-placement="top" title="Compare"><i className="icon-chart-bars" /></a></li>
                    */}
          </ul>
        </div>


        <div className="ps-product__container">
          <p className={props.params.product.Discount != 0 ? 'ps-product__price sale' : 'ps-product__price'}>

            <>
              {localStorage.getItem('ecurrencyCode')}{
                String((props.params.product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2))
              } {
                props.params.product.Discount != 0 ?
                  <><del>{localStorage.getItem('ecurrencyCode')}{(parseInt(props.params.product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(props.params.product.Discount)}</del></>
                  : ''
              }

              <input type="hidden" id={'prodprice' + props.params.product.ProductID} value={props.params.product.SellingPricesArray.SellingPrice} />

            </>


          </p>
          <div className="ps-product__content"><a key={'pid' + props.params.product.ProductID} href={'/product/' + props.params.product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + props.params.product.ProductID} className="ps-product__title">{props.params.product.Name}</a>
            <p><b>Item Code:</b> <br />{props.params.product.ProductRefNo}</p>
            <>
              <div>
                {props.params.product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><a className="ps-btn addcart" ><button className="notify" onClick={props.onNotify.bind(this, props.params.product.ProductID, props.params.product.Name, props.params.product.MaxOnlineOrderQty)} id={props.params.product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></a></p> <Modal
                  show={props.params.visible}
                  onHide={props.handleCancel}
                  centered
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Notify
          </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      onFinish={props.onFinish}
                      name="basic"
                      initialValues={{ remember: true }}
                    >
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[{ required: true, type: 'email' }]}
                      >
                        <Input placeholder="Email Address" />
                      </Form.Item>
                      <Form.Item  >
                        <Input name="prdt" type="hidden" id="prdt" value={props.params.product.Name} />
                        <Input name="prdtURL" type="hidden" id="prdtURL" value={window.location.href} />
                        <Button type="primary" htmlType="submit" danger >
                          Submit
                      </Button>
                      </Form.Item>
                    </Form>

                  </Modal.Body>
                </Modal></div>) : (

                    <Table size="sm" width="50%">
                      <tr>
                        <td>  <div className="form-group--number">
                          <button className="up" onClick={props.onQtyIncrese.bind(this, props.params.product.ProductID)}><FontAwesomeIcon icon={faPlus} /></button>
                          <button className="down" onClick={props.onQtyReduce.bind(this, props.params.product.ProductID)}><FontAwesomeIcon icon={faMinus} /></button>
                          <input className="form-control" type="text" min={1} max={props.params.product.MaxOnlineOrderQty === 0 ? 10000 : props.params.product.MaxOnlineOrderQty} precision={0} id={'prodqty' + props.params.product.ProductID} value={1} snap placeholder={1} />
                        </div></td>
                        <td> <a className="ps-btn addcart" onClick={props.onAddItem.bind(this, 'add', props.params.product.ProductID, props.params.product.Name, props.params.product.MaxOnlineOrderQty, props.params.product.ProductCategoryID, props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferQty) : '', props.params.product.CurrentOffer.ID ? (props.params.product.CurrentOffer.Offer.OfferPrice) : '')} >ADD</a>
                        </td>
                      </tr>
                    </Table>
                  )}
              </div>
            </>

            <Table striped bordered hover size="sm" width="50%">
              <thead>
                <tr>
                  <th>Pack Size</th>
                  <th>Weight</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>

                {
                  props.params.product.UnitCharts.length > 0 ? (
                    props.params.product.UnitCharts.map(elu =>
                      <tr>
                        <td>{elu.Quantity}</td>
                        <td>{props.params.product.Weight}</td>
                        <td>{elu.UnitTypeName}</td>
                      </tr>
                    )
                  ) : (
                      <tr>
                        <td>1</td>
                        <td>{props.params.product.Weight}</td>
                        <td>EA</td>
                      </tr>
                    )
                }


              </tbody>
            </Table>

          </div>
        </div>
      </div>
      <br />

    </LazyLoad>
  </>


);
export default ProducBoxMartFury;
