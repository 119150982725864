import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
 
export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {

    var primaryTextStyle = "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle = "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle = "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

    // console.group("Work with us!");
    // console.log("%cEcologital%c is hiring.", primaryTextStyle, tertiaryTextStyle);
    // console.log("%c Go to%c https://www.ecologital.com %cto apply!", tertiaryTextStyle, secondaryTextStyle, tertiaryTextStyle);
    // console.groupEnd();
 
      fetch(config.get('backendURL') + 'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  render() {
    return (
<>

<div className="section pg-footer">
        
        <div className="container">

             <div className='row'>

                    <div className='col-sm-12'>

											<div className='footer-wrapper'>

													<div className="social-icons">
															<a href="#" className='pl0'><i className="fa-brands fa-facebook"></i></a>
															<a href="#"><i className="fa-brands fa-twitter"></i></a>
															<a href="#"><i className="fa-brands fa-instagram"></i></a>
															<a href="#"><i className="fa-brands fa-linkedin"></i></a>
													</div>
													<div className='addr-wrap'>
															<h6>{this.state.Name ? this.state.Name : ''}</h6>
															<div className='addr-lines'>
                                  {this.state.AddressLine1 ? (<span> {this.state.AddressLine1} </span>) : ''}
          {this.state.AddressLine2 ? (<span> {this.state.AddressLine2} </span>) : ''}
          {this.state.City ? (<span> {this.state.City} </span>) : ''}
          {this.state.StateProvinceRegion ? (<span> {this.state.StateProvinceRegion} </span>) : ''}
          {this.state.PostZipCode ? (<span> {this.state.PostZipCode} </span>) : ''}
          {this.state.Country ? (<span> {this.state.Country} </span>) : ''} 
															</div> 
													</div>

													<div className='contact-info'>
														<span>Phone: {this.state.Phone}</span>
											 
                            {this.state.Email ? (<span> <b>Email</b> : {this.state.Email} </span>) : ''} 
                            {this.state.Fax ? (<span> <b>Fax</b> : {this.state.Fax} </span>) : ''} 

                            {this.state.OpenningHours ? (<span> <b>Openning Hours</b> : {this.state.OpenningHours} </span>) : ''} 
													</div>

											</div>

											<div className='copy'>
							
												<span className='copy-text'>© 2022 All Ceylon Distributors Investment Ltd. All Rights Reserved.</span>
												<div className='copy-links'>
													<a href="#">Privacy Policy</a>
													<span className='copy-sep'>|</span>
													<a href="#">Terms of Use</a>
												</div>

											</div>

                        

                    </div>

              </div>

        </div>

    </div>

 
 
      </>
    );
  }
}