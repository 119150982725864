import React from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import AccountLeftMenu from "./account-left-menu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button, message, Divider } from 'antd';
import config from 'react-global-configuration';
import UpdateAddress from './account-address-update'
import jwt from 'jsonwebtoken';

const key = 'updatable';

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';

  }
  state = {
    loading: false,
    visible: false,
  };
  componentDidMount() {
    document.title = "Address Infirmation | " + config.get('companyPageTitle')
  }

  showModal = (addressId) => {
    //   console.log(addressId)
    this.setState({
      visible: true,
      addressId: addressId,
    });
  };


  DeleteAddress = (id) => {

    Modal.confirm({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this address?',
      onOk() {
        //      console.log(JSON.parse(localStorage.getItem('fullUserDetails')))
        var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));

        fullUserDetails["Addresses"].splice(fullUserDetails["Addresses"].indexOf(id), 1);


        localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));


        message.loading({ content: 'Deleting...', key, marginTop: '20vh' });
        setTimeout(() => {
          message.success({ content: 'Deleted successfully', key, duration: 2, marginTop: '20vh' });
        }, 1600);

        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
          "aud": config.get('CustjwtAud'),
          "exp": timestamp,
          "iss": config.get('CustjwtIss'),
          "usertoken": sessionStorage.getItem('AccessToken')
        };


        var signOptions = {
          "algorithm": "RS256"
        };

        var privateKey = config.get('LoginprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);
        // console.log(token);

        const requestUserDtlsOptions = {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + token,
          },
          body: JSON.stringify(fullUserDetails)
        };
        //  console.log(config.get('API_URL_cust') + 'update/'+config.get('OrganisationID')+'/'+fullUserDetails.UserID)
        //console.log(fullUserDetails)
        fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
          .then(response => response.json())
          .then(function (data) {
            //    console.log(data.Result);


            Modal.success({
              title: 'Address Details',
              content: 'Address has been deleted',
              onOk() { window.location.href = "/account/addresses" },
            });

          });

        // window.location.href = "/account/addresses"
      },
    });

    //  console.log(id)
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });

  };

  render() {
    const { visible, loading, addressId } = this.state;



    if (!this.loggedIn) {
      return <Redirect to='/account/login' />;
    }

    return (

      <div className='account-pages-wrapper'>
        <Row>

          <Col sm={12} >
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/account/member">
                Your Account
              </Breadcrumb.Item> <Breadcrumb.Item>
                Addresses
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>
            <AccountLeftMenu />
          </Col>

          <Col sm={9}>
            <div className="fade alert alert-light show">
              <Row className="justify-content-md-center">

                <Col sm={12}>
                  <Divider orientation="left"><h5>ADDRESSES</h5></Divider>
                  {

                    JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                      return (
                        <>
                          {
                            address.IsDefault === 'true' ? (
                              <h6>Default Address</h6>
                            ) : (
                              <span>

                              </span>
                            )}

                          <>
                            <FontAwesomeIcon icon={faMapMarked} /> {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                            {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                            {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                            {address.City ? (<>{address.City}<br /></>) : ''}
                            {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                            {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                            {address.Country ? (<>{address.Country}<br /> </>) : ''}
                            {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                            {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                            {address.Fax ? (<><b>Landline: </b>{address.Fax}<br /></>) : ''}

                            <hr />

                          </>

                          <Button type="primary" onClick={this.showModal.bind(this, index)}>
                            Edit
                          </Button>&nbsp;
                          <Button type="primary" onClick={this.DeleteAddress.bind(this, index)} >
                            Delete Address
                          </Button>&nbsp;

                          <hr />

                        </>
                      );
                    })
                  }
                  <Modal
                    visible={visible}
                    destroyOnClose="true"
                    title="UPDATE ADDRESS INFORMATION"
                    width="1000px"
                    onCancel={this.handleCancel}
                    footer={[
                      <Button key="back" onClick={this.handleCancel}>
                        Return
                      </Button>,

                    ]}
                  >


                    <UpdateAddress params={{ addressId: this.state.addressId }} />


                  </Modal>
                </Col>
              </Row>

            </div>

          </Col>



        </Row>
      </div>
    )
  }
}

export default Application;