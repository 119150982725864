import React, { useEffect, useState } from 'react';
import Carousel from "../default/carousel";
import ItemPicks from "../default/itempicks";
import Editor from "../default/editor";
import Gallery from "../../lib/gallery/gallery";
import BannerImage from "../default/bannerimage";
import Video from "../default/video";
import Contact from "../../lib/contact"
import Blog from "../default/blogs";
import Icon from "../default/icon.js";
import PricePlanWidget from './PricePlanWidget';
import Button from '../default/Button';
import AccordionWidget from './AccordionWidget';
import RestaurantWidget from './WidgetCompoentens/ResturatnWidget/index'
import { Maps } from './Maps';
import { useDispatch } from "react-redux";
import { getHomeLayoutByID } from './utilities/apiRequests/witmegRetailPaxServerRequests';
import { setHasResturantWidget } from '../../redux/resturantWidgetSlice';
import ImagewithContent from './ImagewithContent';
import PromotedProducts from './EcommerceWidgets/PromotedProducts';
import ShankarPromotedProducts from '../sharkar/EcommerceWidgets/PromotedProducts';

const LayoutInclude = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    widgetData: null,
  });

  useEffect(() => {
    const _initData = async () => {
      const _response = await getHomeLayoutByID({
        ID: props.params.layoutid,
        PageID: props.params.pageID,
        CompanyID: '',
        Start: 0,
        Limit: 1000,
      });

      if (_response) {
        setState((prevState) => ({ ...prevState, widgetData: _response[0] }));
        if (_response[0].Type === 'resturant menu') {
          dispatch(setHasResturantWidget({ hasResturantWidget: true }));
        }
      }
    };

    _initData();
  }, []);

  return (
    <>
      {state.widgetData && (
        <>
          {
            {
              image: (
                <BannerImage
                  params={{
                    pageID: props.params.pageID,
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                    className: 'imageClass',
                  }}
                />
              ),

              icon: (
                <Icon
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                    className: 'imageClass',
                  }}
                />
              ),

              carousel: (
                <Carousel
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              bestselling: (
                <>
                  {props.theme === "Shankar" ?
                    <ShankarPromotedProducts
                      title="Best Selling"
                      params={{
                        ItemHeading: state.widgetData.ItemHeading,
                        ItemData: state.widgetData.ItemData,
                        Type: 'bestselling',
                      }}
                    />
                    : <PromotedProducts
                      title="Best Selling Products"
                      params={{
                        ItemHeading: state.widgetData.ItemHeading,
                        ItemData: state.widgetData.ItemData,
                        Type: 'bestselling',
                      }}
                    />
                  }
                </>
                
              ),

              featured: (
                <>
                  {props.theme === "Shankar" ?
                    <ShankarPromotedProducts
                      title="Featured Products"
                      params={{
                        ItemHeading: state.widgetData.ItemHeading,
                        ItemData: state.widgetData.ItemData,
                        Type: 'featured',
                      }}
                    />
                    : <PromotedProducts
                      title="Featured Products"
                      params={{
                        ItemHeading: state.widgetData.ItemHeading,
                        ItemData: state.widgetData.ItemData,
                        Type: 'featured',
                      }}
                    />
                  }
                </>

                // <ItemPicks
                //   params={{
                //     ItemHeading: state.widgetData.ItemHeading,
                //     ItemData: state.widgetData.ItemData,
                //     Type: 'featured',
                //   }}
                // />
              ),

              'featured offers': (
                <PromotedProducts
                  title="Featured Offers"
                  params={{
                    ItemHeading: state.widgetData.ItemHeading,
                    ItemData: state.widgetData.ItemData,
                    Type: 'featuredoffers',
                  }}
                />
              ),

              offers: (
                <PromotedProducts
                  title="Offers"
                  params={{
                    ItemHeading: state.widgetData.ItemHeading,
                    ItemData: state.widgetData.ItemData,
                    Type: 'offers',
                  }}
                />
              ),

              video: (
                <Video
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              'text editor': (
                <Editor
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              gallery: (
                <Gallery
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              'contact form': (
                <Contact
                  params={{
                    ID: props.params.layoutid,
                    ItemHeading: state.widgetData.ItemHeading,
                    Item: state.widgetData.Item,
                    ItemData: state.widgetData.ItemData,
                  }}
                />
              ),

              blog: (
                <Blog
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              'Price Plan': (
                <PricePlanWidget
                  params={{
                    ID: props.params.layoutid,
                    ItemHeading: state.widgetData.ItemHeading,
                    Item: state.widgetData.Item,
                    ItemData: state.widgetData.ItemData,
                  }}
                />
              ),

              button: (
                <Button
                  params={{
                    X: state.widgetData.X,
                    Y: state.widgetData.Y,
                    ID: props.params.layoutid,
                    Width: state.widgetData.Width,
                    Height: state.widgetData.Height,
                    ItemData: state.widgetData.ItemData,
                    Link: state.widgetData.Link,
                  }}
                />
              ),

              accordion: (
                <AccordionWidget
                  params={{
                    ID: props.params.layoutid,
                    ItemHeading: state.widgetData.ItemHeading,
                    Item: state.widgetData.Item,
                    ItemData: state.widgetData.ItemData,
                  }}
                />
              ),

              maps: (
                <Maps
                  params={{
                    ID: props.params.layoutid,
                    ItemHeading: state.widgetData.ItemHeading,
                    Item: state.widgetData.Item,
                    ItemData: state.widgetData.ItemData,
                  }}
                />
              ),

              'Blank Space': (
                <div>&nbsp;</div>
              ),
              'Image with Text': (
                <ImagewithContent params={{
                  ID: props.params.layoutid,
                  ItemHeading: state.widgetData.ItemHeading,
                  Item: state.widgetData.Item,
                  ItemData: state.widgetData.ItemData,
                }} />
              ),

              'resturant menu': (
                <RestaurantWidget params={{ ItemData: state.widgetData.ItemData }} />
              ),
            }[state.widgetData.Type]
          }
        </>
      )}
    </>
  );
}

export default LayoutInclude;