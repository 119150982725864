import Axios from "axios";
import apiEndpointPaths from "./apiEndpointPaths";
import { APIError, errorLogger } from "./errorHandlers";
import { generateTokenForAppRegistry } from "./tokenGenerators";

export const getCountryList = async () => {
  try {
    let token = await generateTokenForAppRegistry();

    const apiReqUrl = apiEndpointPaths.appRegistry.getCountryList;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "GET",
      url: apiReqUrl,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}