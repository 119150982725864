import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import { Button, Checkbox, Col, InputBox, Label, Message, Row, Select, Space, Stack, Typography } from 'witmeg-ui-system'
import { getCountryList } from '../../utilities/apiRequests/appRegistryServerRequests'
import { getDeliveryDistanceByPostcode, getRestaurantMenuSettingsData } from '../../utilities/apiRequests/neutriPosServerRequests';
import { getLocalStorageData, getRestaurantMenuAvailablePath, LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from '../../utilities/helper';
import { calculateRestaurantMenuCartTotal, RESTAURANT_SETTINGS_TYPES } from '../../utilities/restaurantMenuUtil';
import ValidateData, { VALIDATION_TYPES } from '../../utilities/validateData';
import _ from 'lodash';
import { syncRestaurantMenuCartDetails } from '../../../../redux/resturantWidgetSlice';
import "@fontsource/inter";

const _customerDetailsInitialState = {
  firstName: {
    value: '',
    hasError: false,
    errorType: null,
    errorMessage: null,
    validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_TEXT_ONLY],
  },
  lastName: {
    value: '',
    hasError: false,
    errorType: null,
    errorMessage: null,
    validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_TEXT_ONLY],
  },
  mobileNo: {
    value: '',
    hasError: false,
    errorType: null,
    errorMessage: null,
    errorMessage: null,
    validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_PHONE_NO],
  },
  emailAddress: {
    value: '',
    hasError: false,
    errorType: null,
    errorMessage: null,
    validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_EMAIL],
  },
  customerAddress: {
    line1: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    line2: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [],
    },
    state: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    city: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    postcode: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_POSTCODE],
    },
    country: {
      value: 'United Kingdom',
      hasError: false,
      errorType: null,
      errorMessage: null,
      // validations: [VALIDATION_TYPES.REQUIRED_FIELD],
      validations: [],
    },
  },
  deliveryAddress: {
    line1: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    line2: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [],
    },
    state: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    city: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD],
    },
    postcode: {
      value: '',
      hasError: false,
      errorType: null,
      errorMessage: null,
      validations: [VALIDATION_TYPES.REQUIRED_FIELD, VALIDATION_TYPES.IS_POSTCODE],
    },
    country: {
      value: 'United Kingdom',
      hasError: false,
      errorType: null,
      errorMessage: null,
      // validations: [VALIDATION_TYPES.REQUIRED_FIELD],
      validations: [],
    },
  },
  isDeliveryAddressSame: true,
};

const OrderConfirmationForm = () => {

  let history = useHistory();
  const dispatch = useDispatch();

  const cartData = useSelector((state) => state.resturantData.cartDetails);
  const companyData = useSelector((state) => state.companyData.companyDetails);
  const settings = useSelector((state) => state.companyData.settings);

  const [customerDetails, setCustomerDetails] = useState(_customerDetailsInitialState);
  const [pageState, setPageState] = useState({
    isLoading: false,
    hasError: true,
    isDistanceExceeded: false,
    countryData: [],
  });

  const [menuPath, setMenuPath] = useState('/');

  useEffect(() => {
    const _setData = async () => {
      const _menuPathResponse = await getRestaurantMenuAvailablePath();
      setMenuPath(_menuPathResponse);
    }
    _setData();
  }, []);

  const fetchCountryList = async () => {
    const _countries = await getCountryList();

    if (!_countries || _countries.length <= 0) return;

    const countryData = _countries.map((__country) => ({
      value: __country.ShortCode,
      label: __country.Country,
    }));

    setPageState((prevState) => ({ ...prevState, countryData }));
  };

  // useEffect(() => fetchCountryList(), []);

  const onBackToMenu = () => {
    // history.push(menuPath);
    history.goBack();
    setTimeout(() => { window.location.reload() }, 500)
  }

  const navigateOrderSummary = () => {
    if (settings.websiteType === "Restaurant") {
      validateAllFormData();
    } else {
      validateAllFormData();
      // history.push('/payment-details')
    }
  }

  const toggleIsDeliveryAddressDifferent = () => {
    setCustomerDetails(prevState => ({
      ...prevState,
      isDeliveryAddressSame: !prevState.isDeliveryAddressSame,
    }));
  };

  const onFormDataChanged = (_property, _value) => {
    setCustomerDetails((prevState) => {
      const _propertySplit = _property.split('.');

      if (_propertySplit.length > 1) {
        const _currentValues = prevState[_propertySplit[0]];
        const _newValues = {
          ..._currentValues,
          [_propertySplit[1]]: {
            ..._currentValues[_propertySplit[1]],
            value: _value,
          },
        };

        return {
          ...prevState,
          [_propertySplit[0]]: {
            ..._newValues,
          },
        };
      }

      return {
        ...prevState,
        [_property]: {
          ...prevState[_property],
          value: _value,
        },
      };
    });
  };

  const validateFieldSet = (_fields) => {
    let _newFields = _fields;
    let _pageHasError = false;

    for (const _key in _fields) {
      if (!_fields.hasOwnProperty(_key)) continue;

      let _validationRes = { hasError: false, errorType: null };

      for (const __v of _fields[_key].validations) {
        if (_validationRes.hasError) break;

        _validationRes = ValidateData(__v, _fields[_key].value, _fields[_key].additional ?? null);
      }

      if (_validationRes.hasError) _pageHasError = true;

      _newFields[_key] = {
        ..._newFields[_key],
        ..._validationRes,
      };
    }

    return {
      fields: _newFields,
      hasError: _pageHasError,
    };
  };

  const getDeliveryCharge = async () => {
    const _cloudLocationID = cartData.cloudLocationID;
    const _organizationID = companyData.organizationID;
    const _sourcePostcode = companyData.address.postcode;
    const _destinationPostcode = customerDetails.isDeliveryAddressSame ? customerDetails.customerAddress.postcode.value : customerDetails.deliveryAddress.postcode.value;

    const _deliveryDistance = await getDeliveryDistanceByPostcode({
      CloudLocationID: _cloudLocationID,
      OrganizationID: _organizationID,
      SourcePostcode: _sourcePostcode,
      DestinationPostcode: _destinationPostcode,
    });

    const _deliverySettings = await getRestaurantMenuSettingsData({
      SettingType: RESTAURANT_SETTINGS_TYPES.DELIVERY_SETTING,
      CloudLocationID: _cloudLocationID,
    });

    const { MaxDeliveryDistance, DeliveryCostPerKilometer, FixedDeliveryCost } = _deliverySettings[0];

    const _isDistanceExceeded = Number(_deliveryDistance) > MaxDeliveryDistance ? true : false;

    const _deliveryCost = (FixedDeliveryCost && FixedDeliveryCost > 0) ? FixedDeliveryCost : DeliveryCostPerKilometer * Number(_deliveryDistance);

    return {
      distanceExceeded: _isDistanceExceeded,
      maxDistance: MaxDeliveryDistance,
      cost: _deliveryCost,
      deliveryDistance: Number(_deliveryDistance),
    };
  }

  const navigateToOrderSummary = async () => {
    let _deliveryCharges = null;
    const _isDeliveryAvailable = cartData.orderTypes.find(_item => _item === 'delivery');
    let _canNavigate = true;

    if (_isDeliveryAvailable) {
      _deliveryCharges = await getDeliveryCharge();

      if ((cartData.defaultOrderType === 'delivery' && !_deliveryCharges.distanceExceeded) || cartData.defaultOrderType !== 'delivery') {
        const _newCartData = calculateRestaurantMenuCartTotal({
          ...cartData,
          additions: { ...cartData.additions, deliveryCharges: _deliveryCharges },
        });

        await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
        dispatch(syncRestaurantMenuCartDetails());
      } else {
        _canNavigate = false;
      }
    }

    if (_canNavigate) {
      history.push("/order-summary");
    } else {
      Message.error('Delivery address is outside of our delivery zone. Please enter a valid address within our delivery area.');
    }
  };

  useEffect(() => {
    if (!pageState.hasError) {
      if (settings.websiteType === "Restaurant") {
        navigateToOrderSummary();
      } else {
        history.push("/payment-details")
      }
    }
  }, [pageState.hasError]);

  const validateAllFormData = () => {
    setPageState(prevState => ({ ...prevState, isLoading: true }));
    setCustomerDetails((prevState) => {
      const { customerAddress, deliveryAddress, isDeliveryAddressSame, ..._basicFields } = customerDetails;

      let _newBasic = validateFieldSet(_basicFields);
      let _newCustomerAddress = validateFieldSet(customerAddress);
      let _newDeliveryAddress = { fields: deliveryAddress, hasError: false };

      if (!isDeliveryAddressSame) {
        _newDeliveryAddress = validateFieldSet(deliveryAddress);
      }

      const _pageHasError = _newBasic.hasError || _newCustomerAddress.hasError || _newDeliveryAddress.hasError ? true : false;
      if (!_pageHasError) {
        const _customerFormData = {
          firstName: _basicFields.firstName.value,
          lastName: _basicFields.lastName.value,
          mobileNo: _basicFields.mobileNo.value,
          emailAddress: _basicFields.emailAddress.value,
          customerAddress: {
            line1: customerAddress.line1.value,
            line2: customerAddress.line2.value ?? '',
            state: customerAddress.state.value,
            city: customerAddress.city.value,
            postcode: customerAddress.postcode.value,
            // country: customerAddress.country.value.label,
            country: customerAddress.country.value,
          },
          deliveryAddress: {
            line1: deliveryAddress.line1.value ?? '',
            line2: deliveryAddress.line2.value ?? '',
            state: deliveryAddress.state.value ?? '',
            city: deliveryAddress.city.value ?? '',
            postcode: deliveryAddress.postcode.value ?? '',
            // country: deliveryAddress.country.value?.label ?? '',
            country: deliveryAddress.country.value,
          },
          isDeliveryAddressSame,
        };

        setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS, _customerFormData);
      }

      setPageState((prevState) => ({ ...prevState, hasError: _pageHasError }));

      return {
        ...prevState,
        ..._newBasic.fields,
        customerAddress: _newCustomerAddress.fields,
        deliveryAddress: _newDeliveryAddress.fields,
      };
    });
  };

  return (
    <div>
      <Typography type="title" level={1} className="eco-mb-32 eco-order-confirm-title" color="gray_darkest">Order Confirmation Details</Typography>
      <div className="eco-mb-16">

        <div>
          <Row className="eco-mb-24" gutter={[16, 16]}>
            <Col lg={12} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>First Name</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('firstName', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.firstName.value} error={customerDetails.firstName.hasError} />
                </Col>
              </Row>
            </Col>
            <Col lg={12} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>Last Name</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('lastName', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.lastName.value} error={customerDetails.lastName.hasError} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eco-mb-24" gutter={[16, 16]}>
            <Col lg={12} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>Mobile Number</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('mobileNo', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.mobileNo.value} error={customerDetails.mobileNo.hasError} />
                </Col>
              </Row>
            </Col>
            <Col lg={12} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>Email Address</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('emailAddress', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.emailAddress.value} error={customerDetails.emailAddress.hasError} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eco-mb-24" gutter={[16, 16]}>
            <Col span={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>Address Line 1</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('customerAddress.line1', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.customerAddress.line1.value} error={customerDetails.customerAddress.line1.hasError} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eco-mb-24">
            <Col span={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label>Address Line 2</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('customerAddress.line2', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.customerAddress.line2.value} error={customerDetails.customerAddress.line2.hasError} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eco-mb-24" gutter={[16, 16]}>
            <Col lg={6} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>City</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('customerAddress.city', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.customerAddress.city.value} error={customerDetails.customerAddress.city.hasError} />
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>State/County</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('customerAddress.state', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.customerAddress.state.value} error={customerDetails.customerAddress.state.hasError} />
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label required>Postcode</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox onChange={(e) => onFormDataChanged('customerAddress.postcode', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.customerAddress.postcode.value} error={customerDetails.customerAddress.postcode.hasError} />
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={24} xs={24}>
              <Row className="form-row">
                <Col span={24}>
                  <div className="form-lbl-wrapper"><Label>Country</Label></div>
                </Col>
                <Col span={24}>
                  <InputBox noBorder backgroundOnly type="text" value={customerDetails.customerAddress.country.value} disabled />
                  {/* <Select noBorder onChange={(value) => onFormDataChanged('customerAddress.country', value)} options={pageState.countryData} value={customerDetails.customerAddress.country.value} hasError={customerDetails.customerAddress.country.hasError} placeholder="" /> */}
                </Col>
              </Row>
            </Col>
          </Row>

          <Space size="middle" className="eco-mb-24">
            <Checkbox checked={customerDetails.isDeliveryAddressSame} onChange={(e) => toggleIsDeliveryAddressDifferent()} />
            <Typography type="text">Set the same for delivery</Typography>
          </Space>

          {
            !customerDetails.isDeliveryAddressSame &&
            <div>
              <Row className="eco-mb-24" gutter={[16, 16]}>
                <Col span={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label required>Address Line 1</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line1', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.line1.value} error={customerDetails.deliveryAddress.line1.hasError} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="eco-mb-24">
                <Col span={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label>Address Line 2</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line2', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.line2.value} error={customerDetails.deliveryAddress.line2.hasError} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="eco-mb-24" gutter={[16, 16]}>
                <Col lg={6} sm={24} xs={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label required>City</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.city', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.city.value} error={customerDetails.deliveryAddress.city.hasError} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={24} xs={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label required>State/County</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.state', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.state.value} error={customerDetails.deliveryAddress.state.hasError} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={24} xs={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label required>Postcode</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.postcode', e.target.value)} noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.postcode.value} error={customerDetails.deliveryAddress.postcode.hasError} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={24} xs={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <div className="form-lbl-wrapper"><Label>Country</Label></div>
                    </Col>
                    <Col span={24}>
                      <InputBox noBorder backgroundOnly type="text" value={customerDetails.deliveryAddress.country.value} disabled />
                      {/* <Select noBorder onChange={(value) => onFormDataChanged('deliveryAddress.country', value)} options={pageState.countryData} value={customerDetails.deliveryAddress.country.value} hasError={customerDetails.deliveryAddress.country.hasError} placeholder="" /> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        </div>
      </div>
      <Stack horizontalAlign="h_end">
        <Button variant="primary" className="custom-btn full-width-btn" onClick={navigateOrderSummary}>Next</Button>
      </Stack>
    </div>
  )
}

export default OrderConfirmationForm