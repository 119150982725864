import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser'

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = { itemdata: '' };
	}

	componentDidMount() {
		if ($.isFunction('owlCarousel')) {
			$(".owl-testimonials").owlCarousel({
				navigation: true
			});
		}
		this._isMounted = true;
		axios.get(config.get('backendURL') + 'homelayout/byid/' + this.props.params.id)
			.then(response => {
				console.log(response)
				this.setState({
					itemdata: response.data.itemdata,
					itemheading:response.data.itemheading,
					item:response.data.item
				})
			})
			.catch(function (error) {
				console.log(error);
			})
	};
	render() {
		return (
			<>
			<div class={this.state.item ? (this.state.item.split("||")[0]) : ('') }>
			<h4>{this.state.itemheading ? (
					this.state.itemheading
				) : ('')
				}</h4>
		<div class="row">
			<div class="col-sm-12">
			<div class={this.state.item ? (this.state.item.split("||")[1]) : ('') }>
			<OwlCarousel className='owl-theme' loop={true} margin={20} nav={false} responsiveClass={true} responsive={{ 0: { items: 1 }, 768: { items: 2 }, 992: { items: 3 } }} navText={['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']}>
				{this.state.itemdata ? (
					<>{ReactHtmlParser(this.state.itemdata)}</>
				) : ('')
				}
			</OwlCarousel>
			</div>
			</div>
			</div>
			</div>
			</>
		)
	}
}

export default App;
