import React from 'react';
import { Modal, Form, Button, Input, Divider } from 'antd';
const AccountRegisterVerifyMartFury = (props) => (

    
      <>
           
           <Form
className="ps-form--account-setting"
name="basic"
initialValues={{ remember: true }}
onFinish={props.onFinish}
>
                  <div className="ps-form__header">
                    <h3> Register for Online Shopping</h3>
                  </div>
                  <div className="ps-form__content">
                    <div className="form-group">
                      <label>Verification Code</label>
                      
                      <Form.Item
        name="verificationcode"
        label=""
        rules={[{ required: true, message: 'Please enter a valid verification code' }]}
      >
        <Input placeholder="Please enter your verification code" />
      </Form.Item>

                    </div>
                    We have sent an email with a verification to your email address. In order to complete the sign-up process, Please check your email and enter the verification code above.
        <br/>
If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.
<br/>
If you need assistance, please contact us.
                  </div>

                  
                  <div className="form-group submit">
                    <button className="ps-btn">
                    {props.disabled ? 'Please wait...' : 'Verify Now'}

                    </button>
                  </div>
                  </Form>
         </>
  

);
export default AccountRegisterVerifyMartFury;
