import React from 'react';
import UnderConstructionImage from '../assets/Images/under_construction.jpg';
import KottuKingComingSoonImage from '../assets/Images/kottu-king-coming-soon.jpg';

export default function SiteUnderMaintenance() {
  let pageImage = UnderConstructionImage;

  // switch (window.location.origin) {
  //   case 'http://localhost:3001':
  //     pageImage = KottuKingComingSoonImage;
  //     break;
  //   default:
  //     pageImage = UnderConstructionImage;
  // }

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <img
        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        src={pageImage} />
    </div>
  );
}
