import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faShoppingCart,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";
import { Col, Table, Modal } from "react-bootstrap";
import jwt from "jsonwebtoken";
import LazyLoad from "react-lazyload";
import { renderEmail } from "postonents";
import { Skeleton, message, Button, Form, Input } from "antd";
import config from "react-global-configuration";
import NotifyEmail from "../../email/NotifyEmail";
import { LoadingOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";

const key = "updatable";
const catid = 1;
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      basketitem: [],
      basketqty: [],
      loaded: false,
      regexp: /^[0-9\b]+$/,
    };
  }

  componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      aud: config.get("ProductjwtAud"),
      exp: timestamp,
      iss: config.get("ProductjwtIss"),
    };

    var signOptions = {
      algorithm: "RS256",
    };

    var privateKey = config.get("ProductprivateKey");
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    var payload2 = {
      aud: config.get("SalesjwtAud"),
      exp: timestamp,
      iss: config.get("SalesjwtIss"),
    };

    var privateKey2 = config.get("SalesprivateKey");
    var token2 = jwt.sign(payload2, privateKey2, signOptions);

    console.log(token);
    axios
      .get(
        config.get("API_salesregistry") +
          "setting/" +
          config.get("OrganisationID") +
          "/26",
        { headers: { Authorization: "Bearer " + token2 } }
      )
      .then((response) => {
        console.log(JSON.stringify(response.data.Result));
        sessionStorage.setItem("setting", JSON.stringify(response.data.Result));

        fetch(
          config.get("rest_API_productregistry") +
            "/" +
            this.props.params.Type +
            "/" +
            config.get("OrganisationID") +
            "/" +
            response.data.Result[0].SalesLocationID +
            "/0/20",
          requestOptions
        )
          .then((response) => response.json())
          .then(async (data) => {
            const products = data.Result;
            this.setState({ ploaded: false });
            this.setState({ products: products, ploaded: true });
          });
      })
      .catch((error) => {
        console.log(error);
      });

    const cachedCart = localStorage.getItem("cart");
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }
  }

  onChangeValue = (event) => {
    this.setState({ value: "1" });
  };

  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  onAddItem = (
    ProductID,
    ProductName,
    MaxOnlineOrderQty,
    ProductCategoryID,
    offerItems,
    offerqty,
    offerprice
  ) => {
    const cachedCart = localStorage.getItem("cart");
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem("cart", JSON.stringify(myArray));
    }
    let prodqty = "";

    const basketitem = this.state.basketitem;

    const prodprice = document.getElementById("prodprice" + ProductID).value;
    const prodprimimage = document.getElementById(
      "prodprimimage" + ProductID
    ).value;

    var index = this.state.basketitem.findIndex(
      (a) => a.ProductID === ProductID
    );
    prodqty = document.getElementById("prodqty" + ProductID).value;

    if (!this.state.regexp.test(prodqty)) {
      message.error("Please enter only valid number for the product quantity.");
      return;
    }

    console.log(index);
    if (index !== -1) {
      const aprodqty = basketitem[index]["ProductQty"];

      if (
        MaxOnlineOrderQty != 0 &&
        MaxOnlineOrderQty < parseInt(prodqty) + parseInt(aprodqty)
      ) {
        message.error(
          "Please add less than " +
            MaxOnlineOrderQty +
            " items in total to cart"
        );
        return;
      } else if (prodqty > 1000) {
        message.error(
          "You are not allowed to add more than 1000 " +
            ProductName +
            ", if you need more than 1000 please contact us."
        );
        document.getElementById("prodqty" + ProductID).value = 1;
        return;
      }

      basketitem[index]["ProductQty"] =
        parseFloat(basketitem[index]["ProductQty"]) +
        parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem("cart", JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML =
        parseInt(document.getElementById("cartcount").innerHTML) +
        parseInt(aprodqty);

      console.log("x");
    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value;

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error(
          "Please add less than " +
            MaxOnlineOrderQty +
            " items in total to cart"
        );
        return;
      } else if (prodqty > 1000) {
        message.error(
          "You are not allowed to add more than 1000 " +
            ProductName +
            ", if you need more than 1000 please contact us."
        );
        document.getElementById("prodqty" + ProductID).value = 1;
        return;
      }

      document.getElementById("cartcount").innerHTML =
        parseInt(document.getElementById("cartcount").innerHTML) +
        parseInt(prodqty);

      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: JSON.parse(sessionStorage.getItem("menucategories")).find(
          (category) =>
            category.ProductCategory.ProductCategoryID === ProductCategoryID
        ).ProductCategory.VatRate,
      };

      console.log(ProductsDtl);

      console.log(this.state.basketitem);
      this.setState((state) => {
        {
          basketitem: state.basketitem.push(ProductsDtl);
          localStorage.setItem("cart", JSON.stringify(state.basketitem));
        }
      });

      console.log("y");
    }

    setTimeout(() => {
      const cachedCart = localStorage.getItem("cart");
      if (cachedCart) {
        this.setState({ basketitem: JSON.parse(cachedCart) });
      } else {
        const myArray = [];
        this.setState({ basketitem: myArray });
        localStorage.setItem("cart", JSON.stringify(myArray));
      }

      const basketitem = this.state.basketitem;
      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");

      let checker = (arr, target) => target.some((v) => arr.includes(v));

      //console.log(offerItems);

      // console.log(JSON.parse(localStorage.getItem('cart')));

      let op = JSON.parse(localStorage.getItem("cart")).map(
        ({ ProductID }) => ProductID
      );

      var offer = 0;

      if (checker(op, offeri)) {
        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {
          var cindex = JSON.parse(localStorage.getItem("cart")).findIndex(
            (a) => a.ProductID === el
          );
          // console.log(cindex)

          if (cindex !== -1) {
            totalitems =
              totalitems +
              parseFloat(
                JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
              );
            totalamounts =
              totalamounts +
              parseFloat(
                JSON.parse(localStorage.getItem("cart"))[cindex]["ProductQty"]
              ) *
                parseFloat(
                  JSON.parse(localStorage.getItem("cart"))[cindex][
                    "ProductPrice"
                  ]
                );
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
        // console.log('remainder: ' + remainder)
        offer =
          parseFloat(totalamounts - prodprice * remainder) -
          parseFloat(quotient * offerprice);
        // console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(
          (a) => a.ProductID === ProductID
        );

        basketitem[clickedindex]["OfferAmount"] = offer;

        if (totalitems < offerqty) {
          basketitem[clickedindex]["OfferAmount"] = 0;
        }

        this.setState({ basketitem: basketitem });
        localStorage.setItem("cart", JSON.stringify(basketitem));

        console.log(this.state.basketitem);
      } else {
        this.setState({ basketitem: basketitem });
        localStorage.setItem("cart", JSON.stringify(basketitem));
      }
    }, 100);

    console.log(this.state.basketitem);

    message.loading({ content: "Adding...", key });
    setTimeout(() => {
      message.success({ content: "Added to the cart!", key, duration: 2 });
    }, 800);
  };

  render() {
    const onFinish = (values) => {
      const Notifydata = {
        companyPageTitle: config.get("companyPageTitle"),
        SenderEmail: values.email,
        prdtName: document.getElementById("prdt").value,
        companyLogoCDN: config.get("companyLogoCDN"),
        prdtURL: document.getElementById("prdtURL").value,
      };

      const emailBody = renderEmail(NotifyEmail, { lang: "en", Notifydata });
      async function postData() {
        const ecoEmail = {
          to:
            config.get("companyPageTitle") +
            "<" +
            config.get("SenderEmail") +
            ">",
          bcc: "suren.sivalingam@ecologital.com",
          from: values.email,
          subject: "Product Notification Request",
          html: emailBody,
        };

        const response = await fetch(config.get("backendURL") + "mail/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(ecoEmail),
        });
        return response.json();
      }

      postData().then((data) => {
        message.success(
          "Email has been sent to " + config.get("companyPageTitle")
        );
        this.handleCancel();
      });
    };

    const { products } = this.state;

    return (
      <>
        <div className='list'>
          <h2>{this.props.params.ItemHeading}</h2>
        </div>

        {this.state.ploaded ? (
          products ? (
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={responsive}
              keyBoardControl={true}
              customTransition='all .5'
              transitionDuration={500}
              containerClass='carousel-container'
              itemClass='carousel-item-padding-40-px'
            >
              {products.map((product) => (
                <div>
                  <div className='list'>
                    <LazyLoad>
                      <div className='card'>
                        <div md={{ span: 3, offset: 3 }}>
                          {product.Offers[0] ? (
                            product.Offers[0].Offer.OfferType === 3 ? (
                              <>
                                {" "}
                                <span class='mulitistock'>MULTI BUY</span>
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {product.IsOutOfStock ? (
                            <>
                              {" "}
                              <span class='outofstock'>OUT OF STOCK</span>
                            </>
                          ) : (
                            ""
                          )}{" "}
                          <FontAwesomeIcon icon={faHeart} />
                        </div>

                        <a
                          key={"pid" + product.ProductID}
                          href={
                            "/product/" +
                            product.Name.replace(/ /g, "-")
                              .replace("/", "-")
                              .toLowerCase() +
                            "/" +
                            product.ProductID
                          }
                        >
                          {product.OnLineImages.PrimaryImages !== null ? (
                            <div style={{ width: "auto" }}>
                              {/* {this.state.loaded ? null :
                                    <div className="divLoader">
                                      <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
                                      Loading
                                      </div>
                                  } */}
                              <img
                                src={product.OnLineImages.PrimaryImages.Main}
                                alt={product.Name}
                                onLoad={() => this.setState({ loaded: true })}
                                style={
                                  this.state.loaded ? {} : { display: "none" }
                                }
                              />
                              <input
                                type='hidden'
                                id={"prodprimimage" + product.ProductID}
                                value={product.OnLineImages.PrimaryImages.Main}
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src='/images/noimage.jpg'
                                alt={product.Name}
                              />
                              <input
                                type='hidden'
                                id={"prodprimimage" + product.ProductID}
                                value=''
                              />
                            </div>
                          )}
                        </a>

                        <h4>{product.Name}</h4>
                        <p>
                          <b>Item Code:</b>
                          <br /> {product.ProductRefNo}
                        </p>

                        <div className='row'>
                          <div
                            className={
                              product.Discount != 0
                                ? "priceleft col-sm-6"
                                : "priceleft col-sm-12"
                            }
                          >
                            <p className='price'>
                              <sup className='pricex'>
                                {localStorage.getItem("ecurrencyCode")}
                              </sup>
                              <sub>
                                {
                                  String(
                                    product.SellingPricesArray.SellingPrice *
                                      localStorage.getItem("ecurrencyratio")
                                  ).split(".")[0]
                                }
                                <input
                                  type='hidden'
                                  id={"prodprice" + product.ProductID}
                                  value={
                                    product.SellingPricesArray.SellingPrice
                                  }
                                />
                              </sub>
                              <sup>
                                {String(
                                  product.SellingPricesArray.SellingPrice *
                                    localStorage.getItem("ecurrencyratio")
                                ).split(".")[1] != null
                                  ? String(
                                      (
                                        product.SellingPricesArray
                                          .SellingPrice *
                                        localStorage.getItem("ecurrencyratio")
                                      ).toFixed(2)
                                    ).split(".")[1]
                                  : "00"}
                              </sup>
                            </p>
                          </div>
                          {product.Discount != 0 ? (
                            <div className='priceright col-sm-6'>
                              was {localStorage.getItem("ecurrencyCode")}
                              {parseInt(
                                product.SellingPricesArray.SellingPrice
                              ) *
                                parseInt(
                                  localStorage.getItem("ecurrencyratio")
                                ) +
                                parseInt(product.Discount)}{" "}
                              <br />
                              <span className='dispricetxt'>
                                save {localStorage.getItem("ecurrencyCode")}
                                {product.Discount}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <Table striped bordered hover size='sm' width='50%'>
                          <thead>
                            <tr>
                              <th>Pack Size</th>
                              <th>Weight</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product.UnitCharts.length > 0 ? (
                              product.UnitCharts.map((elu) => (
                                <tr>
                                  <td>{elu.Quantity}</td>
                                  <td>{product.Weight}</td>
                                  <td>{elu.UnitTypeName}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>1</td>
                                <td>{product.Weight}</td>
                                <td>EA</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>

                        {product.IsOutOfStock ? (
                          <div className='text-center'>
                            {" "}
                            <p className='inputandbtn'>
                              <button
                                className='notify'
                                onClick={this.onNotify.bind(
                                  this,
                                  product.ProductID,
                                  product.Name,
                                  product.MaxOnlineOrderQty
                                )}
                                id={product.ProductID}
                              >
                                <FontAwesomeIcon icon={faEnvelope} />
                                Notify
                              </button>
                            </p>{" "}
                            <Modal
                              show={this.state.visible}
                              onHide={this.handleCancel}
                              centered
                              aria-labelledby='example-modal-sizes-title-sm'
                            >
                              <Modal.Header closeButton>
                                <Modal.Title id='example-modal-sizes-title-sm'>
                                  Notify
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form
                                  onFinish={onFinish}
                                  name='basic'
                                  initialValues={{ remember: true }}
                                >
                                  <Form.Item
                                    label='Email Address'
                                    name='email'
                                    rules={[{ required: true, type: "email" }]}
                                  >
                                    <Input placeholder='Email Address' />
                                  </Form.Item>

                                  <Form.Item>
                                    <Input
                                      name='prdt'
                                      type='hidden'
                                      id='prdt'
                                      value={product.Name}
                                    />
                                    <Input
                                      name='prdtURL'
                                      type='hidden'
                                      id='prdtURL'
                                      value={window.location.href}
                                    />
                                    <Button
                                      type='primary'
                                      htmlType='submit'
                                      ref='btn'
                                      danger
                                    >
                                      Submit
                                    </Button>
                                  </Form.Item>
                                </Form>
                              </Modal.Body>
                            </Modal>
                          </div>
                        ) : (
                          <p className='inputandbtn'>
                            <NumericInput
                              min={1}
                              max={
                                product.MaxOnlineOrderQty === 0
                                  ? 10000
                                  : product.MaxOnlineOrderQty
                              }
                              precision={0}
                              className='inputqty'
                              id={"prodqty" + product.ProductID}
                              value={1}
                              snap
                            />
                            <button
                              onClick={this.onAddItem.bind(
                                this,
                                product.ProductID,
                                product.Name,
                                product.MaxOnlineOrderQty,
                                product.ProductCategoryID,
                                product.CurrentOffer.ID
                                  ? product.CurrentOffer.OfferProducts.map(
                                      (prt) => prt.ProductID
                                    ).join(",")
                                  : "",
                                product.CurrentOffer.ID
                                  ? product.CurrentOffer.Offer.OfferQty
                                  : "",
                                product.CurrentOffer.ID
                                  ? product.CurrentOffer.Offer.OfferPrice
                                  : ""
                              )}
                              id={product.ProductID}
                            >
                              <FontAwesomeIcon icon={faShoppingCart} />
                              <span>ADD</span>
                            </button>
                          </p>
                        )}
                      </div>
                      <br />
                    </LazyLoad>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <Col sm={12}>
              <br />
              <Skeleton active avatar paragraph={{ rows: 4 }} />
            </Col>
          )
        ) : (
          <Col sm={12}>
            <br />
            <Skeleton active avatar paragraph={{ rows: 4 }} />
          </Col>
        )}
      </>
    );
  }
}

export default Application;
