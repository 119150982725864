import React, { Component } from 'react';
import config from 'react-global-configuration';
import axios from 'axios';

const Page = props => (
  <li> <a href={'/pages/' + props.page.slug}>{props.page.pagetitle}</a></li>
)

export default class footer extends Component {
  constructor(props) {
    super(props);
    const datax = [];
    this.state = {
      datax, menus: [], comid: ''
    };
  }

  componentDidMount() {
    axios.get(config.get('backendURL') + 'pages/byid/' + this.props.params.FooterItemId + '/' + config.get('companyID'))
      .then(response => {
        if (this.props.params.FooterItemId !== undefined)
          this.setState({ datax: response.data, comid: this.props.params.FooterItemId })
      })
      .catch((error) => {
        console.log(error);
      })
  }


  pageList() {
    return this.state.datax.map(currentpage => {
      return <Page page={currentpage} key={currentpage._id} />;
    })
  }


  render() {
    // console.log(this.props.params.MenuItemId)
    if (this.props.params.FooterItemId != this.state.comid) {
      // this.componentDidMount();
    }
    return (
      <ul className="ps-list--link">{this.pageList()}</ul>
    )
  }
}