import { configureStore } from "@reduxjs/toolkit";
import companyDataReducer from "./companyDataSlice";
import resturantDataReducer from './resturantWidgetSlice'
import retailDataReducer from './retailDataSlice'
export default configureStore({
  reducer: {
    companyData: companyDataReducer,
    resturantData: resturantDataReducer,
    retailData: retailDataReducer
  },
});
