import React, { Component } from 'react';
import { Drawer } from 'antd';
import axios from 'axios';
import Carousel from "../default/carousel";
import ItemPicks from "../apparel/itempicks";
import Editor from "../default/editor";
import BannerImage from "../default/bannerimage";
import Video from "../default/video";
import Gallery from "../../lib/gallery/gallery";
import config from 'react-global-configuration';
import Contact from "../../lib/contact"
import Blog from "../default/blogs";

export default class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
       visible:''
      };
  }
  componentDidMount() {
    //console.log(this.props.params.layoutid)
    axios.get(config.get('backendURL')+'homelayout/byid/'+this.props.params.layoutid)
    .then(response => {
     // console.log(response.data)
      this.setState({ datax: response.data})
    })
    .catch((error) => {
      console.log(error);
    })
  }

 
  render() {
    const { visible } = this.state;
    return (
      <>
       
       {
       this.state.datax ? (
           <>     
            {
            this.state.datax.type === 'image' ?
            <BannerImage params={{ItemData:this.state.datax.itemdata,Link:this.state.datax.link, className:'imageClass'}}  />  
            : ''
            }
            {
            this.state.datax.type === 'carousel' ?
            <Carousel params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata}} />
            : ''
            }
            {
            this.state.datax.type === 'bestselling' ?
            <ItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, Type:'bestselling'}}  />
            : ''
            }
             {
            this.state.datax.type === 'featured' ?
            <ItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, Type:'featured'}}  />
            : ''
            }
            {
            this.state.datax.type === 'featuredoffers' ?
            <ItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, Type:'featuredoffers'}}  />
            : ''
            }
            {
            this.state.datax.type === 'offers' ?
            <ItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, Type:'offers'}}  />
            : ''
            }
           {
            this.state.datax.type === 'video' ?
            <Video params={{ ItemHeading: this.state.datax.itemheading,Item: this.state.datax.item, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
            {
            this.state.datax.type === 'editor' ?
            <Editor params={{ id:this.props.params.layoutid,ItemHeading: this.state.datax.itemheading,Item: this.state.datax.item, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
             {
            this.state.datax.type === 'gallery' ?
            <Gallery params={{ id:this.props.params.layoutid,ItemHeading: this.state.datax.itemheading,Item: this.state.datax.item, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
            {
            this.state.datax.type === 'contact' ?
            <Contact params={{ id:this.props.params.layoutid,ItemHeading: this.state.datax.itemheading,Item: this.state.datax.item, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
            {
                this.state.datax.type === 'blog' ?
                  <Blog params={{ id: this.props.params.layoutid, ItemHeading: this.state.datax.itemheading, Item: this.state.datax.item, ItemData: this.state.datax.itemdata }} />
                  : ''
              }
        </>
       ):(<></>)
  }
</>
    );
  }
}


