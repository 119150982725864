import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Checkbox, Icon, Message, Popup, Stack, TextArea, Typography } from 'witmeg-ui-system';
import { getCurrencyFormat, LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from '../../../utilities/helper';
import '../../common/common.css';
import _ from 'lodash';
import { calculateProductTotals, calculateRestaurantMenuCartTotal, getAdditionSubTotals, getRestaurantMenuCartData, RESTAURANT_MENU_ITEM_STATUS } from '../../../utilities/restaurantMenuUtil';
import { useDispatch, useSelector } from 'react-redux';
import { syncRestaurantMenuCartDetails } from '../../../../../redux/resturantWidgetSlice';
import { restaurantCartUtil } from '../../../utilities/restaurantMenuCartUtil';
import "@fontsource/urbanist";
import "@fontsource/plus-jakarta-sans";
import ConfirmContinueShopping from './ConfirmContinueShopping';

const UpdateCartPopup = ({ showPopup, data, onPopupClose, editCartData }) => {

  const dispatch = useDispatch();

  const restaurantDataState = useSelector((state) => state.resturantData);

  const selectedOrderTypeOriginal = restaurantDataState.orderSettings.urlOrder?.orderType ?? restaurantDataState.orderSettings.settingsDefaultOrder?.orderType;
  const selectedOrderType = selectedOrderTypeOriginal.replace("table", "dineIn")
  const autoShowAddons = data.autoShowAddons ?? false;

  const currencyFormat = getCurrencyFormat(restaurantDataState.currency);

  const [selectedItemData, setSelectedItemData] = useState(()=> {
    if(editCartData && editCartData.data) return editCartData.data;

    return {
      productID: data.productID,
      name: data.name,
      prices: data.prices,
      addons: {
        items: [],
        subTotal: null,
      },
      unitChartItem: null,
      quantity: 1,
      vat: data.vat,
      subTotal: null,
      total: null,
      customerInstructions: '',
      groupID: data.groupID,
    };
  });

  const [timerPopupVisible, SetTimerPopupVisible] = useState(false);
  const [popupState, setPopupState] = useState({
    error:{
      isError: false,
      message: '',
      description: '',
    }
  });

  const onCustomerInstructionsTextChange = (_text) => {
    setSelectedItemData(prevState => ({...prevState, customerInstructions: _text ?? ''}));
  }

  useEffect(() => {
    if (!editCartData && (data && !data.unitChartData)) {
      setSelectedItemData((prevState) => {
        const _vat = data.vat;
        const _prices = data.prices;

        const _subTotal = {
          delivery: getProductTotalCalculations(_prices.delivery, 1, _vat, _vat.deliveryVat.rate),
          collection: getProductTotalCalculations(_prices.collection, 1, _vat, _vat.collectionVat.rate),
          dineIn: getProductTotalCalculations(_prices.dineIn, 1, _vat, _vat.dineInVat.rate),
        };

        const _productData = {
          vat: _vat,
          prices: _prices,
          subTotal: _subTotal,
          total: _subTotal,
          quantity: 1,
        };

        return {
          ...prevState,
          ..._productData,
        };
      });
    }
  }, []);

  const onCartUpdateClick = async () => {
    debugger;
    let _selectedProductData = selectedItemData;
    let _isCartProductEditable = editCartData;

    if (data.unitChartData && data.unitChartData.length > 0 && (!_selectedProductData || !_selectedProductData.unitChartItem)) {
      setPopupState({
        error: {
          isError: true,
          message: 'Item is not selected!',
          description: 'You need select one item to Add to Cart.',
        },
      });
      return;
    } else {
      setPopupState({
        error: {
          isError: false,
          message: '',
          description: '',
        },
      });
    }

    let cartData = await getRestaurantMenuCartData();

    // const _cartItemIndex = _.findIndex(cartData.items, {
    //   product: {
    //     productID: data.productID,
    //     localProductID: data.localProductID,
    //   },
    // });
    // const _sameCartItems = _.filter(cartData.items, {
    //   product: {
    //     productID: data.productID,
    //     localProductID: data.localProductID,
    //   },
    // });

    let _sameCartItems = [];
    for(const __itemIndex in cartData.items) {
      if(!cartData.items[__itemIndex].product) break;

      if((cartData.items[__itemIndex].product.productID === data.productID) && (cartData.items[__itemIndex].product.localProductID === data.localProductID)) {
        _sameCartItems.push({ cartIndex: __itemIndex, data: cartData.items[__itemIndex] });
      }
    }

    let _isCartItemAvailable = null;
    const _isAddonsAvailable = (_selectedProductData.addons && _selectedProductData.addons.items && _selectedProductData.addons.items.length > 0) ? true : false;
    const _isUnitChartAvailable = _selectedProductData.unitChartItem ? true : false;

    if (_sameCartItems.length > 0) {
      for (const __item of _sameCartItems) {
        const __currentProductHasAddons = (__item.data.selection.addons && __item.data.selection.addons.items && __item.data.selection.addons.items.length > 0) ? true : false;
        const __currentProductHasUnitChart = __item.data.selection.unitChartItem ? true : false;

        if((_isAddonsAvailable !== __currentProductHasAddons) || (_isUnitChartAvailable !== __currentProductHasUnitChart)) continue;

        if(_isAddonsAvailable) {
          if(__item.data.selection.addons.items.length !== _selectedProductData.addons.items.length) continue;

          let ___isAddonsSame = 0;
          for(const ____value of __item.data.selection.addons.items){
            const ____isAvailable = _.find(_selectedProductData.addons.items, { addonID: ____value.addonID, unitChartTypeID: ____value.unitChartTypeID });
            if(!____isAvailable) break;

            ___isAddonsSame += 1; 
          }
          if(_selectedProductData.addons.items.length !== ___isAddonsSame) continue;
        }

        if (_isUnitChartAvailable) {
          if(_selectedProductData.unitChartItem.unitChartTypeID !== __item.data.selection.unitChartItem.unitChartTypeID) continue;
        }

        _isCartItemAvailable = __item.data;
        _isCartProductEditable = { selectedIndex: __item.cartIndex };
        break;
      }
    }

    if (!editCartData && _isCartItemAvailable) {
      const _isProductHasAddons = (_selectedProductData.addons && _selectedProductData.addons.items && _selectedProductData.addons.items.length > 0) ? true : false;

      const _newProductQuantity = _selectedProductData.quantity + _isCartItemAvailable.selection.quantity;
      const _newProductData = restaurantCartUtil.changeProductQuantity({
        newQuantity: _newProductQuantity,
        prices: _selectedProductData.prices,
        vat: _selectedProductData.vat
      });

      let _newTotal = _newProductData.subTotal;
      let _newAddonsData = null;

      if(_isProductHasAddons) {
        let ___newAddonItems = [..._selectedProductData.addons.items];

        for (const __item of _isCartItemAvailable.selection.addons.items) {
          let __quantity = __item.quantity;
          const __availableItemIndex = _.findIndex(___newAddonItems, { productID : __item.productID });

          if(__availableItemIndex >= 0) {
            __quantity += ___newAddonItems[__availableItemIndex].quantity;
            const __updatedAddonData = restaurantCartUtil.generateAddonItemData({
              productData: __item,
              quantity: __quantity,
              unitChartTypeID: __item.unitChartTypeID ?? null,
            });

            ___newAddonItems[__availableItemIndex] = __updatedAddonData;
          } else {
            const __newAddonData = restaurantCartUtil.generateAddonItemData({
              productData: __item,
              quantity: __quantity,
              unitChartTypeID: __item.unitChartTypeID ?? null,
            });

            ___newAddonItems.push(__newAddonData);
          }
        }

        _newAddonsData = restaurantCartUtil.combineAddonItems(___newAddonItems);
      }

      _newTotal = getAdditionSubTotals(_newTotal, _newAddonsData?.subTotal ?? 0);
      _selectedProductData = {
        ..._selectedProductData,
        ..._newProductData,
        addons: _isProductHasAddons ? _newAddonsData : _selectedProductData.addons,
        total: _newTotal,
      };

      if((editCartData && editCartData.data && editCartData.selectedIndex >= 0) && (editCartData.selectedIndex !== _isCartProductEditable.selectedIndex)){
        const _newCartItems = cartData.items.filter((_, _index) => _index !== editCartData.selectedIndex);
        cartData = { ...cartData, items: _newCartItems };
      }
    }

    // if ((!editCartData || !editCartData.data) && _cartItemIndex >= 0) {

    //   const _cartItem = cartData.items[_cartItemIndex];

    //   const _isUnitChartItem = _selectedProductData.unitChartItem ? true : false;

    //   const _isSelectedProductHasAddons = (_selectedProductData.addons && _selectedProductData.addons.items && _selectedProductData.addons.items.length > 0) ? true : false;
    //   const _isCartProductHasAddons = (_cartItem.selection.addons && _cartItem.selection.addons.items && _cartItem.selection.addons.items.length) ? true : false;
    //   const _isProductHasAddons = _isSelectedProductHasAddons || _isCartProductHasAddons;

    //   const _isSameUnitChartItem = _isUnitChartItem && (_selectedProductData.unitChartItem.unitChartTypeID === _cartItem.selection.unitChartItem.unitChartTypeID) ? true : false;

    //   let _isUpdatable = !_isUnitChartItem || (_isUnitChartItem && _isSameUnitChartItem) ? true : false;

    //   if(_isUpdatable) {
    //     _isCartProductEditable = { selectedIndex: _cartItemIndex };

    //     const _newProductQuantity = _selectedProductData.quantity + _cartItem.selection.quantity;
    //     const _newProductData = restaurantCartUtil.changeProductQuantity({
    //       newQuantity: _newProductQuantity,
    //       prices: _selectedProductData.prices,
    //       vat: _selectedProductData.vat
    //     });

    //     let _newTotal = _newProductData.subTotal;

    //     let _newAddonsData = null;
        
    //     if(_isProductHasAddons) {
    //       let ___newAddonItems = [];

    //       if(_isSelectedProductHasAddons && !_isCartProductHasAddons) {
    //         ___newAddonItems = _selectedProductData.addons.items;
    //       } else if(!_isSelectedProductHasAddons && _isCartProductHasAddons) {
    //         ___newAddonItems = _cartItem.selection.addons.items;
    //       } else {
    //         ___newAddonItems = [..._selectedProductData.addons.items];

    //         for (const __item of _cartItem.selection.addons.items) {
    //           let __quantity = __item.quantity;
    //           const __availableItemIndex = _.findIndex(___newAddonItems, { productID : __item.productID });

    //           if(__availableItemIndex >= 0) {
    //             __quantity += ___newAddonItems[__availableItemIndex].quantity;
    //             const __updatedAddonData = restaurantCartUtil.generateAddonItemData({
    //               productData: __item,
    //               quantity: __quantity,
    //               unitChartTypeID: __item.unitChartTypeID ?? null,
    //             });

    //             ___newAddonItems[__availableItemIndex] = __updatedAddonData;
    //           } else {
    //             const __newAddonData = restaurantCartUtil.generateAddonItemData({
    //               productData: __item,
    //               quantity: __quantity,
    //               unitChartTypeID: __item.unitChartTypeID ?? null,
    //             });
  
    //             ___newAddonItems.push(__newAddonData);
    //           }

    //         }
    //       }

    //       _newAddonsData = restaurantCartUtil.combineAddonItems(___newAddonItems);
    //       _newTotal = getAdditionSubTotals(_newTotal, _newAddonsData.subTotal);
    //     }

    //     _selectedProductData = {
    //       ..._selectedProductData,
    //       ..._newProductData,
    //       addons: _isProductHasAddons ? _newAddonsData : _selectedProductData.addons,
    //       total: _newTotal,
    //     };
    //   }
    // }


    if (_isCartProductEditable) {
      let cartItems = cartData.items;
      cartItems[_isCartProductEditable.selectedIndex] = { product: data, selection: _selectedProductData };
      cartData = { ...cartData, items: cartItems };
    } else {
      let newCartItem = { product: data, selection: _selectedProductData };
      cartData = { ...cartData, items: [...cartData.items, newCartItem] };
    }

    cartData = { ...cartData, currency: restaurantDataState.currency };

    cartData = calculateRestaurantMenuCartTotal(cartData);

    await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, cartData);

    dispatch(syncRestaurantMenuCartDetails());

    Message.success(`${_selectedProductData.name}${_selectedProductData.unitChartItem ? ` (${_selectedProductData.unitChartItem.typeName})` : ''} ${_isCartProductEditable ? 'updated' : 'added'} to the cart!`);
  
    onPopupClose();
    // SetTimerPopupVisible(true);
  };

  const getProductTotalCalculations = (_price, _qty, _vat, _otVat) => {
    return calculateProductTotals(_price, _qty, _vat.isGeneralVat ? _vat.generalVat.rate : _otVat, _vat.isInclusive);
  }

  const initProductItemData = ({ selectedUnitChartItemData = null, selectedAddonItemData = null, prevState = null, unitChartQuantity = 1, addonQuantity = 1 }) => {
    // if(!prevState) return null;

    let _currentChartItem = prevState.unitChartItem;
    let _addonsData = null;
    let _itemData = {};
    // let _unitChartQuantity = _unitChartQuantity;
    // let _addonQuantity = _addonQuantity;

    /* 
        if unit chart not available use product vat rate
                    or use unit chart vat rate
    */
    if (selectedUnitChartItemData) {
      if (!_currentChartItem || (_currentChartItem && selectedUnitChartItemData.unitChartTypeID !== _currentChartItem.unitChartTypeID)) {
        _itemData = restaurantCartUtil.generateUnitChartItemData({ productData: selectedUnitChartItemData });
      }
    }

    if (selectedAddonItemData) {
      const _newAddonItem = restaurantCartUtil.generateAddonItemData({
        productData: selectedAddonItemData,
        unitChartTypeID: selectedUnitChartItemData?.unitChartTypeID ?? null,
      });

      _addonsData = restaurantCartUtil.combineAddonItems(
        prevState.addons?.items
          ? [_newAddonItem, ...prevState.addons.items]
          : [_newAddonItem]
      );
    }

    let _total = _itemData.subTotal ? _itemData.subTotal : prevState.subTotal;
    if (_addonsData && _addonsData.items && _addonsData.items.length > 0) {
      _total = getAdditionSubTotals(_total, _addonsData.subTotal);
    }

    return {
      ...prevState,
      ..._itemData,
      total: _total,
      addons: _addonsData,
    };
  }

  const setSelectedProductItemData = ({ selectedUnitChartItemData = null, selectedAddonItemData = null }) => {
    setSelectedItemData((prevState) => initProductItemData({ selectedUnitChartItemData, selectedAddonItemData, prevState }));
  };

  const onUnitChartItemSelect = (selectedUnitChartItemData) => {
    /* 
        - check for currently selected unit chart item
            if they are same, do nothing
            if not mark as selected
                clear previous unit chart item from selected addons list
    */
    if (selectedItemData && selectedItemData.unitChartItem && selectedItemData.unitChartItem.unitChartTypeID === selectedUnitChartItemData.unitChartTypeID) return;

    setSelectedProductItemData({ selectedUnitChartItemData });
  };

  const onAddonItemSelectChange = (currentValue, selectedAddonItemData, selectedUnitChartItemData) => {
    /* 
        - if currentValue = true then remove addon item to the list (deselect the item)
        - if currentValue = false then add addon item to the list (select the item)
    */
    if (currentValue) {
      setSelectedItemData((prevState) => {
        const _newAddonsList = prevState.addons.items.filter((_item) => _item.productID !== selectedAddonItemData.productID);
        const _newAddonsData = _newAddonsList.length === 0 ? null : restaurantCartUtil.combineAddonItems(_newAddonsList);

        let _newTotal = prevState.subTotal;
        if (_newAddonsList && _newAddonsList.length > 0) {
          _newTotal = getAdditionSubTotals(_newTotal, _newAddonsData.subTotal);
        }

        return { ...prevState, addons: _newAddonsData, total: _newTotal };
      });
      return;
    }

    setSelectedProductItemData({ selectedAddonItemData, selectedUnitChartItemData });
  };

  const onAddonItemQuantityChange = (selectedAddonData, currentQuantity, increase = true) => {
    setSelectedItemData((prevState) => {
      let _newQuantity = currentQuantity;
      if (!increase && _newQuantity <= 1) return prevState;

      if (increase) _newQuantity += 1;
      if (!increase) _newQuantity -= 1;

      let _newAddonsItems = prevState.addons.items;

      if (selectedAddonData.unitChartTypeID) {
        _newAddonsItems = _newAddonsItems.map((_i) => {
          if (_i.productID === selectedAddonData.productID && _i.unitChartTypeID === selectedAddonData.unitChartTypeID) {
            return {
              ..._i,
              ...restaurantCartUtil.changeProductQuantity({
                vat: _i.vat,
                prices: _i.prices,
                newQuantity: _newQuantity,
              }),
            }
          } else {
            return _i;
          }
        });
      } else {
        _newAddonsItems = _newAddonsItems.map((_i) => {
          if (_i.productID === selectedAddonData.productID) {
            return {
              ..._i,
              ...restaurantCartUtil.changeProductQuantity({
                vat: _i.vat,
                prices: _i.prices,
                newQuantity: _newQuantity,
              }),
            }
          } else {
            return _i;
          }
        });
      }

      const _newAddonsData = restaurantCartUtil.combineAddonItems(_newAddonsItems);

      let _newTotal = getAdditionSubTotals(prevState.subTotal, _newAddonsData.subTotal);

      return { ...prevState, addons: _newAddonsData, total: _newTotal };
    });
  };

  const onProductItemQuantityChange = (increase = true) => {
    setSelectedItemData((prevState) => {
      let _newQuantity = prevState.quantity;
      if (!increase && _newQuantity <= 1) return prevState;

      if (increase) _newQuantity += 1;
      if (!increase) _newQuantity -= 1;

      const _newProductData = restaurantCartUtil.changeProductQuantity({
        newQuantity: _newQuantity,
        prices: prevState.prices,
        vat: prevState.vat,
      });

      let _newTotal = _newProductData.subTotal;
      if (prevState.addons && prevState.addons.items && prevState.addons.items.length > 0) {
        _newTotal = getAdditionSubTotals(_newProductData.subTotal, prevState.addons.subTotal);
      }

      return {
        ...prevState,
        total: _newTotal,
        ..._newProductData,
      };
    });
  };

  const onTimerPopupClose = () => {
    SetTimerPopupVisible(false);
    onPopupClose();
  }

  return (
    <>
      <Popup width={460} className="eco-order-item-popup"  modalVisible={showPopup} modalTitle={data.name ?? ''} mask={true} onCancel={onPopupClose}>
        <div style={{ width: '100%' }}>
          <Typography type="title" level={3} pattern="bold" className="eco-order-item-price">
            {currencyFormat(selectedItemData.prices[selectedOrderType] ?? 0)}
            {/* <p>x {selectedItemData.quantity}</p> */}
          </Typography>
          <Typography  type="text" className='eco-order-item-desc'>{data.description}</Typography>
          {data.status !== RESTAURANT_MENU_ITEM_STATUS.NO_ADDITIONAL_DATA && (
            // <Accordion theme="default" type="content_only" title="Add on 1" collapsed={true}>
              <div style={{ width: '100%' }} className='eco-accordion' >
                {(data.status === RESTAURANT_MENU_ITEM_STATUS.UNIT_CHART_WITH_ADDONS_DATA || data.status === RESTAURANT_MENU_ITEM_STATUS.ONLY_UNIT_CHART_DATA) &&
                  data.unitChartData.map((ucItem) => {
                    const __ucItemData = selectedItemData && selectedItemData.unitChartItem && selectedItemData.unitChartItem.unitChartTypeID === ucItem.unitChartTypeID ? selectedItemData.unitChartItem : null;
                    const __isUcItemSelected = __ucItemData ? true : false;
                    const __ucItemQuantity = __ucItemData ? selectedItemData.quantity : 1;
                    // const __ucItemPrice = __ucItemData ? selectedItemData.subTotal[selectedOrderType].amount : ucItem.prices[selectedOrderType];
                    const __ucItemPrice = ucItem.prices[selectedOrderType];

                    return (
                      <>
                        {/* Unit chart items */}
                        <Stack
                          horizontalAlign="space_between"
                          className={__isUcItemSelected ? 'item selected-item eco-accordion-item' : 'item eco-accordion-item'}
                          onClick={() => onUnitChartItemSelect(ucItem)}>

                          <Typography type="text" className='eco-accordion-item-title'>{ucItem.typeName}</Typography>
                          <Stack className="eco-accordion-item-adding">
                            {/* <div className='eco-accordion-item-minus' onClick={() => onProductItemQuantityChange(false)}>
                              <Icon name="minus" size={16}  />
                            </div> */}
                            <div className='eco-accordion-item-count'>
                              <input className="input-order-amount" value={__ucItemQuantity} readOnly />
                            </div>
                            {/* <div className='eco-accordion-item-plus' onClick={() => onProductItemQuantityChange()}>
                              <Icon name="plus" size={16} />
                            </div> */}
                          </Stack>
                          <Typography type="text" pattern="bold">
                            {currencyFormat(__ucItemPrice)}
                          </Typography>
                        </Stack>

                        {/* Unit Chart Items addons */}
                        {data.status === RESTAURANT_MENU_ITEM_STATUS.UNIT_CHART_WITH_ADDONS_DATA && ucItem.addonsData && ucItem.addonsData.length > 0 && (__isUcItemSelected || autoShowAddons) && (
                            <Stack direction="column" className="eco-mb-24 eco-pa-12 eco-addon-section" verticalAlign="v_start">
                              <Typography type="text" className="eco-mb-16 eco-mt-16" alignment="left" pattern="bold">
                              Add on
                              </Typography>
                              {ucItem.addonsData.map((addonItem) => {
                                const __addonItemData = selectedItemData && selectedItemData.addons && selectedItemData.addons.items && selectedItemData.addons.items.length > 0
                                      ? _.find(selectedItemData.addons.items, { productID: addonItem.productID, unitChartTypeID: ucItem.unitChartTypeID }) : null;
                                const __isAddonItemSelected = __addonItemData ? true : false;
                                const __addonItemQuantity = __addonItemData ? __addonItemData.quantity : 0;
                                // const __addonPrice = __addonItemData ? __addonItemData.subTotal[selectedOrderType].amount : addonItem.prices[selectedOrderType];
                                const __addonPrice = addonItem.prices[selectedOrderType];

                                return (
                                  <Stack horizontalAlign="space_between" className="eco-mb-12 eco-accordion-addon">
                                    <div >
                                    <Stack verticalAlign="v_center" >
                                      <Checkbox
                                        className="eco-mr-8"
                                        checked={__isAddonItemSelected}
                                        onChange={(e) => onAddonItemSelectChange(__isAddonItemSelected, addonItem, ucItem)}/>
                                      <Typography type="text" alignment="left" onClick={() =>  onAddonItemSelectChange(__isAddonItemSelected, addonItem, ucItem)}>
                                        {addonItem.name}
                                      </Typography>
                                    </Stack>
                                    <Typography type="text" pattern="bold" className="eco-accordion-addon-price">
                                      {currencyFormat(__addonPrice)}
                                    </Typography>
                                    </div>

                                    <Stack>
                                      {
                                        __addonItemQuantity !== 0 && (
                                          <div className="eco-accordion-addon-mins" onClick={() =>
                                              __isAddonItemSelected
                                                ? onAddonItemQuantityChange({ productID: addonItem.productID, unitChartTypeID: ucItem.unitChartTypeID }, __addonItemQuantity, false)
                                                : {}
                                            }>
                                            <Icon name="minus" size={16} />
                                          </div>
                                        )
                                      }
                                      <div className="eco-accordion-addon-count">
                                        <input className="input-order-amount" value={__addonItemQuantity} readOnly />
                                      </div>
                                      <div className="eco-accordion-addon-plus" onClick={() =>
                                          __isAddonItemSelected
                                            ? onAddonItemQuantityChange({ productID: addonItem.productID, unitChartTypeID: ucItem.unitChartTypeID }, __addonItemQuantity)
                                            : {}
                                        }>
                                        <Icon name="plus" size={16} />
                                      </div>
                                    </Stack>

                                  </Stack>
                                );
                              })}
                            </Stack>
                          )}
                      </>
                    );
                  })}

                {/* Addons data that doesn't have Unit Chart Items */}
                {data.status === RESTAURANT_MENU_ITEM_STATUS.ONLY_ADDONS_DATA && (
                  <Stack direction="column" className="eco-mb-24 eco-ml-12" verticalAlign="v_start">
                    <Typography type="text" className="eco-mb-8" alignment="left" pattern="bold">
                      Add on
                    </Typography>
                    {data.addonsData.map((addonItem) => {
                      const __addonItemData = selectedItemData && selectedItemData.addons && selectedItemData.addons.items && selectedItemData.addons.items.length > 0
                              ? _.find(selectedItemData.addons.items, { productID: addonItem.productID }) : null;
                      const __isAddonItemSelected = __addonItemData ? true : false;
                      const __addonItemQuantity = __addonItemData ? __addonItemData.quantity : 0;
                      // const __addonPrice = __addonItemData ? __addonItemData.subTotal[selectedOrderType].amount : addonItem.prices[selectedOrderType];
                      const __addonPrice = addonItem.prices[selectedOrderType];

                      return (
                        <Stack horizontalAlign="space_between" className="eco-mb-12 eco-accordion-addon">
                          <div >
                          <Stack verticalAlign="v_center">
                            <Checkbox
                              className="eco-mr-8"
                              checked={__isAddonItemSelected}
                              onChange={(e) => onAddonItemSelectChange(__isAddonItemSelected, addonItem, null)} />
                            <Typography type="text" alignment="left" onClick={() => onAddonItemSelectChange(__isAddonItemSelected, addonItem, null)}>
                              {addonItem.name}
                            </Typography>
                          </Stack>
                          <Typography type="text" pattern="bold" className="eco-accordion-addon-price">
                            {currencyFormat(__addonPrice)}
                          </Typography>
                          </div>
                          <Stack>
                            {
                              __addonItemQuantity !== 0 && (
                                <div className="eco-accordion-addon-mins" onClick={() =>
                                    __isAddonItemSelected
                                      ? onAddonItemQuantityChange({ productID: addonItem.productID }, __addonItemQuantity, false)
                                      : {}
                                  }>
                                  <Icon name="minus" size={16} />
                                </div>
                              )
                            }
                            <div className="eco-accordion-addon-count">
                              <input className="input-order-amount" value={__addonItemQuantity} readOnly />
                            </div>
                            <div className="eco-accordion-addon-plus" onClick={() =>
                                __isAddonItemSelected
                                  ? onAddonItemQuantityChange({ productID: addonItem.productID }, __addonItemQuantity)
                                  : {}
                              }>
                              <Icon name="plus" size={16} />
                            </div>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </div>
            //  </Accordion> 
          )}

          {/* Special Instruction text box */}
          <TextArea
            className="eco-mb-24"
            placeholder="Note to Restaurant (optional)"
            value={selectedItemData.customerInstructions}
            onChange={(e) => onCustomerInstructionsTextChange(e.target.value)} />

          {/* Quantity changer for non Unit chart items */}
          {/* {(data.status === RESTAURANT_MENU_ITEM_STATUS.NO_ADDITIONAL_DATA || data.status === RESTAURANT_MENU_ITEM_STATUS.ONLY_ADDONS_DATA) && (
              <Stack className="eco-mb-32 eco-order-item-qty" verticalAlign="h_center">
                <div className="eco-order-item-qty-ded" onClick={() => onProductItemQuantityChange(false)}>
                  <Icon name="minus" size={24} />
                </div>
                <div className="eco-order-item-qty-count">
                  <input className="input-order-amount" value={selectedItemData.quantity} readOnly />
                </div>
                <div className="eco-order-item-qty-inc" onClick={() => onProductItemQuantityChange()}>
                  <Icon name="plus" size={24} />
                </div>
              </Stack>
          )} */}

          {/* Total Price [(quantity x product item) + addons total] */}
          {/* <Stack
            horizontalAlign="space_between"
            className="eco-mb-36 eco-mt-24"
            style={{ border: '1px solid #C6C7D2', borderRadius: '4px', padding: '30px 20px' }}>
            <Typography type="text" pattern="bold">Price:</Typography>
            <Typography type="text" pattern="bold">
              <span style={{ backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '8px' }}>
                {currencyFormat(selectedItemData.total ? selectedItemData.total[selectedOrderType].amount : 0)}
              </span>
            </Typography>
          </Stack> */}

          <Button className="eco-mb-24 eco-add-to-cart" fullWidth variant="primary" onClick={onCartUpdateClick}>
            {editCartData ? 'Update cart ' : 'Add  to cart '}  
            {console.log("selectedOrderType ===>>", selectedOrderType)}
            {console.log("selectedItemData.total ===>>", selectedItemData.total)}

            &nbsp;&nbsp; {currencyFormat(selectedItemData.total ? selectedItemData.total[selectedOrderType]?.amount : 0)}
            </Button>
          {
            popupState.error.isError && (
              <div className="eco-mb-20">
                <Alert message={popupState.error.message} description={popupState.error.description} showIcon type="error" />
              </div>
            )
          }

        </div>
      </Popup>
      <ConfirmContinueShopping visibility={timerPopupVisible} onPopupClose={onTimerPopupClose} />
    </>
  );
};

export default UpdateCartPopup;