import React, { Component } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import PayVisa from './images/pay_visa.png';
import PayMaster from './images/pay_master.png';
import PayAmex from './images/pay_amex.png';
import PayStripe from './images/pay_stripe.png';
import PayPal from './images/pay_ppl.png';
 
export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {

    var primaryTextStyle = "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle = "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle = "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

    // console.group("Work with us!");
    // console.log("%cEcologital%c is hiring.", primaryTextStyle, tertiaryTextStyle);
    // console.log("%c Go to%c https://www.ecologital.com %cto apply!", tertiaryTextStyle, secondaryTextStyle, tertiaryTextStyle);
    // console.groupEnd();
 
      fetch(config.get('backendURL') + 'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  render() {
    return (
<>

<div className="section pg-footer">
        
        <div className="container">

                <div className='row'>

                    <div className='col-sm-12 col-lg-4'>

                        <div className='brand-wrapper'>

                         
                            <div className='addr-wrap'>
                                <h6>{this.state.Name ? this.state.Name : ''}</h6>
                                <div className='addr-lines'>
                                   
                                   
                                {this.state.AddressLine1 ? (<span> {this.state.AddressLine1} </span>) : ''}
          {this.state.AddressLine2 ? (<span> {this.state.AddressLine2} </span>) : ''}
          {this.state.City ? (<span> {this.state.City} </span>) : ''}
          {this.state.StateProvinceRegion ? (<span> {this.state.StateProvinceRegion} </span>) : ''}
          {this.state.PostZipCode ? (<span> {this.state.PostZipCode} </span>) : ''}
          {this.state.Country ? (<span> {this.state.Country} </span>) : ''} 
          {this.state.Fax ? (<span> <b>Fax</b> : {this.state.Fax} </span>) : ''} 
          {this.state.Email ? (<span> <b>Email</b> : {this.state.Email} </span>) : ''} 
          
           
                                </div> 
                            </div>

                        </div>
                        
                    </div>

                    <div className='col-sm-12 col-lg-4'>

                        <div className='brand-wrapper'>
                            <div className='addr-wrap'>
                                <h6>Call Us</h6>
                                <div className='addr-lines'>
                                    <span>Hotline: {this.state.Phone}</span>
                                    <span>WhatsApp: {this.state.Phone}</span>
                                </div> 
                            </div>
                        </div>

                    </div>

                    <div className='col-sm-12 col-lg-4'>

                        <div className='brand-wrapper'>
                            <div className='addr-wrap'>
                                <h6>Business Hours</h6>
                                <div className='addr-lines'>
                                    <span>{this.state.OpenningHours ? (<span>{this.state.OpenningHours} </span>) : ''} </span>
                                   
                                </div> 
                            </div>
                        </div>

                    </div>

                </div>

                <div className='row'>
                    <div className='col-sm-12 col-md-8'>

                        <div className="social-icons">
                            <a href="#" className='pl0'><i className="fa-brands fa-facebook"></i></a>
                            <a href="#"><i className="fa-brands fa-twitter"></i></a>
                            <a href="#"><i className="fa-brands fa-instagram"></i></a>
                            <a href="#"><i className="fa-brands fa-linkedin"></i></a>
                        </div>

                    </div>

                    <div className='col-sm-12 col-md-4'>

                        <div className='pay_cards'>
                            <div className="payment-icons">
                                <span className='pl0'>
                                    <img src={PayVisa} className="img-fluid" alt="Payment Visa" />
                                </span>
                            </div>
                            <div className="payment-icons">
                                <span className='pl0'>
                                    <img src={PayMaster} className="img-fluid" alt="Payment Master" />
                                </span>
                            </div>

                            <div className="payment-icons">
                                <span className='pl0'>
                                    <img src={PayAmex} className="img-fluid" alt="Amex" />
                                </span>
                            </div>
                            
                            <div className="payment-icons">
                                <span className='pl0'>
                                    <img src={PayStripe} className="img-fluid" alt="Stripe" />
                                </span>
                            </div>

                            <div className="payment-icons">
                                <span className='pl0'>
                                    <img src={PayPal} className="img-fluid" alt="PayPal" />
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='copy'>
            
                    <span className='copy-text'>© 2022 {this.state.Name ? this.state.Name : ''}. All Rights Reserved.</span>

                    <div className='copy-links'>
                        <a href="#">Terms of Use</a>
                        <span className='copy-sep'>|</span>
                        <a href="#">Privacy Policy</a>
                        <span className='copy-sep'>|</span>
                        <a href="#">FAQ</a>
                    </div>

                </div>

        </div>

        <div className='help'>
            <div className='help-cover'>
                <span><i class="fa-solid fa-circle-question"></i></span>
                <p>Need Help</p>
            </div>
        </div>

        <div className='top-up'>
            <div className='icon-up'>
                <i class="fa-solid fa-arrow-up"></i>
            </div>
        </div>

    </div>


    
 
      </>
    );
  }
}