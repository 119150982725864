// import lodash from "lodash";
// import { APIError, errorLogger } from "../errorHandlers";
// import apiEndpointPaths from "../apiEndpointPaths";
import axios from "axios";
import {
  generateEntitlementRegistry,
  generateProdToken,
  generateRetailPaxToken,
  generateSalesRegistryToken,
  generateTokenForEmailRegistry,
} from "./tokenGenerators";

//Get all Companies
// export async function getAllCompaniesData(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllCompanies;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // Create Companies
// export async function createCompany(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.createCompany;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // Get Company By ID
// export async function getCompanyByID(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyById;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Edit Company by ID
// export async function editCompanyById(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.editCompanyById;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // Delete Companies
// export async function deleteCompany(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.deleteCompanyById;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Get Company By OrgID
// export async function getCompanyByOrgID(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyByOrgID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Site Publish Un Publish
// export async function sitePublishUnPublish(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.sitePublish;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Update Company Products
// export async function updateCompanyByProduct(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl =
//       apiEndpointPaths.witmegRetailPaxServer.updateCompanyProducts;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // GET ALL HOME LAYOUTS
// export async function getAllHomeLayouts(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllHomeLayouts;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //GET HOME LAYOUT BY PAGE ID
// export async function getHomeLayoutByPageID(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl =
//       apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutbyPageID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // GET ALL HOME LAYOUTS BY COMPANY ID
// export async function getHomeLayoutByCOMID(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl =
//       apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutByCOMID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // CREATE  HOME LAYOUT
// export async function addHomeLayout(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.addHomeLayout;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // CREATE  HOME LAYOUT
// export async function publishHomelayout(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.publishHomelayout;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = " Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // UPDATE  HOME LAYOUT
// export async function updateHomeLayout(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.updateHomeLayout;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //GET HOME LAYOUT BY ID
// export async function getHomeLayoutByID(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutbyID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // DELETE  HOME LAYOUT
// export async function deleteHomeLayout(reqBody = {}, options = {}) {

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.removeHomeLayout;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // GET ALL WIDGETS
// export async function getAllWidgets(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllWidgets;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // E COMMERCE WIDGETS
// // GET ALL WIDGETS
// export async function getAllProductDetails(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateProdToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.fetchProductList;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // GET  REQUEST BODY
// export async function getRequestBody(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateProdToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.fetchRequest;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const Result = resObj.data.productcategories;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //E COMMERCE OVERLAY
// export async function ecommerceWidgets(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateProdToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl =
//       apiEndpointPaths.witmegRetailPaxServer.fetchEcommerWidgets;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const Result = resObj.data.Result;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // Currency
// export async function getCurrency(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateSalesRegistryToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCurrencies;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const Result = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // GET ALL PAGES
// export async function getAllPages(reqBody = {}, options = {}) {
//   // console.log("I AM API");

//   // console.log(getCompanyByID);

//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllPages;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Create Pages
// export async function createPages(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.createNewPage;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Update Page By ID
// export async function updatePageByID(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.updatePage;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// // getPageByMenuID
// export async function getPageByMenuID(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getPageByMenuID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Delete Page By ID
// export async function deletePageByID(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.deletePage;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// export async function getSubPagesByParentPageID(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     // console.log("reqBody");
//     // console.log(reqBody.ID);
//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getSubPagesByParentPageID;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }

//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// //Themes
// //Get All Themes

// export async function getAllThemes(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateRetailPaxToken();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllThemes;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });

//     const { Result } = resObj.data;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }


// // Get Entitlement list
// export async function getEntitlmentPlan(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateEntitlementRegistry();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getEntitlmentPlan;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "POST",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });


//     const Result = resObj.data.Info;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }



// // Get Vertical List
// export async function getVerticalListPlan(reqBody = {}, options = {}) {
//   try {
//     const { ...fullReqBodyData } = reqBody;
//     const apiReqBody = { ...fullReqBodyData };

//     let token = await generateEntitlementRegistry();

//     const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getVerticalPlan;
//     let headers = {
//       Authorization: `Bearer ${token}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     // const resObj = await axiosWitmegRetailPaxServerInstance({
//     //   url: apiReqUrl,
//     //   method: "POST",
//     //   data: apiReqBody,
//     // });

//     const resObj = await axios({
//       method: "GET",
//       url: apiReqUrl,
//       data: apiReqBody,
//       headers,
//     });


//     const Result = resObj.data.Result;
//     // if (!Status) {
//     //   throw new APIError(Result);
//     // }
//     // console.log("Result");
//     // console.log(Result);
//     return Result;
//   } catch (error) {
//     let customErrMsg = error.customErrMsg || "";
//     if (error.custmErrType === "APIERROR") {
//       switch (true) {
//         default: {
//           customErrMsg = "Error Occurred. Please try again.";
//           break;
//         }
//       }
//     }
//     errorLogger(error, { customErrMsg });
//     return Promise.reject(APIError(customErrMsg, error));
//   }
// }

// customer message send


export async function sendMail(reqBody = {}) {
	try {
		const {Channel, ServiceOrigin, EmailType, TemplateName, ToName, To, FromName, From, Subject, PlainTextContent, HtmlContent } = reqBody;
		
		const data = {
			Channel,
			ServiceOrigin,
			EmailType,
			TemplateName,
			ToName,
			To,
			FromName,
			From,
			Subject,
			PlainTextContent,
			HtmlContent
		};

		const token = await generateTokenForEmailRegistry(false);

		const options = {
			headers: {
				'Authorization': `Bearer ${token}`,
				"Content-type": "application/json; charset=UTF-8",
			},
		}

		const resObj = await axios.post(`${process.env.REACT_APP__EMAILREGISTRY__BASEURL}/v1/email/add`, data, options);

		console.log("resObj", resObj);

		const { Status, Result } = resObj.data;

		if (!Status) {
      console.log(Status)
		}

		return {Result: Result, Status:Status };
	} catch (error) {
    console.log(error)
	}
}