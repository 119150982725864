import { Space } from 'antd/lib'
import React, { useState, useEffect } from 'react'
import { Carousel, Row, Col, Typography, Checkbox } from 'witmeg-ui-system'
import { useSelector, useDispatch } from 'react-redux'
import ProductItem from './ProductItem'
import axios from 'axios';
import config from 'react-global-configuration';
import {setSearchCriteria} from '../../../redux/retailDataSlice'
import { Skeleton, Pagination, Slider, Switch } from 'antd';

export default function ProductsByCategory(props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [itemCount, setItemCount] = useState(12);
    const [currentPage, setCurrentPage] = useState(1)
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [loadingCategories, setLoadingCategories] = useState(true)
    const [productList, setProductList] = useState([])
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])

    const [availableFilterList, setAvailableFilterList] = useState(null)

    const dispatch=useDispatch()
    const settingsState = useSelector((state) => state.companyData.settings)

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);

       dispatch(setSearchCriteria({
            name: props.name || '',
            categoryId:props.categoryId ? Number(props.categoryId) : null
        }))

        if (settingsState.OrgID && settingsState.SalesLocationID) {
            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };
                let OrgID = settingsState.OrgID //config.get("OrganisationID")
                let SalesLocationID = settingsState.SalesLocationID

                let countUrl = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/count/${OrgID}/${SalesLocationID}`;
                axios.post(
                    countUrl,
                    {
                        name: props.name || '',
                        ProductCategoryIDs: props.categoryId ? Number(props.categoryId) : null
                    },
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setItemCount(Number(data.data.Result))
                        }
                    })

                let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${OrgID}/${SalesLocationID}/0/12`;
                console.log("featured url", url)
                axios.post(
                    url,
                    {
                        name: props.name || '',
                        ProductCategoryIDs: props.categoryId ? Number(props.categoryId) : null
                    },
                    requestOptions,
                )
                    .then((data) => {
                        console.log("featured res", data)
                        if (data.data.Status) {
                            setProductList(data.data.Result)
                            setLoadingProducts(false)
                        }
                        if (data.data.ProductFilter.ProductFilterControls) {
                            let filterControlls = Object.keys(data.data.ProductFilter.ProductFilterControls)
                            // setAvailableFilterList(filterControlls)
                            setAvailableFilterList({
                                "PriceRanges": [
                                    [
                                        {
                                            "Key": "_id",
                                            "Value": null
                                        },
                                        {
                                            "Key": "MaxPrice",
                                            "Value": 46
                                        },
                                        {
                                            "Key": "MinPrice",
                                            "Value": 10.29
                                        }
                                    ]
                                ],
                                "Weight": [
                                    "1 Kg",
                                    "10 Kg",
                                    "10KG",
                                    "1KG",
                                    "2 KG",
                                    "2 Kg",
                                    "20KG",
                                    "2KG",
                                    "5 KG",
                                    "5 Kg",
                                    "5KG"
                                ],
                                "ProductCategoryIDs": null,
                                "ProductSubCategoryIDs": [],
                                "BrandIDs": [
                                    9,
                                    10
                                ],
                                "CustomArrays": {
                                    "Colour": [],
                                    "Size": []
                                },
                                "ProductFilterControls": {
                                    "Price": {
                                        "ControlName": "Price",
                                        "ControlLabel": "Price",
                                        "ControlType": "Slider",
                                        "ControlSelectionType": "Many"
                                    },
                                    "ProductCategory": {
                                        "ControlName": "ProductCategory",
                                        "ControlLabel": "Product Category",
                                        "ControlType": "CheckBox",
                                        "ControlSelectionType": "Many"
                                    },
                                    "ProductSubCategory": {
                                        "ControlName": "ProductSubCategory",
                                        "ControlLabel": "Product Sub Category",
                                        "ControlType": "CheckBox",
                                        "ControlSelectionType": "Many"
                                    },
                                    "Brand": {
                                        "ControlName": "Brand",
                                        "ControlLabel": "Brand",
                                        "ControlType": "CheckBox",
                                        "ControlSelectionType": "Many"
                                    },
                                    "Weight": {
                                        "ControlName": "Weight",
                                        "ControlLabel": "Weight",
                                        "ControlType": "CheckBox",
                                        "ControlSelectionType": "Many"
                                    },
                                    "CustomArray": [
                                        {
                                            "ControlName": "Colour",
                                            "ControlLabel": "Colour",
                                            "ControlType": "Colour Plate",
                                            "ControlSelectionType": "Many"
                                        },
                                        {
                                            "ControlName": "Size",
                                            "ControlLabel": "Size",
                                            "ControlType": "CheckBox",
                                            "ControlSelectionType": "Many"
                                        }
                                    ]
                                }
                            })
                        }
                    })


                axios.get(`${process.env.REACT_APP_rest_API_productregistry}/onlinecategories/${OrgID}`, requestOptions).then((categoryData) => {
                    // console.log("featured categoryData", categoryData)
                    if (categoryData.data.Status) {
                        setCategories(categoryData.data.Result)
                        setLoadingCategories(false)
                    }
                })

                axios.get(`${process.env.REACT_APP_rest_API_productregistry}/onlinebrands/${OrgID}`, requestOptions).then((brandData) => {
                    // console.log("featured categoryData", categoryData)
                    if (brandData.data.Status) {
                        setBrands(brandData.data.Result)
                    }
                })

            });
        }



        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };

    }, [settingsState])

    const fetchProducts = (pageNumber) => {
        axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServerRequest: false,
            serviceName: "productRegistry",
        }).then(async function (authresponse) {
            setLoadingProducts(true)
            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authresponse.data.token}`,
                }
            };

            let OrgID = settingsState.OrgID //config.get("OrganisationID")
            let SalesLocationID = settingsState.SalesLocationID
            let url = process.env.REACT_APP_rest_API_productregistry_V2 + `/smartfilter/pagination/${OrgID}/${SalesLocationID}/${(pageNumber - 1) * 12}/12`;
            console.log("featured url", url)
            axios.post(
                url,
                {
                    name: props.name || '',
                    ProductCategoryIDs: props.categoryId ? Number(props.categoryId) : null
                },
                requestOptions,
            )
                .then((data) => {
                    console.log("featured res", data)
                    if (data.data.Status) {
                        setProductList(data.data.Result)
                        setLoadingProducts(false)
                    }
                })
        })
    }


    const onChangePageNumber = (page, pageSize) => {
        setCurrentPage(page)
        fetchProducts(page)
        props.updateQueryParams({ 'page': page })
    }


    return (
        <>
            <div style={{ maxWidth: '1650px', margin: 'auto' }}>
                <Typography type="title" level={3}></Typography>
                <div>
                    <Row>
                        <Col lg={6} md={8} sm={24} xs={24}>
                            <br />
                            <h3 className='eco-ml-8'>Filters </h3>
                            <hr />
                            {availableFilterList && availableFilterList.PriceRanges && availableFilterList.PriceRanges.length > 0 ?
                                <div className='eco-ma-8'>
                                    <h4>Price</h4>
                                    <Slider
                                        range
                                        defaultValue={[0, 100]}
                                        marks={{
                                            0: '0',//availableFilterList.PriceRanges && availableFilterList.PriceRanges.find(p => p.key==='MinPrice').Value, 
                                            100: '100' //availableFilterList.PriceRanges && availableFilterList.PriceRanges.find(p => p.key==='MaxPrice').Value}}
                                        }}
                                    />
                                </div>
                                : null
                            }

                            {availableFilterList && availableFilterList.Weight && availableFilterList.Weight.length > 0 ?
                                <div className='eco-ma-8'>
                                    <h4>Weight</h4>
                                    {availableFilterList.Weight.map((w, wi) => {
                                        return (
                                            <div><Checkbox /> <span>{w}</span></div>
                                        )
                                    })}
                                </div>
                                : null
                            }
                            {
                                availableFilterList && availableFilterList.BrandIDs && availableFilterList.BrandIDs.length > 0 ?
                                    <div className='eco-ma-8'>
                                        <h4>Brand</h4>
                                        {availableFilterList.BrandIDs.map((w, wi) => {
                                            return (
                                                <div><Checkbox /> <span>{brands.find(b => b.BrandID === w).BrandName}</span></div>
                                            )
                                        })}
                                    </div>
                                    : null
                            }
                            {/* {availableFilterList && Object.keys(availableFilterList?.ProductFilterControls).map((f, i) => {
                                return (
                                    <div key={`filterHead${i}`} className='eco-ml-8'>
                                        <h4>{f.ControlLabel || f}</h4>
                                        <br />
                                    </div>
                                )
                            })} */}
                        </Col>

                        <Col lg={18} md={16}>
                            {loadingProducts ?
                                <>
                                    <br />
                                    <Row gutter={16}>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                        <Col lg={6} md={8} sm={12} xs={24}><Skeleton /></Col>
                                    </Row>
                                </> :
                                <Row>

                                    {productList.map((p) => {
                                        return (
                                            <Col key={p.ID} xl={6} lg={8} md={12} sm={12} xs={24}>
                                                <div >
                                                    <ProductItem product={p} isFeatured={false} isOffers={false} />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>

                            }
                            <Row>
                                <Col>
                                    <Pagination current={currentPage} total={itemCount} defaultPageSize={12} onChange={onChangePageNumber} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <br />
        </>
    )
}

