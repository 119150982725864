import React from 'react';
import { Modal, Select, Form, Button, Radio, Divider, Steps, Checkbox, Popover, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import config from 'react-global-configuration';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import jwt from 'jsonwebtoken';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import axios from 'axios';
import {
    CloseOutlined
} from '@ant-design/icons';
const content = (
    <div>
        <p>By checking this box you are requesting to<br />
        sign for your delivery and should ensure <br />
        someone is home to receive it. Occasionally <br />
        our delivery partners may still choose to<br />
        require a signature even if this box is not<br /> selected.</p>
    </div>
);

const { Option } = Select;
const CheckoutApparel = (props, ref) => (
    <div className="ps-checkout ps-section--shopping">
        <div className="container">
            <div className="ps-section__header">
                <h1>Checkout Information</h1>
            </div>
            <div className="ps-section__content">
                <Form
                    {...props.layout}
                    name="basic"
                    className="ps-form--checkout"
                    initialValues={{ remember: true }}
                    onFinish={props.onFinish}
                    onFinishFailed={props.onFinishFailed}
                >
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12  ">
                            {
                                sessionStorage.getItem('deliverytype') === 1 ? (
                                    <> The person collecting your order will need to bring a copy of the order confirmation and suitable identification such as a photo ID. Once payment is approved or unless paying by cash, your order will be ready for collection in store within our store opening time. We will contact you by email or phone when your full order is ready to be picked up.</>

                                ) : (
                                        <>

                                            <div className="fade alert alert-light show">


                                                <Divider orientation="left"><h5>SELECT OR CREATE A DELIVERY ADDRESS</h5></Divider>


                                                <Row>
                                                    <Col sm={12}>


                                                    </Col>
                                                    <Col sm={8}>

                                                        <b>USE A SAVED ADDRESS</b>


                                                    </Col>
                                                    <Col sm={4}>

                                                        <Button color="primary" onClick={props.showModal.bind(this, 100)} style={{ marginBottom: '1rem' }}>
                                                            Create a new address
                </Button>



                                                    </Col>

                                                    <Col sm={12}>
                                                        <Radio.Group onChange={props.onChange} value={props.params.value}>

                                                            {

                                                                JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                    return (
                                                                        <>
                                                                            {address.AddressType === "Delivery" ? (

                                                                                <>
                                                                                    <Radio value={index}>
                                                                                        {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                        {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                        {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                        {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                        {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                        {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                        {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                        {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                        {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                        {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                    </Radio>
                                                                                    <hr />
                                                                                </>
                                                                            ) : ''

                                                                            }
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </Radio.Group>


                                                    </Col>
                                                    <Col sm={12}>
                                                        <Divider orientation="left"><h5>DELIVERY INSTRUCTIONS</h5></Divider>
                                                        <Form.Item
                                                            name="signature"
                                                            label=""
                                                        >
                                                            <Checkbox onChange={props.onDeliveryTickChange} >Delivery requires signature   <Popover content={content} className="homeMessage" title="Delivery requires signature">
                                                                <u>What is this?</u>
                                                            </Popover></Checkbox>
                                                        </Form.Item>



                                                        <Form.Item
                                                            name="DeliveryIns"
                                                            label="Delivery Instructions"
                                                        >
                                                            <Input placeholder="e.g. Knock at the back door" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col sm={4}>
                                                    </Col>
                                                    <Col sm={4}>

                                                        <Button type="primary" htmlType="submit" danger >

                                                            Proceed to Payment
            </Button>
                                                    </Col>




                                                </Row>

                                            </div>
                                            <Modal
                                                visible={props.params.visible}
                                                title="NEW DELIVERY ADDRESS INFORMATION"
                                                width="1000px"
                                                onCancel={props.handleCancel}
                                                footer={[
                                                    <Button key="back" onClick={props.handleCancel}>
                                                        Return
                    </Button>,

                                                ]}
                                            >

                                                <>

                                                    <Form layout="vertical"
                                                        name="register"
                                                        ref={props.formRef}
                                                        onFinish={props.onCreateAddress} >

                                                        <Form.Item
                                                            name="SelectAddress"
                                                            extra="Please click below add new button to save the address"
                                                            label="Select an existing address to add"
                                                        >

                                                            <Select
                                                                onChange={(val) => props.selectAddress(this, val)}
                                                            >
                                                                {

                                                                    JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                        return (
                                                                            <>

                                                                                {
                                                                                    <>

                                                                                        {address.AddressType === "Billing" ? (

                                                                                            <Option value={index}>
                                                                                                {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                            </Option>

                                                                                        ) : ''
                                                                                        }
                                                                                    </>


                                                                                }
                                                                            </>
                                                                        );
                                                                    })
                                                                }


                                                            </Select>

                                                        </Form.Item>


                                                        <Form.Item
                                                            name="AddressType"
                                                            label="Address Type"
                                                            initialValue="Delivery"
                                                            rules={[{ required: true, message: 'Please enter a address type' }]}
                                                        >

                                                            <Select   >

                                                                <Option value="Delivery">Delivery</Option>

                                                            </Select>

                                                        </Form.Item>


                                                        <Form.Item
                                                            name="Address"
                                                            label="Address"
                                                            rules={[{ required: true, message: 'Please enter address' }]}
                                                        >
                                                            <Input id="Address" placeholder="Please enter address" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="Address2"
                                                            label="Address 2"
                                                            rules={[{ required: false, message: 'Please enter address 2' }]}
                                                        >
                                                            <Input placeholder="Please enter address 2" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="City"
                                                            label="City"
                                                            rules={[{ required: true, message: 'Please enter city' }]}
                                                        >
                                                            <Input placeholder="Please enter city" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="StateProvinceRegion"
                                                            label="State / Province / Region"
                                                            rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                                        >
                                                            <RegionDropdown
                                                                country={props.params.country}
                                                                value={props.params.region}
                                                                countryValueType="short"
                                                                classes="ant-input"
                                                                onChange={(val) => props.selectRegion(val)} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="PostZipCode"
                                                            label="Post / Zip Code"
                                                            rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                                        >
                                                            <Input placeholder="Please enter post / zip code" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="Country"
                                                            label="Country"
                                                            rules={[{ required: true, message: 'Please enter country' }]}
                                                        >
                                                            <CountryDropdown
                                                                value={props.params.country}
                                                                valueType="short"
                                                                classes="ant-input"
                                                                onChange={(val) => props.selectCountry(val)} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="Phone"
                                                            label="Phone"
                                                            rules={[{ required: true, message: 'Please enter valid phone' }]}
                                                        >
                                                            <Input placeholder="Please enter phone" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="Fax"
                                                            label="Fax"
                                                            rules={[{ message: 'Please enter valid fax' }]}
                                                        >
                                                            <Input placeholder="Please enter fax" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="Email"
                                                            label="Email"
                                                            rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                                        >
                                                            <Input placeholder="Please enter email" />
                                                        </Form.Item>

                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit" ref="btn" danger >
                                                                {props.disabled ? 'Please wait...' : 'Add New Address'}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>

                                                </>



                                            </Modal>
                                        </>
                                    )
                            }

                        </div>
                        <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12  ">
                            <div className="ps-form__total">
                                <h3 className="ps-form__heading">Your Order</h3>
                                <div className="ps-block--checkout-order">
                                    <div className="ps-block__content">
                                        <figure>
                                            <figcaption><strong>Product</strong><strong>Total</strong></figcaption>
                                        </figure>

                                        {
                                            props.params.basketitem.length ? (

                                                <table className="table ps-block__products">
                                                    <tbody>

                                                        {
                                                            props.params.basketitem.map(item => (
                                                                <tr>
                                                                    <td><a href="#"> {item.ProductName} ×{item.ProductQty}</a>
                                                                        <p>Sold By:<strong>{config.get('companyPageTitle')}</strong></p>
                                                                    </td>
                                                                    <td>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</td>
                                                                </tr>
                                                            )
                                                            )
                                                        }

                                                    </tbody>
                                                </table>
                                            ) : (
                                                    <span>
                                                        <h6>Your cart is empty</h6>
                                                        <p>Find hundred of quality products at bargain prices everyday!</p>
                                                    </span>
                                                )
                                        }

                                        <h4 className="ps-block__title">Subtotal: <span>{localStorage.getItem('ecurrencyCode')} {props.params.total}</span></h4>
                                        <div className="ps-block__shippings">



                                            {
                                                sessionStorage.getItem('deliverytype') === 1 ? (
                                                    <>

                                                        <figure>
                                                            <h4>Click and Collect</h4>
                                                            <p>Free shipping</p>
                                                        </figure>

                                                    </>
                                                ) : (
                                                        <>


                                                            <h4 className="ps-block__title">Delivery: <span>{localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}</span></h4>


                                                        </>
                                                    )
                                            }

                                        </div>

                                        {props.params.offer != 0 ? (<>


                                            <h4 className="ps-block__title">Offer Discount: <span>{localStorage.getItem('ecurrencyCode')} -{props.params.offer}</span></h4>
                                        </>) : ''
                                        }
                                        <h3>Total <span>{localStorage.getItem('ecurrencyCode')} {(parseFloat(props.params.total) - props.params.offer + parseFloat(sessionStorage.getItem('deliverycost'))).toFixed(2)}</span></h3>

                                    </div><a className="ps-btn ps-btn--fullwidth" href="checkout.html">Proceed to payment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>


            </div>
        </div>
    </div>


);
export default CheckoutApparel;
