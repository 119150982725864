import { getLocalStorageData, LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from "./helper";
import _ from 'lodash';
import { getAddonProductPrices, getRestaurantMenuQRCodeSettings, getRestaurantMenuSettingsData } from "./apiRequests/neutriPosServerRequests";

export const RESTAURANT_MENU_ITEM_STATUS = {
  ONLY_UNIT_CHART_DATA: 'only-unit-chart-data-available',
  ONLY_ADDONS_DATA: 'only-addons-data-available',
  NO_ADDITIONAL_DATA: 'no-additional-data',
  UNIT_CHART_WITH_ADDONS_DATA: 'unit-chart-with-addons-data',
};

export const RESTAURANT_SETTINGS_TYPES = {
  QR_CODE: 'QRCode',
  MAIN_ORDER_TYPES: {
    KEY: 'MainOrderTypes',
    OPT: {
      DINE_IN: {
        name: 'dineIn',
        icon: 'table',
        title: 'Dine-In',
        typeName: 'TABLE',
      },
      COLLECTION: {
        name: 'collection',
        icon: 'collection',
        title: 'Collection',
        typeName: 'TAKE AWAY',
      },
      DELIVERY: {
        name: 'delivery',
        icon: 'delivery-bike',
        title: 'Delivery',
        typeName: 'DELIVERY',
      },
      CUSTOMER_CHOICE: {
        title: 'Let customer choose',
        name: 'customer-choice',
      },
    },
  },
  CARD_TYPES: 'CardTypes',
  PAYMENT_METHODS: {
    KEY: 'PaymentMethods',
    OPT: {
      CASH: {
        name: 'cash',
        title: 'Cash',
      },
      CARD: {
        name: 'card',
        title: 'Card',
      },
    },
  },
  PAYMENT_METHODS_ORDER_TYPES: 'PaymentMethods_OrderTypes',
  PAYMENT_GATEWAY: 'PaymentGateway',
  EXTERNAL_API: 'ExternalAPI',
  AVAILABLE_OPTION: {
    KEY: 'AvailableOption',
    OPT: {
      CHECKOUT: {
        name: 'Checkout',
        title: 'Checkout',
        icon: 'delivery',
      },
      MENU_ONLY: {
        name: 'MenuOnly',
        title: 'Menu Only',
        icon: 'table',
      },
    },
  },
  AVAILABLE_LOCATIONS: 'AvailableLocations',
  DELIVERY_SETTING: 'DeliverySetting',
};

export const RESTAURANT_LAYOUTS = {
  LAYOUT_1: 'layout1',
  LAYOUT_2: 'layout2',
  LAYOUT_3: 'layout3',
  LAYOUT_4: 'layout4',
  LAYOUT_5: 'layout5',
  LAYOUT_6: 'layout6',
}

export const DividerStyleCategory = {
  DIVIDER_1: 'divider1',
  DIVIDER_2: 'divider2',
  DIVIDER_3: 'divider3',
  DIVIDER_4: 'divider4',
};

export const fetchRestaurantMenuSettings = async ({ type, cloudLocationsData }) => {
  const promises = cloudLocationsData.map(
    ({ CloudLocationID }) =>
      new Promise(async function (resolve, refuse) {
        resolve(
          (await getRestaurantMenuSettingsData({
            SettingType: type,
            CloudLocationID,
          })) ?? null
        );
      })
  );

  return await Promise.all(promises);
};

export const getRestaurantMenuCartData = async () => {
  const cartData = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA);
  let newCartData = {
    currency: 'GBP',
    items: [],
    subTotal: {},
    total: {},
    additions: {
      deliveryCharges: null,
      promoCode: '',
    },
    defaultOrderType: null,
    orderTypes: [],
    cloudLocationID: '',
    organizationID: '',
  };

  if (cartData && cartData.cloudLocationID) newCartData = cartData;

  return newCartData;
};

export const getRestaurantMenuCurrency = async () => {
  const _currency = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_MENU_CURRENCY);
  const _newCurrency = "GBP";

  if (_currency) return _currency;

  return _newCurrency;
}

export const changeRestaurantMenuCurrency = async (_currency) => {
  await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_MENU_CURRENCY, _currency);
}

export const getAdditionSubTotals = (_subTotal1, _subTotal2) => {
  if (!_subTotal1) return _subTotal2;
  if (!_subTotal2) return _subTotal1;

  return {
    delivery: {
      amount: _subTotal1.delivery.amount + _subTotal2.delivery.amount,
      vatAmount: _subTotal1.delivery.vatAmount + _subTotal2.delivery.vatAmount,
    },
    collection: {
      amount: _subTotal1.collection.amount + _subTotal2.collection.amount,
      vatAmount: _subTotal1.collection.vatAmount + _subTotal2.collection.vatAmount,
    },
    dineIn: {
      amount: _subTotal1.dineIn.amount + _subTotal2.dineIn.amount,
      vatAmount: _subTotal1.dineIn.vatAmount + _subTotal2.dineIn.vatAmount,
    },
  };
};

export const getProductItemDataForPopup = async (data) => {
  const _addonDataCompiler = async (_addons) => {
    const _addonsIds = _addons.map((_item) => _item.ProductID);
    const _response = await getAddonProductPrices({ ProductIDs: _addonsIds });

    return _addons.map((__item) => {
      const __addonItemData = _.find(_response, { ID: __item.ProductID });
      const __addonProductPriceData = _.find(__addonItemData.ServicePriceInfo.Price, { ServiceProvider: 'Restaurant' });
      const __addonProductPrices = {
        delivery: __addonProductPriceData.DeliveryPrice,
        collection: __addonProductPriceData.TakeAwayPrice,
        dineIn: __addonProductPriceData.TablePrice,
      };

      const _addonVat = getRestaurantProductVatValues({
        isInclusive: true,
        isGeneralVat: __addonItemData.VATRate ? true : false,
        prices: __addonProductPrices,
        vatRates: {
          delivery: __addonItemData.DeliveryVatRate ?? 0,
          collection: __addonItemData.TakeAwayVatRate ?? 0,
          dineIn: __addonItemData.TableVatRate ?? 0,
          general: __addonItemData.VatRate ?? 0,
        },
      });

      return {
        productID: __item.ProductID,
        localProductID: __item.LocalProductID,
        name: __item.Name.toCapitalFirst(),
        onlineName: __item.OnlineName.toCapitalFirst(),
        groupName: __item.GroupName,
        localAttachmentID: __item.LocalAttachmentID,
        groupItemName: __item.GroupItemName,
        onlineItemName: __item.OnlineItemName,
        addonID: __item.ID,
        prices: __addonProductPrices,
        vat: _addonVat,
      };
    });
  };

  if (!data || data.ProductName === '' || !data.ProductData || !data.ProductData.ID) return null;

  let unitChartData = (!data.ProductData.UnitChartInfo || data.ProductData.UnitChartInfo.length > 0) ? data.ProductData.UnitChartInfo : null;
  let addonsConfigs = data.AddonInfo ?? null;
  let addonsData = data.AddonsData ?? null;
  let productStatus = RESTAURANT_MENU_ITEM_STATUS.NO_ADDITIONAL_DATA;
  const productPriceData = _.find(data.ProductData.ServicePriceInfo.Price, { ServiceProvider: 'Restaurant' });
  const productPrices = {
    delivery: productPriceData.DeliveryPrice,
    collection: productPriceData.TakeAwayPrice,
    dineIn: productPriceData.TablePrice,
  };
  const productVatRates = {
    delivery: data.ProductData.DeliveryVATRate ?? 0,
    collection: data.ProductData.TakeAwayVATRate ?? 0,
    dineIn: data.ProductData.TableVATRate ?? 0,
    general: data.ProductData.VATRate ?? 0,
  };
  const productVat = getRestaurantProductVatValues({
    isInclusive: true,
    isGeneralVat: productVatRates.general ? true : false,
    prices: productPrices,
    vatRates: productVatRates
  });
  let autoShowAddons = false;

  /* Checks for the Unit chart items */
  if (unitChartData && unitChartData.length > 0) {
    let newData = [];
    productStatus = RESTAURANT_MENU_ITEM_STATUS.ONLY_UNIT_CHART_DATA;

    for (const item of unitChartData) {
      const _ucItemPriceData = _.find(item.ServicePriceInfo, { ServiceProvider: 'Restaurant' });
      const _ucProductPrices = {
        delivery: _ucItemPriceData.DeliveryPrice,
        collection: _ucItemPriceData.TakeAwayPrice,
        dineIn: _ucItemPriceData.TablePrice,
      };

      const _ucItemVat = getRestaurantProductVatValues({
        isInclusive: true,
        isGeneralVat: productVatRates.general ? true : false,
        prices: _ucProductPrices,
        vatRates: productVatRates
      });

      let _itemAddonsData = item.AddonsData ?? null;

      /* Checks for addons data inside of thr unit chart item */
      if (_itemAddonsData && _itemAddonsData.length > 0) {
        productStatus = RESTAURANT_MENU_ITEM_STATUS.UNIT_CHART_WITH_ADDONS_DATA;
        _itemAddonsData = await _addonDataCompiler(_itemAddonsData);
      }

      autoShowAddons = item.AutoShowAddons;

      const _itemData = {
        unitChartLocalID: item.LocalID,
        // name: item.ItemName.Translations,
        typeName: item.UnitType.Type.toCapitalFirst(),
        prices: _ucProductPrices,
        configs: item.AddonInfo,
        addonsData: _itemAddonsData,
        unitChartTypeID: item.UnitTypeID,
        unitQuantity: item.Quantity,
        vat: _ucItemVat,
      };
      newData.push(_itemData);
    }

    unitChartData = newData;
  }

  /* Checks for addons data */
  if (addonsData && addonsData.length > 0) {
    productStatus = RESTAURANT_MENU_ITEM_STATUS.ONLY_ADDONS_DATA;
    addonsData = await _addonDataCompiler(addonsData);
  }

  /* 
      product name
      unit chart data with total
      addons data with total
      special instructions
  */
  const productData = {
    id: data.ID,
    name: data.ProductName.toCapitalFirst() ?? {},
    description: data.ProductData.ItemDescription.Translations.en.toCapitalFirst() ?? {},
    image: (data?.ProductData?.ImageURL && data?.ProductData?.ImageURL.POS && data?.ProductData?.ImageURL.POS.length > 0) ? data?.ProductData?.ImageURL.POS[0] : null,
    mainMenuID: data.MainMenuID,
    subMenuID: data.SubMenuID,
    instructionData: data.InstructionData,
    productID: data.ProductData.ID,
    localProductID: data.ProductData.LocalID,
    groupID: data.GroupID,
    prices: productPrices,
    unitChartData,
    addonsConfigs,
    addonsData: addonsData,
    vat: productVat,
    status: productStatus,
    autoShowAddons,
  };

  return productData;
}

export const calculateRestaurantMenuCartTotal = (cartData) => {
  let _deliveryTotal = { amount: 0, vatAmount: 0 };
  let _collectionTotal = { amount: 0, vatAmount: 0 };
  let _dineInTotal = { amount: 0, vatAmount: 0 };
  // let _deliverySubTotal = { amount: 0, vatAmount: 0 };
  // let _collectionSubTotal = { amount: 0, vatAmount: 0 };
  // let _dineInSubTotal = { amount: 0, vatAmount: 0 };

  for (const __item of cartData.items) {
    _deliveryTotal = {
      amount: _deliveryTotal.amount + __item.selection.total.delivery.amount,
      vatAmount: _deliveryTotal.vatAmount + __item.selection.total.delivery.vatAmount,
    };
    // _deliverySubTotal = {
    //     amount: _deliverySubTotal.amount + __item.selection.subTotal.delivery.amount,
    //     vatAmount: _deliverySubTotal.vatAmount + __item.selection.subTotal.delivery.vatAmount,
    // };

    // _collectionSubTotal = {
    //     amount: _collectionSubTotal.amount + __item.selection.subTotal.collection.amount,
    //     vatAmount: _collectionSubTotal.vatAmount + __item.selection.subTotal.collection.vatAmount,
    // };
    _collectionTotal = {
      amount: _collectionTotal.amount + __item.selection.total.collection.amount,
      vatAmount: _collectionTotal.vatAmount + __item.selection.total.collection.vatAmount,
    };

    // _dineInSubTotal = {
    //     amount: _dineInSubTotal.amount + __item.selection.subTotal.dineIn.amount,
    //     vatAmount: _dineInSubTotal.vatAmount + __item.selection.subTotal.dineIn.vatAmount,
    // };
    _dineInTotal = {
      amount: _dineInTotal.amount + __item.selection.total.dineIn.amount,
      vatAmount: _dineInTotal.vatAmount + __item.selection.total.dineIn.vatAmount,
    };
  }

  const _subTotal = {
    delivery: { ..._deliveryTotal },
    collection: _collectionTotal,
    dineIn: _dineInTotal
  }

  if (cartData.additions.deliveryCharges && cartData.additions.deliveryCharges.cost) {
    _deliveryTotal.amount += cartData.additions.deliveryCharges.cost;
  }

  const _total = {
    delivery: _deliveryTotal,
    collection: _collectionTotal,
    dineIn: _dineInTotal,
  };

  return {
    ...cartData,
    subTotal: _subTotal,
    total: _total,
  };
};

export const getRestaurantMenuProductVatValue = (isInclusive, price, vatRate) => {
  if (price === 0 || vatRate === 0) return 0;

  if (isInclusive) {
    return _.round(price - (price / (1 + vatRate)), 2);
  } else {
    return _.round(price * vatRate, 2);
  }
};

// export const calculateProductPriceWithQuantity = (price, quantity, vat, isInclusive) => {
//   if (isInclusive) {
//     return price * quantity;
//   } else {
//     return price * vat * quantity;
//   }
// };

export const calculateProductTotals = (price, quantity, vat, isInclusive) => {
  const amount = price * quantity;
  const vatAmount = getRestaurantMenuProductVatValue(isInclusive, amount, vat);

  // if (isInclusive) {
  //   amount = price * quantity;
  // } else {
  //   amount = (price + (price * vat)) * quantity;
  //   vatAmount = getRestaurantMenuProductVatValue(isInclusive, amount, vat);
  // }
  return { amount, vatAmount };
};

export const calculateProductPriceWithQuantity = (price, quantity, vat, isInclusive) => {
  let priceWVat = 0;
  let priceWOVat = 0;

  if (isInclusive) {
    priceWVat = price * quantity;
  } else {
    priceWOVat = price * quantity;
    priceWVat = price * vat * quantity;
  }

  return { priceWVat, priceWOVat };
};

export const getRestaurantProductVatValues = ({ prices = { dineIn: 0, delivery: 0, collection: 0 }, isInclusive = true, isGeneralVat = false, vatRates = { delivery: 0, collection: 0, dineIn: 0, general: 0 } }) => {
  let deliveryVatValue = 0;
  let collectionVatValue = 0;
  let dineInVatValue = 0;
  let generalVatValue = {
    delivery: 0,
    collection: 0,
    dineIn: 0,
  };

  if (prices.delivery && prices.delivery !== 0 && vatRates && vatRates.delivery && vatRates.delivery !== 0) {
    deliveryVatValue = getRestaurantMenuProductVatValue(isInclusive, prices.delivery, vatRates.delivery);

    if (vatRates.general && vatRates.general !== 0) {
      generalVatValue.delivery = getRestaurantMenuProductVatValue(isInclusive, prices.delivery, vatRates.general);
    }
  }

  if (prices.collection && prices.collection !== 0 && vatRates && vatRates.collection && vatRates.collection !== 0) {
    collectionVatValue = getRestaurantMenuProductVatValue(isInclusive, prices.collection, vatRates.collection);

    if (vatRates.general && vatRates.general !== 0) {
      generalVatValue.collection = getRestaurantMenuProductVatValue(isInclusive, prices.collection, vatRates.general);
    }
  }

  if (prices.dineIn && prices.dineIn !== 0 && vatRates && vatRates.dineIn && vatRates.dineIn !== 0) {
    dineInVatValue = getRestaurantMenuProductVatValue(isInclusive, prices.dineIn, vatRates.dineIn);

    if (vatRates.general && vatRates.general !== 0) {
      generalVatValue.dineIn = getRestaurantMenuProductVatValue(isInclusive, prices.dineIn, vatRates.general);
    }
  }

  return {
    isInclusive: isInclusive,
    isGeneralVat,
    deliveryVat: {
      rate: vatRates.delivery,
      value: deliveryVatValue,
    },
    collectionVat: {
      rate: vatRates.collection,
      value: collectionVatValue,
    },
    dineInVat: {
      rate: vatRates.dineIn,
      value: dineInVatValue,
    },
    generalVat: {
      rate: vatRates.general,
      deliveryVatValue: generalVatValue.delivery,
      collectionVatValue: generalVatValue.collection,
      dineInVatValue: generalVatValue.dineIn,
    },
  };
}

const getProductTotalCalculations = (_price, _qty, _vat, _otVat) =>
  calculateProductTotals(_price, _qty, _vat.isGeneralVat ? _vat.generalVat.rate : _otVat, _vat.isInclusive);

export const updateRestaurantMenuCartItemQuantity = async (_cartData, _index, _increase = true) => {
  let _cartItem = _cartData.items[_index];

  let _newQuantity = _cartItem.selection.quantity;
  if (!_increase && _newQuantity <= 1) return;

  if (_increase) _newQuantity += 1;
  if (!_increase) _newQuantity -= 1;

  const _prices = _cartItem.selection.prices;
  const _vat = _cartItem.selection.vat;

  const _newSubTotal = {
    delivery: getProductTotalCalculations(_prices.delivery, _newQuantity, _vat, _vat.deliveryVat.rate),
    collection: getProductTotalCalculations(_prices.collection, _newQuantity, _vat, _vat.collectionVat.rate),
    dineIn: getProductTotalCalculations(_prices.dineIn, _newQuantity, _vat, _vat.dineInVat.rate),
  };

  let _newTotal = _newSubTotal;
  if (_cartItem.selection.addons && _cartItem.selection.addons.items && _cartItem.selection.addons.items.length > 0) {
    _newTotal = getAdditionSubTotals(_newSubTotal, _cartItem.selection.addons.subTotal);
  }

  const _updatedCartItem = {
    ..._cartItem,
    selection: {
      ..._cartItem.selection,
      quantity: _newQuantity,
      subTotal: _newSubTotal,
      total: _newTotal,
    }
  };

  let _newCartItems = _cartData.items.map((__value, __index) => __index === _index ? _updatedCartItem : __value);

  const _newCartData = calculateRestaurantMenuCartTotal({ ..._cartData, items: _newCartItems });

  await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
}

export const updateRestaurantMenuCartItemAddonsQuantity = async (_cartData, _index, _addonIndex, _increase = true) => {
  // TODO: need to implement
}

export const deleteRestaurantMenuCartItem = async (_cartData, _index) => {
  const _newCartItems = _cartData.items.filter((__, __i) => __i !== _index);
  const _newCartData = calculateRestaurantMenuCartTotal({ ..._cartData, items: _newCartItems });

  await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
}

export const getOrderTypeData = async (_locations) => {
  let _orderTypeData = [];

  if (_locations && _locations.length > 0) {
    const _orderTypeSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
      cloudLocationsData: _locations
    });

    for (const _loc of _locations) {
      const _orderTypes = _orderTypeSettings.flat().filter(_ot => _ot.CloudLocationID === _loc.CloudLocationID);
      _orderTypeData.push(_orderTypes);
    }

    return _orderTypeData;
  }
}

export const getRestaurantQRCodeSettings = async (_locationID, _organizationid) => {
  if (!_locationID) return null;

  const _qrCodeSettings = await getRestaurantMenuQRCodeSettings({ CloudLocationID: _locationID, OrganizationID: _organizationid });

  if (!_qrCodeSettings || !_qrCodeSettings.ID || _qrCodeSettings.ID === '') return null;

  return {
    qrURLs: {
      delivery: _qrCodeSettings.DeliveryQRCode,
      collection: _qrCodeSettings.TakeawayQRCode,
      table: _qrCodeSettings.TableQRCodes,
    },
    logo: '',
  };
};